import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import ClavaButton from "../Atoms/ClavaButton";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import Overlay from "./Overlay";

const modalRoot = document.getElementById("root") as HTMLElement;

const AsideModal: React.FC<React.PropsWithChildren<{
  close: () => void,
  saveClb?: () => void,
  saveText?: string,
  saveDisabled?: boolean,
  title: string
}>> = ({ close, title, saveDisabled, children, saveClb, saveText }) => {
  const { l } = useContext(ClavaContext);
  return ReactDOM.createPortal(
    <React.Fragment>
      <Overlay close={close} />
      <div
        className="fixed right-0 top-0 h-screen rounded-l-xl min-w-96 bg-white dark:bg-bg-dark flex flex-col items-stretch justify-start">
        <div
          className="flex flex-row items-center justify-between px-4 pt-4 pb-2 border-b border-b-light-gray dark:border-b-light-gray-dark">
          <h3 className="font-semibold text-xl">{title}</h3>
          <button className="border-none bg-none outline-0 p-2" onClick={close}>
            <FontAwesomeIcon icon={faClose} size="lg" />
          </button>
        </div>
        <div
          className="px-4 py-2 overflow-y-scroll flex-1 flex flex-col items-stretch justify-start">
          {children}
        </div>
        {!!saveClb && (
          <div className="px-4 py-2 flex items-center justify-center">
            <ClavaButton onClick={saveClb} disabled={saveDisabled}>
              {saveText ?? translate("save", l)}
            </ClavaButton>
          </div>
        )}
      </div>
    </React.Fragment>, modalRoot
  );
};

export default AsideModal;