import React, { useCallback, useContext, useMemo, useState } from "react";
import { translate } from "../../config/translator";
import { useBlocker } from "react-router";
import { ClavaContext, SiteBlockerContext } from "../../config/contexts";
import MessageBox from "./MessageBox";
import { SiteBlockerContextType } from "../../config/types";

const SiteBlocker: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { l } = useContext(ClavaContext);
  const [isBlocking, setIsBlocking] = useState(false);
  const blocker = useBlocker(isBlocking);
  const toggle = useCallback(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
  }, [blocker]);
  const proceed = useCallback(() => {
    if (blocker.state === "blocked") {
      blocker.proceed();
      setIsBlocking(false);
    }
  }, [blocker]);
  const contextValue = useMemo<SiteBlockerContextType>(() => ({ block: setIsBlocking }), []);
  return (
    <SiteBlockerContext.Provider value={contextValue}>
      {children}
      <MessageBox open={blocker.state === "blocked"} toggle={toggle} btn1Clb={toggle}
                  btn1Text={translate("stayHere", l)} btn2Clb={proceed}
                  btn2Text={translate("proceed", l)} title={translate("caution", l)}
                  type={"warning"}
                  msg={translate("sureLeave", l)}>
      </MessageBox>
    </SiteBlockerContext.Provider>
  );
};

export default SiteBlocker;