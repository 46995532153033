import React, { useContext, useEffect, useMemo, useState } from "react";
import { Blog } from "../../client/api";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import useEditParams from "../../hooks/useEditParams";
import { showTranslated } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import BlogEditForm from "./BlogEditForm";

const BlogEdit: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const { id, navNotFound } = useEditParams("blogId", "/news");
  const [blog, setBlog] = useState<Blog>();
  const { loading, call, status, StatusComponent } = useServer(true);
  useEffect(() => {
    if (id) {
      call("getNews", [id], "notFound").then(setBlog, navNotFound);
    } else {
      navNotFound();
    }
  }, [id, call, navNotFound]);
  const breadcrump = useMemo(() => {
    if (!blog) {
      return [];
    }
    return [
      showTranslated(blog.title, l)
    ];
  }, [blog, l]);

  return (
    <LoadingBlock isLoading={loading}>
      {!!status && !!status.key && StatusComponent}
      <Breadcrumps additional={breadcrump}></Breadcrumps>
      {!!blog &&
        <BlogEditForm blog={blog} changes={setBlog} />}

    </LoadingBlock>
  );
};
export default BlogEdit;