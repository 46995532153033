import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SiteBlockerContext } from "../config/contexts";
import { formatDate } from "../config/utils";
import { LanguageLocaleEnum } from "../client/api";

export declare type AdditionalCheck<T extends Record<string, any>, U extends string & keyof T> = (a: T[U], b: T[U]) => boolean;

function capitalizeProp<T extends string>(prop: T): Capitalize<T> {
  return prop.charAt(0).toUpperCase() + prop.slice(1) as Capitalize<T>;
}

type ChangeFunctionName<T extends string> = `onChange${Capitalize<T>}`;
type ChangeTransFunctionName<T extends string, L extends string> = `onChange${Capitalize<T>}${L}`;

export function changeFuncNames<T extends string>(prop: T): ChangeFunctionName<T> {
  return `onChange${capitalizeProp(prop)}`;
}

function changeTransFuncNames<T extends string, L extends string>(prop: T, lang: L): ChangeTransFunctionName<T, L> {
  return `onChange${capitalizeProp(prop)}${lang}`;
}

type StringChangeFunctions<Obj extends Record<string, any>, T extends string & (keyof Obj)> = Record<ChangeFunctionName<T>, (val: string | undefined) => void>
type BoolChangeFunctions<Obj extends Record<string, any>, T extends string & (keyof Obj)> = Record<ChangeFunctionName<T>, (val: boolean) => void>
type NumberChangeFunctions<Obj extends Record<string, any>, T extends string & (keyof Obj)> = Record<ChangeFunctionName<T>, (val: string | number) => void>
type DateChangeFunctions<Obj extends Record<string, any>, T extends string & (keyof Obj)> = Record<ChangeFunctionName<T>, (val: Date) => void>
type TransChangeFunctions<Obj extends Record<string, any>, T extends string & (keyof Obj), D extends string> =
  Record<ChangeTransFunctionName<T, D>, (val: string) => void>;

type ChangeName<T extends string> = `${T}Changed`;
type TransChangeName<T extends string, L extends string> = `${T}Changed${L}`;

export function changeNames<T extends string>(prop: T): ChangeName<T> {
  return `${prop}Changed`;
}

type Changed<Obj extends Record<string, any>, T extends string & (keyof Obj), L extends string> = Record<ChangeName<T> | TransChangeName<T, L>, boolean>;

type NumberValName<T extends string> = `${T}Val`;

export function numberValNames<T extends string>(prop: T): NumberValName<T> {
  return `${prop}Val`;
}

type NumberValues<Obj extends Record<string, any>, T extends string & (keyof Obj)> = Record<NumberValName<T>, string>;

type DateValNames<T extends string> = `${T}Date`;

function dateValNames<T extends string>(prop: T): DateValNames<T> {
  return `${prop}Date`;
}

type DateValues<Obj extends Record<string, any>, T extends string & (keyof Obj)> = Record<DateValNames<T>, Date>;

type TransValueNames<T extends string, L extends string> = `${T}${L}`;

function transValNames<T extends string, L extends string>(prop: T, lang: L): TransValueNames<T, L> {
  return `${prop}${lang}`;
}

type TransValues<Obj extends Record<string, any>, T extends string & (keyof Obj), L extends string> = Record<TransValueNames<T, L>, string>;

export function useFormData<
  FormObject extends Record<string, any>,
  Props extends string & keyof FormObject,
  PropsWFunc extends string & keyof FormObject,
  StringProps extends Props,
  BoolProps extends Props,
  NumberProps extends Props,
  DateProps extends Props,
  TransProps extends Props,
  MandatoryProps extends (Props | PropsWFunc)
>(
  initialValue: FormObject,
  props: Props[],
  propsWFunc: { [P in PropsWFunc]: AdditionalCheck<FormObject, P> },
  stringProps: StringProps[] = [],
  boolProps: BoolProps[] = [],
  numberProps: NumberProps[] = [],
  dateProps: DateProps[] = [],
  translationProps: TransProps[] = [],
  mandatoryProps: MandatoryProps[] = [],
  blockIfChanges = true
) {
  const { block } = useContext(SiteBlockerContext);
  const [formData, setFormData] = useState(initialValue);
  const [initial, setInitial] = useState(initialValue);

  const onChange = useCallback((prop: Props | PropsWFunc, val: FormObject[Props | PropsWFunc]) => {
    setFormData(fd => ({
      ...fd,
      [prop]: val
    }));
  }, []);
  const stringFunctions = useMemo(() => {
    const retval: StringChangeFunctions<FormObject, StringProps> = Object({});
    stringProps.forEach((prop) => {
      retval[changeFuncNames(prop)] = ((val: string | undefined) => {

        onChange(prop, val as FormObject[StringProps]);

      });
    });
    return retval;
  }, [stringProps, onChange]);
  const boolFunctions = useMemo(() => {
    const retval: BoolChangeFunctions<FormObject, BoolProps> = Object({});
    boolProps.forEach((prop) => {
      retval[changeFuncNames(prop)] = ((val: boolean) => {
        onChange(prop, val as FormObject[BoolProps]);
      });
    });
    return retval;
  }, [boolProps, onChange]);
  const numberFunctions = useMemo(() => {
    const retval: NumberChangeFunctions<FormObject, NumberProps> = Object({});
    numberProps.forEach((prop) => {
      retval[changeFuncNames(prop)] = ((val: string | number) => {
        if (typeof val === "string") {
          onChange(prop, parseFloat(val) as FormObject[NumberProps]);
        } else {
          onChange(prop, val as FormObject[NumberProps]);
        }
      });
    });
    return retval;
  }, [numberProps, onChange]);
  const dateFunctions = useMemo(() => {
    const retval: DateChangeFunctions<FormObject, DateProps> = Object({});
    dateProps.forEach((prop) => {
      retval[changeFuncNames(prop)] = ((val: Date) => {
        onChange(prop, formatDate(val, LanguageLocaleEnum.DE, true) as FormObject[DateProps]);
      });
    });
    return retval;
  }, [dateProps, onChange]);
  const transFunctions = useMemo(() => {
    const retval: TransChangeFunctions<FormObject, TransProps, "De" | "It" | "En"> = Object({});
    translationProps.forEach((prop) => {
      retval[changeTransFuncNames(prop, "De")] = (val: string) => {
        setFormData(fd => ({
          ...fd,
          [prop]: {
            ...fd[prop],
            textDE: val
          }
        }));
      };
      retval[changeTransFuncNames(prop, "It")] = (val: string) => {
        setFormData(fd => ({
          ...fd,
          [prop]: {
            ...fd[prop],
            textIT: val
          }
        }));
      };
      retval[changeTransFuncNames(prop, "En")] = (val: string) => {
        setFormData(fd => ({
          ...fd,
          [prop]: {
            ...fd[prop],
            textEN: val
          }
        }));
      };
    });
    return retval;
  }, [translationProps]);
  const [hasChanged, noChange1, allChange1] = useMemo(() => {
    const retval: Changed<FormObject, Props, "De" | "It" | "En"> = Object({});
    let nothingChangedT = true;
    let allChangedT = true;
    props.forEach((prop) => {
      let c = true;
      if (translationProps.includes(prop as TransProps)) {
        c = formData[prop].textDE === initial[prop].textDE;
        nothingChangedT = nothingChangedT && c;
        if (mandatoryProps.includes(prop as MandatoryProps))
          allChangedT = allChangedT && !c;
        retval[`${changeNames(prop)}De`] = !c;
        c = formData[prop].textIT === initial[prop].textIT;
        nothingChangedT = nothingChangedT && c;
        if (mandatoryProps.includes(prop as MandatoryProps))
          allChangedT = allChangedT && !c;
        retval[`${changeNames(prop)}It`] = !c;
        c = formData[prop].textEN === initial[prop].textEN;
        nothingChangedT = nothingChangedT && c;
        if (mandatoryProps.includes(prop as MandatoryProps))
          allChangedT = allChangedT && !c;
        retval[`${changeNames(prop)}En`] = !c;
      } else {
        c = formData[prop] === initial[prop];
        nothingChangedT = nothingChangedT && c;
        if (mandatoryProps.includes(prop as MandatoryProps))
          allChangedT = allChangedT && !c;
        retval[changeNames(prop)] = !c;
      }
    });
    return [retval, nothingChangedT, allChangedT];
  }, [mandatoryProps, formData, initial, props, translationProps]);
  const [additionalChanged, noChange2, allChange2] = useMemo(() => {
    const retval: Changed<FormObject, PropsWFunc, "De" | "It" | "En"> = Object({});
    let nothingChangedT = true;
    let allChangedT = true;
    (Object.keys(propsWFunc) as PropsWFunc[]).forEach((prop) => {
      const c = propsWFunc[prop](formData[prop], initial[prop]);
      nothingChangedT = nothingChangedT && c;
      if (mandatoryProps.includes(prop as MandatoryProps))
        allChangedT = allChangedT && !c;
      retval[changeNames(prop)] = !c;
    });
    return [retval, nothingChangedT, allChangedT];
  }, [mandatoryProps, propsWFunc, formData, initial]);
  const nothingChanged = useMemo(() => {
    return noChange2 && noChange1;
  }, [noChange2, noChange1]);
  const allChanged = useMemo(() => {
    return allChange1 && allChange2;
  }, [allChange1, allChange2]);
  const numberValues = useMemo(() => {
    const retval: NumberValues<FormObject, NumberProps> = Object({});
    numberProps.forEach((prop) => {
      if (Number.isNaN(formData[prop])) {
        retval[numberValNames(prop)] = "";
      } else {
        retval[numberValNames(prop)] = formData[prop]?.toString(10);
      }
    });
    return retval;
  }, [formData, numberProps]);
  const dateValues = useMemo(() => {
    const retval: DateValues<FormObject, DateProps> = Object({});
    dateProps.forEach((prop) => {
      retval[dateValNames(prop)] = new Date(formData[prop]);
    });
    return retval;
  }, [formData, dateProps]);
  const transValues = useMemo(() => {
    const retval: TransValues<FormObject, TransProps, "De" | "It" | "En"> = Object({});
    translationProps.forEach((prop) => {
      retval[transValNames(prop, "De")] = formData[prop].textDE;
      retval[transValNames(prop, "It")] = formData[prop].textIT;
      retval[transValNames(prop, "En")] = formData[prop].textEN;
    });
    return retval;
  }, [formData, translationProps]);
  useEffect(() => {
    if (blockIfChanges) {
      block(!nothingChanged);
    }
  }, [block, blockIfChanges, nothingChanged]);
  return {
    ...formData,
    ...numberValues,
    ...dateValues,
    ...transValues,
    setFormData,
    onChange,
    setInitial,
    ...hasChanged,
    ...additionalChanged,
    nothingChanged,
    allChanged,
    ...stringFunctions,
    ...boolFunctions,
    ...numberFunctions,
    ...dateFunctions,
    ...transFunctions
  };
}
