import React, { useCallback } from "react";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LeagueIcon from "../../components/Icons/LeagueIcon";
import { UserEssential } from "../../client/api";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import { useNavigate } from "react-router";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { TableRowItem, useList } from "../../hooks/useList";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const items: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  },
  {
    label: "username"
  },
  {
    label: "email"
  },
  {
    label: "tel"
  },
  {
    label: "status"
  },
  {
    label: "player"
  },
  {
    label: "action"
  }
];
const UserList: React.FC = () => {
  const navigator = useNavigate();
  const mapper = useCallback((us: UserEssential) => {
    return {
      id: us.id,
      search: `${us.username} ${us.givenName} ${us.familyName} ${us.email}`.toLowerCase(),
      items: [
        { label: us.id },
        { label: `${us.givenName} ${us.familyName}` },
        { label: us.username },
        {
          label: us.email,
          pill: {
            type: us.emailConfirmed ? "success" : "danger",
            text: us.emailConfirmed ? "confirmed" : "notConfirmed"
          }
        },
        {
          label: us.tel,
          pill: {

            type: us.telConfirmed ? "success" : "danger",
            text: us.telConfirmed ? "confirmed" : "notConfirmed"
          }
        },
        {
          centered: true,
          pill: !!us.playerId ? { type: "success", text: "connected" } : {
            type: "danger",
            text: "notConnected"
          }
        },
        {
          centered: true,
          buttons: [
            {
              icon: faPencil,
              onClick: () => {
                navigator("/user/" + us.id);
              },
              type: "primary"
            },
            {
              icon: faTrash,
              onClick: () => {
                // TODO
              },
              type: "danger"
            }
          ]
        }
      ]
    } as TableRowItem;
  }, [navigator]);
  const {
    q,
    setQ,
    onCheckAll,
    selected,
    allSelected,
    filtered,
    setItems,
    onCheck, limit
  } = useList<UserEssential>(mapper, 30);
  const { loading, call } = useServer(true);
  const onSearch = useCallback((off: number, lim: number) => {
    return call("searchUser", [q === "" ? " " : q, off, lim]);
  }, [q, call]);
  useInfiniteScroll(onSearch, setItems, 0, limit);

  return (<>
      <Breadcrumps>

      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"userManagement"} onSearch={setQ} q={q} icon={<LeagueIcon />}>

        </ListBlockHeader>
        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <Table>
            <TableHeader items={items} onCheckAll={onCheckAll} allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        checked={selected.includes(fil.id)}
                        key={"userManagement-" + fil.id} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>
    </>
  );
};

export default UserList;
