import React, { useMemo } from "react";
import { TranslatorKeys } from "../../config/translator";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pill from "../Atoms/Pill";
import { Image } from "../../client/api";
import ClavaImage from "../Atoms/ClavaImage";
import { Link } from "react-router-dom";

export declare type CellButton = {
  icon?: IconDefinition;
  label?: string;
  onClick: () => void;
  disabled?: boolean;
  type: "success" | "warning" | "danger" | "primary" | "secondary";
}
export declare type CellPill = {
  text: TranslatorKeys;
  type: "success" | "warning" | "danger" | "info";
}

export declare type TableCellItem = {
  key?: string;
  label?: string | number;
  iconCircle?: boolean;
  icon?: Image | IconDefinition;
  buttons?: CellButton[];
  pill?: CellPill;
  centered?: boolean;
}
const IdRegex = /\{Id:[a-z]+[0-9]+}/g;

function convertToIdStr(label: string, idx: number): React.JSX.Element {
  const matched = label.match(IdRegex);
  if (!matched) {
    return (
      <span className="block" key={label + "-" + idx}>{label}</span>
    );
  }
  const idStr = matched[0];
  const id = parseInt(idStr.replace("{Id:", "").replace("}", "").replace(/[a-z]*/g, ""), 10);
  const path = idStr.replace("{Id:", "").replace("}", "").replace(/[0-9]*/g, "");
  return (
    <div className="block" key={label + "-" + idx}>
      <span className="inline">{label.replace(IdRegex, "")}</span>
      <Link className="inline text-primary"
            to={"/" + path + "/" + id}> {idStr.replace(path, "")}</Link>
    </div>
  );
}

const TableCell: React.FC<TableCellItem & { myKey: string }> = ({
                                                                  myKey, centered,
                                                                  label, icon, iconCircle,
                                                                  buttons,
                                                                  pill
                                                                }) => {
  const lableSplitted = useMemo(() => {
    if (typeof label !== "string")
      return label;
    if (label.indexOf("\n") === -1) {
      return convertToIdStr(label, 1);
    }
    const splitted = label.split("\n");
    return (<div>
      {splitted.map(convertToIdStr)}
    </div>);
  }, [label]);
  return (
    <td
      className={"p-2"}>
      <div
        className={"flex flex-row items-center " + (centered ? "justify-center" : "justify-start")}>
        {!!icon && (
          <div
            className={`w-8 aspect-square mr-4 ${iconCircle ? "rounded-full overflow-hidden" : ""}`}>
            {typeof icon === "object" && "url" in icon ? (
              <ClavaImage width={"100%"} image={icon} />) : (
              <FontAwesomeIcon icon={icon} size="2x" />
            )}
          </div>
        )}
        {lableSplitted}
        {!!buttons && (
          <div className="flex flex-row items-center justify-center">
            {buttons.map((button, i) => (
              <button key={"btn-" + i + "-" + myKey} onClick={button.onClick}
                      disabled={button.disabled}
                      className={`mx-1 ${!button.label ? "w-10 h-10" : "px-2 py-1"} flex flex-row items-center justify-center rounded-lg bg-transparent border border-light-gray dark:border-light-gray-dark  ` + (button.type === "secondary" ? "hover:bg-input-bg hover:dark:bg-input-bg-dark" : button.type === "success" ? "hover:bg-green hover:text-white" : button.type === "danger" ? "hover:bg-red hover:text-white" : button.type === "primary" ? "hover:bg-primary hover:text-white" : "hover:bg-orange hover:text-white") + (button.disabled ? " opacity-50" : "")}>
                {!!button.icon && (
                  <FontAwesomeIcon icon={button.icon} className={button.label ? "mr-2" : ""} />)}
                {button.label}
              </button>
            ))}
          </div>
        )}
        {!!pill && (
          <Pill text={pill.text} status={pill.type} />
        )}
      </div>
    </td>
  );
};

export default TableCell;
