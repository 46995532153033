import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LeagueIcon from "../../components/Icons/LeagueIcon";
import { ClavaContext } from "../../config/contexts";
import { showTranslated, translate, TranslatorKeys } from "../../config/translator";
import { League, LeagueCategory } from "../../client/api";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faFileCsv, faLayerGroup, faPlus, faRefresh } from "@fortawesome/pro-solid-svg-icons";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import { useNavigate } from "react-router";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import MessageBox from "../../components/Atoms/MessageBox";
import { TableRowItem, useList } from "../../hooks/useList";
import LeagueCreateModal from "./LeagueCreateModal";
import Filter, { FilterProp } from "../../components/Views/Filter";
import useBoolState from "../../hooks/useBoolState";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import FileUploader from "../../components/FileUploader/FileUploader";
import CsvView from "../../components/Import/CsvView";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const items: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "type"
  },
  {
    label: "province"
  },
  {
    label: "name"
  },
  {
    label: "preset"
  },
  {
    label: "status"
  },
  {
    label: "action"
  }
];
const LeagueList: React.FC = () => {
  const { l, filterAoi } = useContext(ClavaContext);
  const [categories, setCategories] = useState<LeagueCategory[]>([]);
  const [importCsv, toggleImportCsv] = useBoolState(false);
  const [file, setFile] = useState<File>();
  const navigator = useNavigate();
  const mapper = useCallback((lea: League) => {
    const cat = categories.find(ca => ca.category === lea.category);
    return {
      id: lea.id,
      search: showTranslated(lea.name, l).toLowerCase(),
      items: [
        { label: lea.id },
        { label: cat ? showTranslated(cat.translation, l) : lea.category },
        { label: lea.areasOfInterest.map(area => translate(area.key as TranslatorKeys, l)).join(", ") },
        { label: showTranslated(lea.name, l) + " " + lea.year },
        { label: lea.tableRating },
        {
          centered: true,
          pill: lea.active ? { type: "success", text: "active" } : {
            type: "danger",
            text: "inactive"
          }
        },
        {
          centered: true,
          buttons: [
            {
              icon: faPencil,
              onClick: () => {
                navigator("/league/" + lea.id);
              },
              type: "primary"
            },
            {
              icon: faTrash,
              onClick: () => {
                setDel(lea);
              },
              type: "danger"
            }
          ]
        }
      ]
    } as TableRowItem;
  }, [l, categories, navigator]);
  const {
    q,
    setQ,
    onCheckAll,
    selected,
    allSelected,
    filtered,
    setItems,
    sthChanges,
    onCheck, limit
  } = useList<League>(mapper, 30);
  const [del, setDel] = useState<League>();
  const [create, toggleCreate] = useBoolState(false);
  const [aoiF, setAoiF] = useState<string | undefined>(filterAoi);
  const [active, setActive] = useState(true);
  const { loading, call } = useServer(true);
  const onSearch = useCallback((off: number, lim: number) => {
    return call("searchLeagues", [q === "" ? " " : q, off, lim, aoiF, active]);
  }, [q, aoiF, active, call]);
  useInfiniteScroll(onSearch, setItems, 0, limit);
  useEffect(() => {
    call("getLeagueCategories", []).then(setCategories);
  }, [call]);
  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);

  const navReorder = useCallback(() => {
    navigator("/league/reorder");
  }, [navigator]);
  const navCategories = useCallback(() => {
    navigator("/league/categories");
  }, [navigator]);

  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("deleteLeague", [id], "deleteFailed").then(() => {
        sthChanges(id);
        setDel(undefined);
      });
    }
  }, [del, call, sthChanges]);
  const filters = useMemo<FilterProp<"active" | "areaOfInterestFilter">[]>(() => {
    return [
      {
        type: "areaOfInterestFilter",
        val: aoiF,
        setVal: setAoiF
      },
      {
        type: "active",
        val: active,
        setVal: setActive
      }
    ];
  }, [aoiF, active]);
  const onCancelUpload = useCallback(() => {
    setFile(undefined);
  }, []);
  const onFinishUpload = useCallback(() => {
    setFile(undefined);
    toggleImportCsv();
  }, [toggleImportCsv]);
  if (!!file) {
    return (
      <CsvView file={file} className="mt-2" type={"league"}
               cancel={onCancelUpload} finish={onFinishUpload} />
    );
  }
  return (<>
      <Breadcrumps>
        <ClavaButton onClick={navCategories} lightSecondary className="mr-2">
          <FontAwesomeIcon icon={faLayerGroup} className="mr-2" />
          {translate("categories", l)}
        </ClavaButton>
        <ClavaButton onClick={navReorder} lightSecondary className="mr-2">
          <FontAwesomeIcon icon={faRefresh} className="mr-2" />
          {translate("reorderLeague", l)}
        </ClavaButton>
        <ClavaButton onClick={toggleCreate} light>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {translate("createLeague", l)}
        </ClavaButton>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"leagueManagement"} onSearch={setQ} q={q} icon={<LeagueIcon />}>
          <ClavaButton onClick={toggleImportCsv} lightSecondary className="mr-2">
            <FontAwesomeIcon icon={faFileCsv} size="lg" className="mr-1" />
            {translate("importCsv", l)}
          </ClavaButton>
          <Filter filter={filters} uniqueId={"league-filters"} />
        </ListBlockHeader>
        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <Table>
            <TableHeader items={items} onCheckAll={onCheckAll} allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        checked={selected.includes(fil.id)}
                        key={"leagueManagement-" + fil.id} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>

      {importCsv && !file && (
        <FileUploader as={1} type={"csv"} callback={setFile} close={toggleImportCsv} />
      )}
      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? showTranslated(del.name, l) : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
      {create && (
        <LeagueCreateModal onAdd={sthChanges} close={toggleCreate} />
      )}
    </>
  );
};

export default LeagueList;
