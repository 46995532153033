import React, { useEffect, useMemo, useState } from "react";
import { Ad } from "../../client/api";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import useEditParams from "../../hooks/useEditParams";
import AdvertisingEditForm from "./AdvertisingEditForm";

const AdvertisingEdit: React.FC = () => {
  const { id, navNotFound } = useEditParams("adId", "/advertising");
  const [ad, setAd] = useState<Ad>();
  const { loading, call, status, StatusComponent } = useServer(true);
  useEffect(() => {
    if (id) {
      call("getAd", [id], "notFound").then(setAd, navNotFound);
    } else {
      navNotFound();
    }
  }, [id, call, navNotFound]);
  const breadcrump = useMemo(() => {
    if (!ad) {
      return [];
    }
    return [
      ad.name
    ];
  }, [ad]);

  return (
    <LoadingBlock isLoading={loading}>
      {!!status && !!status.key && StatusComponent}
      <Breadcrumps additional={breadcrump}></Breadcrumps>
      {!!ad &&
        <AdvertisingEditForm ad={ad} changes={setAd} />}

    </LoadingBlock>
  );
};
export default AdvertisingEdit;