/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Punishment } from '../models/Punishment';
import type { PunishmentCreate } from '../models/PunishmentCreate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PunishmentService {
    /**
     * Get Punishment
     * @param punishmentId
     * @returns Punishment Successful Response
     * @throws ApiError
     */
    public static getPunishmentPunishmentPunishmentIdGet(
        punishmentId: number,
    ): CancelablePromise<Punishment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/punishment/{punishment_id}',
            path: {
                'punishment_id': punishmentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Punishment
     * @param requestBody
     * @returns Punishment Successful Response
     * @throws ApiError
     */
    public static createPunishmentPunishmentPost(
        requestBody: PunishmentCreate,
    ): CancelablePromise<Punishment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/punishment/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Punishments By League
     * @param leagueId
     * @returns Punishment Successful Response
     * @throws ApiError
     */
    public static getPunishmentsByLeaguePunishmentLeagueLeagueIdGet(
        leagueId: number,
    ): CancelablePromise<Array<Punishment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/punishment/league/{league_id}',
            path: {
                'league_id': leagueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
