import React, { useContext, useEffect, useState } from "react";
import { CardTypeEnum, ChanceTypeEnum, EventTypeEnum, GoalTypeEnum } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import { AllEventTypes } from "../../config/types";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { showTranslated } from "../../config/translator";

type SelectedEventType<T extends EventTypeEnum> = T extends EventTypeEnum.GOAL ? GoalTypeEnum : T extends EventTypeEnum.CARD ? CardTypeEnum : ChanceTypeEnum;

function EventTypePicker<T extends EventTypeEnum>({ type, onSet, selected }: {
  type: T,
  selected: SelectedEventType<T> | undefined,
  onSet: (val: SelectedEventType<T>) => void
}) {
  const { l } = useContext(ClavaContext);
  const [eventTypes, setEventTypes] = useState<AllEventTypes>();
  const { call, loading } = useServer(true);
  useEffect(() => {
    call("getAllEventTypes", []).then(setEventTypes);
  }, [call]);

  if (!eventTypes)
    return null;
  return (
    <div className="flex flex-row items-center justify-evenly flex-wrap my-4">
      {eventTypes[type === EventTypeEnum.CARD ? "card" : type === EventTypeEnum.GOAL ? "goals" : "chance"].map((evType) => (
        <ClavaButton key={evType.key} onClick={() => {
          onSet(evType.key as SelectedEventType<T>);
        }} lightSecondary={selected !== evType.key}>
          {showTranslated(evType.translation, l)}
        </ClavaButton>
      ))}
    </div>
  );

}

export default EventTypePicker;