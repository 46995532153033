import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import FattureIcon from "../../components/Icons/FattureIcon";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/pro-solid-svg-icons";
import { translate } from "../../config/translator";
import useBoolState from "../../hooks/useBoolState";
import { ClavaContext } from "../../config/contexts";
import FileUploader from "../../components/FileUploader/FileUploader";
import { useParams } from "react-router";
import axios, { AxiosResponse } from "axios";
import { IssuedDocument, ListIssuedDocumentsResponse } from "@fattureincloud/fattureincloud-ts-sdk";
import { errorLogging } from "../../config/utils";

type Fattura = {
  id: string;
  date: string;
  amount: number;
}

// Configure OAuth2 access token for authorization:

const FattureMain = () => {
  const { l } = useContext(ClavaContext);
  const params = useParams();
  const page = useRef(0);
  const hasMore = useRef(true);
  const [fatturas, setFatturas] = useState<IssuedDocument[]>();
  const [token, setToken] = useState<string>();
  const code = useMemo(() => {
    if (!("code" in params))
      return undefined;
    const p = params["code"];
    if (!p)
      return undefined;
    return p;
  }, [params]);
  useEffect(() => {
    if (code) {
      window.localStorage.setItem("FIC_TOKEN", code);
      setToken(code);
    } else {
      const c = window.localStorage.getItem("FIC_TOKEN");
      if (c) {
        setToken(c);
      }
    }
  }, [code]);
  const getSuccess = useCallback((res: AxiosResponse) => {
    if (res.data.success) {
      const response: ListIssuedDocumentsResponse = res.data.data;
      if (response.current_page) {
        page.current = response.current_page + 1;
        if (response.last_page)
          hasMore.current = response.current_page < response.last_page;
      }
      if (response.data)
        setFatturas(response.data);
    }
  }, []);
  useEffect(() => {
    if (token) {
      axios.post("https://fattura.clava-sports.com/fatturas", {
        filename: token
      }, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      }).then(getSuccess, errorLogging);
    }
  }, [token, getSuccess]);
  const getMore = useCallback(() => {
    if (token) {
      axios.post("https://fattura.clava-sports.com/fatturas", {
        filename: token,
        page: 0
      }, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      }).then(getSuccess, errorLogging);
    }
  }, [token, getSuccess]);
  const [importCsv, toggleImportCsv] = useBoolState(false);
  const [file, setFile] = useState<File>();
  const onAuth = useCallback(() => {
    location.href = "https://fattura.clava-sports.com/oauth?state=backoffice";
  }, []);
  return (
    <React.Fragment>
      <Breadcrumps>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"fatture"} icon={<FattureIcon />}>
          <ClavaButton onClick={toggleImportCsv} lightSecondary disabled={!token} className="mr-2">
            <FontAwesomeIcon icon={faFileCsv} size="lg" className="mr-1" />
            {translate("importCsv", l)}
          </ClavaButton>
        </ListBlockHeader>
        {!token ? (
          <ClavaButton onClick={onAuth}>
            Authenticate
          </ClavaButton>
        ) : (
          <div>
            <span>{JSON.stringify(fatturas)}</span>
            <ClavaButton onClick={getMore}>More</ClavaButton>
          </div>
        )}
      </RoundedBlock>
      {importCsv && !file && (
        <FileUploader as={1} type={"csv"} callback={setFile} close={toggleImportCsv} />
      )}
    </React.Fragment>
  );
};

export default FattureMain;
