import React, { useCallback, useContext, useMemo } from "react";
import { translate, TranslatorKeys } from "../../config/translator";
import ClavaDateInput from "../Atoms/ClavaDateInput";
import ClavaTextInput from "../Atoms/ClavaTextInput";
import ClavaButton from "../Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faSave } from "@fortawesome/pro-regular-svg-icons";
import { ClavaContext } from "../../config/contexts";
import {
  AdditionalCheck,
  changeFuncNames,
  changeNames,
  numberValNames,
  useFormData
} from "../../hooks/useFormData";

function BulkActions<FormObject extends Record<string, any>,
  Props extends string & keyof FormObject,
  PropsWFunc extends string & keyof FormObject,
  StringProps extends Props,
  BoolProps extends Props,
  NumberProps extends Props,
  DateProps extends Props,
  TransProps extends Props,
  MandatoryProps extends (Props | PropsWFunc)>({
                                                 selected,
                                                 onSave,
                                                 props,
                                                 propsWFunc,
                                                 boolProps,
                                                 stringProps,
                                                 numberProps,
                                                 translationProps,
                                                 dateProps,
                                                 mandatoryProps,
                                                 initial,
                                                 blockIfChanges,
                                                 onCancel
                                               }: {
  initial: FormObject,
  props: Props[],
  propsWFunc?: { [P in PropsWFunc]: AdditionalCheck<FormObject, P> },
  stringProps?: StringProps[],
  boolProps?: BoolProps[],
  numberProps?: NumberProps[],
  dateProps?: DateProps[],
  translationProps?: TransProps[],
  mandatoryProps?: MandatoryProps[],
  blockIfChanges?: boolean,
  selected: number[],
  onCancel: () => void;
  onSave: (val: FormObject) => void
}) {
  const { l } = useContext(ClavaContext);
  const form = useFormData(
    initial,
    props ?? [],
    propsWFunc ?? ({} as { [P in PropsWFunc]: AdditionalCheck<FormObject, P> }),
    stringProps ?? [],
    boolProps ?? [],
    numberProps ?? [],
    dateProps ?? [],
    translationProps ?? [],
    mandatoryProps ?? [],
    blockIfChanges);
  const onSaveBulk = useCallback(() => {
    if (!form.nothingChanged && selected.length > 1) {
      onSave(form);
    }
  }, [onSave, form, selected]);
  const formElements = useMemo<{ prop: Props, type: "number" | "date" | "string" }[][]>(() => {
    return ((dateProps ?? []).map((prop: Props) => ({
      prop,
      type: "date"
    })).concat((numberProps ?? []).map((prop: Props) => ({
      prop,
      type: "number"
    }))).concat((stringProps ?? []).map(((prop: Props) => ({
      prop,
      type: "string"
    })))) as { prop: Props, type: "number" | "date" | "string" }[]).reduce<{
      prop: Props,
      type: "number" | "date" | "string"
    }[][]>((prev, cur) => {
      if (prev.length === 0)
        return [[cur]];
      if (prev[prev.length - 1].length === 3) {
        prev.push([cur]);
      } else {
        prev[prev.length - 1].push(cur);
      }
      return prev;
    }, []);
  }, [numberProps, dateProps, stringProps]);
  if (selected.length > 1)
    return (
      <>
        <h3 className="font-bold mt-2">{translate("bulkActions", l)}</h3>
        {formElements.map((cur, idx) => (
          <div className="flex-1 mx-2 flex flex-row items-center justify-start my-2"
               key={"bulk-" + idx}>
            {cur.map((prop) => prop.type === "date" ? (
              <ClavaDateInput name="matchStartTime"
                              onChange={form[changeFuncNames(prop.prop) as keyof typeof form] as ((date: Date) => void)}
                              key={"bulk-input-" + prop.prop}
                              value={new Date(form[prop.prop])}
                              changed={form[changeNames(prop.prop)] as boolean}
                              label={translate(prop.prop as TranslatorKeys, l)}
                              type={"datetime"}
                              className="flex-1 mx-2" />
            ) : prop.type === "number" ? (
              <ClavaTextInput className="flex-1 mx-2"
                              key={"bulk-input-" + prop.prop}
                              onChange={form[changeFuncNames(prop.prop) as keyof typeof form] as ((val: string) => void)}
                              value={form[numberValNames(prop.prop) as keyof typeof form] as string}
                              changed={form[changeNames(prop.prop)] as boolean}
                              label={translate(prop.prop as TranslatorKeys, l)} />
            ) : (
              <ClavaTextInput className="flex-1 mx-2"
                              onChange={form[changeFuncNames(prop.prop) as keyof typeof form] as ((val: string) => void)}
                              key={"bulk-input-" + prop.prop}
                              value={form[prop.prop as keyof typeof form] as string}
                              changed={form[changeNames(prop.prop)] as boolean}
                              label={translate(prop.prop as TranslatorKeys, l)} />

            ))}
          </div>
        ))}
        <div className="flex-1 mx-2 flex flex-row items-center justify-start mb-4">
          <ClavaButton onClick={onCancel} secondary
                       className="flex-1 mx-2">
            <FontAwesomeIcon icon={faCancel} className="mr-2" size="lg" />
            {translate("cancel", l)}
          </ClavaButton>
          <div className="flex-1 mx-2" />
          <ClavaButton onClick={onSaveBulk} disabled={form.nothingChanged}
                       className="flex-1 mx-2">
            <FontAwesomeIcon icon={faSave} className="mr-2" size="lg" />
            {translate("save", l)}
          </ClavaButton>
        </div>
      </>
    );
  return null;
}

export default BulkActions;
