import Papa from "papaparse";
import { TranslatorKeys } from "./translator";
import moment from "moment";
import {
  LeagueCheck,
  LeagueMatchCheck,
  PlayerPositionEnum,
  SquadCheck,
  TeamCheck
} from "../client/api";
import { PLAYER_POSITION_SORT_DIRECTION } from "./constants";

export declare type ImportCell = {
  col: number;
  value: string;
}
export declare type ImportRow = {
  row: number;
  cols: ImportCell[];
}
export declare type ParseError = {
  row: number;
  error: TranslatorKeys;
  params: Record<string, string>;
}
export declare type Parsed = {
  errors: ParseError[];
  filename: string;
  lines: ImportRow[];
};


export function parseRowLeague(
  data: ImportRow[]
): {
  errors: ParseError[];
  result: LeagueCheck[]
} {
  const errors: ParseError[] = [];
  const result = data.map<LeagueCheck>((item) => {
    let areaOfInterestCodes: string[] = [];
    const name = item.cols[0].value;
    if (name.length < 3) {
      errors.push({
        row: item.row,
        error: "leagueNameTooShort",
        params: {}
      });
    } else {
      if (item.cols.length < 2) {
        errors.push({
          row: item.row,
          error: "noAois",
          params: {}
        });
      } else {
        for (let i = 1; i < item.cols.length; i++) {
          if (item.cols[i].value && item.cols[i].value.length !== 0)
            areaOfInterestCodes.push(item.cols[i].value);
        }
        if (areaOfInterestCodes.length === 0) {
          errors.push({
            row: item.row,
            error: "noAois",
            params: {}
          });
        }
      }
    }
    areaOfInterestCodes = areaOfInterestCodes.filter((val, idx, arr) => arr.indexOf(val) === idx);
    return {
      name: item.cols[0].value,
      areaOfInterestCodes
    };
  });

  return {
    errors,
    result
  };
}

export function parseRowTeam(
  data: ImportRow[]
): {
  errors: ParseError[];
  result: TeamCheck[]
} {
  const errors: ParseError[] = [];
  const result = data.filter(item => {
    if (item.cols.length !== 4) {
      errors.push({
        row: item.row,
        error: "invalidRowLength",
        params: { "[row]": item.row.toString(10) }
      });
      return false;
    }
    return true;
  }).map<TeamCheck>((item) => {
    const leagueId = parseInt(item.cols[3].value, 10);
    const rowParam = { "[row]": item.row.toString(10) };
    if (Number.isNaN(leagueId)) {
      errors.push({
        row: item.row,
        error: "leagueNoNum",
        params: rowParam
      });
    }
    return {
      leagueId,
      name: item.cols[0].value,
      officialName: item.cols[1].value,
      logoUrl: item.cols[2].value
    };
  });

  return {
    errors,
    result
  };
}

export function parseRowPlayer(
  data: ImportRow[]
): {
  errors: ParseError[];
  result: SquadCheck[]
} {
  const errors: ParseError[] = [];
  const result = data.filter(item => {
    if (item.cols.length !== 4) {
      errors.push({
        row: item.row,
        error: "invalidRowLength",
        params: { "[row]": item.row.toString(10) }
      });
      return false;
    }
    return true;
  }).map<SquadCheck>((item) => {
    const teamId = parseInt(item.cols[0].value, 10);
    const rowParam = { "[row]": item.row.toString(10) };
    if (Number.isNaN(teamId)) {
      errors.push({
        row: item.row,
        error: "teamIdNoNum",
        params: rowParam
      });
    }
    const pos = item.cols[3].value.toLowerCase() as PlayerPositionEnum;
    if (PLAYER_POSITION_SORT_DIRECTION.indexOf(pos) === -1) {
      errors.push({
        row: item.row,
        error: "playerPosInvalid",
        params: rowParam
      });
    }
    return {
      teamId,
      familyName: item.cols[1].value,
      givenName: item.cols[2].value,
      position: pos
    };
  });


  return {
    errors,
    result
  };
}

export function parseRowMatch(
  data: ImportRow[]
): {
  errors: ParseError[];
  result: LeagueMatchCheck[]
} {
  const errors: ParseError[] = [];
  const result = data.filter(item => {
    if (item.cols.length !== 6) {
      errors.push({
        row: item.row,
        error: "invalidRowLength",
        params: { "[row]": item.row.toString(10) }
      });
      return false;
    }
    return true;
  }).map<LeagueMatchCheck>((item) => {
    const matchDay = parseInt(item.cols[0].value, 10);
    const rowParam = { "[row]": item.row.toString(10) };
    if (Number.isNaN(matchDay)) {
      errors.push({
        row: item.row,
        error: "matchDayNoNum",
        params: rowParam
      });
    }
    const leagueId = parseInt(item.cols[5].value, 10);
    if (Number.isNaN(leagueId)) {
      errors.push({
        row: item.row,
        error: "leagueNoNum",
        params: rowParam
      });
    }
    const dateStr = `${item.cols[1].value.replace(/[-\\\/]/g, ".")} ${item.cols[2].value}`;
    const timestamp = moment(dateStr, "DD.MM.YYYY HH:mm");
    let startTime = moment().toISOString();
    if (Number.isNaN(timestamp.date())) {
      errors.push({
        row: item.row,
        error: "dateFormatWrong",
        params: rowParam
      });
    } else {
      startTime = timestamp.toISOString();
    }
    return {
      team1: item.cols[3].value,
      team2: item.cols[4].value,
      matchDay,
      leagueId,
      startTime
    };
  });
  return {
    errors,
    result
  };
}

export function parseCsv(file: File): Promise<Parsed> {
  return new Promise<Parsed>((resolve) => {
    Papa.parse<string[]>(file, {
      skipEmptyLines: true,
      header: false,
      complete: (result) => {
        resolve({
          errors: [],
          filename: file.name,
          lines: result.data.map((arr, row) => {
            return {
              row,
              cols: arr.map((value, col) => ({
                col,
                value
              }))
            };
          })
        });
      }
    });
  });
}
