import React, { useContext, useMemo } from "react";
import { translate, TranslatorKeys } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import { Link } from "react-router-dom";
import LeagueIcon from "../Icons/LeagueIcon";
import MatchIcon from "../Icons/MatchIcon";
import ReportsIcon from "../Icons/ReportsIcon";
import TeamIcon from "../Icons/TeamIcon";
import UserIcon from "../Icons/UserIcon";
import LogIcon from "../Icons/LogIcon";
import LiveTablesIcon from "../Icons/LiveTablesIcon";
import AdIcon from "../Icons/AdIcon";
import NewsIcon from "../Icons/NewsIcon";
import FeedIcon from "../Icons/FeedIcon";
import DashboardIcon from "../Icons/DashboardIcon";
import TournamentIcon from "../Icons/TournamentIcon";
import FattureIcon from "../Icons/FattureIcon";

function getIcon(path: string) {
  if (path.startsWith("/league")) {
    return LeagueIcon;
  }
  if (path.startsWith("/match")) {
    return MatchIcon;
  }
  if (path.startsWith("/team")) {
    return TeamIcon;
  }
  if (path.startsWith("/reporting")) {
    return ReportsIcon;
  }
  if (path.startsWith("/log")) {
    return LogIcon;
  }
  if (path.startsWith("/live-tables")) {
    return LiveTablesIcon;
  }
  if (path.startsWith("/advertising")) {
    return AdIcon;
  }
  if (path.startsWith("/news")) {
    return NewsIcon;
  }
  if (path.startsWith("/feed")) {
    return FeedIcon;
  }
  if (path.startsWith("/user")) {
    return UserIcon;
  }
  if (path.startsWith("/tournament")) {
    return TournamentIcon;
  }
  if (path.startsWith("/fatture")) {
    return FattureIcon;
  }
  return DashboardIcon;
}

const SidebarItem: React.FC<{
  text: TranslatorKeys,
  open: boolean,
  path: string,
  active?: boolean
}> = ({ path, text, open, active }) => {
  const { l } = useContext(ClavaContext);
  const Icon = useMemo(() => {
    return getIcon(path);
  }, [path]);
  return (
    <li
      className={"p-2 my-1 border-none rounded-xl hover:font-medium " + (active ? "bg-primary-40 text-primary font-medium" : "bg-transparent hover:bg-light-gray hover:dark:bg-light-gray-dark")}>
      <Link to={path} className="flex flex-row items-center justify-start">
        <Icon primary={active} />
        {open && (<span className="text-lg ml-2">{translate(text, l)}</span>)}
      </Link>
    </li>
  );
};

export default SidebarItem;
