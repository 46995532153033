import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CancelablePromise, League, Team, TeamListElement } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { showTranslated, translate } from "../../config/translator";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LeagueIcon from "../../components/Icons/LeagueIcon";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { faArrowsLeftRight } from "@fortawesome/pro-regular-svg-icons";
import MessageBox from "../../components/Atoms/MessageBox";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Table from "../../components/Table/Table";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { TableRowItem, useList } from "../../hooks/useList";
import AddTeamModal from "../../components/Views/AddTeamModal";
import { useNavigate } from "react-router";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const headerItems: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  },
  {
    label: "outOfComp"
  },
  {
    label: "change"
  },
  {
    label: "delete"
  }
];
const LeagueEditTeams: React.FC<{
  league: League,
  changes: (league: League) => void
}> = ({ league, changes }) => {
  const { l } = useContext(ClavaContext);
  const { call, loading } = useServer(false);
  const navigator = useNavigate();

  const mapper = useCallback((team: TeamListElement): TableRowItem => {
    return {
      id: team.id,
      search: showTranslated(team.name, l).toLowerCase(),
      items: [
        {
          buttons: [{
            onClick: () => {
              navigator("/team/" + team.id);
            },
            type: "secondary",
            label: team.id.toString(10)
          }]
        },
        {
          icon: team.emblem,
          label: showTranslated(team.name, l) + `${team.leagues.filter(lea => lea.main).length > 1 ? `(${translate("multipleMainLeagues", l)})` : ""}`
        },
        {
          pill: {
            type: team.outOfCompetition ? "warning" : "success",
            text: team.outOfCompetition ? "yes" : "no"
          }
        },
        {
          centered: true,
          buttons: [
            {
              icon: faArrowsLeftRight,
              onClick: () => {
                // TODO tauschen
              },
              type: "primary"
            }
          ]
        },
        {
          centered: true,
          buttons: [
            {
              icon: faTrash,
              onClick: () => {
                setDel(team);
              },
              type: "danger"
            }
          ]
        }
      ]
    };
  }, [l, navigator]);
  const {
    selected,
    onCheck,
    items,
    onCheckAll,
    allSelected,
    q,
    setQ,
    setItems,
    filtered
  } = useList(mapper);
  const [del, setDel] = useState<TeamListElement>();
  const [setOutOfComp, setSetOutOfComp] = useState(false);
  const [add, setAdd] = useState(false);
  const toggleSetOutOfComp = useCallback(() => {
    setSetOutOfComp(oc => !oc);
  }, []);
  const toggleAdd = useCallback(() => {
    setAdd(a => !a);
  }, []);
  useEffect(() => {
    call("getTeamsOfLeague", [league.id]).then(setItems);
  }, [call, league.id, setItems]);

  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);
  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("removeTeamFromLeague", [league.id, [id]], "deleteFailed").then(() => {
        setItems(as => as ? as.filter(a => a.id !== id) : undefined);
        setDel(undefined);
      });
    }
  }, [del, call, league.id, setItems]);
  const onSetOutOfComp = useCallback(() => {
    setSetOutOfComp(false);
    if (items) {
      const ts = items.filter(t => selected.includes(t.id));
      const promises: CancelablePromise<Team>[] = [];
      ts.forEach((te) => {
        promises.push(call("patchTeam", [te.id, { outOfCompetition: !te.outOfCompetition }]));
      });
      Promise.allSettled(promises).then(() => {
        call("getTeamsOfLeague", [league.id]).then(setItems);
      });
    }
  }, [call, league.id, setItems, items, selected]);
  const onAdd = useCallback((ids: TeamListElement[]) => {
    call("addTeamsToLeague", [league.id, ids.map(t => t.id)]).then(changes);
  }, [league.id, call, changes]);
  const teamIds = useMemo(() => {
    if (!items)
      return [];
    return items.map(t => t.id);
  }, [items]);
  return (<RoundedBlock>
      <ListBlockHeader title={`${translate("teams", l)} [${items?.length}]`} onSearch={setQ} q={q}
                       icon={<LeagueIcon />}>
        <ClavaButton onClick={toggleSetOutOfComp} lightSecondary disabled={selected.length === 0}
                     className="mr-2">
          {translate("outOfComp", l)}
        </ClavaButton>
        <ClavaButton onClick={toggleAdd} lightSecondary className="mr-2">
          {translate("addTeam", l)}
        </ClavaButton>
      </ListBlockHeader>
      <LoadingBlock isLoading={loading}>
        <Table>
          <TableHeader items={headerItems} onCheckAll={onCheckAll} allChecked={allSelected} />
          <tbody>
          {filtered.map(fil => (
            <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                      checked={selected.includes(fil.id)}
                      key={"league-teams-" + fil.id} />
          ))}
          </tbody>
        </Table>
      </LoadingBlock>
      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? showTranslated(del.name, l) : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
      <MessageBox type={"info"} open={setOutOfComp} toggle={toggleSetOutOfComp}
                  msg={translate("sureSetOutOfComp", l)} btn1Clb={onSetOutOfComp}
                  btn1Text={translate("yes", l)} btn2Clb={toggleSetOutOfComp}
                  btn2Text={translate("no", l)} />
      {add && (
        <AddTeamModal onAdd={onAdd} close={toggleAdd} alreadyInIds={teamIds} />
      )}
    </RoundedBlock>
  );
};

export default LeagueEditTeams;
