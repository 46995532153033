// noinspection HtmlUnknownAttribute

import React, { useCallback, useMemo, useRef, useState } from "react";
import { Video } from "../../client/api";
import { calcMediaStyle } from "../../config/mediaUtils";

const FeedDownloadVideo: React.FC<{
  video: Video, width: string | number, className?: string;
}> = ({ video, width, className }) => {
  const outerDiv = useRef<HTMLDivElement>(null);
  const [reload, setReload] = useState(128);
  const divWidth = useMemo(() => {
    if (outerDiv.current) return outerDiv.current.clientWidth;
    return reload;
  }, [reload]);

  const calcEffectiveStyle = useCallback(() => {
    setReload(r => r + 1);
  }, []);
  const w = useMemo(() => {
    if (typeof width === "string" && width.indexOf("%") !== -1) {
      return divWidth;
    }
    let wi = parseInt(width.toString(), 10);
    if (Number.isNaN(wi)) {
      wi = 1920;
    }
    return wi;
  }, [divWidth, width]);
  const containerStyle = useMemo<React.CSSProperties>(() => {
    if (video && video.cropAspectRatio) {
      return {
        width,
        aspectRatio: video.cropAspectRatio,
        overflow: "hidden",
        position: "relative"
      };
    }
    return {
      width,
      aspectRatio: video?.cropAspectRatio ?? 1,
      position: "relative"
    };
  }, [video, width]);
  const effectiveStyle = useMemo<React.CSSProperties>(() => {
    if (video) {
      return calcMediaStyle(w, video.aspectRatio, video);
    }
    return {};
  }, [w, video]);
  return (
    <div style={containerStyle} className={className} ref={outerDiv}>
      <div style={effectiveStyle}>
        <div className="relative">
          <video
            src={video.dash.replace("manifest/video.mpd", "downloads/default.mp4")} controls />
          <img src={video.dash.replace("manifest/video.mpd", "thumbnails/thumbnail.jpg")}
               className="w-100 h-100" onLoad={calcEffectiveStyle} alt="Poster" />
        </div>
      </div>
    </div>
  );
};

export default FeedDownloadVideo;
