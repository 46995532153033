/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UsersPerAreaOfInterest } from '../models/UsersPerAreaOfInterest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MonitoringService {
    /**
     * Get Active Users Per Area Of Interest
     * @returns UsersPerAreaOfInterest Successful Response
     * @throws ApiError
     */
    public static getActiveUsersPerAreaOfInterestMonitoringActiveUsersPerAreaOfInterestGet(): CancelablePromise<Array<UsersPerAreaOfInterest>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/monitoring/active_users_per_area_of_interest',
        });
    }
    /**
     * Get Users Per Area Of Interest
     * @returns UsersPerAreaOfInterest Successful Response
     * @throws ApiError
     */
    public static getUsersPerAreaOfInterestMonitoringUsersPerAreaOfInterestGet(): CancelablePromise<Array<UsersPerAreaOfInterest>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/monitoring/users_per_area_of_interest',
        });
    }
}
