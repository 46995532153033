/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_patch_tournament_pdf_tournament_pdf__tournament_id__patch } from '../models/Body_patch_tournament_pdf_tournament_pdf__tournament_id__patch';
import type { PlayerStatisticsListNew } from '../models/PlayerStatisticsListNew';
import type { SportEnum } from '../models/SportEnum';
import type { StatisticKeyEnum } from '../models/StatisticKeyEnum';
import type { Tournament } from '../models/Tournament';
import type { TournamentCreate } from '../models/TournamentCreate';
import type { TournamentGroup } from '../models/TournamentGroup';
import type { TournamentGroupCreate } from '../models/TournamentGroupCreate';
import type { TournamentGroupList } from '../models/TournamentGroupList';
import type { TournamentGroupPatch } from '../models/TournamentGroupPatch';
import type { TournamentGroupStandingList } from '../models/TournamentGroupStandingList';
import type { TournamentList } from '../models/TournamentList';
import type { TournamentPatch } from '../models/TournamentPatch';
import type { TournamentSecret } from '../models/TournamentSecret';
import type { TournamentSecretPatch } from '../models/TournamentSecretPatch';
import type { TournamentStage } from '../models/TournamentStage';
import type { TournamentStageCreate } from '../models/TournamentStageCreate';
import type { TournamentStageList } from '../models/TournamentStageList';
import type { TournamentStagePatch } from '../models/TournamentStagePatch';
import type { TournamentTeamCreate } from '../models/TournamentTeamCreate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TournamentService {
    /**
     * Get Tournament Pdf
     * @param tournamentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getTournamentPdfTournamentPdfTournamentIdGet(
        tournamentId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tournament/pdf/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Tournament Pdf
     * @param tournamentId
     * @param formData
     * @returns Tournament Successful Response
     * @throws ApiError
     */
    public static patchTournamentPdfTournamentPdfTournamentIdPatch(
        tournamentId: number,
        formData: Body_patch_tournament_pdf_tournament_pdf__tournament_id__patch,
    ): CancelablePromise<Tournament> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/tournament/pdf/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tournament
     * @param tournamentId
     * @returns Tournament Successful Response
     * @throws ApiError
     */
    public static getTournamentTournamentTournamentIdGet(
        tournamentId: number,
    ): CancelablePromise<Tournament> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tournament/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Tournament
     * @param tournamentId
     * @param requestBody
     * @returns Tournament Successful Response
     * @throws ApiError
     */
    public static patchTournamentTournamentTournamentIdPatch(
        tournamentId: number,
        requestBody: TournamentPatch,
    ): CancelablePromise<Tournament> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/tournament/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Tournament
     * @param tournamentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteTournamentTournamentTournamentIdDelete(
        tournamentId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tournament/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tournament Secrets
     * @param tournamentId
     * @returns TournamentSecret Successful Response
     * @throws ApiError
     */
    public static getTournamentSecretsTournamentSecretsTournamentIdGet(
        tournamentId: number,
    ): CancelablePromise<TournamentSecret> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tournament/secrets/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Tournament Secrets
     * @param tournamentId
     * @param requestBody
     * @returns TournamentSecret Successful Response
     * @throws ApiError
     */
    public static patchTournamentSecretsTournamentSecretsTournamentIdPatch(
        tournamentId: number,
        requestBody: TournamentSecretPatch,
    ): CancelablePromise<TournamentSecret> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/tournament/secrets/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Tournament
     * @param requestBody
     * @returns Tournament Successful Response
     * @throws ApiError
     */
    public static createTournamentTournamentPost(
        requestBody: TournamentCreate,
    ): CancelablePromise<Tournament> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tournament/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tournament By Date
     * @param areaOfInterestId
     * @param date
     * @param sports
     * @returns TournamentList Successful Response
     * @throws ApiError
     */
    public static getTournamentByDateTournamentAreaOfInterestAreaOfInterestIdDateDateGet(
        areaOfInterestId: number,
        date: string,
        sports: SportEnum,
    ): CancelablePromise<TournamentList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tournament/area_of_interest/{area_of_interest_id}/date/{date}',
            path: {
                'area_of_interest_id': areaOfInterestId,
                'date': date,
            },
            query: {
                'sports': sports,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Order
     * @param areaOfInterestId
     * @param requestBody
     * @returns TournamentList Successful Response
     * @throws ApiError
     */
    public static setOrderTournamentSetOrderPut(
        areaOfInterestId: number,
        requestBody: Array<number>,
    ): CancelablePromise<Array<TournamentList>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tournament/set_order',
            query: {
                'area_of_interest_id': areaOfInterestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Team To Tournament By Name
     * @param tournamentId
     * @param requestBody
     * @returns Tournament Successful Response
     * @throws ApiError
     */
    public static addTeamToTournamentByNameTournamentTournamentIdTeamNamePost(
        tournamentId: number,
        requestBody: TournamentTeamCreate,
    ): CancelablePromise<Tournament> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tournament/{tournament_id}/team/name',
            path: {
                'tournament_id': tournamentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Team To Tournament
     * @param teamId
     * @param tournamentId
     * @returns Tournament Successful Response
     * @throws ApiError
     */
    public static addTeamToTournamentTournamentTournamentIdTeamTeamIdPost(
        teamId: number,
        tournamentId: number,
    ): CancelablePromise<Tournament> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tournament/{tournament_id}/team/{team_id}',
            path: {
                'team_id': teamId,
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Team From Tournament
     * @param teamId
     * @param tournamentId
     * @returns Tournament Successful Response
     * @throws ApiError
     */
    public static removeTeamFromTournamentTournamentTournamentIdTeamIdTeamIdDelete(
        teamId: number,
        tournamentId: number,
    ): CancelablePromise<Tournament> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tournament/{tournament_id}/team/id/{team_id}',
            path: {
                'team_id': teamId,
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Tournament Stage
     * @param tournamentId
     * @param requestBody
     * @returns TournamentStage Successful Response
     * @throws ApiError
     */
    public static createTournamentStageTournamentStageTournamentIdPost(
        tournamentId: number,
        requestBody: TournamentStageCreate,
    ): CancelablePromise<TournamentStage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tournament/stage/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tournament Stages
     * @param tournamentId
     * @returns TournamentStageList Successful Response
     * @throws ApiError
     */
    public static getTournamentStagesTournamentStageTournamentIdGet(
        tournamentId: number,
    ): CancelablePromise<TournamentStageList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tournament/stage/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Stage From Tournament
     * @param tournamentStageId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeStageFromTournamentTournamentStageTournamentStageIdDelete(
        tournamentStageId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tournament/stage/{tournament_stage_id}',
            path: {
                'tournament_stage_id': tournamentStageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Tournament Stage
     * @param tournamentStageId
     * @param requestBody
     * @returns TournamentStage Successful Response
     * @throws ApiError
     */
    public static patchTournamentStageTournamentStageTournamentStageIdPatch(
        tournamentStageId: number,
        requestBody: TournamentStagePatch,
    ): CancelablePromise<TournamentStage> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/tournament/stage/{tournament_stage_id}',
            path: {
                'tournament_stage_id': tournamentStageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tournament Group Standings
     * @param tournamentId
     * @returns TournamentGroupStandingList Successful Response
     * @throws ApiError
     */
    public static getTournamentGroupStandingsTournamentGroupStandingsTournamentIdGet(
        tournamentId: number,
    ): CancelablePromise<TournamentGroupStandingList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tournament/group/standings/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tournament Groups
     * @param tournamentId
     * @returns TournamentGroupList Successful Response
     * @throws ApiError
     */
    public static getTournamentGroupsTournamentGroupTournamentIdGet(
        tournamentId: number,
    ): CancelablePromise<TournamentGroupList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tournament/group/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Group To Tournament
     * @param tournamentId
     * @param requestBody
     * @returns TournamentGroup Successful Response
     * @throws ApiError
     */
    public static addGroupToTournamentTournamentGroupTournamentIdPost(
        tournamentId: number,
        requestBody: TournamentGroupCreate,
    ): CancelablePromise<TournamentGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tournament/group/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Group From Tournament
     * @param tournamentGroupId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeGroupFromTournamentTournamentGroupTournamentGroupIdDelete(
        tournamentGroupId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tournament/group/{tournament_group_id}',
            path: {
                'tournament_group_id': tournamentGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Group
     * @param tournamentGroupId
     * @param requestBody
     * @returns TournamentGroup Successful Response
     * @throws ApiError
     */
    public static patchGroupTournamentGroupTournamentGroupIdPatch(
        tournamentGroupId: number,
        requestBody: TournamentGroupPatch,
    ): CancelablePromise<TournamentGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/tournament/group/{tournament_group_id}',
            path: {
                'tournament_group_id': tournamentGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Team To Group By Id
     * @param tournamentGroupId
     * @param teamId
     * @returns TournamentGroupStandingList Successful Response
     * @throws ApiError
     */
    public static addTeamToGroupByIdTournamentGroupTournamentGroupIdTeamTeamIdPost(
        tournamentGroupId: number,
        teamId: number,
    ): CancelablePromise<TournamentGroupStandingList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tournament/group/{tournament_group_id}/team/{team_id}/',
            path: {
                'tournament_group_id': tournamentGroupId,
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Team From Group
     * @param tournamentGroupId
     * @param teamId
     * @returns TournamentGroupStandingList Successful Response
     * @throws ApiError
     */
    public static removeTeamFromGroupTournamentGroupTournamentGroupIdTeamTeamIdDelete(
        tournamentGroupId: number,
        teamId: number,
    ): CancelablePromise<TournamentGroupStandingList> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tournament/group/{tournament_group_id}/team/{team_id}/',
            path: {
                'tournament_group_id': tournamentGroupId,
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tournament Statistics
     * @param tournamentId
     * @param statistic
     * @returns PlayerStatisticsListNew Successful Response
     * @throws ApiError
     */
    public static getTournamentStatisticsTournamentStatisticsTournamentIdGet(
        tournamentId: number,
        statistic: StatisticKeyEnum,
    ): CancelablePromise<PlayerStatisticsListNew> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tournament_statistics/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            query: {
                'statistic': statistic,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
