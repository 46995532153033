import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";

const ComingSoon = () => {
  const { l } = useContext(ClavaContext);
  return (

    <div className="flex flex-col items-center justify-center">
      <FontAwesomeIcon icon={faTriangleExclamation} className="mb-2" size="3x"
                       color="#FFAB49" />
      <h1 className="font-bold text-xl mb-2">{translate("comingSoon", l)}</h1>
      <p className="italic">{translate("comingSoonCont", l)}</p>
    </div>
  );
};

export default ComingSoon;