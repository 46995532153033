import React from "react";
import Breadcrumps from "../components/Layout/Breadcrumps";
import RoundedBlock from "../components/Atoms/RoundedBlock";
import ComingSoon from "../components/Views/ComingSoon";

const NotFound = () => (
  <>
    <Breadcrumps />
    <RoundedBlock>
      <ComingSoon />
    </RoundedBlock>
  </>
);

export default NotFound;