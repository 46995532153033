import React, { useContext, useEffect } from "react";
import { translate } from "../../../config/translator";
import { ClavaContext } from "../../../config/contexts";
import { AreaOfInterest } from "../../../client/api";
import ClavaSelect from "../ClavaSelect";
import { useAoi } from "../../../hooks/useAois";


const AoiSelect: React.FC<{
  selected: AreaOfInterest | undefined,
  setSelected: (val: AreaOfInterest | undefined) => void;
  className?: string;
  autoSelect?: boolean
}> = ({
        selected, autoSelect,
        setSelected,
        className
      }) => {
  const { l } = useContext(ClavaContext);
  const { aoiItems, selectedAoi, onChangeAoi } = useAoi(selected, setSelected);
  useEffect(() => {
    if (autoSelect && !selected) {
      onChangeAoi(selectedAoi);
    }
  }, [selected, autoSelect, selectedAoi, onChangeAoi]);
  return (
    <ClavaSelect uniqueId={"select-aoi"} className={className}
                 withSearch
                 items={aoiItems}
                 onChange={onChangeAoi}
                 value={selectedAoi}
                 label={translate("chooseProvince", l)} />
  );
};

export default AoiSelect;
