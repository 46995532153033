/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageTypeEnum } from '../models/ImageTypeEnum';
import type { MediaCrop } from '../models/MediaCrop';
import type { MediaUpload } from '../models/MediaUpload';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserMediaService {
    /**
     * Request Image Upload
     * @param aspectRatio
     * @param requestBody
     * @returns MediaUpload Successful Response
     * @throws ApiError
     */
    public static requestImageUploadUserMediaImagePost(
        aspectRatio: number,
        requestBody: MediaCrop,
    ): CancelablePromise<MediaUpload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user_media/image',
            query: {
                'aspectRatio': aspectRatio,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Request Video Upload
     * @param aspectRatio
     * @param requestBody
     * @returns MediaUpload Successful Response
     * @throws ApiError
     */
    public static requestVideoUploadUserMediaVideoPost(
        aspectRatio: number,
        requestBody: MediaCrop,
    ): CancelablePromise<MediaUpload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user_media/video',
            query: {
                'aspectRatio': aspectRatio,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Request Tus Upload
     * @param aspectRatio
     * @param uploadLength
     * @param requestBody
     * @param fileName
     * @returns MediaUpload Successful Response
     * @throws ApiError
     */
    public static requestTusUploadUserMediaTusPost(
        aspectRatio: number,
        uploadLength: number,
        requestBody: MediaCrop,
        fileName?: string,
    ): CancelablePromise<MediaUpload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user_media/tus',
            query: {
                'aspect_ratio': aspectRatio,
                'upload_length': uploadLength,
                'file_name': fileName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Image
     * @param imageId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteImageUserMediaImageImageIdDelete(
        imageId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user_media/image/{image_id}',
            path: {
                'image_id': imageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Video
     * @param videoId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteVideoUserMediaVideoVideoIdDelete(
        videoId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user_media/video/{video_id}',
            path: {
                'video_id': videoId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Max Upload Length Seconds
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getMaxUploadLengthSecondsUserMediaMaxUploadLengthSecondsGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_media/max_upload_length_seconds',
        });
    }
    /**
     * Get Aspect Ration
     * @param imageType
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getAspectRationUserMediaRatioImageTypeGet(
        imageType: ImageTypeEnum,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_media/ratio/{image_type}',
            path: {
                'image_type': imageType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
