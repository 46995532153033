/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_image_media_image_post } from '../models/Body_upload_image_media_image_post';
import type { Image } from '../models/Image';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MediaService {
    /**
     * Upload Image
     * @param formData
     * @param removeBackground
     * @param fitToEmblem
     * @returns Image Successful Response
     * @throws ApiError
     */
    public static uploadImageMediaImagePost(
        formData: Body_upload_image_media_image_post,
        removeBackground: boolean = false,
        fitToEmblem: boolean = false,
    ): CancelablePromise<Image> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/media/image',
            query: {
                'remove_background': removeBackground,
                'fit_to_emblem': fitToEmblem,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Download Image
     * @param mediaId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static downloadImageMediaDownloadMediaIdGet(
        mediaId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/media/download/{media_id}',
            path: {
                'media_id': mediaId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
