import React from "react";
import TableHeaderItemView, { TableHeaderItem } from "./TableHeaderItemView";

const TableHeader: React.FC<{
  items: TableHeaderItem[],
  moveable?: boolean;
  onMoveLeft?: (idx: number) => void;
  onMoveRight?: (idx: number) => void;
  onCheckAll: () => void,
  allChecked: boolean,
  sorted?: string;
  onSort?: (key: string) => void,
}> = ({ items, sorted, onSort, allChecked, moveable, onMoveLeft, onMoveRight, onCheckAll }) => {
  return (
    <thead>
    <tr>
      <th className="p-2 bg-input-bg dark:bg-input-bg-dark rounded-l-xl">
        <input type="checkbox" onChange={onCheckAll} checked={allChecked} />
      </th>
      {items.map((it, idx) => (
        <TableHeaderItemView key={"th-" + (it.key ?? it.label)} item={it} idx={idx} sorted={sorted}
                             onSort={onSort}
                             onMoveLeft={onMoveLeft} onMoveRight={onMoveRight}
                             moveable={moveable} first={idx === items.length - 1} />
      ))}
    </tr>
    </thead>
  );
};

export default TableHeader;
