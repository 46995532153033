import React, { useCallback, useContext, useMemo, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import { AdCreate, AdPositionEnum, Image } from "../../client/api";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { translate } from "../../config/translator";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { ClavaSelectItems } from "../../config/types";
import ClavaSelect from "../../components/Atoms/ClavaSelect";
import ClavaDateInput from "../../components/Atoms/ClavaDateInput";
import ClavaSwitch from "../../components/Atoms/ClavaSwitch";
import { sameIdArray } from "../../config/utils";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ClavaSelectMultiple from "../../components/Atoms/ClavaSelectMultiple";
import FileUploader from "../../components/FileUploader/FileUploader";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { useNavigate } from "react-router";
import { AdditionalCheck, useFormData } from "../../hooks/useFormData";
import { adPositionItems } from "../../config/constants";
import { faAd } from "@fortawesome/pro-regular-svg-icons";
import { useAois } from "../../hooks/useAois";

const emptyAd: AdCreate = {
  name: "",
  url: "",
  start: new Date().toISOString(),
  stop: new Date().toISOString(),
  color: "",
  areaOfInterestIds: [],
  imageDesktopId: "",
  imageMobileId: "",
  paused: false,
  position: AdPositionEnum.HOME_MATCH,
  priority: 1
};

const aoiCheck: AdditionalCheck<AdCreate, "areaOfInterestIds"> = sameIdArray;
const AdvertisingCreate: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const form = useFormData(emptyAd,
    ["url", "name", "priority", "position", "paused", "start", "stop", "color"],
    { areaOfInterestIds: aoiCheck },
    ["name", "color", "url", "position"],
    ["paused"],
    ["priority"],
    ["start", "stop"],
    [],
    ["name", "stop", "url", "areaOfInterestIds"]
  );
  const [patchImage, setPatchImage] = useState(false);
  const [img, setImg] = useState<Image>();
  const { loading, call, StatusComponent } = useServer(false);

  const onPatchImage = useCallback(() => {
    setPatchImage(pi => !pi);
  }, []);
  const breadcrump = useMemo(() => {
    return [
      translate("createAd", l)
    ];
  }, [l]);
  const positionItems = useMemo<ClavaSelectItems[]>(
    () => adPositionItems(l),
    [l]
  );
  const as = useMemo(() => {
    if (form.position === AdPositionEnum.MATCH_HISTORY_BOTTOM) return 1080 / 120;
    if (form.position === AdPositionEnum.STANDINGS) return 1080 / 120;
    if (form.position === AdPositionEnum.TEAM_OF_THE_WEEK) return 788 / 260;
    return 1080 / 300;
  }, [form.position]);
  const {
    aoiItems,
    selectedAois,
    onChangeAois
  } = useAois(true, form.areaOfInterestIds ?? [], (val) => {
    form.onChange("areaOfInterestIds", val);
  });
  const onChangeImage = useCallback((file: Image) => {
    setImg(file);
    form.setFormData(fd => ({
      ...fd,
      imageMobileId: file.id,
      imageDesktopId: file.id
    }));
    onPatchImage();
  }, [onPatchImage, form]);
  const navigator = useNavigate();
  const onSave = useCallback(() => {
    if (form.allChanged) {
      const cr: AdCreate = {
        name: form.name,
        position: form.position,
        start: form.start,
        stop: form.stop,
        paused: form.paused,
        color: form.color,
        priority: form.priority,
        areaOfInterestIds: form.areaOfInterestIds,
        url: form.url,
        imageMobileId: form.imageMobileId,
        imageDesktopId: form.imageDesktopId
      };
      call("createAd", [cr], "saveFailed", "saveSuccess").then((res) => {
        setTimeout(() => {
          navigator("/advertising/" + res.id);
        }, 5000);
        form.setInitial(cr);
      });
    }
  }, [form, call, navigator]);
  return (
    <>
      <Breadcrumps additional={breadcrump}></Breadcrumps>
      <RoundedBlock>
        <ItemBlockHeader image={img ?? faAd}
                         imageHeader={as} name={form.name ?? translate("newEntry", l)}
                         onPatchImage={onPatchImage}>
          <ClavaButton onClick={onSave}
                       disabled={!form.allChanged || !img}>{translate("save", l)}</ClavaButton>
        </ItemBlockHeader>
        <LoadingBlock isLoading={loading}>
          {StatusComponent}
          <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
            <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeName}
                            changed={form.nameChanged}
                            value={form.name}
                            label={translate("name", l)} />
            <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeUrl}
                            changed={form.urlChanged}
                            value={form.url}
                            label={translate("url", l)} />
            <ClavaSelect className="flex-1 mx-2" value={form.position}
                         changed={form.positionChanged}
                         items={positionItems}
                         onChange={form.onChangePosition}
                         label={translate("adPosition", l)} uniqueId={"adPosition"} />
          </div>
          <div className="flex flex-row items-center mx-[-8px] my-4">
            <ClavaDateInput value={form.startDate} onChange={form.onChangeStart}
                            className="flex-1 mx-2"
                            changed={form.startChanged}
                            name={"startDate"}
                            label={translate("startDate", l)} type={"datetime"} />
            <ClavaDateInput value={form.stopDate} onChange={form.onChangeStop}
                            className="flex-1 mx-2"
                            changed={form.stopChanged}
                            name={"endDate"}
                            label={translate("stopDate", l)} type={"datetime"} />
            <ClavaSwitch value={!!form.paused} className="flex-1 mx-2"
                         onChange={form.onChangePaused}
                         changed={form.pausedChanged}
                         label={translate("paused", l)} />
          </div>
          <div className="flex flex-row items-center mx-[-8px] my-4">
            <ClavaTextInput onChange={form.onChangePriority} value={form.priorityVal} type="number"
                            changed={form.priorityChanged}
                            label={translate("priority", l)} className="flex-1 mx-2" />
            <ClavaTextInput onChange={form.onChangeColor} value={form.color ?? ""}
                            changed={form.colorChanged}
                            label={translate("backgroundColor", l)} className="flex-1 mx-2" />
            <ClavaSelectMultiple uniqueId={"aois-ad"} items={aoiItems} onChange={onChangeAois}
                                 changed={form.areaOfInterestIdsChanged}
                                 label={translate("province", l)} value={selectedAois}
                                 className="flex-1 mx-2" />

          </div>
        </LoadingBlock>
      </RoundedBlock>
      {patchImage && (
        <FileUploader as={as} type={"image"} callback={onChangeImage} close={onPatchImage} />
      )}
    </>
  );
};

export default AdvertisingCreate;