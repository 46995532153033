import { useCallback, useContext, useMemo } from "react";
import { ClavaSelectItems, IDType } from "../config/types";
import { mapTranslatableToItem } from "../components/Atoms/ClavaSelect";
import { ClavaContext } from "../config/contexts";
import { AreaOfInterest } from "../client/api";

export function useAoi(value: AreaOfInterest | undefined, setValue: (val: AreaOfInterest) => void)
  : {
  aoiItems: ClavaSelectItems[],
  selectedAoi: string;
  onChangeAoi: (val: string | undefined) => void
} {
  const { aois, aoi, l } = useContext(ClavaContext);
  const aoiItems = useMemo(() => {
    return aois.map(mapTranslatableToItem(l));
  }, [l, aois]);
  const selectedAoi = useMemo(() => {
    if (!value) return aoi.toString(10);
    return value.id.toString(10);
  }, [value, aoi]);

  const onChangeAoi = useCallback((val: string | undefined) => {
    if (val) {
      const id = parseInt(val, 10);
      const aoiObj = aois.find(a => a.id === id);
      if (aoiObj) {
        setValue(aoiObj);
      }
    }
  }, [setValue, aois]);

  return { aoiItems, selectedAoi, onChangeAoi };
}

export function useAois<B extends boolean, T extends (B extends true ? IDType : AreaOfInterest)>(
  isId: B,
  value: T[],
  setValue: (val: T[]) => void): {
  aoiItems: ClavaSelectItems[],
  selectedAois: string[];
  onChangeAois: (val: string[]) => void
} {
  const { aois, l } = useContext(ClavaContext);
  const aoiItems = useMemo(() => {
    return aois.map(mapTranslatableToItem(l));
  }, [l, aois]);
  const selectedAois = useMemo(() => {
    return value.map(aoi => isId ? aoi.toString(10) : (aoi as AreaOfInterest).id.toString(10));
  }, [value, isId]);
  const onChangeAois = useCallback((val: string[]) => {
    const idArr = val.map(v => parseInt(v, 10));
    if (isId) {
      setValue(idArr as T[]);
    } else {
      const aoisF = aois.filter(aoi => idArr.includes(aoi.id));
      setValue(aoisF as T[]);
    }
  }, [setValue, aois, isId]);
  return { aoiItems, selectedAois, onChangeAois };
}