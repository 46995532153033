import React from "react";
import Header from "../components/Layout/Header";
import Sidebar from "../components/Layout/Sidebar";
import Footer from "../components/Layout/Footer";
import { Outlet } from "react-router-dom";
import SiteBlocker from "../components/Atoms/SiteBlocker";
import TaskView from "../components/Tasks/TaskView";


const Main: React.FC = () => {
  return (
    <SiteBlocker>
      <div className="flex flex-row">
        <Sidebar />
        <div className="flex-1 mx-1 flex flex-col items-stretch justify-start">
          <Header />
          <Outlet />
          <Footer />
        </div>
        <TaskView />
      </div>
    </SiteBlocker>
  );
};

export default Main;
