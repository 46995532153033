import React, { useCallback, useContext, useMemo, useState } from "react";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { TableRowItem, useList } from "../../hooks/useList";
import { SportEnum, Team, TeamListElement } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import { showTranslated, translate } from "../../config/translator";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import useServer from "../../hooks/useServer";
import { IDType } from "../../config/types";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router";
import Filter, { FilterProp } from "../../components/Views/Filter";
import MessageBox from "../../components/Atoms/MessageBox";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faPlus } from "@fortawesome/pro-solid-svg-icons";
import useBoolState from "../../hooks/useBoolState";
import CreateTeamModal from "../../components/Views/CreateTeamModal";
import TeamIcon from "../../components/Icons/TeamIcon";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import CsvView from "../../components/Import/CsvView";
import FileUploader from "../../components/FileUploader/FileUploader";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const items: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  },
  {
    label: "league"
  },
  {
    label: "status"
  },
  {
    label: "officialName"
  },
  {
    label: "internalName"
  },
  {
    label: "action"
  }
];
const TeamList = () => {
  const { l, filterAoi, sports } = useContext(ClavaContext);
  const [del, setDel] = useState<TeamListElement>();
  const toggleDelete = useCallback(() => {
    setDel(undefined);
  }, []);
  const navigator = useNavigate();
  const mapper = useCallback((item: TeamListElement): TableRowItem => {
    const status = !!item.leagues.find(le => le.active);
    return {
      search: showTranslated(item.name, l).toLowerCase(),
      id: item.id,
      items: [
        {
          label: item.id
        }, {
          icon: item.emblem,
          label: showTranslated(item.name, l)
        }, {
          label: item.leagues.filter(le => le.active).map(le => `${showTranslated(le.name, l)} ${le.year} {Id:league${le.id}}`).join("\n")
        }, {
          pill: {
            type: status ? "success" : "danger",
            text: status ? "active" : "inactive"
          }
        }, {
          label: item.officialName
        }, {
          label: item.internalName
        }, {
          buttons: [{
            icon: faPencil,
            onClick: () => {
              navigator("/team/" + item.id);
            },
            type: "primary"
          },
            {
              icon: faTrash,
              onClick: () => {
                setDel(item);
              },
              type: "danger"
            }]
        }]
    };
  }, [l, navigator]);
  const {
    q,
    setQ,
    setItems,
    filtered,
    selected,
    allSelected,
    onCheck,
    onCheckAll,
    limit
  } = useList(mapper, 30);
  const { call, loading } = useServer(true);
  const [leagueId, setLeagueId] = useState<IDType>();
  const [aoiId, setAoiId] = useState<string | undefined>(filterAoi);
  const [importCsv, toggleImportCsv] = useBoolState(false);
  const [file, setFile] = useState<File>();
  const [create, toggleCreate] = useBoolState(false);
  const [active, setActive] = useState<boolean>(true);
  const [sportsF, setSportsF] = useState<SportEnum>();
  const onSearch = useCallback((off: number, lim: number) => {
    return call("searchTeams", [q.length === 0 ? " " : q, off, lim, leagueId, aoiId, active]);
  }, [q, call, leagueId, aoiId, active]);
  useInfiniteScroll(onSearch, setItems, 0, limit);
  const filters = useMemo<FilterProp<"sportsFilter" | "leagueFilter" | "areaOfInterestFilter" | "active">[]>(() => {
    return [
      {
        type: "sportsFilter",
        val: sportsF,
        setVal: setSportsF
      },
      {
        type: "areaOfInterestFilter",
        val: aoiId,
        setVal: setAoiId
      },
      {
        type: "leagueFilter",
        val: leagueId,
        setVal: setLeagueId
      },
      {
        type: "active",
        val: active,
        setVal: setActive
      }
    ];
  }, [aoiId, active, sportsF, leagueId]);
  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("deleteTeam", [id], "deleteFailedBcsSquadMatch").then(() => {
        setItems(itms => itms ? itms.filter(it => it.id !== id) : undefined);
      });
    }
  }, [del, setItems, call]);
  const onCreateTeam = useCallback((t: Team) => {
    setItems(itms => itms ? [t as TeamListElement].concat(itms) : [t]);
  }, [setItems]);
  const onCancelUpload = useCallback(() => {
    setFile(undefined);
  }, []);
  const onFinishUpload = useCallback(() => {
    setFile(undefined);
    toggleImportCsv();
  }, [toggleImportCsv]);
  if (!!file) {
    return (
      <CsvView file={file} className="mt-2" type={"team"}
               cancel={onCancelUpload} finish={onFinishUpload} />
    );
  }
  return (
    <React.Fragment>
      <Breadcrumps>
        <ClavaButton onClick={toggleCreate} light>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {translate("createTeam", l)}
        </ClavaButton>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"teamManagement"} onSearch={setQ} q={q}
                         icon={<TeamIcon />}>
          <ClavaButton onClick={toggleImportCsv} lightSecondary className="mr-2">
            <FontAwesomeIcon icon={faFileCsv} size="lg" className="mr-1" />
            {translate("importCsv", l)}
          </ClavaButton>
          <Filter filter={filters} sports={sports} uniqueId={"team-filters"}
                  className="mr-2" />
        </ListBlockHeader>
        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <Table>
            <TableHeader items={items} onCheckAll={onCheckAll} allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        checked={selected.includes(fil.id)}
                        key={"teamManagement-" + fil.id} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>
      {importCsv && !file && (
        <FileUploader as={1} type={"csv"} callback={setFile} close={toggleImportCsv} />
      )}
      <MessageBox type={"danger"} open={!!del} toggle={toggleDelete}
                  msg={translate("sureDelete", l, { "[name]": del ? showTranslated(del.name, l) : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDelete}
                  btn2Text={translate("no", l)} />
      {create && (
        <CreateTeamModal onAdd={onCreateTeam} close={toggleCreate} />
      )}
    </React.Fragment>
  );
};

export default TeamList;
