import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  ImportCell,
  ImportRow,
  parseCsv,
  Parsed,
  ParseError,
  parseRowLeague,
  parseRowMatch,
  parseRowPlayer,
  parseRowTeam
} from "../../config/import";
import useBoolState from "../../hooks/useBoolState";
import TableHeader from "../Table/TableHeader";
import { translate, TranslatorKeys } from "../../config/translator";
import RoundedBlock from "../Atoms/RoundedBlock";
import Table from "../Table/Table";
import ClavaButton from "../Atoms/ClavaButton";
import { ClavaContext } from "../../config/contexts";
import TableRowEditable from "../Table/TableRowEditable";
import ListBlockHeader from "../Layout/ListBlockHeader";
import { TableEditableRowItem, useEditableList } from "../../hooks/useEditableList";
import ClavaSwitch from "../Atoms/ClavaSwitch";
import { TableCellEditableItem } from "../Table/TableCellEditable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faMagnifyingGlassChart,
  faSave,
  faTrash
} from "@fortawesome/pro-regular-svg-icons";
import {
  LeagueImport_Output,
  LeagueImportResponse,
  LeagueMatchImport_Output,
  LeagueMatchImportResponse,
  SquadImport_Output,
  SquadImportResponse,
  TeamImport_Output,
  TeamImportResponse
} from "../../client/api";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../Atoms/LoadingBlock";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import client from "../../client";
import { saveTask } from "../../redux/actions/generalActions";
import { useDispatch } from "react-redux";
import { AllActions } from "../../redux/actions/types";
import { Dispatch } from "redux";
import { TableHeaderItem } from "../Table/TableHeaderItemView";

const matchHeaders: TableHeaderItem[] = [
  {
    label: "row"
  },
  {
    label: "currentMatchDay"
  },
  {
    label: "date"
  },
  {
    label: "time"
  },
  {
    label: "team1"
  },
  {
    label: "team2"
  },
  {
    label: "leagueId"
  }
];
const playerHeaders: TableHeaderItem[] = [
  {
    label: "row"
  },
  {
    label: "givenName"
  },
  {
    label: "familyName"
  },
  {
    label: "position"
  }
];
const leagueHeaders: TableHeaderItem[] = [
  {
    label: "row"
  },
  {
    label: "name"
  },
  {
    label: "province"
  }
];
const teamHeaders: TableHeaderItem[] = [
  {
    label: "row"
  },
  {
    label: "name"
  },
  {
    label: "officialName"
  },
  {
    label: "emblemUrl"
  },
  {
    label: "league"
  }
];
const LETTERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U"
];

function arrayMove(arr: ImportCell[], idx: number, up: boolean): ImportCell[] {
  if ((up && (idx + 1) >= arr.length) || (!up && (idx - 1) < 0)) {
    return arr;
  }
  if (up) {
    return arr.slice(0, idx).concat([arr[idx + 1]]).concat([arr[idx]]).concat(arr.slice(idx + 2));
  }
  return arr.slice(0, idx - 1).concat([arr[idx]]).concat([arr[idx - 1]]).concat(arr.slice(idx + 1));
}

const CsvView: React.FC<{
  file: File,
  className?: string,
  cancel: () => void;
  finish: () => void;
  type: "match" | "player" | "league" | "team"
}> = ({
        file,
        className, type,
        cancel, finish
      }) => {
  const { l } = useContext(ClavaContext);
  const [hasHeader, toggleHasHeader] = useBoolState(true);
  const [ignoreErrors, toggleIgnoreErrors] = useBoolState(false);
  const [parseErrors, setErrors] = useState<ParseError[]>([]);
  const [data, setData] = useState<Parsed>();
  const headers = useMemo<TableHeaderItem[]>(() => {
    if (type === "match") {
      if (data && data.lines.length > 0 && data.lines[0].cols.length > (matchHeaders.length - 1))
        return matchHeaders.concat(Array(data.lines[0].cols.length - matchHeaders.length + 1).fill(0).map((_, idx) => ({
          label: LETTERS[idx] as TranslatorKeys
        })));
      return matchHeaders;
    } else if (type === "player") {
      if (data && data.lines.length > 0 && data.lines[0].cols.length > (playerHeaders.length - 1))
        return playerHeaders.concat(Array(data.lines[0].cols.length - playerHeaders.length + 1).fill(0).map((_, idx) => ({
          label: LETTERS[idx] as TranslatorKeys
        })));
      return playerHeaders;
    } else if (type === "league") {
      if (data && data.lines.length > 0 && data.lines[0].cols.length > (leagueHeaders.length - 1))
        return leagueHeaders.concat(Array(data.lines[0].cols.length - leagueHeaders.length + 1).fill(0).map((_, idx) => ({
          label: "province",
          key: "province-" + idx

        })));
      return leagueHeaders;
    } else if (type === "team") {
      if (data && data.lines.length > 0 && data.lines[0].cols.length > (teamHeaders.length - 1))
        return teamHeaders.concat(Array(data.lines[0].cols.length - teamHeaders.length + 1).fill(0).map((_, idx) => ({
          label: LETTERS[idx] as TranslatorKeys
        })));
      return teamHeaders;
    } else {
      return [];
    }
  }, [type, data]);
  const mapper = useCallback((item: ImportRow): TableEditableRowItem => {
    const firstCol: TableCellEditableItem = {
      col: -1,
      value: item.row.toString(10),
      readonly: true
    };
    return {
      row: item.row,
      items: [firstCol].concat(item.cols.map<TableCellEditableItem>(col => ({
        ...col,
        readonly: false
      }))),
      search: item.cols.map(c => c.value).join(" ").toLowerCase()
    };
  }, []);
  const {
    q,
    setQ,
    selected,
    allSelected,
    onCheckAll,
    onUncheckAll,
    onCheck,
    items,
    filtered,
    setItems
  } = useEditableList(mapper, 10000);
  const { call, loading } = useServer(false);
  const [importResultMatch, setImportResultMatch] = useState<LeagueMatchImportResponse>();
  const [importResultPlayer, setImportResultPlayer] = useState<SquadImportResponse>();
  const [importResultTeam, setImportResultTeam] = useState<TeamImportResponse>();
  const [importResultLeague, setImportResultLeague] = useState<LeagueImportResponse>();
  const setItemsCont = useCallback((parsed: Parsed, h: boolean) => {
    setData(parsed);
    if (h) {
      setItems(parsed.lines.slice(1));
    } else {
      setItems(parsed.lines);
    }
  }, [setItems]);
  const parse = useCallback(() => {
    parseCsv(file).then((parsed) => {
      setData(parsed);
      setItemsCont(parsed, hasHeader);
    });
  }, [setItemsCont, file, hasHeader]);
  const onChangeData = useCallback((row: number, col: number, value: string) => {
    setItems(d => d ? d.map(r => r.row === row ? {
      ...r,
      cols: r.cols.map(c => c.col === col ? {
        ...c,
        value
      } : c)
    } : r) : undefined);
  }, [setItems]);
  const onUpload = useCallback(() => {
    if (items) {
      if (type === "match") {
        const { errors, result } = parseRowMatch(items);
        if (errors.length !== 0) {
          if (ignoreErrors) {
            setErrors([]);
            toggleIgnoreErrors();
            call("importMatches", [result]).then(setImportResultMatch);
          } else {
            setErrors(errors);
          }
        } else {
          call("importMatches", [result]).then(setImportResultMatch);
          setErrors(errors);
        }
      } else if (type === "player") {
        const { errors, result } = parseRowPlayer(items);
        if (errors.length !== 0) {
          if (ignoreErrors) {
            setErrors([]);
            toggleIgnoreErrors();
            call("importPlayers", [result]).then(setImportResultPlayer);
          } else {
            setErrors(errors);
          }
        } else {
          call("importPlayers", [result]).then(setImportResultPlayer);
          setErrors(errors);
        }
      } else if (type === "team") {

        const { errors, result } = parseRowTeam(items);
        if (errors.length !== 0) {
          if (ignoreErrors) {
            setErrors([]);
            toggleIgnoreErrors();
            call("importTeam", [result]).then(setImportResultTeam);
          } else {
            setErrors(errors);
          }
        } else {
          call("importTeam", [result]).then(setImportResultTeam);
          setErrors(errors);
        }
      } else if (type === "league") {
        const { errors, result } = parseRowLeague(items);
        if (errors.length !== 0) {
          if (ignoreErrors) {
            setErrors([]);
            toggleIgnoreErrors();
            call("importLeague", [result]).then(setImportResultLeague);
          } else {
            setErrors(errors);
          }
        } else {
          call("importLeague", [result]).then(setImportResultLeague);
          setErrors(errors);
        }
      }
    }
  }, [call, type, ignoreErrors, toggleIgnoreErrors, items]);
  const setHasHeaders = useCallback((val: boolean) => {
    toggleHasHeader();
    if (data) {
      setItemsCont(data, val);
    }
  }, [setItemsCont, toggleHasHeader, data]);
  const onMoveLeft = useCallback((idx: number) => {
    if (data) {
      const newData: Parsed = {
        filename: data.filename,
        lines: data.lines.map(line => {
          return {
            ...line,
            cols: arrayMove(line.cols, idx - 1, false)
          };
        }),
        errors: data.errors
      };
      setData(newData);
      setItemsCont(newData, hasHeader);
    }
  }, [data, hasHeader, setItemsCont]);
  const onMoveRight = useCallback((idx: number) => {
    if (data) {
      const newData: Parsed = {
        filename: data.filename,
        lines: data.lines.map(line => {
          return {
            ...line,
            cols: arrayMove(line.cols, idx - 1, true)
          };
        }),
        errors: data.errors
      };
      setData(newData);
      setItemsCont(newData, hasHeader);
    }
  }, [data, hasHeader, setItemsCont]);
  const onDelete = useCallback(() => {
    if (data) {
      const newData: Parsed = {
        filename: data.filename,
        lines: data.lines.filter(line => !selected.includes(line.row)),
        errors: data.errors
      };
      onUncheckAll();
      setData(newData);
      setItemsCont(newData, hasHeader);
    }
  }, [data, onUncheckAll, selected, hasHeader, setItemsCont]);
  const importResultMsg = useMemo(() => {
    if (type === "match") {
      if (!importResultMatch) {
        return "";
      }
      return importResultMatch.filter(res => typeof res === "string").length + " " + translate("errors", l);
    } else if (type === "player") {
      if (!importResultPlayer) {
        return "";
      }
      return importResultPlayer.filter(res => typeof res === "string").length + " " + translate("errors", l);
    } else if (type === "team") {
      if (!importResultTeam) {
        return "";
      }
      return importResultTeam.filter(res => typeof res === "string").length + " " + translate("errors", l);
    } else if (type === "league") {
      if (!importResultLeague) {
        return "";
      }
      return importResultLeague.filter(res => typeof res === "string").length + " " + translate("errors", l);
    }
    return "";
  }, [importResultMatch, importResultPlayer, importResultLeague, importResultTeam, type, l]);
  const importResultErrors = useMemo<string[]>(() => {
    if (type === "match") {
      if (!importResultMatch) {
        return [];
      }
      return importResultMatch.filter(res => typeof res === "string") as string[];
    } else if (type === "player") {
      if (!importResultPlayer) {
        return [];
      }
      return importResultPlayer.filter(res => typeof res === "string") as string[];
    } else if (type === "league") {
      if (!importResultLeague) {
        return [];
      }
      return importResultLeague.filter(res => typeof res === "string") as string[];
    } else if (type === "team") {
      if (!importResultTeam) {
        return [];
      }
      return importResultTeam.filter(res => typeof res === "string") as string[];

    }
    return [];
  }, [importResultPlayer, importResultLeague, importResultTeam, importResultMatch, type]);
  const importResultSuccessMatch = useMemo<LeagueMatchImport_Output[]>(() => {
    if (!importResultMatch) {
      return [];
    }
    return importResultMatch.filter(res => typeof res !== "string") as LeagueMatchImport_Output[];
  }, [importResultMatch]);

  const importResultSuccessPlayer = useMemo<SquadImport_Output[]>(() => {
    if (!importResultPlayer) {
      return [];
    }
    return importResultPlayer.filter(res => typeof res !== "string") as SquadImport_Output[];
  }, [importResultPlayer]);
  const importResultSuccessTeam = useMemo<TeamImport_Output[]>(() => {
    if (!importResultTeam) {
      return [];
    }
    return importResultTeam.filter(res => typeof res !== "string") as TeamImport_Output[];
  }, [importResultTeam]);
  const importResultSuccessLeague = useMemo<LeagueImport_Output[]>(() => {
    if (!importResultLeague) {
      return [];
    }
    return importResultLeague.filter(res => typeof res !== "string") as LeagueImport_Output[];
  }, [importResultLeague]);
  const dispatch = useDispatch<Dispatch<AllActions>>();
  const onFinalUpload = useCallback(() => {
    if (type === "match") {
      if (importResultMatch) {
        setImportResultMatch(undefined);
        call("startImportMatchesTask", [importResultSuccessMatch]).then((tsk) => {
          saveTask(dispatch, {
            ...tsk,
            title: translate("importCsv", l) + " " + file.name
          });
          finish();
        });
      }
    } else if (type === "player") {
      if (importResultPlayer) {
        setImportResultPlayer(undefined);
        call("startImportPlayersTask", [importResultSuccessPlayer]).then((tsk) => {
          saveTask(dispatch, {
            ...tsk,
            title: translate("importCsv", l) + " " + file.name
          });
          finish();
        });
      }
    } else if (type === "team") {
      if (importResultTeam) {
        setImportResultTeam(undefined);
        call("startImportTeam", [importResultSuccessTeam]).then((tsk) => {
          saveTask(dispatch, {
            ...tsk,
            title: translate("importCsv", l) + " " + file.name
          });
          finish();
        });
      }
    } else if (type === "league") {
      if (importResultLeague) {
        setImportResultLeague(undefined);
        call("startImportLeague", [importResultSuccessLeague]).then((tsk) => {
          saveTask(dispatch, {
            ...tsk,
            title: translate("importCsv", l) + " " + file.name
          });
          finish();
        });
      }
    }
  }, [dispatch, type, file.name, l, finish, call, importResultSuccessLeague, importResultSuccessTeam, importResultSuccessMatch, importResultSuccessPlayer, importResultMatch, importResultLeague, importResultTeam, importResultPlayer]);
  const searchTeams = useCallback((val: string, index: number) => {
    return new Promise<string[]>((resolve) => {
      if (!!data) {
        const row = filtered.find(f => f.row === index);
        if (row) {
          const leagueId = row.items[row.items.length - 1];
          if (leagueId) {
            client().searchTeams(val, 0, 10, parseInt(leagueId.value, 10)).then((teams) => {
              resolve(teams.map(t => t.officialName));
            }, () => {
              resolve([]);
            });
          } else resolve([]);
        } else resolve([]);
      } else resolve([]);
    });
  }, [data, filtered]);
  const renderFiltered = useCallback((row: TableEditableRowItem, idx: number) => {
    if (data) {
      let success: LeagueMatchImport_Output | TeamImport_Output | LeagueImport_Output | SquadImport_Output | undefined;
      let error: string | undefined;
      if (type === "match" && !!importResultMatch && typeof importResultMatch[idx] !== "string") {
        success = importResultMatch[idx] as LeagueMatchImport_Output;
      }
      if (type === "match" && !!importResultMatch && typeof importResultMatch[idx] === "string") {
        error = importResultMatch[idx] as string;
      }
      if (type === "player" && !!importResultPlayer && typeof importResultPlayer[idx] !== "string") {
        success = importResultPlayer[idx] as SquadImport_Output;
      }
      if (type === "player" && !!importResultPlayer && typeof importResultPlayer[idx] === "string") {
        error = importResultPlayer[idx] as string;
      }
      if (type === "league" && !!importResultLeague && typeof importResultLeague[idx] !== "string") {
        success = importResultLeague[idx] as LeagueImport_Output;
      }
      if (type === "league" && !!importResultLeague && typeof importResultLeague[idx] === "string") {
        error = importResultLeague[idx] as string;
      }
      if (type === "team" && !!importResultTeam && typeof importResultTeam[idx] !== "string") {
        success = importResultTeam[idx] as TeamImport_Output;
      }
      if (type === "team" && !!importResultTeam && typeof importResultTeam[idx] === "string") {
        error = importResultTeam[idx] as string;
      }
      return (
        <TableRowEditable autocomplete={searchTeams} row={row.row} items={row.items}
                          onCheck={onCheck}
                          key={data.filename + "-row-" + row.row}
                          onChange={onChangeData} checked={selected.includes(row.row)}
                          success={success}
                          error={error}
        />
      );
    }
    return null;
  }, [data, importResultMatch, importResultTeam, importResultLeague, importResultPlayer, onChangeData, onCheck, searchTeams, selected, type]);
  const taskStartEnabled = useMemo(() => {
    return !!data && (!!importResultMatch || !!importResultPlayer || !!importResultTeam || !!importResultLeague);
  }, [importResultPlayer, importResultMatch, importResultTeam, importResultLeague, data]);
  return (
    <RoundedBlock className={className}>
      <ListBlockHeader title={"importCsv"} onSearch={setQ} q={q}>
        <div className="flex flex-row items-center">
          <ClavaButton onClick={cancel} lightSecondary className="mr-2">
            <FontAwesomeIcon icon={faChevronLeft} size="lg" className="mr-1" />
            {translate("cancel", l)}
          </ClavaButton>
          <ClavaSwitch onChange={setHasHeaders} value={hasHeader} className="mr-2"
                       label={translate("hasHeaders", l)} horizontal />
          {!data && (
            <ClavaButton onClick={parse} className="mr-2">
              <FontAwesomeIcon icon={faMagnifyingGlassChart} size="lg" className="mr-1" />
              {translate("parse", l)}
            </ClavaButton>
          )}
          <ClavaButton onClick={onDelete} disabled={selected.length === 0} danger className="mr-2">
            <FontAwesomeIcon icon={faTrash} size="lg" className="mr-1" />
            {translate("delete", l)}
          </ClavaButton>
          <LoadingBlock isLoading={loading} className="mr-2 flex flex-row items-center">
            <ClavaButton onClick={onUpload} disabled={!data}>
              <FontAwesomeIcon icon={faSave} size="lg" className="mr-1" />
              {translate("upload", l)}
            </ClavaButton>
            {taskStartEnabled && (
              <ClavaButton onClick={onFinalUpload} disabled={importResultErrors.length !== 0}
                           className="ml-2">
                <FontAwesomeIcon icon={faUpload} size="lg" className="mr-1" />
                {translate("startTask", l)}
              </ClavaButton>
            )}
          </LoadingBlock>
        </div>
      </ListBlockHeader>
      {parseErrors.length !== 0 && (
        <div>
          <ClavaSwitch onChange={toggleIgnoreErrors} label={translate("ignoreErrors", l)}
                       value={ignoreErrors} />
          {parseErrors.map((err) => (
            <span className="block"
                  key={err.row + "-" + err.error}>{translate(err.error, l, err.params)}</span>
          ))}
        </div>
      )}
      <span>{importResultMsg}</span>
      <Table>
        <TableHeader items={headers} moveable onMoveLeft={onMoveLeft} onMoveRight={onMoveRight}
                     onCheckAll={onCheckAll} allChecked={allSelected} />
        <tbody>
        {!data ? (
          <tr>
            <td colSpan={headers.length} className="text-center">
              {file.name}
            </td>
          </tr>
        ) : filtered.map(renderFiltered)}
        </tbody>
      </Table>


      {/* <MessageBox type={"info"} open={!!importResult} toggle={removeImportResult}
                  title={translate("importResult", l)}
                  msg={importResultMsg} btn1Clb={onFinalUpload}
                  btn1Disabled={importResultErrors.length !== 0}
                  btn1Text={translate("startTask", l)}
                  btn2Clb={removeImportResult} btn2Text={translate("edit", l)}>
        <div className="max-h-[50vh] overflow-scroll">
          {importResultErrors.length !== 0 && (
            <div className="border border-red rounded-xl p-2">
              {importResultErrors.map(err => (
                <span className="block text-red font-bold" key={err}>{err}</span>
              ))}
            </div>
          )}
          {importResultSuccess.length !== 0 && (
            <div className="border border-green rounded-xl p-2">
              {importResultSuccess.map((match, idx) => (
                <div className="flex flex-row items-center justify-start p-2"
                     key={importResultMsg + "-" + idx}>
                  <div className="flex-1 flex items-start justify-between">
                    <span>{formatDate(match.startTime, l)}</span>
                    <span>{showTranslated(match.league.name, l)}</span>
                  </div>
                  <div className="flex-1 flex items-start justify-between">
                    <div className="flex flex-row items-center justify-start">
                      <div className="w-12 aspect-square">
                        <ClavaImage width={"100%"} imageUrl={match.team1.emblemUrl} />
                      </div>
                      <span>{showTranslated(match.team1.name, l)}</span>
                    </div>
                    <div className="flex flex-row items-center justify-start">
                      <div className="w-12 aspect-square">
                        <ClavaImage width={"100%"} imageUrl={match.team2.emblemUrl} />
                      </div>
                      <span>{showTranslated(match.team2.name, l)}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </MessageBox> */}
    </RoundedBlock>
  );
};

export default CsvView;
