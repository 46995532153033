import React, { useCallback, useContext, useEffect, useState } from "react";
import { IDType } from "../../config/types";
import { League, LeagueListElement } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import useBoolState from "../../hooks/useBoolState";
import { showTranslated, translate } from "../../config/translator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import MessageBox from "../../components/Atoms/MessageBox";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import client from "../../client";
import { useNavigate } from "react-router";

const TeamLeagueItem: React.FC<{
  teamId: IDType,
  league: LeagueListElement,
  deletedLeague: (leagueId: IDType) => void
}> = ({ teamId, deletedLeague, league }) => {
  const { l } = useContext(ClavaContext);
  const navigator = useNavigate();
  const [wantDelete, toggleDelete] = useBoolState(false);
  const [tmpLeague, setTmpLeague] = useState<League>();
  const { call, loading, StatusComponent } = useServer(false);
  useEffect(() => {
    client().getLeague(league.id).then(setTmpLeague);
  }, [league.id]);
  const onDelete = useCallback(() => {
    call("removeSingleTeamFromLeague", [league.id, teamId], "deleteFailedBcsMatch").then(() => {
      setTimeout(() => {
        deletedLeague(league.id);
      });
    });
  }, [deletedLeague, league.id, call, teamId]);
  const navLeague = useCallback(() => {
    navigator("/league/" + league.id);
  }, [navigator, league.id]);
  return (
    <div
      className="flex-1 rounded-xl py-2 px-4 border border-light-gray dark:border-light-gray-dark m-2 flex flex-row items-center justify-between">
      <button onClick={navLeague}
              className="flex flex-col items-start justify-evenly self-stretch ouline-0">
        <span className="font-semibold">{`${showTranslated(league.name, l)} ${league.year}`}</span>
        {tmpLeague && (
          <span>{tmpLeague.areasOfInterest.map(aoi => showTranslated(aoi.name, l)).join(" | ")}</span>)}
      </button>
      <button onClick={toggleDelete} className="m-2 p-2 ouline-0">
        <LoadingBlock isLoading={loading}>
          {StatusComponent}
          <FontAwesomeIcon icon={faTrash} size="lg" />
        </LoadingBlock>
      </button>
      <MessageBox type={"danger"} open={wantDelete} toggle={toggleDelete}
                  msg={translate("sureDelete", l, { "[name]": showTranslated(league.name, l) })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Text={translate("no", l)}
                  btn2Clb={toggleDelete} />
    </div>
  );
};

export default TeamLeagueItem;
