import React, { useCallback, useContext, useEffect } from "react";
import { ClavaContext } from "../../config/contexts";
import { LeagueCategory } from "../../client/api";
import { useNavigate } from "react-router";
import { showTranslated, translate } from "../../config/translator";
import { TableRowItem, useList } from "../../hooks/useList";
import { numberedHash } from "../../config/utils";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/pro-solid-svg-icons";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LeagueIcon from "../../components/Icons/LeagueIcon";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import useServer from "../../hooks/useServer";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const items: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  }
];
const LeagueCategories = () => {
  const { l } = useContext(ClavaContext);
  const navigator = useNavigate();
  const mapper = useCallback((lea: LeagueCategory) => {
    return {
      id: numberedHash(lea.category),
      search: lea.category.toLowerCase(),
      items: [
        { label: lea.category },
        { label: showTranslated(lea.translation, l) }
      ]
    } as TableRowItem;
  }, [l]);
  const {
    q,
    setQ,
    onCheckAll,
    selected,
    allSelected,
    filtered,
    setItems,
    onCheck
  } = useList<LeagueCategory>(mapper, 100);
  const navLeagues = useCallback(() => {
    navigator("/league");
  }, [navigator]);
  const { loading, call } = useServer(true);
  useEffect(() => {
    call("getLeagueCategories", []).then(setItems);
  }, [call, setItems]);

  return (<React.Fragment>
      <Breadcrumps>
        <ClavaButton onClick={navLeagues} lightSecondary className="mr-2">
          <FontAwesomeIcon icon={faLayerGroup} className="mr-2" />
          {translate("categories", l)}
        </ClavaButton>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"categories"} onSearch={setQ} q={q} icon={<LeagueIcon />}>
        </ListBlockHeader>
        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <Table>
            <TableHeader items={items} onCheckAll={onCheckAll} allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        checked={selected.includes(fil.id)}
                        key={"leagueManagement-" + fil.id} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>
    </React.Fragment>
  );
};

export default LeagueCategories;
