import React, { useCallback, useContext, useEffect, useState } from "react";
import { MatchListElementList, PunishmentCreate } from "../../client/api";
import { IDType } from "../../config/types";
import { ClavaContext } from "../../config/contexts";
import { useFormData } from "../../hooks/useFormData";
import useServer from "../../hooks/useServer";
import { showTranslated, translate } from "../../config/translator";
import AsideModal from "../../components/Layout/AsideModal";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { formatDate, getMatchDate } from "../../config/utils";
import ClavaImage from "../../components/Atoms/ClavaImage";

const emptyPunishment: PunishmentCreate = {
  deduction: 0,
  teamId: 0,
  leagueId: 0,
  cause: {
    textDE: "",
    textEN: "",
    textIT: ""
  }
};
const PunishmentCreateEditModal: React.FC<{
  teamId: IDType,
  leagueId: IDType,
  close: () => void
  onRefresh: () => void
}> = ({ teamId, leagueId, onRefresh, close }) => {
  const { l } = useContext(ClavaContext);
  const form = useFormData(emptyPunishment,
    ["matchId", "teamId", "cause", "deduction"],
    {},
    [],
    [],
    ["deduction"],
    [],
    ["cause"],
    ["deduction"]
  );
  const [matchId, setMatchId] = useState<IDType>();
  const { call, StatusComponent, status, loading } = useServer(true);
  const [matches, setMatches] = useState<MatchListElementList>([]);
  const [q, setQ] = useState("");
  useEffect(() => {
    call("searchMatches", [q === "" ? " " : q, 0, 100, undefined, leagueId, teamId]).then(setMatches);
  }, [call, q, leagueId, teamId]);
  const onSave = useCallback(() => {
    if (!form.nothingChanged && matchId) {
      call("createPunishment", [{
        matchId,
        leagueId, teamId,
        deduction: form.deduction,
        cause: form.cause
      }], "saveFailed", "saveSuccess").then(() => {
        onRefresh();
      });
    }
  }, [onRefresh, form, call, leagueId, teamId, matchId]);
  return (
    <AsideModal close={close} title={translate("createPunishment", l)} saveClb={onSave}
                saveDisabled={!form.allChanged}>
      {status && (!status.success || status.key === "saveSuccess") ? StatusComponent : null}
      <div className="flex flex-col items-stretch justify-start">
        <ClavaTextInput onChange={form.onChangeCauseDe}
                        changed={form.causeChangedDe}
                        value={form.causeDe} label={translate("causeDe", l)} className="my-2" />
        <ClavaTextInput onChange={form.onChangeCauseIt} changed={form.causeChangedIt}
                        value={form.causeIt} label={translate("causeIt", l)} className="my-2" />
        <ClavaTextInput onChange={form.onChangeCauseEn} changed={form.causeChangedEn}
                        value={form.causeEn} label={translate("causeEn", l)} className="my-2" />
        <ClavaTextInput onChange={form.onChangeDeduction} changed={form.deductionChanged}
                        type="number"
                        value={form.deductionVal} label={translate("deduction", l)}
                        className="my-2" />
        <ClavaTextInput onChange={setQ} value={q} label={translate("search", l)} className="mt-4" />
      </div>
      <LoadingBlock isLoading={loading} className="flex flex-col items-stretch justify-start">
        {matches.map((m) => (
          <button onClick={() => {
            setMatchId(m.id);
          }}
                  className={"p-2 my-1 flex flex-row items-center rounded-lg border border-light-gray dark:border-b-light-gray-dark hover:border-primary " + (matchId === m.id ? "bg-primary-30" : "")}
                  key={"searchm-" + m.id}>
            <span className="mr-2">
              {formatDate(getMatchDate(m), l)}
            </span>
            <div className="flex-1 flex flex-col items-stretch justify-start">
              <div className="flex flex-row items-start justify-start my-1">
                <div className="w-6 h-6"><ClavaImage width={"100%"} image={m.team1.emblem} /></div>
                <div className="flex-1 mx-2 flex flex-row items-start justify-start">
                  <span>{showTranslated(m.team1.name, l)}</span>
                </div>
                <span className="font-bold">{m.goal1}</span>
              </div>
              <div className="flex flex-row items-start justify-start my-1">
                <div className="w-6 h-6"><ClavaImage width={"100%"} image={m.team2.emblem} /></div>
                <div className="flex-1 mx-2 flex flex-row items-start justify-start">
                  <span>{showTranslated(m.team2.name, l)}</span>
                </div>
                <span className="font-bold">{m.goal2}</span>
              </div>
            </div>
          </button>
        ))}
      </LoadingBlock>
    </AsideModal>
  );
};

export default PunishmentCreateEditModal;
