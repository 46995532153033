import React, { useContext } from "react";
import { LeagueMatchImport_Output } from "../../client/api";
import { formatDate } from "../../config/utils";
import { showTranslated } from "../../config/translator";
import ClavaImage from "../Atoms/ClavaImage";
import { ClavaContext } from "../../config/contexts";

const MatchSuccess: React.FC<{ success: LeagueMatchImport_Output }> = ({ success }) => {
  const { l } = useContext(ClavaContext);
  return (
    <>
      <div className="flex-1 flex flex-col items-center justify-center">
        <span>{formatDate(success.startTime, l)}</span>
        <span>{showTranslated(success.league.name, l)}</span>
      </div>
      <div className="flex-1 flex items-start justify-between">
        <div className="flex flex-row items-center justify-start">
          <div className="w-12 aspect-square">
            <ClavaImage width={"100%"} imageUrl={success.team1.emblemUrl} />
          </div>
          <span>{showTranslated(success.team1.name, l)}</span>
        </div>
        <div className="flex flex-row items-center justify-start">
          <div className="w-12 aspect-square">
            <ClavaImage width={"100%"} imageUrl={success.team2.emblemUrl} />
          </div>
          <span>{showTranslated(success.team2.name, l)}</span>
        </div>
      </div>
    </>
  );
};

export default MatchSuccess;
