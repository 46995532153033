import React, { useContext, useEffect, useMemo, useState } from "react";
import { Tournament } from "../../client/api";
import { showTranslated } from "../../config/translator";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import useEditParams from "../../hooks/useEditParams";
import TournamentEditForm from "./TournamentEditForm";
import TournamentEditTeamsGroupsStages from "./TournamentEditTeamsGroupsStages";

const TournamentEdit: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const { id, navNotFound } = useEditParams("tournamentId", "/tournament");
  const [tournament, setTournament] = useState<Tournament>();
  const { loading, call, status, StatusComponent } = useServer(true);
  useEffect(() => {
    if (id) {
      call("getCup", [id], "notFound").then(setTournament, navNotFound);
    } else {
      navNotFound();
    }
  }, [call, navNotFound, id]);
  const breadcrump = useMemo(() => {
    if (!tournament) {
      return [];
    }
    return [
      showTranslated(tournament.name, l)
    ];
  }, [tournament, l]);

  return (
    <LoadingBlock isLoading={loading}>
      {!!status && !!status.key && StatusComponent}
      <Breadcrumps additional={breadcrump}></Breadcrumps>
      {!!tournament && <TournamentEditForm tournament={tournament} />}
      {!!tournament &&
        <TournamentEditTeamsGroupsStages tournament={tournament} changes={setTournament} />}
    </LoadingBlock>
  );
};
TournamentEdit.displayName = "TournamentEdit";
export default TournamentEdit;