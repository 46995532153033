import React, { useCallback, useContext, useMemo, useState } from "react";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LeagueIcon from "../../components/Icons/LeagueIcon";
import { ClavaContext } from "../../config/contexts";
import { showTranslated, translate } from "../../config/translator";
import { TournamentMinimal } from "../../client/api";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import { useNavigate } from "react-router";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import MessageBox from "../../components/Atoms/MessageBox";
import { TableRowItem, useList } from "../../hooks/useList";
import Filter, { FilterProp } from "../../components/Views/Filter";
import useBoolState from "../../hooks/useBoolState";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { formatDate } from "../../config/utils";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const items: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  },
  {
    label: "type"
  },
  {
    label: "startDate"
  },
  {
    label: "stopDate"
  },
  {
    label: "action"
  }
];
const TournamentList: React.FC = () => {
  const { l, filterAoi } = useContext(ClavaContext);
  const navigator = useNavigate();
  const [del, setDel] = useState<TournamentMinimal>();
  const mapper = useCallback((tournament: TournamentMinimal) => {
    return {
      id: tournament.id,
      search: showTranslated(tournament.name, l).toLowerCase(),
      items: [
        { label: tournament.id },
        { label: showTranslated(tournament.name, l) },
        { label: tournament.cup ? translate("cup", l) : translate("tournament", l) },
        { label: formatDate(new Date(tournament.dateFrom), l, false, false, true) },
        { label: formatDate(new Date(tournament.dateTo), l, false, false, true) },
        {
          centered: true,
          buttons: [
            {
              icon: faPencil,
              onClick: () => {
                navigator("/tournament/" + tournament.id);
              },
              type: "primary"
            },
            {
              icon: faTrash,
              onClick: () => {
                setDel(tournament);
              },
              type: "danger"
            }
          ]
        }
      ]
    } as TableRowItem;
  }, [l, navigator]);
  const {
    q,
    setQ,
    onCheckAll,
    selected,
    allSelected,
    filtered,
    setItems,
    sthChanges,
    onCheck, limit
  } = useList<TournamentMinimal>(mapper, 30);
  const [create, toggleCreate] = useBoolState(false);
  const { loading, call } = useServer(true);
  const [cup, setCup] = useState<boolean>(false);
  const onSearch = useCallback((off: number, lim: number) => {
    return call("searchTournaments", [q === "" ? " " : q, off, lim, cup]);
  }, [q, cup, call]);
  useInfiniteScroll(onSearch, setItems, 0, limit);
  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);

  const filters = useMemo<FilterProp<"cup">[]>(() => {
    return [
      {
        type: "cup",
        val: cup,
        setVal: setCup
      }
    ];
  }, [cup]);
  const onDelete = useCallback(() => {
    // TODO
  }, []);
  return (<>
      <Breadcrumps>
        <ClavaButton onClick={toggleCreate} light>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {translate("createLeague", l)}
        </ClavaButton>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"tournamentManagement"} onSearch={setQ} q={q} icon={<LeagueIcon />}>
          <Filter filter={filters} uniqueId={"tournament-filters"} />
        </ListBlockHeader>
        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <Table>
            <TableHeader items={items} onCheckAll={onCheckAll} allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        checked={selected.includes(fil.id)}
                        key={"leagueManagement-" + fil.id} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>

      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? showTranslated(del.name, l) : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
    </>
  );
};

export default TournamentList;
