import React from "react";

const HorizontalLine: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
                                                                                     className,
                                                                                     children
                                                                                   }) => (
  <div
    className={"border-b border-b-light-gray dark:border-b-light-gray-dark w-full py-2 mb-2 " + className}>
    {children}
  </div>
);

export default HorizontalLine;