import React, { useCallback, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileType } from "../../config/types";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import { faPhotoFilm } from "@fortawesome/pro-regular-svg-icons";
import useDropzone from "../../hooks/useDropzone";
import { faFileCsv, faFilePdf, faSquareCaretDown } from "@fortawesome/pro-solid-svg-icons";

const imageMimes = ["image/gif", "image/png", "image/jpeg", "image/webp", "image/svg+xml"];
const videoMimes = ["video/mp4", "video/avi", "video/mov", "video/webm", "video/x-msvideo", "video/mpeg", "video/quicktime", "video/h264", "video/3gpp"];

function getMimesByType(type: FileType): string[] {
  if (type === "image") {
    return imageMimes;
  }
  if (type === "video") {
    return videoMimes;
  }
  if (type === "media") {
    return imageMimes.concat(videoMimes);
  }
  if (type === "csv") {
    return ["text/csv"];
  }
  return ["application/pdf"];
}

function checkMime(type: FileType, mime: string): boolean {
  return getMimesByType(type).includes(mime.toLowerCase());
}

const FileUploaderUpload: React.FC<{ type: FileType, onSelect: (f: File) => void }> = ({
                                                                                         type,
                                                                                         onSelect
                                                                                       }) => {
  const { l } = useContext(ClavaContext);
  const isDragging = useDropzone();
  const [wrongType, setWrongType] = useState<File>();
  const onDragEnd = useCallback((e: React.DragEvent) => {
    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      if (!checkMime(type, e.dataTransfer.files[0].type)) {
        setWrongType(e.dataTransfer.files[0]);
      } else {
        onSelect(e.dataTransfer.files[0]);
      }
    }
  }, [onSelect, type]);
  const onSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      if (!checkMime(type, e.target.files[0].type)) {
        setWrongType(e.target.files[0]);
      } else {
        onSelect(e.target.files[0]);
      }
    }
  }, [onSelect, type]);
  return (
    <label htmlFor="file-input" onDrop={onDragEnd}
           className={`relative flex-1 border rounded-xl flex flex-col items-center justify-center p-8 cursor-pointer border-dashed ${isDragging ? "border-primary bg-primary-30" : "bg-light-gray dark:bg-light-gray-dark border-placeholder dark:border-placeholder-dark"}`}>
      <input type="file"
             accept={getMimesByType(type).join(",")}
             id="file-input" className="hidden" onChange={onSelectFile} />
      {isDragging ? (
        <>
          <FontAwesomeIcon icon={faSquareCaretDown} size="3x" className="text-primary m-8" />
          <span
            className="text-center text-primary font-bold text-xl">{translate("dropImageHere", l)}</span>
        </>
      ) : (
        <>
          {!!wrongType && (
            <span
              className="text-center text-red font-semibold">{`${wrongType.name}: ${translate("wrongFileType", l)} ${getMimesByType(type).map(f => f.split("/")[1]).join(", ")}`}</span>
          )}
          <FontAwesomeIcon
            icon={type === "pdf" ? faFilePdf : type === "csv" ? faFileCsv : faPhotoFilm} size="5x"
            className="text-primary m-8" />
          <span className="text-center font-semibold">{translate("uploadOrDragNDrop", l)}</span>
        </>)}
    </label>
  );
};

export default FileUploaderUpload;