import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import SingleTaskView from "./SingleTaskView";
import { loadTasks } from "../../redux/actions/generalActions";

const TaskView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    loadTasks(dispatch);
  }, [dispatch]);
  const { tasks } = useSelector<RootState, RootState["general"]>((state) => state.general);

  return (
    <div className="fixed top-8 right-0 flex flex-col items-stretch justify-start">
      {tasks.map((task) => <SingleTaskView key={"task-" + task.id} task={task} />)}
    </div>
  );
};

export default TaskView;
