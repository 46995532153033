import React, { useContext, useEffect, useMemo, useState } from "react";
import { Team } from "../../client/api";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import useEditParams from "../../hooks/useEditParams";
import { showTranslated } from "../../config/translator";
import TeamEditForm from "./TeamEditForm";
import TeamEditSquad from "./TeamEditSquad";

const TeamEdit: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const { id, navNotFound } = useEditParams("teamId", "/team");
  const [team, setTeam] = useState<Team>();
  const { loading, call, status, StatusComponent } = useServer(true);
  useEffect(() => {
    if (id) {
      call("getTeam", [id], "notFound").then(setTeam, navNotFound);
    } else {
      navNotFound();
    }
  }, [call, navNotFound, id]);
  const breadcrump = useMemo(() => {
    if (!team) {
      return [];
    }
    return [
      showTranslated(team.name, l)
    ];
  }, [team, l]);

  return (
    <LoadingBlock isLoading={loading}>
      {!!status && !!status.key && StatusComponent}
      <Breadcrumps additional={breadcrump}></Breadcrumps>
      {!!team && (<TeamEditForm team={team} changes={setTeam} />)}
      {!!team && (<TeamEditSquad teamId={team.id} />)}

    </LoadingBlock>
  );
};
export default TeamEdit;