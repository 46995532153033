import React, { useCallback, useContext, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { showTranslated, translate } from "../../config/translator";
import { Blog } from "../../client/api";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import TableRow from "../../components/Table/TableRow";
import { formatDate } from "../../config/utils";
import MessageBox from "../../components/Atoms/MessageBox";
import { TableRowItem, useList } from "../../hooks/useList";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import NewsIcon from "../../components/Icons/NewsIcon";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const items: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "title"
  },
  {
    label: "sports"
  },
  {
    label: "createDate"
  },
  {
    label: "deleted"
  },
  {
    label: "action"
  }
];
const BlogList: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const [del, setDel] = useState<Blog>();

  const navigator = useNavigate();

  const mapper = useCallback((blog: Blog) => {
    return {
      id: blog.id,
      search: showTranslated(blog.title, l).toLowerCase() + " " + (blog.summary ? showTranslated(blog.summary, l).toLowerCase() : ""),
      items: [
        { label: blog.id },
        {
          label: showTranslated(blog.title, l)
        },
        { label: blog.sports ? translate(blog.sports, l) : translate("all", l) },
        { label: formatDate(blog.date, l) },
        {
          pill: {
            type: blog.deleted ? "danger" : "success",
            text: blog.deleted ? "yes" : "no"
          }
        },
        {
          centered: true,
          buttons: [
            {
              icon: faPencil,
              onClick: () => {
                navigator("/news/" + blog.id);
              },
              type: "primary"
            },
            {
              icon: faTrash,
              onClick: () => {
                setDel(blog);
              },
              type: "danger"
            }
          ]
        }
      ]
    } as TableRowItem;
  }, [navigator, l]);
  const {
    q,
    setQ,
    setItems,
    selected,
    allSelected,
    onCheckAll,
    onCheck,
    sthChanges,
    limit,
    filtered
  } = useList(mapper, 30);
  const { call, loading } = useServer(true);
  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);
  const onSearch = useCallback((off: number, lim: number) => {
    return call("searchNews", [q.length === 0 ? " " : q, off, lim]);
  }, [q, call]);
  useInfiniteScroll(onSearch, setItems, 0, limit);

  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("deleteNews", [id]).then(() => {
        sthChanges(id);
        setDel(undefined);
      });
    }
  }, [del, call, sthChanges]);
  const onNavBlog = useCallback(() => {
    navigator("/news/create");
  }, [navigator]);
  return (
    <>
      <Breadcrumps />
      <RoundedBlock>
        <ListBlockHeader title={"news"} onSearch={setQ} q={q} icon={<NewsIcon />}>
          <ClavaButton onClick={onNavBlog} lightSecondary
                       className="mr-2">{translate("createBlog", l)}</ClavaButton>
        </ListBlockHeader>
        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <Table>
            <TableHeader items={items} onCheckAll={onCheckAll}
                         allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        key={"ad-list-" + fil.id}
                        checked={selected?.includes(fil.id)} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>

      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? showTranslated(del.title, l) : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
    </>
  );
};

export default BlogList;
