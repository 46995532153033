import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IDType } from "../../config/types";
import { ClavaContext } from "../../config/contexts";
import { League, TeamListElement } from "../../client/api";
import useServer from "../../hooks/useServer";
import AsideModal from "../Layout/AsideModal";
import { translate, TranslatorKeys } from "../../config/translator";
import LeagueSelect from "../Atoms/Common/LeagueSelect";
import ClavaTextInput from "../Atoms/ClavaTextInput";
import LoadingBlock from "../Atoms/LoadingBlock";
import TeamLineItem from "../LineItems/TeamLineItem";
import ClavaSwitch from "../Atoms/ClavaSwitch";
import { makeAoiItems } from "../../config/utils";
import ClavaSelect from "../Atoms/ClavaSelect";

const AddTeamModal: React.FC<{
  onAdd: (ids: TeamListElement[]) => void,
  close: () => void,
  single?: boolean,
  title?: TranslatorKeys,
  alreadyInIds?: IDType[],
  initialQuery?: string
}> = ({ close, single, initialQuery, title, onAdd, alreadyInIds }) => {
  const { l, regions, aois, filterAoi } = useContext(ClavaContext);
  const [selectedAoi, setSelectedAoi] = useState(filterAoi);
  const [selectedLeague, setSelectedLeague] = useState<League>();
  const [selectedTeams, setSelectedTeams] = useState<TeamListElement[]>([]);
  const [active, setActive] = useState<boolean>(true);
  const [q, setQ] = useState(initialQuery ?? "");
  const [teams, setTeams] = useState<TeamListElement[]>([]);
  const { call, loading } = useServer(true);
  useEffect(() => {
    const t = setTimeout(() => {
      call("searchTeams", [q === "" ? " " : q, 0, 30, selectedLeague?.id, selectedAoi, active]).then(setTeams);
    }, 500);
    return () => {
      window.clearTimeout(t);
    };
  }, [call, q, selectedLeague, selectedAoi, active]);
  const onSelect = useCallback((id: IDType) => {
    if (selectedTeams.find(t => t.id === id)) {
      setSelectedTeams(selectedTeams.filter(i => i.id !== id));
    } else {
      const t = teams.find(tea => tea.id === id);
      if (t) {
        if (single)
          setSelectedTeams([t]);
        else
          setSelectedTeams(selectedTeams.concat([t]));
      }
    }
  }, [single, teams, selectedTeams]);
  const onSave = useCallback(() => {
    onAdd(selectedTeams);
  }, [selectedTeams, onAdd]);
  const allSelected = useMemo(() => {
    if (!alreadyInIds)
      return selectedTeams.map(t => t.id);
    return alreadyInIds.concat(selectedTeams.map(t => t.id));
  }, [alreadyInIds, selectedTeams]);
  const aoiItems = useMemo(() => {
    return makeAoiItems(regions, aois, l);
  }, [regions, aois, l]);
  const onSetSelectedAoi = useCallback((val: string | undefined) => {
    if (val) {
      setSelectedAoi(val);
    } else {
      setSelectedAoi(filterAoi);
    }
  }, [filterAoi]);
  return (
    <AsideModal title={translate(title ?? "addTeam", l)} close={close} saveClb={onSave}
                saveDisabled={selectedTeams.length === 0}>
      <ClavaSelect value={selectedAoi} uniqueId="aoi-add-team-modal" items={aoiItems}
                   onChange={onSetSelectedAoi}
                   className="mb-2" />
      <LeagueSelect selected={selectedLeague} setSelected={setSelectedLeague} className="mb-2" />
      <ClavaSwitch onChange={setActive} value={active} label={translate("active", l)}
                   className="mb-2" />
      <ClavaTextInput onChange={setQ} value={q} placeholder={translate("search", l)}
                      label={translate("chooseTeams", l)} className="mb-2" />
      <LoadingBlock isLoading={loading} className="flex flex-col items-stretch justify-start">
        {teams.map((t) => (
          <TeamLineItem team={t} onSelect={onSelect} disabled={alreadyInIds?.includes(t.id)}
                        selected={allSelected.includes(t.id)} icon
                        selectBox
                        key={"add-team-modal-" + t.id} />
        ))}
      </LoadingBlock>
    </AsideModal>
  );
};

export default AddTeamModal;
