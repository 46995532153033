import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const TournamentIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 9C19 10.45 18.57 11.78 17.83 12.89C16.75 14.49 15.04 15.62 13.05 15.91C12.71 15.97 12.36 16 12 16C11.64 16 11.29 15.97 10.95 15.91C8.96 15.62 7.25 14.49 6.17 12.89C5.43 11.78 5 10.45 5 9C5 5.13 8.13 2 12 2C15.87 2 19 5.13 19 9Z"
        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M21.25 18.4704L19.6 18.8604C19.23 18.9504 18.94 19.2304 18.86 19.6004L18.51 21.0704C18.32 21.8704 17.3 22.1104 16.77 21.4804L12 16.0004L7.22999 21.4904C6.69999 22.1204 5.67999 21.8804 5.48999 21.0804L5.13999 19.6104C5.04999 19.2404 4.75999 18.9504 4.39999 18.8704L2.74999 18.4804C1.98999 18.3004 1.71999 17.3504 2.26999 16.8004L6.16999 12.9004C7.24999 14.5004 8.95999 15.6304 10.95 15.9204C11.29 15.9804 11.64 16.0104 12 16.0104C12.36 16.0104 12.71 15.9804 13.05 15.9204C15.04 15.6304 16.75 14.5004 17.83 12.9004L21.73 16.8004C22.28 17.3404 22.01 18.2904 21.25 18.4704Z"
        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.58 5.98L13.17 7.15999C13.25 7.31999 13.46 7.48 13.65 7.51L14.72 7.68999C15.4 7.79999 15.56 8.3 15.07 8.79L14.24 9.61998C14.1 9.75998 14.02 10.03 14.07 10.23L14.31 11.26C14.5 12.07 14.07 12.39 13.35 11.96L12.35 11.37C12.17 11.26 11.87 11.26 11.69 11.37L10.69 11.96C9.97 12.38 9.54 12.07 9.73 11.26L9.97 10.23C10.01 10.04 9.94 9.75998 9.8 9.61998L8.97 8.79C8.48 8.3 8.64 7.80999 9.32 7.68999L10.39 7.51C10.57 7.48 10.78 7.31999 10.86 7.15999L11.45 5.98C11.74 5.34 12.26 5.34 12.58 5.98Z"
        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TournamentIcon;