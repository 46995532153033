import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Image, Team, TeamListElement } from "../../client/api";
import { useFormData } from "../../hooks/useFormData";
import useServer from "../../hooks/useServer";
import { ClavaContext } from "../../config/contexts";
import useBoolState from "../../hooks/useBoolState";
import { useNavigate } from "react-router";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { showTranslated, translate } from "../../config/translator";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { emptyFunc } from "../../config/utils";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import TeamLeagueItem from "./TeamLeagueItem";
import { IDType } from "../../config/types";
import MessageBox from "../../components/Atoms/MessageBox";
import FileUploader from "../../components/FileUploader/FileUploader";
import ComingSoon from "../../components/Views/ComingSoon";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import AddLeagueModal from "../../components/Views/AddLeagueModal";
import AddTeamModal from "../../components/Views/AddTeamModal";

const TeamEditForm: React.FC<{ team: Team, changes: (team: Team) => void }> = ({
                                                                                 team,
                                                                                 changes
                                                                               }) => {
  const { l } = useContext(ClavaContext);
  const form = useFormData(team,
    ["name", "officialName", "outOfCompetition"],
    {},
    ["officialName"],
    ["outOfCompetition"], [], [], ["name"]);
  const [wantPatchImage, toggleWantPatchImage, setWantPatchImage] = useBoolState(false);
  const [patchImage, togglePatchImage, setPatchImage] = useBoolState(false);
  const [adopt, toggleAdopt, setAdopt] = useBoolState(false);
  const [addLeague, toggleAddLeague] = useBoolState(false);
  const [del, toggleDelete] = useBoolState(false);
  const { call, loading, StatusComponent } = useServer(false);
  const navigator = useNavigate();
  useEffect(() => {
    if (patchImage || adopt) {
      setWantPatchImage(false);
    }
  }, [adopt, patchImage, setWantPatchImage]);
  const onDelete = useCallback(() => {
    call("deleteTeam", [team.id], "deleteFailedBcsSquadMatch").then(() => {
      navigator("/team");
    });
  }, [team.id, call, navigator]);
  const onChangeImage = useCallback((img: Image) => {
    call("patchTeam", [
      team.id, {
        emblemId: img.id
      }
    ], "saveFailed", "saveSuccess").then((res) => {
      setPatchImage(false);
      changes(res);
      form.setInitial(res);
    });
  }, [setPatchImage, team.id, changes, call, form]);
  const onChangeImageServer = useCallback((file: File) => {
    call("patchTeamEmblem", [
      team.id, file
    ], "saveFailed", "saveSuccess").then((res) => {
      setPatchImage(false);
      changes(res);
      form.setInitial(res);
    });
  }, [setPatchImage, team.id, changes, call, form]);

  const onSave = useCallback(() => {
    if (!form.nothingChanged) {
      call("patchTeam", [team.id, {
        name: form.name,
        officialName: form.officialName,
        outOfCompetition: form.outOfCompetition
      }], "saveFailed", "saveSuccess").then((res) => {
        changes(res);
        form.setInitial(res);
      });
    }
  }, [changes, form, call, team.id]);
  const onChangeName = useCallback((val: string) => {
    form.onChangeNameDe(val);
    form.onChangeNameIt(val);
    form.onChangeNameEn(val);
  }, [form]);
  const leagueDeleted = useCallback((league: IDType) => {
    changes({
      ...team,
      leagues: team.leagues.filter(lea => lea.id !== league)
    });
  }, [changes, team]);
  const onAddLeague = useCallback((lIds: IDType[]) => {
    call("addLeaguesToTeam", [team.id, lIds]).then(changes);
  }, [changes, call, team.id]);

  const leagueIds = useMemo(() => {
    return team.leagues.map(le => le.id);
  }, [team.leagues]);
  const onAdopt = useCallback((ids: TeamListElement[]) => {
    if (ids.length)
      call("patchTeam", [
        team.id, {
          emblemId: ids[0].emblem.id
        }
      ], "saveFailed", "saveSuccess").then((res) => {
        setAdopt(false);
        changes(res);
        form.setInitial(res);
      });
  }, [call, changes, form, setAdopt, team.id]);
  return (
    <React.Fragment>
      <RoundedBlock>
        <ItemBlockHeader image={team.emblem}
                         name={showTranslated(team.name, l)}
                         onDelete={toggleDelete}
                         id={team.id}
                         onPatchImage={toggleWantPatchImage}>
          <ClavaButton onClick={onSave}
                       disabled={form.nothingChanged}>{translate("save", l)}</ClavaButton>
        </ItemBlockHeader>
      </RoundedBlock>
      <div className="flex flex-row items-stretch justify-start mt-2">
        <RoundedBlock className="mr-2 w-[70%]">
          <ListBlockHeader title={"generalData"} />
          <LoadingBlock isLoading={loading}>
            {StatusComponent}
            <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
              <ClavaTextInput className="flex-1 mx-2" onChange={onChangeName}
                              changed={form.nameChangedDe}
                              value={form.nameDe}
                              label={translate("name", l)} />
              <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeOfficialName}
                              changed={form.officialNameChanged}
                              value={form.officialName}
                              label={translate("officialName", l)} />
              <ClavaTextInput className="flex-1 mx-2" value={team.internalName}
                              onChange={emptyFunc} disabled
                              label={translate("internalName", l)} />
            </div>
            <ListBlockHeader title={"leagues"}>
              <ClavaButton onClick={toggleAddLeague} lightSecondary>
                <FontAwesomeIcon icon={faPlus} size="lg" className="mr-2" />
                {translate("addLeague", l)}
              </ClavaButton>
            </ListBlockHeader>

            <h2 className="font-semibold">{translate("leagues", l)}</h2>
            <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
              {team.leagues.slice(0, 2).map((league) => (
                <TeamLeagueItem teamId={team.id} league={league} deletedLeague={leagueDeleted}
                                key={"team-league-item-" + team.id + "-" + league.id} />
              ))}
            </div>
            {team.leagues.length > 2 && (
              <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
                {team.leagues.slice(2, 4).map((league) => (
                  <TeamLeagueItem teamId={team.id} league={league} deletedLeague={leagueDeleted}
                                  key={"team-league-item-" + team.id + "-" + league.id} />
                ))}
              </div>
            )}
            {team.leagues.length > 4 && (
              <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
                {team.leagues.slice(4, 6).map((league) => (
                  <TeamLeagueItem teamId={team.id} league={league} deletedLeague={leagueDeleted}
                                  key={"team-league-item-" + team.id + "-" + league.id} />
                ))}
              </div>
            )}
            {team.leagues.length > 6 && (
              <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
                {team.leagues.slice(6, 8).map((league) => (
                  <TeamLeagueItem teamId={team.id} league={league} deletedLeague={leagueDeleted}
                                  key={"team-league-item-" + team.id + "-" + league.id} />
                ))}
              </div>
            )}
          </LoadingBlock>
        </RoundedBlock>
        <RoundedBlock className="flex-auto">

          <ListBlockHeader title={"clubManagement"} />
          <ComingSoon />
        </RoundedBlock>
      </div>
      {patchImage && (
        <FileUploader as={1} type={"image"} callbackTeamEmblem={onChangeImageServer}
                      callback={onChangeImage} close={togglePatchImage} />
      )}
      {addLeague && (
        <AddLeagueModal alreadyInIds={leagueIds} onAdd={onAddLeague} close={toggleAddLeague} />)}
      {adopt &&
        <AddTeamModal initialQuery={showTranslated(team.name, l)} onAdd={onAdopt}
                      close={toggleAdopt} single title={"adoptFormOtherTeam"} />}
      <MessageBox type={"danger"} open={del} toggle={toggleDelete}
                  msg={translate("sureDelete", l, { "[name]": showTranslated(team.name, l) })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDelete}
                  btn2Text={translate("no", l)} />
      <MessageBox type={"info"} open={wantPatchImage} toggle={toggleWantPatchImage}
                  msg={translate("howPatchTeamEmblem", l)}
                  btn1Clb={togglePatchImage} btn1Text={translate("upload", l)}
                  btn2Clb={toggleAdopt}
                  btn2Text={translate("adoptFormOtherTeam", l)} btn2Primary />
    </React.Fragment>
  );
};

export default TeamEditForm;
