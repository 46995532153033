import { useCallback, useMemo } from "react";
import { Ad, Blog, League, MatchLeague, Team, Tournament } from "../client/api";
import { useNavigate, useParams } from "react-router";

type params = {
  leagueId: League,
  teamId: Team,
  adId: Ad
  matchId: MatchLeague
  tournamentId: Tournament
  blogId: Blog
}

type ParamsTypes = keyof params;

export default function useEditParams(param: ParamsTypes, alternatePath: string) {
  const params = useParams();
  const navigator = useNavigate();
  const id = useMemo(() => {
    if (!(param in params))
      return undefined;
    const p = params[param];
    if (!p)
      return undefined;

    const i = parseInt(p, 10);
    if (Number.isNaN(i))
      return undefined;

    return i;

  }, [param, params]);
  const navNotFound = useCallback(() => {
    setTimeout(() => {
      navigator(alternatePath);
    }, 2000);
  }, [alternatePath, navigator]);
  return {
    id,
    navNotFound
  };
}