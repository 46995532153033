/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeagueCheck } from '../models/LeagueCheck';
import type { LeagueImport_Input } from '../models/LeagueImport_Input';
import type { LeagueImportResponse } from '../models/LeagueImportResponse';
import type { LeagueMatchCheck } from '../models/LeagueMatchCheck';
import type { LeagueMatchImport_Input } from '../models/LeagueMatchImport_Input';
import type { LeagueMatchImportResponse } from '../models/LeagueMatchImportResponse';
import type { SquadCheck } from '../models/SquadCheck';
import type { SquadImport_Input } from '../models/SquadImport_Input';
import type { SquadImportResponse } from '../models/SquadImportResponse';
import type { Task } from '../models/Task';
import type { TeamCheck } from '../models/TeamCheck';
import type { TeamImport_Input } from '../models/TeamImport_Input';
import type { TeamImportResponse } from '../models/TeamImportResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ImportService {
    /**
     * Check League Match Import
     * @param requestBody
     * @returns LeagueMatchImportResponse Successful Response
     * @throws ApiError
     */
    public static checkLeagueMatchImportImportCheckMatchPut(
        requestBody: Array<LeagueMatchCheck>,
    ): CancelablePromise<LeagueMatchImportResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/import/check/match',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check League Import
     * @param requestBody
     * @returns LeagueImportResponse Successful Response
     * @throws ApiError
     */
    public static checkLeagueImportImportCheckLeaguePut(
        requestBody: Array<LeagueCheck>,
    ): CancelablePromise<LeagueImportResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/import/check/league',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Team Import
     * @param requestBody
     * @returns TeamImportResponse Successful Response
     * @throws ApiError
     */
    public static checkTeamImportImportCheckTeamPut(
        requestBody: Array<TeamCheck>,
    ): CancelablePromise<TeamImportResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/import/check/team',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Squad Import
     * @param requestBody
     * @returns SquadImportResponse Successful Response
     * @throws ApiError
     */
    public static checkSquadImportImportCheckSquadPut(
        requestBody: Array<SquadCheck>,
    ): CancelablePromise<SquadImportResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/import/check/squad',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start League Match Import
     * @param requestBody
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static startLeagueMatchImportImportMatchPost(
        requestBody: Array<LeagueMatchImport_Input>,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/import/match',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start League Import
     * @param requestBody
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static startLeagueImportImportLeaguePost(
        requestBody: Array<LeagueImport_Input>,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/import/league',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start Team Import
     * @param requestBody
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static startTeamImportImportTeamPost(
        requestBody: Array<TeamImport_Input>,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/import/team',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start Squad Import
     * @param requestBody
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static startSquadImportImportSquadPost(
        requestBody: Array<SquadImport_Input>,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/import/squad',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
