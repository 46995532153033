import React, { useContext, useMemo } from "react";
import RoundedBlock from "../Atoms/RoundedBlock";
import { useLocation } from "react-router";
import { HomeRoute, NotFoundRoute, routes } from "../../config/routes";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import HomeIcon from "../Icons/HomeIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { ClavaRoute } from "../../config/types";
import { Link } from "react-router-dom";

const Breadcrumps: React.FC<React.PropsWithChildren<{
  additional?: string[]
}>> = ({ additional, children }) => {
  const { l } = useContext(ClavaContext);
  const currentPath = useLocation();
  const routeName = useMemo<ClavaRoute>(() => {
    if (currentPath.pathname === "" || currentPath.pathname === "/") {
      return HomeRoute;
    }
    const r = routes.find(ro => typeof ro === "string" ? false : currentPath.pathname.startsWith(ro.path));
    if (typeof r === "object") {
      return r;
    }
    return NotFoundRoute;
  }, [currentPath.pathname]);
  return (
    <RoundedBlock className="mb-2 flex flex-row items-center justify-start">
      <HomeIcon primary />
      <FontAwesomeIcon icon={faChevronRight} className="mx-2" />
      <Link to={routeName.path}>{translate(routeName.name, l)}</Link>
      {!!additional && additional.map(add => (
        <React.Fragment key={add}>
          <FontAwesomeIcon icon={faChevronRight} className="mx-2" />
          <span>{add}</span>
        </React.Fragment>
      ))}

      <div className="flex-1 flex flex-row justify-end">
        <div className="flex-1"></div>
        {children}
      </div>
    </RoundedBlock>
  );
};

export default Breadcrumps;