import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ClavaSelectItems, IDType } from "../../../config/types";
import { showTranslated, translate } from "../../../config/translator";
import { ClavaContext } from "../../../config/contexts";
import { League, LeagueListElement } from "../../../client/api";
import ClavaSelect from "../ClavaSelect";
import useServer from "../../../hooks/useServer";


const LeagueSelect: React.FC<{
  selectedAoi?: IDType,
  selected?: League | LeagueListElement | undefined,
  setSelected?: (val: League | undefined) => void;
  className?: string
  disabled?: boolean
}> = ({
        selected,
        setSelected,
        selectedAoi, disabled,
        className
      }) => {
  const { l, aoi, sports } = useContext(ClavaContext);
  const { call } = useServer(false);
  const [leagues, setLeagues] = useState<League[]>();
  useEffect(() => {
    call("getLeaguesOfAoi", [selectedAoi ?? aoi, sports]).then(setLeagues);
  }, [selectedAoi, aoi, sports, call]);

  const setSelectedCont = useCallback((val: string | undefined) => {
    if (setSelected) {
      if (!val || !leagues) {
        setSelected(undefined);
      } else {
        const id = parseInt(val, 10);
        setSelected(leagues.find((league) => league.id === id));
      }
    }
  }, [setSelected, leagues]);
  const items = useMemo<ClavaSelectItems[]>(() => {
    if (!leagues)
      return [];
    return leagues.map(le => ({
      key: le.id.toString(10),
      label: showTranslated(le.name, l)
    }));
  }, [l, leagues]);
  const realSelected = useMemo(() => {
    if (!selected)
      return undefined;
    return selected.id.toString(10);
  }, [selected]);
  return (
    <ClavaSelect uniqueId={"select-league"} className={className}
                 withSearch
                 items={items}
                 disabled={disabled}
                 onChange={setSelectedCont}
                 value={realSelected}
                 label={translate("selectLeague", l)} />
  );
};

export default LeagueSelect;