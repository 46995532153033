import React, { useCallback, useMemo, useState } from "react";

export type ClavaButtonProps = {
  secondary?: boolean;
  danger?: boolean;
  canWrap?: boolean;
  light?: boolean;
  lightSecondary?: boolean;
  disabled?: boolean;
  size?: "fit" | "full";
  className?: string;
  onClick: () => void;
  tempDisableTimeout?: number
};

const ClavaButton: React.FC<React.PropsWithChildren<ClavaButtonProps>> = ({
                                                                            onClick,
                                                                            canWrap,
                                                                            secondary,
                                                                            tempDisableTimeout,
                                                                            disabled,
                                                                            size,
                                                                            light,
                                                                            className,
                                                                            lightSecondary,
                                                                            danger,
                                                                            children
                                                                          }) => {
  const [tempDisabled, setTempDisabled] = useState(false);
  const btnClasses = useMemo(() => {
    let style = "";
    if (disabled || tempDisabled) {
      style +=
        "rounded-[15px] py-2 px-8 flex flex-row items-center justify-center border-0 text-center bg-transparent text-primary opacity-60";
    } else if (secondary)
      style +=
        "rounded-[15px] py-2 px-8 flex flex-row items-center justify-center border border-primary text-center bg-white dark:bg-bg-dark active:bg-btn-active hover:bg-btn-active text-primary active:text-font-dark hover:text-font-dark";
    else if (danger)
      style +=
        "rounded-[15px] py-2 px-8 flex flex-row items-center justify-center border border-red text-center bg-white dark:bg-bg-dark active:bg-btn-active hover:bg-btn-active active:border-btn-active hover:border-btn-active text-red active:text-font-dark hover:text-font-dark";
    else if (lightSecondary)
      style +=
        "rounded-[10px] py-1 px-4 flex flex-row items-center justify-center border-primary text-center bg-transparent active:bg-btn-active hover:bg-btn-active text-primary active:text-font-dark hover:text-font-dark";
    else if (light)
      style +=
        "rounded-[10px] py-1 px-4 flex flex-row items-center justify-center border-0 bg-primary-30 text-center active:bg-btn-active hover:bg-btn-active text-primary font-semibold active:text-font-dark hover:text-font-dark";
    else style +=
        "rounded-[15px] py-2 px-8 flex flex-row items-center justify-center border-0 bg-primary text-center active:bg-btn-active hover:bg-btn-active text-font-dark";
    if (size === "full") {
      style += " w-full";
    } else {
      style += " w-fit";
    }
    if (!canWrap) {
      style += " text-nowrap";
    }
    style += ` ${className}`;
    return style;
  }, [danger, className, canWrap, lightSecondary, tempDisabled, light, disabled, size, secondary]);
  const onClickCont = useCallback(() => {
    if (!tempDisabled && !disabled) {
      setTempDisabled(true);
      setTimeout(() => {
        setTempDisabled(false);
      }, tempDisableTimeout ?? 5000);
      onClick();
    }
  }, [onClick, disabled, tempDisableTimeout, tempDisabled]);
  return (
    <button className={btnClasses} onClick={onClickCont} type="button">
      {children}
    </button>
  );
};

export default ClavaButton;
