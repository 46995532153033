import React from "react";

type DropzoneContextValue = {
  isDragging: boolean
}

const DropzoneContext = React.createContext<DropzoneContextValue | undefined>(
  undefined
);

export function DropzoneProvider({ children }: { children: React.ReactNode }) {
  const [isDragging, setDragging] = React.useState(false);
  const isFileFromOS = React.useRef(true);
  const cachedTarget = React.useRef<EventTarget | null>(null);
  const hasDraggedFileFromBrowserOutsideOfWindow = React.useRef(false);

  React.useEffect(() => {
    const handleDragEnter = (e: DragEvent) => {
      cachedTarget.current = e.target;
    };
    const handleDragStart = () => {
      isFileFromOS.current = false;
      hasDraggedFileFromBrowserOutsideOfWindow.current = false;
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();

      if (isFileFromOS.current) {
        hasDraggedFileFromBrowserOutsideOfWindow.current = false;

        if (!isDragging) setDragging(true);
      }
    };

    const handleDragLeave = (e: DragEvent) => {
      if (e.target === cachedTarget.current) {
        if (
          isFileFromOS.current &&
          !hasDraggedFileFromBrowserOutsideOfWindow.current
        ) {
          if (isDragging) setDragging(false);
        } else {
          hasDraggedFileFromBrowserOutsideOfWindow.current = true;
          isFileFromOS.current = true;
        }
      }
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      isFileFromOS.current = true;
      hasDraggedFileFromBrowserOutsideOfWindow.current = false;

      if (isDragging) setDragging(false);
    };

    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("dragstart", handleDragStart);
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);

    // Cleanup.
    return () => {
      window.removeEventListener("dragenter", handleDragEnter);
      window.removeEventListener("dragstart", handleDragStart);
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, [isDragging]);

  const value = React.useMemo(() => {
    return { isDragging };
  }, [isDragging]);

  return (
    <DropzoneContext.Provider value={value}>
      {children}
    </DropzoneContext.Provider>
  );
}

export default function useDropzone() {
  const context = React.useContext(DropzoneContext);

  if (!context) {
    throw new Error(`useDropzone must be used within a DropzoneProvider`);
  }

  return context.isDragging;
}