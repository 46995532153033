import React, { useCallback, useEffect, useRef } from "react";
import { CancelablePromise } from "../client/api";
import { CollectionType } from "../config/types";
import { unique } from "../config/utils";

export function useInfiniteScroll<T extends CollectionType>(
  func: (off: number, lim: number) => CancelablePromise<T[]>,
  setItems: React.Dispatch<React.SetStateAction<T[] | undefined>>,
  initialOffset = 0,
  limit = 100) {
  const offset = useRef(initialOffset);
  const hasMore = useRef(true);
  const isLoading = useRef(false);
  const setItemsWithHasMore = useCallback((items: T[]) => {
    if (offset.current === 0) {
      setItems(items);
    } else {
      setItems(itm => itm ? unique(itm.concat(items)) : items);
    }
    offset.current = offset.current + limit;
    hasMore.current = items.length >= limit;
    isLoading.current = false;
  }, [setItems, limit]);
  const listener = useCallback(() => {
    const elem = document.getElementsByTagName("html");
    if (elem.length !== 0 && hasMore.current) {
      const scrollElem = elem[0];
      if (scrollElem.scrollTop + window.innerHeight > scrollElem.scrollHeight - 200) {
        if (!isLoading.current) {
          isLoading.current = true;
          func(offset.current, limit).then(setItemsWithHasMore);
        }
      }
    }
  }, [setItemsWithHasMore, limit, func]);
  useEffect(() => {
    document.addEventListener("scroll", listener);
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [listener]);
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      offset.current = 0;
      hasMore.current = true;
      func(0, limit).then(setItemsWithHasMore);
    }, 1000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [func, limit, setItemsWithHasMore]);
  return {
    offset,
    hasMore, setItemsWithHasMore
  };
}