import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Image, Post, Video } from "../../client/api";
import AsideModal from "../../components/Layout/AsideModal";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import ClavaImage from "../../components/Atoms/ClavaImage";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import FeedDownloadVideo from "./FeedDownloadVideo";
import client from "../../client";
import { getFileSize, getFileSizeVideo } from "../../config/mediaUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import LoadingBlock from "../../components/Atoms/LoadingBlock";

const FeedDownloadModal: React.FC<{ post: Post, close: () => void }> = ({ post, close }) => {
  const { l } = useContext(ClavaContext);
  const ffmpegRef = useRef(new FFmpeg());
  const [downloadLink, setDownloadLink] = useState<string>();
  const [downloadLink2, setDownloadLink2] = useState<string>();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const load = useCallback(async () => {
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
    const ffmpeg = ffmpegRef.current;
    // ffmpeg.on("log", ({ message }) => {
    //  console.log(message);
    // });
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm")
    });
  }, []);
  useEffect(() => {
    load().then(() => {
      setLoaded(true);
    });
  }, [load]);
  const [selected, setSelected] = useState<Image | Video | undefined>(post.userMedia.length === 1 ? post.userMedia[0] : undefined);
  const errorHandler = useCallback((err: any) => {

    setLoading(false);
    setError(err.toString());
  }, []);
  const downloadImage = useCallback((image: Image) => {
    const ffmpeg = ffmpegRef.current;
    if (ffmpeg) {
      fetchFile(image.url).then((blob) => {
        getFileSize(blob).then((size) => {
          const w = size.w * (1 / (image.cropScale ?? 1));
          const h = w / (image.cropAspectRatio ?? 1);
          const x = image.cropX ? size.w * image.cropX * -1 : 0;
          const y = image.cropY ? size.h * image.cropY * -1 : 0;
          ffmpeg.writeFile("output.avif", blob).then((ok) => {
            if (ok) {
              ffmpeg.exec([
                "-i", "output.avif",
                "-vf", `crop=${w}:${h}:${x}:${y}`,
                "-update", "true",
                "output.png"]).then(() => {
                ffmpeg.readFile("output.png").then((data) => {
                  setLoading(false);
                  setDownloadLink(URL.createObjectURL(new Blob([data], { type: "image/png" })));
                }, errorHandler);
              }, errorHandler);
            }
          }, errorHandler);
        }, errorHandler);
      }, errorHandler);
    }
  }, [errorHandler]);
  const downloadVideo = useCallback((video: File) => {
    const ffmpeg = ffmpegRef.current;
    if (ffmpeg && selected) {
      fetchFile(video).then((blob) => {
        getFileSizeVideo(URL.createObjectURL(video)).then((size) => {
          const w = size.w * (1 / (selected.cropScale ?? 1));
          const h = w / (selected.cropAspectRatio ?? 1);
          const x = selected.cropX ? size.w * selected.cropX * -1 : 0;
          const y = selected.cropY ? size.h * selected.cropY * -1 : 0;
          ffmpeg.writeFile("input.mp4", blob).then((ok) => {
            if (ok) {
              ffmpeg.exec([
                "-i", "input.mp4",
                "-vf", `crop=${w}:${h}:${x}:${y}`,
                "output.mp4"]).then(() => {
                ffmpeg.readFile("output.mp4").then((data) => {
                  setLoading(false);
                  setDownloadLink2(URL.createObjectURL(new Blob([data], { type: "video/mp4" })));
                }, errorHandler);
              }, errorHandler);
            }
          }, errorHandler);
        }, errorHandler);
      }, errorHandler);
    }
  }, [errorHandler, selected]);
  const onConvert = useCallback((i = 0) => {
    const ffmpeg = ffmpegRef.current;
    setDownloadLink(undefined);
    setLoading(true);
    if (selected && ffmpeg) {
      if ("url" in selected) {
        downloadImage(selected);
      } else {
        client().getDownloadLink(selected.id).then((res) => {
          setLoading(false);
          setDownloadLink(res);
        }, () => {
          if (i > 5) {
            errorHandler("Create Download link Failed, try again later!");
            setLoading(false);
          } else {
            setTimeout(() => {
              onConvert(i + 1);
            }, 5000);
          }
        });
      }
    }
  }, [errorHandler, selected, downloadImage]);
  const onUploadVideo = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    if (e.target.files?.length) {
      downloadVideo(e.target.files[0]);
    }
  }, [downloadVideo]);
  return (
    <AsideModal close={close} title={translate("downloadMedia", l)} saveClb={onConvert}
                saveDisabled={!selected || !loaded || !!downloadLink}
                saveText={translate("convert", l)}>
      <LoadingBlock isLoading={loading} className="flex flex-col items-stretch justify-start">
        {post.userMedia.map((media) => (
          <button onClick={() => {
            setSelected(media);
            setDownloadLink(undefined);
          }} key={"media-" + media.id}
                  className={`my-2 border border-light-gray rounded-xl p-2 dark:border-b-light-gray-dark hover:border-primary flex flex-row items-center justify-evenly ${selected?.id === media.id ? "bg-primary-30" : ""}`}>
            <span>{"url" in media ? "Photo" : "Video"}</span>
            <div className="w-36">
              {"url" in media ? (
                <ClavaImage width={144} image={media} />
              ) : (
                <FeedDownloadVideo width={144}
                                   video={media} />
              )}
            </div>
          </button>
        ))}
        {!!downloadLink && !!selected && (
          <>
            <a href={downloadLink}
               className="bg-light-gray text-primary font-bold text-2xl w-full p-4 rounded-xl border border-primary hover:bg-primary-30 flex flex-row items-center justify-center"
               download={"url" in selected ? `${selected.id}.png` : `${selected.id}.mp4`}>
              <FontAwesomeIcon icon={faDownload} className={"w-6 h-6 mr-6"} />
              {translate("download", l)}
            </a>
            {!("url" in selected) && (
              <>
                <label htmlFor="upload-crop"
                       className="text-wrap max-w-64 mt-6">{translate("canConvertVideo", l)}</label>
                <input type={"file"} accept="video/mp4"
                       className="bg-light-gray w-full p-4 rounded-xl border border-primary hover:bg-primary-30 flex flex-row items-center justify-center"
                       id="upload-crop" onChange={onUploadVideo} />
              </>
            )}
          </>
        )}
        {!!downloadLink2 && !!selected && (
          <a href={downloadLink2}
             className="bg-light-gray text-primary font-bold text-2xl w-full p-4 rounded-xl border border-primary hover:bg-primary-30 flex flex-row items-center justify-center"
             download={`${selected.id}_cropped.mp4`}>
            <FontAwesomeIcon icon={faDownload} className={"w-6 h-6 mr-6"} />
            {translate("download", l)}
          </a>
        )}
        {!!error && (
          <span className="text-red">{error}</span>
        )}
      </LoadingBlock>
    </AsideModal>
  );
};

export default FeedDownloadModal;
