import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const SoccerIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  const otherColor = useMemo(() => {
    return primary ? "white" : (theme === "light" ? "white" : "black");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 16 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.87431 6.13276C1.26705 6.66829 0.66086 7.20543 0 7.79028C0.0578856 6.18905 0.51829 4.79152 1.40319 3.46851C1.59346 4.32621 1.76873 5.12495 1.94828 5.92262C1.96865 6.01429 1.94024 6.07487 1.87431 6.13276Z"
        fill={otherColor} />
      <path
        d="M4.32063 13.2319C4.64597 13.9867 4.97613 14.7388 5.32773 15.5445C3.83182 14.995 2.64249 14.1255 1.65039 12.8706C2.52135 12.9558 3.32693 13.0341 4.1325 13.1129C4.21451 13.1209 4.2815 13.1419 4.32063 13.2319Z"
        fill={otherColor} />
      <path
        d="M4.18516 12.542C3.26918 12.4546 2.34622 12.3662 1.42327 12.2782C1.29517 12.2659 1.2003 12.2311 1.12366 12.1024C0.49442 11.0533 0.149786 9.91419 0.0141838 8.70482C0.00239232 8.59761 0.0324071 8.52202 0.114948 8.44912C0.825654 7.82513 1.53368 7.19847 2.23849 6.56806C2.32478 6.49033 2.40357 6.48121 2.50434 6.51391C3.17216 6.73102 3.83999 6.94706 4.50889 7.16148C4.60376 7.19204 4.64503 7.25047 4.67129 7.3475C4.96876 8.45501 5.27105 9.56092 5.57549 10.6668C5.60497 10.7735 5.57656 10.8545 5.51492 10.9386C5.15099 11.4345 4.78921 11.9314 4.42957 12.4305C4.36847 12.5152 4.31058 12.5881 4.18516 12.542Z"
        fill={otherColor} />
      <path
        d="M1.8913 3.05249C1.86128 2.91847 1.89666 2.81876 1.98885 2.7153C2.78477 1.82329 3.72917 1.13927 4.81988 0.653052C4.95227 0.594085 5.06107 0.594621 5.19292 0.673959C5.96419 1.13712 6.73761 1.59546 7.51853 2.042C7.67503 2.13099 7.72434 2.22855 7.71791 2.40063C7.70451 2.74854 7.71362 3.09752 7.71362 3.44596C7.71362 3.77618 7.7029 4.10747 7.71791 4.43715C7.72649 4.62638 7.67182 4.7411 7.50727 4.84992C6.66096 5.41172 5.82216 5.98531 4.9871 6.56427C4.86919 6.64628 4.77271 6.6554 4.64461 6.61305C3.99501 6.39701 3.34487 6.18259 2.69151 5.97888C2.56985 5.94082 2.51946 5.87756 2.49427 5.76177C2.29596 4.85796 2.09443 3.95523 1.8913 3.05249Z"
        fill={otherColor} />
      <path
        d="M7.89497 1.60825C7.19284 1.18904 6.48696 0.775736 5.72266 0.324903C7.26574 -0.104487 8.73271 -0.105559 10.2007 0.305605C10.1981 0.395128 10.1354 0.40853 10.0952 0.432653C9.45145 0.81326 8.80667 1.19119 8.16189 1.57019C8.07828 1.61951 8.00431 1.67365 7.89497 1.60825Z"
        fill={otherColor} />
      <path
        d="M10.6842 7.02994C10.7732 7.08945 10.7882 7.13877 10.7582 7.24705C10.4698 8.29131 10.1884 9.33825 9.91185 10.3857C9.87326 10.5326 9.80895 10.5787 9.65619 10.5755C9.1036 10.5653 8.54994 10.5712 7.99681 10.5712C7.44368 10.5712 6.89001 10.5653 6.33688 10.5755C6.18628 10.5782 6.11928 10.5353 6.08069 10.3873C5.80466 9.33932 5.52327 8.29292 5.23492 7.24812C5.2049 7.14037 5.21937 7.09159 5.30781 7.03155C6.17073 6.44778 7.03205 5.86132 7.89122 5.27218C7.96894 5.21857 8.02253 5.21643 8.10079 5.27057C8.95996 5.85971 9.82127 6.44617 10.6842 7.02994Z"
        fill={otherColor} />
      <path
        d="M11.1245 13.0684C10.7531 13.9046 10.3865 14.7425 10.03 15.5852C9.96947 15.7283 9.88585 15.7927 9.74328 15.8195C9.16496 15.9283 8.58557 16.0253 7.99225 15.9942C7.40106 16.0258 6.82167 15.9278 6.24335 15.8173C6.10292 15.79 6.02038 15.7224 5.96035 15.5793C5.60929 14.7441 5.24858 13.9132 4.87822 13.0866C4.8139 12.9429 4.82087 12.8507 4.91359 12.7274C5.2668 12.2584 5.61197 11.7829 5.95178 11.3031C6.02682 11.1964 6.10292 11.153 6.23424 11.1541C7.41232 11.1589 8.58986 11.1584 9.7674 11.1546C9.88585 11.1541 9.96089 11.1873 10.0322 11.2876C10.3827 11.7807 10.7391 12.2702 11.1015 12.7553C11.1835 12.8647 11.1776 12.9488 11.1245 13.0684Z"
        fill={otherColor} />
      <path
        d="M8.47222 2.04619C9.251 1.59751 10.0255 1.13917 10.7968 0.677079C10.9254 0.599885 11.0337 0.5897 11.1698 0.650276C12.2696 1.13971 13.2215 1.82962 14.0164 2.73451C14.0684 2.79347 14.1375 2.84547 14.1166 2.94143C14.0201 3.39387 13.9258 3.84684 13.8266 4.29875C13.7237 4.76781 13.6096 5.23472 13.5131 5.70485C13.4809 5.86139 13.4225 5.94769 13.259 5.99808C12.6384 6.18731 12.0231 6.39316 11.4083 6.60009C11.2448 6.6553 11.1167 6.6419 10.9677 6.5379C10.1407 5.96324 9.30674 5.39822 8.47008 4.8375C8.32965 4.74315 8.2723 4.64451 8.27552 4.47029C8.28677 3.7734 8.2857 3.07652 8.27605 2.37963C8.27391 2.21398 8.33072 2.12768 8.47222 2.04619Z"
        fill={otherColor} />
      <path
        d="M14.3264 12.8898C13.3611 14.1243 12.1664 14.9874 10.6699 15.5487C10.9213 14.9654 11.1496 14.4337 11.3796 13.9019C11.4498 13.7394 11.5414 13.5829 11.5907 13.4141C11.6631 13.1669 11.8201 13.1058 12.0613 13.0876C12.7356 13.0377 13.4077 12.9573 14.0809 12.8909C14.149 12.8839 14.2186 12.8898 14.3264 12.8898Z"
        fill={otherColor} />
      <path
        d="M14.0433 5.93164C14.2234 5.12754 14.4003 4.32291 14.5884 3.47217C15.4771 4.78285 15.9251 6.18413 15.9991 7.79448C15.3275 7.20052 14.7171 6.65962 14.1055 6.11927C14.0455 6.06673 14.0256 6.01098 14.0433 5.93164Z"
        fill={otherColor} />
      <path
        d="M15.9725 8.68482C15.8546 9.91563 15.4928 11.0591 14.8604 12.1157C14.7971 12.2218 14.7226 12.2657 14.6085 12.2765C13.6678 12.366 12.7266 12.4561 11.7865 12.5515C11.6783 12.5622 11.6279 12.5166 11.5716 12.4384C11.2071 11.9323 10.8395 11.4279 10.4696 10.9256C10.4048 10.8377 10.3946 10.7551 10.4235 10.6517C10.7253 9.55486 11.0265 8.45753 11.3186 7.35805C11.3519 7.23261 11.4167 7.18544 11.5261 7.1506C12.1612 6.9485 12.7969 6.74855 13.4277 6.53412C13.5837 6.48105 13.6871 6.50249 13.8088 6.61185C14.4777 7.21546 15.1546 7.8105 15.8283 8.40821C15.9141 8.48433 16.0288 8.54544 15.9725 8.68482Z"
        fill={otherColor} />
      <path
        d="M7.50727 4.84992C6.66096 5.41172 5.82216 5.98531 4.9871 6.56427C4.86919 6.64628 4.77271 6.6554 4.64461 6.61305C3.99501 6.39701 3.34487 6.18259 2.69151 5.97888C2.56985 5.94082 2.51946 5.87756 2.49427 5.76177C2.29596 4.85796 2.09443 3.95523 1.8913 3.05249C1.86128 2.91847 1.89666 2.81876 1.98885 2.7153C2.78477 1.82329 3.72917 1.13927 4.81988 0.653052C4.95227 0.594085 5.06107 0.594621 5.19292 0.673959C5.96419 1.13712 6.73761 1.59546 7.51853 2.042C7.67503 2.13099 7.72434 2.22855 7.71791 2.40063C7.70451 2.74854 7.71362 3.09752 7.71362 3.44596C7.71362 3.77618 7.7029 4.10747 7.71791 4.43715C7.72649 4.62638 7.67182 4.7411 7.50727 4.84992Z"
        fill={finalColor} />
      <path
        d="M14.1166 2.94143C14.0201 3.39387 13.9258 3.84684 13.8266 4.29875C13.7237 4.76781 13.6096 5.23472 13.5131 5.70485C13.4809 5.86139 13.4225 5.94769 13.259 5.99808C12.6384 6.18731 12.0231 6.39316 11.4083 6.60009C11.2448 6.6553 11.1167 6.6419 10.9677 6.5379C10.1407 5.96324 9.30674 5.39822 8.47008 4.8375C8.32965 4.74315 8.2723 4.64451 8.27552 4.47029C8.28677 3.7734 8.2857 3.07652 8.27605 2.37963C8.27391 2.21398 8.33072 2.12768 8.47222 2.04619C9.251 1.59751 10.0255 1.13917 10.7968 0.677079C10.9254 0.599885 11.0337 0.5897 11.1698 0.650276C12.2696 1.13971 13.2215 1.82962 14.0164 2.73451C14.0684 2.79347 14.1375 2.84547 14.1166 2.94143Z"
        fill={finalColor} />
      <path
        d="M5.51492 10.9386C5.15099 11.4345 4.78921 11.9314 4.42957 12.4305C4.36847 12.5152 4.31058 12.5881 4.18516 12.542C3.26918 12.4546 2.34622 12.3662 1.42327 12.2782C1.29517 12.2659 1.2003 12.2311 1.12366 12.1024C0.49442 11.0533 0.149786 9.91419 0.0141838 8.70482C0.00239232 8.59761 0.0324071 8.52202 0.114948 8.44912C0.825654 7.82513 1.53368 7.19847 2.23849 6.56806C2.32478 6.49033 2.40357 6.48121 2.50434 6.51391C3.17216 6.73102 3.83999 6.94706 4.50889 7.16148C4.60376 7.19204 4.64503 7.25047 4.67129 7.3475C4.96876 8.45501 5.27105 9.56092 5.57549 10.6668C5.60497 10.7735 5.57656 10.8545 5.51492 10.9386Z"
        fill={finalColor} />
      <path
        d="M15.9725 8.68482C15.8546 9.91563 15.4928 11.0591 14.8604 12.1157C14.7971 12.2218 14.7226 12.2657 14.6085 12.2765C13.6678 12.366 12.7266 12.4561 11.7865 12.5515C11.6783 12.5622 11.6279 12.5166 11.5716 12.4384C11.2071 11.9323 10.8395 11.4279 10.4696 10.9256C10.4048 10.8377 10.3946 10.7551 10.4235 10.6517C10.7253 9.55486 11.0265 8.45753 11.3186 7.35805C11.3519 7.23261 11.4167 7.18544 11.5261 7.1506C12.1612 6.9485 12.7969 6.74855 13.4277 6.53412C13.5837 6.48105 13.6871 6.50249 13.8088 6.61185C14.4777 7.21546 15.1546 7.8105 15.8283 8.40821C15.9141 8.48433 16.0288 8.54544 15.9725 8.68482Z"
        fill={finalColor} />
      <path
        d="M11.1245 13.0684C10.7531 13.9046 10.3865 14.7425 10.03 15.5852C9.96947 15.7283 9.88585 15.7927 9.74328 15.8195C9.16496 15.9283 8.58557 16.0253 7.99225 15.9942C7.40106 16.0258 6.82167 15.9278 6.24335 15.8173C6.10292 15.79 6.02038 15.7224 5.96035 15.5793C5.60929 14.7441 5.24858 13.9132 4.87822 13.0866C4.8139 12.9429 4.82087 12.8507 4.91359 12.7274C5.2668 12.2584 5.61197 11.7829 5.95178 11.3031C6.02682 11.1964 6.10292 11.153 6.23424 11.1541C7.41232 11.1589 8.58986 11.1584 9.7674 11.1546C9.88585 11.1541 9.96089 11.1873 10.0322 11.2876C10.3827 11.7807 10.7391 12.2702 11.1015 12.7553C11.1835 12.8647 11.1776 12.9488 11.1245 13.0684Z"
        fill={finalColor} />
      <path
        d="M10.7582 7.24705C10.4698 8.29131 10.1884 9.33825 9.91185 10.3857C9.87326 10.5326 9.80895 10.5787 9.65619 10.5755C9.1036 10.5653 8.54994 10.5712 7.99681 10.5712C7.44368 10.5712 6.89001 10.5653 6.33688 10.5755C6.18628 10.5782 6.11928 10.5353 6.08069 10.3873C5.80466 9.33932 5.52327 8.29292 5.23492 7.24812C5.2049 7.14037 5.21937 7.09159 5.30781 7.03155C6.17073 6.44778 7.03205 5.86132 7.89122 5.27218C7.96894 5.21857 8.02253 5.21643 8.10079 5.27057C8.95996 5.85971 9.82127 6.44617 10.6842 7.02994C10.7732 7.08945 10.7882 7.13877 10.7582 7.24705Z"
        fill={finalColor} />
      <path
        d="M1.87431 6.13276C1.26705 6.66829 0.66086 7.20543 0 7.79028C0.0578856 6.18905 0.51829 4.79152 1.40319 3.46851C1.59346 4.32621 1.76873 5.12495 1.94828 5.92262C1.96865 6.01429 1.94024 6.07487 1.87431 6.13276Z"
        fill={finalColor} />
      <path
        d="M15.9991 7.79448C15.3275 7.20052 14.7171 6.65962 14.1055 6.11927C14.0455 6.06673 14.0256 6.01098 14.0433 5.93164C14.2234 5.12754 14.4003 4.32291 14.5884 3.47217C15.4771 4.78285 15.9251 6.18413 15.9991 7.79448Z"
        fill={finalColor} />
      <path
        d="M5.32773 15.5445C3.83182 14.995 2.64249 14.1255 1.65039 12.8706C2.52135 12.9558 3.32693 13.0341 4.1325 13.1129C4.21451 13.1209 4.2815 13.1419 4.32063 13.2319C4.64597 13.9867 4.97613 14.7388 5.32773 15.5445Z"
        fill={finalColor} />
      <path
        d="M10.2007 0.305605C10.1981 0.395128 10.1354 0.40853 10.0952 0.432653C9.45145 0.81326 8.80667 1.19119 8.16189 1.57019C8.07828 1.61951 8.00431 1.67365 7.89497 1.60825C7.19284 1.18904 6.48696 0.775736 5.72266 0.324903C7.26574 -0.104487 8.73271 -0.105559 10.2007 0.305605Z"
        fill={finalColor} />
      <path
        d="M14.3264 12.8898C13.3611 14.1243 12.1664 14.9874 10.6699 15.5487C10.9213 14.9654 11.1496 14.4337 11.3796 13.9019C11.4498 13.7394 11.5414 13.5829 11.5907 13.4141C11.6631 13.1669 11.8201 13.1058 12.0613 13.0876C12.7356 13.0377 13.4077 12.9573 14.0809 12.8909C14.149 12.8839 14.2186 12.8898 14.3264 12.8898Z"
        fill={finalColor} />
    </svg>
  );
};

export default SoccerIcon;