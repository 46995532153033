import React, { useCallback, useContext, useEffect, useState } from "react";
import { IDType } from "../../config/types";
import TableHeader from "../../components/Table/TableHeader";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import { PlayerSquadElement } from "../../client/api";
import { TableRowItem, useList } from "../../hooks/useList";
import { showTranslated, translate } from "../../config/translator";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { faFileCsv, faPlus, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import ClavaButton from "../../components/Atoms/ClavaButton";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import Table from "../../components/Table/Table";
import TableRow from "../../components/Table/TableRow";
import MessageBox from "../../components/Atoms/MessageBox";
import useBoolState from "../../hooks/useBoolState";
import TeamEditPlayer from "./TeamEditPlayer";
import UserIcon from "../../components/Icons/UserIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileUploader from "../../components/FileUploader/FileUploader";
import CsvView from "../../components/Import/CsvView";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const headerItems: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "image"
  },
  {
    label: "givenName"
  },
  {
    label: "familyName"
  },
  {
    label: "position"
  },
  {
    label: "status"
  },
  {
    label: "action"
  }
];
const TeamEditSquad: React.FC<{ teamId: IDType }> = ({ teamId }) => {
  const { l } = useContext(ClavaContext);
  const { call, loading } = useServer(false);
  const [edit, setEdit] = useState<PlayerSquadElement>();
  const [del, setDel] = useState<PlayerSquadElement>();
  const [importCsv, toggleImportCsv] = useBoolState(false);
  const [file, setFile] = useState<File>();
  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);
  const mapper = useCallback((player: PlayerSquadElement): TableRowItem => {
    return {
      id: player.id,
      search: `${player.givenName} ${player.familyName} ${showTranslated(player.playerPosition.translation, l)}`.toLowerCase(),
      items: [
        { label: player.id },
        {
          icon: player.photo ?? faUserCircle,
          iconCircle: true
        },
        {
          label: player.givenName
        },
        {
          label: player.familyName
        },
        {
          label: showTranslated(player.playerPosition.translation, l)
        },
        {
          pill: {
            type: player.user?.id ? "success" : "warning",
            text: player.user?.id ? "connected" : "notConnected"
          }
        },
        {
          buttons: [
            {
              icon: faPencil,
              onClick: () => {
                setEdit(player);
              },
              type: "primary"
            },
            {
              icon: faTrash,
              onClick: () => {
                setDel(player);
              },
              type: "danger"
            }
          ]
        }
      ]
    };
  }, [l]);
  const {
    selected,
    onCheck,
    onCheckAll,
    allSelected,
    q,
    setQ,
    setItems,
    filtered
  } = useList(mapper);
  useEffect(() => {
    call("getPlayersOfTeam", [teamId]).then(setItems);
  }, [call, teamId, setItems]);
  const [add, toggleAdd] = useBoolState(false);
  const onDeletePlayer = useCallback(() => {
    if (del) {
      const id = del.id;
      call("removePlayerFormTeam", [id, teamId], "deleteFailed", "deleteSuccess").then(() => {
        setItems(sq => sq ? sq.filter(player => player.id !== id) : undefined);
      });
    }
  }, [del, call, teamId, setItems]);

  const onCancelUpload = useCallback(() => {
    setFile(undefined);
  }, []);
  const onFinishUpload = useCallback(() => {
    setFile(undefined);
    toggleImportCsv();
  }, [toggleImportCsv]);
  if (!!file) {
    return (
      <React.Fragment>
        <CsvView file={file} className="mt-2" type={"match"}
                 cancel={onCancelUpload} finish={onFinishUpload} />
      </React.Fragment>
    );
  }
  return (
    <div className="flex flex-row items-stretch justify-start mt-2">
      <RoundedBlock className="mr-2 w-[70%]">
        <ListBlockHeader title={"squad"} onSearch={setQ} q={q} icon={<UserIcon />}>
          <ClavaButton onClick={toggleImportCsv} lightSecondary className="mr-2">
            <FontAwesomeIcon icon={faFileCsv} size="lg" className="mr-1" />
            {translate("importCsv", l)}
          </ClavaButton>
          <ClavaButton onClick={toggleAdd} lightSecondary className="mr-2">
            <FontAwesomeIcon icon={faPlus} size="lg" className="mr-1" />
            {translate("addPlayer", l)}
          </ClavaButton>
        </ListBlockHeader>
        <LoadingBlock isLoading={loading}>
          <Table>
            <TableHeader items={headerItems} onCheckAll={onCheckAll} allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        checked={selected.includes(fil.id)}
                        key={"league-teams-" + fil.id} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>

      <RoundedBlock className="flex-auto">
        <ListBlockHeader title={"editPlayer"}
                         icon={<UserIcon />} />
        {edit && (<TeamEditPlayer player={edit} teamId={teamId} />)}
      </RoundedBlock>
      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? del.givenName : "" })}
                  btn1Clb={onDeletePlayer} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
      {importCsv && !file && (
        <FileUploader as={1} type={"csv"} callback={setFile} close={toggleImportCsv} />
      )}
    </div>
  );
};

export default TeamEditSquad;
