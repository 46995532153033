import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const FattureIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <text x={5} y={16} fontSize={2} stroke={finalColor}>
        13.54€
      </text>
      <text x={5} y={13} fontSize={2} stroke={finalColor}>
        13.54€
      </text>
      <text x={5} y={10} fontSize={2} stroke={finalColor}>
        13.54€
      </text>
      <path
        d="M13.75 17.5V7.5H9.375C8.33984 7.5 7.5 6.66016 7.5 5.625V1.25H2.5C1.80859 1.25 1.25 1.80859 1.25 2.5V17.5C1.25 18.1914 1.80859 18.75 2.5 18.75H12.5C13.1914 18.75 13.75 18.1914 13.75 17.5ZM13.7305 6.25C13.7031 6.14062 13.6484 6.03906 13.5664 5.96094L9.03906 1.43359C8.95703 1.35156 8.85938 1.29688 8.75 1.26953V5.625C8.75 5.96875 9.03125 6.25 9.375 6.25H13.7305ZM0 2.5C0 1.12109 1.12109 0 2.5 0H8.59766C9.09375 0 9.57031 0.199219 9.92188 0.550781L14.4492 5.07422C14.8008 5.42578 15 5.90234 15 6.39844V17.5C15 18.8789 13.8789 20 12.5 20H2.5C1.12109 20 0 18.8789 0 17.5V2.5Z"
        fill={finalColor} />
    </svg>
  );
};

export default FattureIcon;
