import React, { useEffect, useState } from "react";
import { Tournament, TournamentGroupStanding, TournamentStage } from "../../client/api";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";
import TournamentEditTeams from "./TournamentEditTeams";
import TournamentEditGroups from "./TournamentEditGroups";
import TournamentEditStages from "./TournamentEditStages";

const headerItems: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  },
  {
    label: "group"
  },
  {
    label: "position"
  },
  {
    label: "action"
  }
];
const TournamentEditTeamsGroupsStages: React.FC<{
  tournament: Tournament,
  changes: (tournament: Tournament) => void
}> = ({ tournament, changes }) => {
  const { call, loading } = useServer(false);
  const [groups, setGroups] = useState<TournamentGroupStanding[]>([]);
  const [stages, setStages] = useState<TournamentStage[]>([]);

  useEffect(() => {
    call("getCupStandings", [tournament.id]).then(setGroups);
    call("getCupStages", [tournament.id]).then(setStages);
  }, [call, tournament.id]);

  return (
    <LoadingBlock isLoading={loading}>
      {!tournament.cup && (
        <TournamentEditGroups tournament={tournament} changes={setGroups} groups={groups} />)}
      <TournamentEditStages tournament={tournament} changes={setStages} stages={stages} />
      <TournamentEditTeams tournament={tournament} changes={changes} groups={groups}
                           stages={stages} />
    </LoadingBlock>
  );
};
TournamentEditTeamsGroupsStages.displayName = "TournamentEditTeamsGroupsStages";
export default TournamentEditTeamsGroupsStages;
