import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const VolleyIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 21 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4"
            d="M17.3917 7.18587L11.928 2.71445C10.8603 1.84406 9.19202 1.83552 8.13265 2.70592L2.66897 7.18587C1.88487 7.82587 1.40941 9.10585 1.57624 10.1128L2.62727 16.5468C2.86917 17.989 4.17878 19.1239 5.60518 19.1239H14.4472C15.8569 19.1239 17.1915 17.9634 17.4334 16.5383L18.4844 10.1042C18.6346 9.10585 18.1591 7.82587 17.3917 7.18587Z"
            fill={finalColor} />
      <path
        d="M10.026 16.3507C9.684 16.3507 9.40039 16.0606 9.40039 15.7107V13.1507C9.40039 12.8009 9.684 12.5107 10.026 12.5107C10.368 12.5107 10.6516 12.8009 10.6516 13.1507V15.7107C10.6516 16.0606 10.368 16.3507 10.026 16.3507Z"
        fill={finalColor} />

    </svg>
  );
};

export default VolleyIcon;