/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Location } from '../models/Location';
import type { LocationCreate } from '../models/LocationCreate';
import type { LocationPatch } from '../models/LocationPatch';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LocationService {
    /**
     * Create Location
     * @param requestBody
     * @returns Location Successful Response
     * @throws ApiError
     */
    public static createLocationLocationPost(
        requestBody: LocationCreate,
    ): CancelablePromise<Location> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/location/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Location
     * @param locationId
     * @param requestBody
     * @returns Location Successful Response
     * @throws ApiError
     */
    public static updateLocationLocationLocationIdPatch(
        locationId: number,
        requestBody: LocationPatch,
    ): CancelablePromise<Location> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/location/{location_id}',
            path: {
                'location_id': locationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
