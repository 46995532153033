import React, { useCallback, useContext, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { showTranslated, translate, TranslatorKeys } from "../../config/translator";
import { Ad } from "../../client/api";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import TableRow from "../../components/Table/TableRow";
import { formatDate } from "../../config/utils";
import AdIcon from "../../components/Icons/AdIcon";
import MessageBox from "../../components/Atoms/MessageBox";
import { TableRowItem, useList } from "../../hooks/useList";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";


const items: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "priority"
  },
  {
    label: "startStop"
  },
  {
    label: "adPosition"
  },
  {
    label: "name"
  },
  {
    label: "province"
  },
  {
    label: "paused"
  },
  {
    label: "action"
  }
];
const AdvertisingList: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const [del, setDel] = useState<Ad>();

  const navigator = useNavigate();

  const mapper = useCallback((ad: Ad) => {
    return {
      id: ad.id,
      search: ad.name.toLowerCase(),
      items: [
        { label: ad.id },
        {
          centered: true,
          pill: {
            text: ad.priority.toString(10) as TranslatorKeys,
            type: ad.priority < 3 ? "success" : ad.priority < 5 ? "info" : ad.priority < 7 ? "warning" : "danger"
          }
        },
        { label: formatDate(ad.start, l, false, false, true) + " - " + formatDate(ad.stop, l, false, false, true) },
        { label: translate(ad.position, l) },
        { label: ad.name },
        { label: ad.areasOfInterest.map(area => showTranslated(area.name, l)).join(", ") },
        {
          centered: true,
          pill: {
            text: ad.paused ? "yes" : "no",
            type: ad.paused ? "danger" : "info"
          }
        },
        {
          centered: true,
          buttons: [
            {
              icon: faPencil,
              onClick: () => {
                navigator("/advertising/" + ad.id);
              },
              type: "primary"
            },
            {
              icon: faTrash,
              onClick: () => {
                setDel(ad);
              },
              type: "danger"
            }
          ]
        }
      ]
    } as TableRowItem;
  }, [navigator, l]);
  const {
    q,
    setQ,
    setItems,
    selected,
    allSelected,
    onCheckAll,
    onCheck,
    sthChanges,
    limit,
    filtered
  } = useList(mapper, 30);
  const { call, loading } = useServer(true);
  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);
  const onSearch = useCallback((off: number, lim: number) => {
    return call("searchAds", [q.length === 0 ? " " : q, off, lim]);
  }, [q, call]);
  useInfiniteScroll(onSearch, setItems, 0, limit);

  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("deleteAd", [id]).then(() => {
        sthChanges(id);
        setDel(undefined);
      });
    }
  }, [del, call, sthChanges]);
  const onNavAd = useCallback(() => {
    navigator("/advertising/create");
  }, [navigator]);
  return (
    <>
      <Breadcrumps />
      <RoundedBlock>
        <ListBlockHeader title={"advertising"} onSearch={setQ} q={q} icon={<AdIcon />}>
          <ClavaButton onClick={onNavAd} lightSecondary
                       className="mr-2">{translate("createAd", l)}</ClavaButton>
        </ListBlockHeader>
        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <Table>
            <TableHeader items={items} onCheckAll={onCheckAll}
                         allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        key={"ad-list-" + fil.id}
                        checked={selected?.includes(fil.id)} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>

      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? del.name : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
    </>
  );
};

export default AdvertisingList;
