import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const ReportsIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 13.1046 18.8954 14 20 14Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        fill={primary ? finalColor : "none"}
        strokeLinejoin="round" />
      <path
        d="M20 6C21.1046 6 22 5.10457 22 4C22 2.89543 21.1046 2 20 2C18.8954 2 18 2.89543 18 4C18 5.10457 18.8954 6 20 6Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        fill={primary ? finalColor : "none"}
        strokeLinejoin="round" />
      <path
        d="M20 22C21.1046 22 22 21.1046 22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        fill={primary ? finalColor : "none"}
        strokeLinejoin="round" />
      <path
        d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        fill={primary ? finalColor : "none"}
        strokeLinejoin="round" />
      <path d="M6 12H18" stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
      <path d="M18 4H14C12 4 11 5 11 7V17C11 19 12 20 14 20H18" stroke={finalColor}
            strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>
  );
};

export default ReportsIcon;