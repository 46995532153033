import React, { useCallback, useContext, useMemo, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import { translate } from "../../config/translator";

const ClavaSwitch: React.FC<{
  disabled?: boolean;
  label?: string;
  horizontal?: boolean;
  changed?: boolean;
  onText?: string;
  offText?: string;
  error?: boolean;
  className?: string;
  value?: boolean;
  onChange: (val: boolean) => void;
}> = ({
        onText,
        offText,
        horizontal,
        disabled,
        label, className, changed,
        value,
        onChange,
        error
      }) => {
  const { l } = useContext(ClavaContext);
  const [val, setVal] = useState(value);
  const switchClass = useMemo(() => {
    let style =
      "rounded-full border border-primary flex flex-row items-center justify-between p-0.5 bg-transparent border-input-border dark:border-input-border-dark";

    if (disabled) {
      style += " opacity-50";
    }
    return style;
  }, [disabled]);
  const realOnText = useMemo(() => {
    if (onText) return onText;
    return translate("yes", l);
  }, [onText, l]);
  const realOffText = useMemo(() => {
    if (offText) return offText;
    return translate("no", l);
  }, [offText, l]);
  const onToggle = useCallback(() => {
    onChange(!val);
    setVal(!val);
  }, [onChange, val]);
  return (
    <button
      className={`relative flex w-full ${
        horizontal
          ? "flex-row items-center justify-between"
          : "flex-col items-start justify-stretch"
      } ${className}`}
      onClick={onToggle}
      type="button"
    >
      {!!label && (
        <span
          className={`font-semibold text-sm relative ${horizontal ? "mr-2" : "mb-1"} ${
            error ? "!text-red" : ""
          }`}
        >
          {label}
          {changed && (
            <span
              className="absolute top-[-.25rem] right-[-.75rem] text-orange font-bold text-lg">*</span>)}
        </span>
      )}
      <div className={switchClass}>
        <div
          className={`rounded-full flex flex-col items-center font-semibold justify-center text-xs w-9 h-9 transition-all ${
            val
              ? "mr-0 ml-6 bg-primary dark:bg-primary text-white"
              : "border border-light-gray dark:border-light-gray-dark bg-input-bg dark:bg-input-bg-dark ml-0 mr-6"
          }`}
        >
          {val ? realOnText : realOffText}
        </div>
      </div>
    </button>
  );
};

export default ClavaSwitch;
