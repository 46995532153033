import React, { useCallback, useEffect, useMemo, useRef } from "react";

export declare type ClavaTextInputProps = {
  onChange: (val: number) => void;
  value?: number;
  disabled?: boolean;
  changed?: boolean;
  error?: boolean;
  title?: string;
  label?: string;
  name?: string;
  className?: string;
  innerRef?: React.RefObject<HTMLInputElement>;
};

const ClavaMinutePicker: React.FC<ClavaTextInputProps> = ({
                                                            onChange,
                                                            value, className,
                                                            disabled,
                                                            label,
                                                            changed,
                                                            name, error,
                                                            innerRef,
                                                            title
                                                          }) => {

  const onChangeCont = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = parseInt(e.target.value, 10);
      if (!Number.isNaN(val))
        onChange(val);
    },
    [onChange]
  );
  const borderBgClass = useMemo(() => {
    let style = " outline-0 active:bg-input-bg-active active:dark:bg-input-bg-active-dark focus:bg-input-bg-active focus:dark:bg-input-bg-active-dark border-[0.5px] active:dark:border-input-border-active-dark focus:dark:border-input-border-active-dark text-font dark:text-font-dark";

    if (disabled) {
      style += " border-input-border";
    } else {
      style +=
        " bg-input-bg dark:bg-input-bg-dark border-input-border dark:border-input-border-dark active:border-input-border-active focus:border-input-border-active placeholder-placeholder dark:placeholder-placeholder-dark";
    }
    return style;
  }, [disabled]);
  const inputClass = useMemo(() => {
    let style =
      "py-2 px-10 text-center w-32 h-10 rounded-xl";
    style += borderBgClass;
    if (error) {
      style += " !border-red";
    }
    return style;
  }, [borderBgClass, error]);
  const pressedDown = useRef(false);
  const pressedUp = useRef(false);
  const timerUp = useRef(600);
  const timerDown = useRef(600);
  const onDown = useCallback(() => {
    onChange(value ? Math.max(value - 1, 0) : 0);
    pressedDown.current = true;
  }, [value, onChange]);
  const onDownCancel = useCallback(() => {
    pressedDown.current = false;
  }, []);
  const onUp = useCallback(() => {
    onChange(value ? value + 1 : 0);
    pressedUp.current = true;
  }, [value, onChange]);
  const onUpCancel = useCallback(() => {
    pressedUp.current = false;
  }, []);
  useEffect(() => {
    const val = value ? value : 0;
    const down = setInterval(() => {
      if (pressedDown.current) {
        timerDown.current = 50;
        onChange(Math.max(val - 1, 0));
      } else timerDown.current = 600;
    }, timerDown.current);
    const up = setInterval(() => {
      if (pressedUp.current) {
        timerUp.current = 50;
        onChange(Math.max(val + 1, 0));
      } else timerUp.current = 600;
    }, timerUp.current);
    return () => {
      clearInterval(down);
      clearInterval(up);
    };
  }, [onChange, value]);
  return (
    <div className={"relative flex flex-col items-start justify-stretch w-full " + className}>
      {!!label && (
        <span
          className={`font-semibold text-sm mb-1 relative ${error ? "!text-red" : ""}`}
        >
          {label}
          {changed && (
            <span
              className="absolute top-[-.25rem] right-[-.75rem] text-orange font-bold text-lg">*</span>)}
        </span>
      )}
      <div className="flex items-center gap-x-1.5 relative">
        <input ref={innerRef} title={title} name={name} disabled={disabled}
               className={inputClass} onChange={onChangeCont}
               type="text" value={value?.toString(10)} />
        <button type="button" onMouseDown={onDown} onMouseUp={onDownCancel} disabled={disabled}
                className={`size-10 absolute left-0 top-0 flex items-center justify-center outline-0 border-input-border dark:border-input-border-dark border-r`}>
          <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24"
               height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
               strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M5 12h14"></path>
          </svg>
        </button>

        <button type="button" onMouseDown={onUp} onMouseUp={onUpCancel} disabled={disabled}
                className={`size-10 absolute right-0 top-0 flex items-center justify-center outline-0 border-input-border dark:border-input-border-dark border-l`}>
          <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24"
               height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
               strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M5 12h14"></path>
            <path d="M12 5v14"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ClavaMinutePicker;
