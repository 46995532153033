import React, { useCallback, useContext, useState } from "react";
import { Tournament } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import { useFormData } from "../../hooks/useFormData";
import { showTranslated, translate } from "../../config/translator";
import useServer from "../../hooks/useServer";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import { faTrophy } from "@fortawesome/pro-solid-svg-icons";
import ClavaButton from "../../components/Atoms/ClavaButton";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import HorizontalLine from "../../components/Atoms/HorizontalLine";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import ClavaSwitch from "../../components/Atoms/ClavaSwitch";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { useNavigate } from "react-router";
import MessageBox from "../../components/Atoms/MessageBox";
import ClavaDateInput from "../../components/Atoms/ClavaDateInput";

const TournamentEditForm: React.FC<{
  tournament: Tournament
}> = ({ tournament }) => {
  const { l, aois } = useContext(ClavaContext);
  const form = useFormData(
    tournament,
    ["cup", "name", "dateFrom", "dateTo", "pdf", "link", "location", "matchSectionAmount", "matchSectionPauseDurationMinutes", "matchSectionDurationMinutes"],
    {},
    ["link", "pdf", "location"],
    ["cup"],
    ["matchSectionDurationMinutes", "matchSectionAmount", "matchSectionPauseDurationMinutes"],
    ["dateFrom", "dateTo"],
    ["name"]
  );
  const [del, setDel] = useState(false);
  const { call, loading } = useServer(false);
  const toggleDelete = useCallback(() => {
    setDel(d => !d);
  }, []);

  const onSave = useCallback(() => {
    if (!form.nothingChanged) {
      call("patchCup", [tournament.id, {
        name: {
          textDE: form.nameDe,
          textIT: form.nameIt,
          textEN: form.nameEn
        },
        cup: form.cup,
        link: form.link,
        dateTo: form.dateTo,
        dateFrom: form.dateFrom,
        matchSectionAmount: form.matchSectionAmount,
        matchSectionDurationMinutes: form.matchSectionDurationMinutes,
        matchSectionPauseDurationMinutes: form.matchSectionPauseDurationMinutes
      }]).then((res) => {
        form.setInitial(res);
      });
    }
  }, [form, call, tournament.id]);
  const navigator = useNavigate();
  const onDelete = useCallback(() => {
    // TODO
  }, []);
  return (
    <RoundedBlock className={"mb-2"}>
      <ItemBlockHeader image={faTrophy} imageRounded name={showTranslated(tournament.name, l)}
                       id={tournament.id}
                       onDelete={toggleDelete}>
        <div className="flex flex-row items-center justify-start">
          <ClavaButton onClick={onSave}
                       disabled={form.nothingChanged}>{translate("save", l)}</ClavaButton>
        </div>
      </ItemBlockHeader>
      <LoadingBlock isLoading={loading}>
        <HorizontalLine>
          <h2 className="font-bold">{translate("generalData", l)}</h2>
        </HorizontalLine>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeNameDe}
                          changed={form.nameChangedDe} name={"textDE"}
                          value={form.nameDe}
                          label={translate("nameDe", l)} />
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeNameIt} name={"textIT"}
                          changed={form.nameChangedIt}
                          value={form.nameIt}
                          label={translate("nameIt", l)} />
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeNameEn} name={"textEN"}
                          changed={form.nameChangedEn}
                          value={form.nameEn}
                          label={translate("nameEn", l)} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaDateInput className="flex-1 mx-2" value={form.dateFromDate}
                          changed={form.dateFromChanged}
                          type="datetime"
                          name="dateFrom"
                          onChange={form.onChangeDateFrom}
                          label={translate("startDate", l)} />
          <ClavaDateInput className="flex-1 mx-2" value={form.dateToDate}
                          changed={form.dateToChanged}
                          type="datetime"
                          name="dateTo"
                          onChange={form.onChangeDateTo}
                          label={translate("stopDate", l)} /></div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextInput onChange={form.onChangeLocation} value={form.location?.name.textDE}
                          changed={form.locationChanged} label={translate("selectLocation", l)}
                          className="flex-1 mx-2" />
          <ClavaTextInput onChange={form.onChangeLink} value={form.link}
                          changed={form.linkChanged} label={translate("url", l)}
                          className="flex-1 mx-2" />
          <ClavaSwitch onChange={form.onChangeCup} value={form.cup} changed={form.cupChanged}
                       className="flex-1 mx-2" label={translate("cup", l)} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextInput onChange={form.onChangeMatchSectionAmount}
                          value={form.matchSectionAmountVal}
                          type="number"
                          changed={form.matchSectionAmountChanged}
                          label={translate("sectionAmount", l)}
                          className="flex-1 mx-2" />
          <ClavaTextInput onChange={form.onChangeMatchSectionDurationMinutes}
                          value={form.matchSectionDurationMinutesVal}
                          type="number"
                          changed={form.matchSectionDurationMinutesChanged}
                          label={translate("sectionDuration", l)}
                          className="flex-1 mx-2" />
          <ClavaTextInput onChange={form.onChangeMatchSectionPauseDurationMinutes}
                          value={form.matchSectionPauseDurationMinutesVal} type="number"
                          changed={form.matchSectionPauseDurationMinutesChanged}
                          label={translate("sectionPause", l)}
                          className="flex-1 mx-2" />
        </div>
      </LoadingBlock>

      <MessageBox type={"danger"} open={del} toggle={toggleDelete}
                  msg={translate("sureDelete", l, { "[name]": showTranslated(tournament.name, l) })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDelete}
                  btn2Text={translate("no", l)} />
    </RoundedBlock>
  );
};
TournamentEditForm.displayName = "TournamentEditForm";
export default TournamentEditForm;