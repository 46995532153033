import { useCallback, useRef, useState } from "react";

export function useLSState(localStorageString: string): [boolean, (val: boolean) => void] {
  const [val, setVal] = useState(!(localStorage.getItem(localStorageString) === "false"));
  const lsString = useRef(localStorageString);
  const setValCont = useCallback((v: boolean) => {
    setVal(v);
    localStorage.setItem(lsString.current, v ? "true" : "false");
  }, []);
  return [val, setValCont];
}