import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ClavaSelectItems, IDType } from "../../config/types";
import AsideModal from "../../components/Layout/AsideModal";
import {
  CardTypeEnum,
  ChanceTypeEnum,
  EventType,
  EventTypeEnum,
  GoalTypeEnum,
  PlayerSquadElement
} from "../../client/api";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import useBoolState from "../../hooks/useBoolState";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsLeftRight,
  faBolt,
  faCards,
  faSoccerBall
} from "@fortawesome/pro-regular-svg-icons";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ClavaSelect from "../../components/Atoms/ClavaSelect";
import EventTypePicker from "./EventTypePicker";
import ClavaMinuteInput from "../../components/Atoms/ClavaMinuteInput";
import ClavaSwitch from "../../components/Atoms/ClavaSwitch";

const CreateEventModal: React.FC<{
  curMinute: number;
  close: () => void;
  finish: (event: EventType) => void;
  teamId: IDType,
  otherTeamId: IDType,
  matchId: IDType
}> = ({ teamId, otherTeamId, matchId, close, finish, curMinute }) => {
  const { l } = useContext(ClavaContext);
  const { call, loading } = useServer(false);
  const [type, setType] = useState<EventTypeEnum>();
  const [player, setPlayer] = useState<PlayerSquadElement>();
  const [players, setPlayers] = useState<PlayerSquadElement[]>();
  const [otherPlayer, setOtherPlayer] = useState<PlayerSquadElement>();
  const [minute, setMinute] = useState(curMinute);
  const [chanceType, setChanceType] = useState<ChanceTypeEnum>();
  const [cardType, setCardType] = useState<CardTypeEnum>();
  const [goalType, setGoalType] = useState<GoalTypeEnum>();
  const [injured, toggleInjured] = useBoolState(false);
  const onSave = useCallback(() => {
    if (type === EventTypeEnum.GOAL) {
      call("postGoalEvent", [matchId, teamId, 0, 0, minute, player?.id, otherPlayer?.id, goalType, true]).then(finish);
    } else if (type === EventTypeEnum.CARD && cardType) {
      call("postCardEvent", [matchId, teamId, cardType, minute, player?.id]).then(finish);
    } else if (type === EventTypeEnum.CHANCE && chanceType) {
      call("postChanceEvent", [matchId, teamId, chanceType, minute, player?.id]).then(finish);
    }
    if (type === EventTypeEnum.CHANGE && player && otherPlayer) {
      call("postChangeEvent", [matchId, teamId, player.id, minute, injured, otherPlayer.id]).then(finish);
    }
  }, [call, finish, minute, matchId, type, teamId, player, otherPlayer, injured, chanceType, cardType, goalType]);
  useEffect(() => {
    call("getPlayersOfTeam", [goalType === GoalTypeEnum.OWN_GOAL ? otherTeamId : teamId]).then(setPlayers);
  }, [call, goalType, otherTeamId, teamId]);
  const saveDisabled = useMemo(() => {
    if (!type)
      return true;
    if (type === EventTypeEnum.CHANGE && (!player || !otherPlayer))
      return true;
    if (type === EventTypeEnum.CHANCE && !chanceType)
      return true;
    if (type === EventTypeEnum.CARD && !cardType)
      return true;
    return false;
  }, [type, cardType, chanceType, player, otherPlayer]);
  const setGoal = useCallback(() => {
    setType(EventTypeEnum.GOAL);
  }, []);
  const setCard = useCallback(() => {
    setType(EventTypeEnum.CARD);
  }, []);
  const setChange = useCallback(() => {
    setType(EventTypeEnum.CHANGE);
  }, []);
  const setChance = useCallback(() => {
    setType(EventTypeEnum.CHANCE);
  }, []);
  const playerItems = useMemo<ClavaSelectItems[]>(() => {
    if (!players)
      return [];
    return players.map<ClavaSelectItems>(p => ({
      key: p.id.toString(10),
      label: `${p.familyName} ${p.givenName}`
    }));
  }, [players]);
  const onSetPlayer = useCallback((val: string | undefined) => {
    if (val) {
      if (players) {
        const id = parseInt(val, 10);
        const p = players.find(pl => pl.id === id);
        if (p)
          setPlayer(p);
      }
    } else {
      setPlayer(undefined);
    }
  }, [players]);
  const onSetOtherPlayer = useCallback((val: string | undefined) => {
    if (val) {
      if (players) {
        const id = parseInt(val, 10);
        const p = players.find(pl => pl.id === id);
        if (p)
          setOtherPlayer(p);
      }
    } else {
      setOtherPlayer(undefined);
    }
  }, [players]);
  const onSetEvType = useCallback((val: GoalTypeEnum | CardTypeEnum | ChanceTypeEnum) => {
    if (type === EventTypeEnum.GOAL) {
      setGoalType(val as GoalTypeEnum);
    } else if (type === EventTypeEnum.CARD) {
      setCardType(val as CardTypeEnum);
    } else if (type === EventTypeEnum.CHANCE) {
      setChanceType(val as ChanceTypeEnum);
    }
  }, [type]);
  return (
    <AsideModal close={close} title={translate("createEvent", l)} saveClb={onSave}
                saveDisabled={saveDisabled}>
      <div className="flex flex-row items-center justify-evenly my-4">
        <ClavaButton onClick={setGoal} lightSecondary={type !== EventTypeEnum.GOAL}
                     className="mr-2">
          <FontAwesomeIcon icon={faSoccerBall} size="lg" className="mr-2" />
          {translate(EventTypeEnum.GOAL, l)}
        </ClavaButton>
        <ClavaButton onClick={setCard} lightSecondary={type !== EventTypeEnum.CARD}
                     className="mr-2">
          <FontAwesomeIcon icon={faCards} size="lg" className="mr-2" />
          {translate(EventTypeEnum.CARD, l)}
        </ClavaButton>
        <ClavaButton onClick={setChance} lightSecondary={type !== EventTypeEnum.CHANCE}
                     className="mr-2">
          <FontAwesomeIcon icon={faBolt} size="lg" className="mr-2" />
          {translate(EventTypeEnum.CHANCE, l)}
        </ClavaButton>
        <ClavaButton onClick={setChange} lightSecondary={type !== EventTypeEnum.CHANGE}
                     className="mr-2">
          <FontAwesomeIcon icon={faArrowsLeftRight} size="lg" className="mr-2" />
          {translate(EventTypeEnum.CHANGE, l)}
        </ClavaButton>
      </div>
      <LoadingBlock isLoading={loading}>
        <ClavaMinuteInput onChange={setMinute} value={minute} label={translate("minute", l)}
                          disabled={!type} />
        <ClavaSelect uniqueId={"event-player"} className="my-4" items={playerItems}
                     value={player?.id?.toString(10)}
                     onChange={onSetPlayer}
                     label={translate(type === EventTypeEnum.CHANGE ? "choosePlayerOut" : type === EventTypeEnum.CARD ? "chooseWrongdoer" : "chooseScorer", l)} />
        {type && type !== EventTypeEnum.CHANGE && type !== EventTypeEnum.EVENT && (
          <EventTypePicker type={type}
                           selected={type === EventTypeEnum.GOAL ? goalType : type === EventTypeEnum.CARD ? cardType : chanceType}
                           onSet={onSetEvType} />)}
        {(type === EventTypeEnum.GOAL || type === EventTypeEnum.CHANGE || type === EventTypeEnum.CHANCE) && (
          <ClavaSelect uniqueId={"event-player"} className="my-4" items={playerItems}
                       onChange={onSetOtherPlayer}
                       value={otherPlayer?.id?.toString(10)}
                       label={translate(type === EventTypeEnum.CHANGE ? "choosePlayerIn" : "chooseAssist", l)} />)}
        {type && type === EventTypeEnum.CHANGE && (
          <ClavaSwitch onChange={toggleInjured} value={injured} label={translate("injured", l)}
                       className={"my-4"} />
        )}
      </LoadingBlock>
    </AsideModal>
  );
};

export default CreateEventModal;
