import React, { useCallback, useContext, useMemo, useState } from "react";
import { EventType, EventTypeEnum } from "../../client/api";
import { IDType } from "../../config/types";
import { ClavaContext } from "../../config/contexts";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showTranslated, translate } from "../../config/translator";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { sortEventsMinute } from "../../config/utils";
import CreateEventModal from "./CreateEventModal";

const MatchEvents: React.FC<{
  events: EventType[],
  team1Id: IDType,
  sthChanged: () => void;
  team2Id: IDType,
  curMinute: number,
  matchId: IDType
}> = ({ events, curMinute, sthChanged, team2Id, team1Id, matchId }) => {
  const { l } = useContext(ClavaContext);
  const [newEvent, setNewEvent] = useState<IDType>();
  const team1Events = useMemo(() => {
    return events.filter(e => e.teamId === team1Id).sort(sortEventsMinute);
  }, [events, team1Id]);
  const team2Events = useMemo(() => {
    return events.filter(e => e.teamId === team2Id).sort(sortEventsMinute);
  }, [events, team2Id]);
  const addEvent1 = useCallback(() => {
    setNewEvent(team1Id);
  }, [team1Id]);
  const addEvent2 = useCallback(() => {
    setNewEvent(team2Id);
  }, [team2Id]);
  const toggleCreate = useCallback(() => {
    setNewEvent(undefined);
  }, []);
  const onFinish = useCallback(() => {
    setNewEvent(undefined);
    sthChanged();
  }, [sthChanged]);
  return (
    <div className="flex flex-row items-stretch justify-between mx-[-8px] mt-2 my-4">
      <div className="flex-1 mx-2 flex flex-col items-start justify-start">
        <ClavaButton onClick={addEvent1} lightSecondary className=" mb-2">
          <FontAwesomeIcon icon={faPlus} size="lg" className="mr-2" />
          {translate("addEvent", l)}
        </ClavaButton>
        {team1Events.map((itm) => (
          <div
            className="flex flex-row self-stretch items-center justify-start border-b border-b-light-gray dark:border-b-light-gray-dark my-2"
            key={"event-" + itm.id}>
            <span className="mr-4">{itm.minute}&apos;</span>
            <div className="flex-1 flex flex-col items-start justify-start mx-8">
              <span
                className="font-semibold">{itm.player ? `${itm.player.familyName} ${itm.player.givenName}` : translate("noPlayer", l)}</span>
              <span>{itm.type === EventTypeEnum.GOAL ?
                `${translate("assist", l)}: ${itm.assist ? `${itm.assist.familyName} ${itm.assist.givenName}` : translate("noPlayer", l)}, ${translate("goalType", l)}: ${itm.goalType ? showTranslated(itm.goalType.translation, l) : translate("none", l)}`
                : itm.type === EventTypeEnum.CARD ? `${translate("cardType", l)}: ${showTranslated(itm.cardType.translation, l)}`
                  : itm.type === EventTypeEnum.CHANCE ? `${translate("chance", l)}: ${itm.chanceType ? showTranslated(itm.chanceType.translation, l) : ""}`
                    : `IN: ${itm.playerIn.familyName} ${itm.playerIn.givenName}`}</span>
            </div>
            <span>{translate(itm.type, l)}</span>
          </div>))}
      </div>
      <div className="flex-1 mx-2 flex flex-col items-end justify-start">
        <ClavaButton onClick={addEvent2} lightSecondary className=" mb-2">
          {translate("addEvent", l)}
          <FontAwesomeIcon icon={faPlus} size="lg" className="ml-2" />
        </ClavaButton>

        {team2Events.map((itm) => (
          <div
            className="flex flex-row self-stretch items-center justify-end border-b border-b-light-gray dark:border-b-light-gray-dark my-2"
            key={"event-" + itm.id}>
            <span className="text-right">{translate(itm.type, l)}</span>
            <div className="flex-1 flex flex-col items-end justify-start mx-8">
              <span
                className="font-semibold text-right">{itm.player ? `${itm.player.familyName} ${itm.player.givenName}` : translate("noPlayer", l)}</span>
              <span className="text-right">{itm.type === EventTypeEnum.GOAL ?
                `${translate("assist", l)}: ${itm.assist ? `${itm.assist.familyName} ${itm.assist.givenName}` : translate("noPlayer", l)}, ${translate("goalType", l)}: ${itm.goalType ? showTranslated(itm.goalType.translation, l) : translate("none", l)}`
                : itm.type === EventTypeEnum.CARD ? `${translate("cardType", l)}: ${showTranslated(itm.cardType.translation, l)}`
                  : itm.type === EventTypeEnum.CHANCE ? `${translate("chance", l)}: ${itm.chanceType ? showTranslated(itm.chanceType.translation, l) : ""}`
                    : `IN: ${itm.playerIn.familyName} ${itm.playerIn.givenName}`}</span>
            </div>
            <span className="text-right">{itm.minute}&apos;</span>
          </div>))}
      </div>
      {!!newEvent &&
        <CreateEventModal curMinute={curMinute} close={toggleCreate} finish={onFinish}
                          teamId={newEvent} otherTeamId={newEvent === team1Id ? team2Id : team1Id}
                          matchId={matchId} />}
    </div>
  );
};

export default MatchEvents;