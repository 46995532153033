import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IDType } from "../../config/types";
import { League, SportEnum, TeamListElement } from "../../client/api";
import { translate, TranslatorKeys } from "../../config/translator";
import ReactDOM from "react-dom";
import ClavaSelect, { mapTranslatableToItem, mapTranslationToItem } from "../Atoms/ClavaSelect";
import { SPORTS } from "../../config/constants";
import { ClavaContext } from "../../config/contexts";
import client from "../../client";
import ClavaSwitch from "../Atoms/ClavaSwitch";
import ClavaButton from "../Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import useBoolState from "../../hooks/useBoolState";
import Overlay from "../Layout/Overlay";
import ClavaTextInput from "../Atoms/ClavaTextInput";
import { makeAoiItems } from "../../config/utils";

type FilterType =
  "areaOfInterestFilter"
  | "leagueFilter"
  | "teamFilter"
  | "sportsFilter"
  | TranslatorKeys;

export declare type FilterProp<T extends FilterType> = T extends "leagueFilter" | "teamFilter" ? {
  type: T,
  val: IDType | undefined,
  setVal: (aoi: IDType | undefined) => void
} : T extends "sportsFilter" ? {
  type: T,
  val: SportEnum | undefined,
  setVal: (aoi: SportEnum | undefined) => void
} : T extends "areaOfInterestFilter" | "matchDay" ? {
  type: T,
  val: string | undefined,
  setVal: (val: string | undefined) => void
} : {
  type: T,
  val: boolean,
  setVal: (val: boolean) => void
}

function SingleFilter<T extends FilterType>({
                                              val,
                                              setVal,
                                              type,
                                              leagueId,
                                              aoiId,
                                              sports
                                            }: FilterProp<T> & {
  leagueId?: IDType,
  sports?: SportEnum,
  aoiId?: string
}) {
  const { l, aois, regions } = useContext(ClavaContext);
  const [leagues, setLeagues] = useState<League[]>([]);
  const [teams, setTeams] = useState<TeamListElement[]>([]);
  const sportItems = useMemo(() => {
    return SPORTS.map(mapTranslationToItem(l));
  }, [l]);

  const onChange = useCallback((v: string | undefined) => {
    if (type === "sportsFilter") {
      if (v)
        setVal(v as unknown as SportEnum);
      else
        setVal(v as undefined);
    }
    if (type === "leagueFilter" || type === "teamFilter") {
      if (v)
        setVal(parseInt(v, 10));
      else
        setVal(v as undefined);
    }
    if (type === "areaOfInterestFilter") {
      setVal(v);
    }
  }, [type, setVal]);
  useEffect(() => {
    if (type === "leagueFilter" && aoiId) {
      client().getLeagues(aoiId).then(setLeagues);
    }
  }, [type, aoiId]);
  useEffect(() => {
    if (type === "teamFilter" && leagueId) {
      client().getTeamsOfLeague(leagueId).then(setTeams);
    }
  }, [type, leagueId]);

  const aoiItems = useMemo(() => {
    return makeAoiItems(regions, aois, l);
  }, [l, regions, aois]);
  const leagueItems = useMemo(() => {
    return leagues.map(mapTranslatableToItem(l));
  }, [l, leagues]);
  const teamItems = useMemo(() => {
    return teams.map(mapTranslatableToItem(l));
  }, [l, teams]);
  if (type === "sportsFilter") {
    return (
      <ClavaSelect uniqueId={"sports-filter"} items={sportItems} onChange={onChange} value={val}
                   withNone
                   label={translate("sports", l)} className="my-2" />
    );
  }
  if (type === "areaOfInterestFilter") {
    return (
      <ClavaSelect uniqueId={"aoi-filter"} items={aoiItems} onChange={onChange} withNone
                   value={val} label={translate("province", l)} className="my-2" />
    );
  }
  if (type === "leagueFilter") {
    return (
      <ClavaSelect uniqueId={"league-filter"} withSearch items={leagueItems} withNone
                   disabled={!aoiId || !sports} onChange={onChange} value={val?.toString(10)}
                   label={translate("league", l)} className="my-2" />
    );
  }
  if (type === "teamFilter") {
    return (
      <ClavaSelect uniqueId={"team-filter"} withSearch items={teamItems} disabled={!leagueId}
                   withNone
                   onChange={onChange} value={val?.toString(10)} label={translate("team", l)}
                   className="my-2" />
    );
  }
  if (type === "matchDay") {
    return (
      <ClavaTextInput onChange={setVal} value={val} label={translate(type, l)} type="text"
                      className="my-2" />
    );
  }
  return (
    <ClavaSwitch onChange={setVal} value={val} label={translate(type, l)} className="my-2" />
  );
}

const modalRoot = document.getElementById("root") as HTMLElement;

function Filter<T extends FilterType>({ filter, uniqueId, leagueId, sports, className }: {
  filter: FilterProp<T>[],
  uniqueId: string,
  leagueId?: IDType, sports?: SportEnum, className?: string
}) {
  const { l, filterAoi } = useContext(ClavaContext);
  const [open, toggleOpen] = useBoolState(false);
  const filtersRef = useRef<HTMLSpanElement>(null);
  const leagueIdF = useMemo(() => {
    return filter.find(f => f.type === "leagueFilter")?.val as (IDType | undefined);
  }, [filter]);
  const aoiFilter = useMemo<FilterProp<"areaOfInterestFilter">>(() => {
    return filter.find(f => f.type === "areaOfInterestFilter") as FilterProp<"areaOfInterestFilter">;
  }, [filter]);
  const prevFilterAoi = useRef(filterAoi);
  useEffect(() => {
    if (aoiFilter && prevFilterAoi.current !== filterAoi) {
      prevFilterAoi.current = filterAoi;
      aoiFilter.setVal(filterAoi);
    }
  }, [aoiFilter, filterAoi]);
  const aoiIdF = useMemo(() => {
    return aoiFilter?.val as (string | undefined);
  }, [aoiFilter]);
  const sportsF = useMemo(() => {
    return filter.find(f => f.type === "sportsFilter")?.val as (SportEnum | undefined);
  }, [filter]);
  const activeFilters = useMemo(() => {
    return filter.reduce<number>((prev, cur) => prev + (typeof cur.val === "undefined" ? 0 : 1), 0);
  }, [filter]);
  const style = useMemo(() => {
    if (!open || !filtersRef.current) return undefined;
    const rect = filtersRef.current.getBoundingClientRect();
    return {
      top: rect.top + rect.height + 10,
      left: rect.left
    };
  }, [open]);
  return (<React.Fragment>
      <ClavaButton onClick={toggleOpen} lightSecondary className={className}>
        <FontAwesomeIcon icon={faFilter} />
        <span className="ml-2" ref={filtersRef}>{translate("filters", l)}</span>
        <div
          className="bg-primary-30 ml-2 w-5 h-5 rounded-full flex items-center justify-center self-start">
          <span className="text-sm text-primary font-bold">{activeFilters}</span>
        </div>
      </ClavaButton>
      {open &&
        ReactDOM.createPortal(
          <React.Fragment>
            <Overlay close={toggleOpen} />
            <div className="fixed flex flex-col rounded-xl bg-white dark:bg-bg-dark" style={style}>
              <div
                className="p-4 border-b border-b-light-gray dark:border-b-light-gray-dark flex flex-row items-center justify-between">
                <h3 className="font-bold">{translate("filters", l)}</h3>
                <button className="border-none outline-none" role="button" onClick={toggleOpen}>
                  <FontAwesomeIcon icon={faClose} size="lg" />
                </button>
              </div>
              <div className="p-4 flex flex-col">
                {filter.map((f, i) => (
                  <SingleFilter {...f} leagueId={leagueIdF ?? leagueId} aoiId={aoiIdF}
                                sports={sportsF ?? sports} key={uniqueId + "-" + i} />
                ))}
              </div>
            </div>
          </React.Fragment>, modalRoot
        )
      }
    </React.Fragment>
  );
}

export default Filter;
