import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CloudflareImage, FileType, ImagePickerReturnFile } from "../../config/types";
import { Image, MediaCrop, Video } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import { translate, TranslatorKeys } from "../../config/translator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import ClavaButton from "../Atoms/ClavaButton";
import FileUploaderUpload from "./FileUploaderUpload";
import FileUploaderCropper from "./FileUploaderCropper";
import FileUploaderPreview from "./FileUploaderPreview";
import { mapToImage, mapToVideo } from "../../config/mediaUtils";
import { AS_MEDIATHEK } from "../../config/constants";
import FileUploaderMediathek from "./FileUploaderMediathek";
import ReactDOM from "react-dom";
import Overlay from "../Layout/Overlay";
import useBoolState from "../../hooks/useBoolState";

type FileUploaderProps<T extends FileType> = {
  as: number,
  callbackTeamEmblem?: (file: File) => void,
  type: T,
  callback: (file: ImagePickerReturnFile<T>) => void,
  close: (error?: TranslatorKeys) => void
};

type ChooseTypes = "upload" | "import" | "mediathek";
const modalRoot = document.getElementById("root") as HTMLElement;

function FileUploader<T extends FileType>({
                                            as,
                                            type,
                                            callback,
                                            callbackTeamEmblem,
                                            close
                                          }: FileUploaderProps<T>) {
  const { l } = useContext(ClavaContext);
  const [crop, setCrop] = useState<MediaCrop>();
  const [file, setFile] = useState<File>();
  const [blocked, toggleBlocked] = useBoolState(false);
  const [mediathek, setMediathek] = useState<ImagePickerReturnFile<T>[]>(JSON.parse(localStorage.getItem(AS_MEDIATHEK) ?? "[]"));
  const [cropperHidden, setCropperHidden] = useState(false);
  const [chooseType, setChooseType] = useState<ChooseTypes>("upload");
  const onClose = useCallback(() => {
    if (!!file && type !== "pdf" && type !== "csv") {
      toggleBlocked();
    } else {
      close();
    }
  }, [toggleBlocked, close, file, type]);
  const toggleCropper = useCallback(() => {
    setCropperHidden(ch => !ch);
  }, []);
  const selectUpload = useCallback(() => {
    setFile(undefined);
    setCropperHidden(false);
    setChooseType("upload");
  }, []);
  const selectImport = useCallback(() => {
    setFile(undefined);
    setCropperHidden(false);
    setChooseType("import");
  }, []);
  const selectMediathek = useCallback(() => {
    setFile(undefined);
    setCropperHidden(false);
    setChooseType("mediathek");
  }, []);
  const style = useMemo<CSSProperties>(() => {
    const width = window.innerWidth / 2;
    const height = window.innerHeight / 2;
    if (chooseType === "upload") {
      return {
        width,
        minHeight: height
      };
    }
    return {
      width,
      minHeight: Math.min(width / as, height / 1.5)
    };
  }, [chooseType, as]);
  const onFinish = useCallback((img: CloudflareImage) => {
    if (crop) {
      let retval: Image | Video | undefined = undefined;
      if (type === "image") {
        retval = mapToImage(img, crop);
      }
      if (type === "video") {
        retval = mapToVideo(img, crop);
      }
      if (type === "media") {
        retval = img.path.indexOf("mdp") === -1 ?
          mapToImage(img, crop) : mapToVideo(img, crop);
      }
      if (retval) {
        setMediathek(mt => {
          const newMt = mt.concat([retval as ImagePickerReturnFile<T>]);
          localStorage.setItem(AS_MEDIATHEK, JSON.stringify(newMt));
          return newMt;
        });
        callback(retval as ImagePickerReturnFile<T>);
      }
    }
  }, [type, callback, crop]);
  useEffect(() => {
    if ((type === "pdf" || type === "csv") && file) {
      callback(file as ImagePickerReturnFile<T>);
    }
  }, [file, type, callback]);
  const uploadTeamEmblem = useCallback(() => {
    if (file && callbackTeamEmblem) {
      callbackTeamEmblem(file);
    }
  }, [file, callbackTeamEmblem]);
  return ReactDOM.createPortal((
    <div className="fixed inset-0 flex items-center justify-center">
      <Overlay close={onClose} />
      <div
        className="max-w-[60vw] border-orange my-2 bg-white dark:bg-bg-dark rounded-xl drop-shadow-lg">
        <div
          className="flex flex-row items-center justify-between mb-2 border-b border-b-light-gray dark:border-b-light-gray-dark">
          <span
            className="font-semibold text-lg p-4">{translate(type === "pdf" ? "selectDocument" : type === "image" ? "selectImage" : type === "video" ? "selectVideo" : "selectMedia", l)}</span>
          <button className="border-none outline-none p-4" role="button" onClick={onClose}>
            <FontAwesomeIcon icon={faClose} size="lg" />
          </button>
        </div>
        <div
          className="flex flex-row items-center justify-start mb-2 pb-2 border-b border-b-light-gray dark:border-b-light-gray-dark">
          <ClavaButton onClick={selectUpload} lightSecondary={chooseType !== "upload" && !file}
                       className="mx-2">
            {translate("upload", l)}
          </ClavaButton>
          <ClavaButton onClick={selectImport} lightSecondary={chooseType !== "import" && !file}
                       className="mx-2">
            {translate("importViaUrl", l)}
          </ClavaButton>
          <ClavaButton onClick={selectMediathek}
                       lightSecondary={chooseType !== "mediathek" && !file}
                       className="mx-2">
            {translate("mediathek", l)}
          </ClavaButton>
        </div>
        <div className={`m-4 w-[50vw] relative flex flex-col items-stretch justify-stretch`}
             style={style}>
          {blocked && (
            <>
              <span
                className="text-red text-center font-semibold">{translate("sureLeave", l)}</span>
              <div
                className={`flex flex-row items-center justify-between my-2`}>
                <ClavaButton onClick={close} secondary
                             className="mr-2">
                  {translate("close", l)}
                </ClavaButton>
                <ClavaButton onClick={toggleBlocked}>
                  {translate("stayHere", l)}
                </ClavaButton>
              </div>
            </>
          )}
          {file && type !== "pdf" ? (
            <>
              {!!callbackTeamEmblem && (
                <ClavaButton onClick={uploadTeamEmblem} className="my-2 mx-auto">
                  {translate("uploadTeamEmblem", l)}
                </ClavaButton>
              )}
              {!cropperHidden && (
                <FileUploaderCropper file={file} aspectRatio={as} setCrop={setCrop} />)}
              <FileUploaderPreview file={file} crop={crop} toggleCropper={toggleCropper}
                                   callback={onFinish} />
            </>
          ) : chooseType === "upload" ? (
            <FileUploaderUpload type={type} onSelect={setFile} />
          ) : chooseType === "mediathek" ? (
            <FileUploaderMediathek as={as} type={type} mediathek={mediathek} callback={callback} />
          ) : null}
        </div>
      </div>
    </div>
  ), modalRoot);

}

export default FileUploader;
