import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { League, LeagueCategory } from "../../client/api";
import { ClavaSelectItems } from "../../config/types";
import { ClavaContext } from "../../config/contexts";
import { AdditionalCheck, useFormData } from "../../hooks/useFormData";
import ClavaSelect, {
  mapTranslatableToItem,
  mapTranslationToItem
} from "../../components/Atoms/ClavaSelect";
import { showTranslated, translate } from "../../config/translator";
import { SPORTS, tableRatingItems } from "../../config/constants";
import useServer from "../../hooks/useServer";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import { faTrophy } from "@fortawesome/pro-solid-svg-icons";
import ClavaButton from "../../components/Atoms/ClavaButton";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import HorizontalLine from "../../components/Atoms/HorizontalLine";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import ClavaSelectMultiple from "../../components/Atoms/ClavaSelectMultiple";
import ClavaSwitch from "../../components/Atoms/ClavaSwitch";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { sameArray } from "../../config/utils";
import { useNavigate } from "react-router";
import MessageBox from "../../components/Atoms/MessageBox";

const aoiCheck: AdditionalCheck<League, "areasOfInterest"> = sameArray;
const LeagueEditForm: React.FC<{
  league: League
}> = ({ league }) => {
  const { l, aois } = useContext(ClavaContext);
  const form = useFormData(
    league,
    ["category", "name", "tableRating", "year", "sports", "externalId", "matchDays", "currentMatchDay", "main", "category", "officialName", "hasPlayerStatistics", "hasTeamStatistics", "hasManOfTheMatch", "hasFeed", "hasLineup", "hasSquad", "hasScorers", "hasBetting", "amountPlayers", "active", "matchSectionAmount", "matchSectionDurationMinutes", "matchSectionPauseDurationMinutes", "amountMatchDaysHalfSeason", "internalName"],
    { areasOfInterest: aoiCheck },
    ["category", "tableRating", "officialName", "sports", "externalId", "internalName"],
    ["main", "hasPlayerStatistics", "hasTeamStatistics", "active", "hasManOfTheMatch", "hasFeed", "hasScorers", "hasLineup", "hasSquad", "hasBetting"],
    ["year", "matchDays", "currentMatchDay", "amountPlayers", "matchSectionAmount", "matchSectionDurationMinutes", "matchSectionPauseDurationMinutes", "amountMatchDaysHalfSeason"],
    [],
    ["name"]
  );
  const [del, setDel] = useState(false);
  const [categories, setCategories] = useState<LeagueCategory[]>([]);
  const { call, loading } = useServer(false);
  const toggleDelete = useCallback(() => {
    setDel(d => !d);
  }, []);
  useEffect(() => {
    call("getLeagueCategories", []).then(setCategories);
  }, [call]);
  const aoiItems = useMemo(() => {
    return aois.map(mapTranslatableToItem(l));
  }, [l, aois]);
  const catItems = useMemo<ClavaSelectItems[]>(() => {
    return categories.map((cat) => ({
      key: cat.category,
      label: showTranslated(cat.translation, l)
    }));
  }, [l, categories]);
  const sportItems = useMemo(() => {
    return SPORTS.map(mapTranslationToItem(l));
  }, [l]);
  const onChangeAois = useCallback((val: string[]) => {
    const idArr = val.map(v => parseInt(v, 10));
    const as = aois.filter(aoi => idArr.includes(aoi.id));
    form.onChange("areasOfInterest", as);
  }, [form, aois]);
  const selectedAois = useMemo(() => {
    return form.areasOfInterest.map(aoi => aoi.id.toString(10));
  }, [form.areasOfInterest]);
  const onSave = useCallback(() => {
    if (!form.nothingChanged) {
      call("patchLeague", [league.id, {
        name: {
          textDE: form.nameDe,
          textIT: form.nameIt,
          textEN: form.nameEn
        },
        hasFeed: form.hasFeed,
        hasBetting: form.hasBetting,
        hasPlayerStatistics: form.hasPlayerStatistics,
        hasTeamStatistics: form.hasTeamStatistics,
        hasManOfTheMatch: form.hasManOfTheMatch,
        hasLineup: form.hasLineup,
        hasScorers: form.hasScorers,
        hasSquad: form.hasSquad,
        sports: form.sports,
        category: form.category,
        amountPlayers: form.amountPlayers,
        officialName: form.officialName,
        main: form.main,
        tableRating: form.tableRating,
        year: form.year,
        matchDays: form.matchDays,
        matchSectionAmount: form.matchSectionAmount,
        matchSectionDurationMinutes: form.matchSectionDurationMinutes,
        matchSectionPauseDurationMinutes: form.matchSectionPauseDurationMinutes,
        amountMatchDaysHalfSeason: form.amountMatchDaysHalfSeason,
        active: form.active,
        areaOfInterestIds: form.areasOfInterest.map(aoi => aoi.id),
        currentMatchDay: form.currentMatchDay,
        externalId: form.externalId,
        internalName: form.internalName
      }]).then((res) => {
        form.setInitial(res);
      });
    }
  }, [form, call, league.id]);
  const navigator = useNavigate();
  const onDelete = useCallback(() => {
    call("deleteLeague", [league.id], "deleteFailed").then(() => {
      navigator("/league");
    });
  }, [league.id, call, navigator]);
  return (
    <RoundedBlock className={"mb-2"}>
      <ItemBlockHeader image={faTrophy} imageRounded name={showTranslated(league.name, l)}
                       id={league.id}
                       onDelete={toggleDelete}>
        <div className="flex flex-row items-center justify-start">
          <ClavaSelect uniqueId={"league-select"}
                       items={[{ key: "pres-1", label: "Herren angepasst" }]} onChange={() => {
            // TODO presets
          }} label="Preset" value={"pres-1"} disabled />

          <ClavaSwitch onChange={form.onChangeActive}
                       value={form.active}
                       changed={form.activeChanged}
                       label={translate("active", l)}
                       className="mx-2" />
          <ClavaButton onClick={onSave}
                       disabled={form.nothingChanged}>{translate("save", l)}</ClavaButton>
        </div>
      </ItemBlockHeader>
      <LoadingBlock isLoading={loading}>
        <HorizontalLine>
          <h2 className="font-bold">{translate("generalData", l)}</h2>
        </HorizontalLine>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeNameDe}
                          changed={form.nameChangedDe} name={"textDE"}
                          value={form.nameDe}
                          label={translate("nameDe", l)} />
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeNameIt} name={"textIT"}
                          changed={form.nameChangedIt}
                          value={form.nameIt}
                          label={translate("nameIt", l)} />
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeNameEn} name={"textEN"}
                          changed={form.nameChangedEn}
                          value={form.nameEn}
                          label={translate("nameEn", l)} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaSelect className="flex-1 mx-2" value={form.tableRating}
                       changed={form.tableRatingChanged}
                       items={tableRatingItems}
                       onChange={form.onChangeTableRating}
                       label={translate("rankingCalculation", l)} uniqueId={"tableRating"} />
          <ClavaSelectMultiple className="flex-1 mx-2" uniqueId="league-aois" items={aoiItems}
                               value={selectedAois}
                               onChange={onChangeAois}
                               label={translate("province", l)} />
          <ClavaSelect className="flex-1 mx-2" value={form.category}
                       changed={form.categoryChanged}
                       items={catItems}
                       onChange={form.onChangeCategory}
                       label={translate("category", l)} uniqueId={"category"} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextInput onChange={form.onChangeYear} value={form.yearVal} type="number"
                          changed={form.yearChanged} label={translate("year", l)}
                          className="flex-1 mx-2" />
          <ClavaSelect className="flex-1 mx-2" value={form.sports}
                       changed={form.sportsChanged}
                       items={sportItems}
                       onChange={form.onChangeSports}
                       label={translate("sports", l)} uniqueId={"league-sports"} />
          <ClavaTextInput onChange={form.onChangeOfficialName} value={form.officialName}
                          changed={form.officialNameChanged} label={translate("officialName", l)}
                          className="flex-1 mx-2" />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextInput onChange={form.onChangeMatchDays} value={form.matchDaysVal}
                          type="number"
                          changed={form.matchDaysChanged} label={translate("matchDays", l)}
                          className="flex-1 mx-2" />
          <ClavaTextInput onChange={form.onChangeCurrentMatchDay} value={form.currentMatchDayVal}
                          type="number"
                          changed={form.currentMatchDayChanged}
                          label={translate("currentMatchDay", l)}
                          className="flex-1 mx-2" />
          <ClavaTextInput onChange={form.onChangeAmountMatchDaysHalfSeason}
                          value={form.amountMatchDaysHalfSeasonVal} type="number"
                          changed={form.amountMatchDaysHalfSeasonChanged}
                          label={translate("matchDaysHalfSeason", l)}
                          className="flex-1 mx-2" />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextInput onChange={form.onChangeMatchSectionAmount}
                          value={form.matchSectionAmountVal} type="number"
                          changed={form.matchSectionAmountChanged}
                          label={translate("sectionAmount", l)}
                          className="flex-1 mx-2" />
          <ClavaTextInput onChange={form.onChangeMatchSectionDurationMinutes}
                          value={form.matchSectionDurationMinutesVal} type="number"
                          changed={form.matchSectionDurationMinutesChanged}
                          label={translate("sectionDuration", l)}
                          className="flex-1 mx-2" />
          <ClavaTextInput onChange={form.onChangeMatchSectionPauseDurationMinutes}
                          value={form.matchSectionPauseDurationMinutesVal} type="number"
                          changed={form.matchSectionPauseDurationMinutesChanged}
                          label={translate("sectionPause", l)}
                          className="flex-1 mx-2" />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextInput onChange={form.onChangeExternalId}
                          value={form.externalId ?? ""}
                          changed={form.externalIdChanged}
                          label={translate("externalId", l)}
                          className="flex-1 mx-2" />
          <ClavaTextInput onChange={form.onChangeInternalName}
                          value={form.internalName ?? ""}
                          changed={form.internalNameChanged}
                          label={translate("internalName", l)}
                          className="flex-1 mx-2" />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaSwitch onChange={form.onChangeMain} value={form.main}
                       label={translate("mainLeague", l)} className="flex-1 mx-2"
                       changed={form.mainChanged} />
          <ClavaSwitch onChange={form.onChangeHasPlayerStatistics}
                       value={form.hasPlayerStatistics}
                       label={translate("hasPlayerStats", l)} className="flex-1 mx-2"
                       changed={form.hasPlayerStatisticsChanged} />
          <ClavaSwitch onChange={form.onChangeHasTeamStatistics} value={form.hasTeamStatistics}
                       label={translate("hasTeamStats", l)} className="flex-1 mx-2"
                       changed={form.hasTeamStatisticsChanged} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaSwitch onChange={form.onChangeHasManOfTheMatch} value={form.hasManOfTheMatch}
                       label={translate("hasMOTM", l)} className="flex-1 mx-2"
                       changed={form.hasManOfTheMatchChanged} />
          <ClavaSwitch onChange={form.onChangeHasFeed} value={form.hasFeed}
                       label={translate("hasFeed", l)} className="flex-1 mx-2"
                       changed={form.hasFeedChanged} />
          <ClavaSwitch onChange={form.onChangeHasBetting} value={form.hasBetting}
                       label={translate("hasBetting", l)} className="flex-1 mx-2"
                       changed={form.hasBettingChanged} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaSwitch onChange={form.onChangeHasLineup} value={form.hasLineup}
                       label={translate("hasLineup", l)} className="flex-1 mx-2"
                       changed={form.hasLineupChanged} />
          <ClavaSwitch onChange={form.onChangeHasSquad} value={form.hasSquad}
                       label={translate("hasSquad", l)} className="flex-1 mx-2"
                       changed={form.hasSquadChanged} />
          <ClavaSwitch onChange={form.onChangeHasScorers} value={form.hasScorers}
                       label={translate("hasScorers", l)} className="flex-1 mx-2"
                       changed={form.hasScorersChanged} />
        </div>
      </LoadingBlock>

      <MessageBox type={"danger"} open={del} toggle={toggleDelete}
                  msg={translate("sureDelete", l, { "[name]": showTranslated(league.name, l) })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDelete}
                  btn2Text={translate("no", l)} />
    </RoundedBlock>
  );
};

export default LeagueEditForm;