import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { showTranslated, Translatable, translate, TranslatorKeys } from "../../config/translator";
import { LanguageLocaleEnum } from "../../client/api";
import { ClavaSelectItems } from "../../config/types";
import { ClavaContext } from "../../config/contexts";
import { faChevronDown, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import Overlay from "../Layout/Overlay";


export function mapTranslatableToItem<T extends Translatable>(l: LanguageLocaleEnum) {
  return (item: T): ClavaSelectItems => {
    return {
      key: item.id.toString(10),
      label: showTranslated(item.name, l)
    };
  };
}

export function mapTranslationToItem<T extends TranslatorKeys>(l: LanguageLocaleEnum) {
  return (item: T): ClavaSelectItems => {
    return {
      key: item,
      label: translate(item, l)
    };
  };
}

export function getClassName(realValue: boolean, iconL: boolean, transparent?: boolean, disabled?: boolean) {
  let style = "w-full m-0 p-0 outline-0 focus:outline-0 text-left ";
  if (transparent) {
    style += "border-none m-0 p-0 cursor-pointer ";
  } else {
    style += "rounded-xl border-[0.5px] py-2 ";
    if (disabled) {
      style += " border-input-border opacity-60";
    } else {
      style +=
        " bg-input-bg active:bg-input-bg-active active:dark:border-input-border-active-dark focus:dark:border-input-border-active-dark active:dark:bg-input-bg-active-dark focus:bg-input-bg-active focus:dark:bg-input-bg-active-dark dark:bg-input-bg-dark border-input-border dark:border-input-border-dark active:border-input-border-active focus:border-input-border-active";
    }
  }
  if (realValue) {
    if (transparent) {
      style += "font-semibold text-primary ";
    } else {
      style += "text-black dark:text-white ";
    }
  } else {
    style += "text-placeholder dark:text-placeholder-dark ";
  }

  if (iconL) {
    if (transparent) {
      style += "px-4 ";
    } else {
      style += "px-8 ";
    }
  } else {
    if (transparent) {
      style += "pr-8 pl-0";
    } else {
      style += "pl-2 pr-8 ";
    }
  }
  return style;
}

export const ClavaSelectWrapper: React.FC<{
  error?: boolean;
  label?: string;
  changed?: boolean;
  q: string;
  setQ: (val: string) => void;
  withSearch?: boolean;
  realPlaceholder?: string;
  disabled?: boolean;
  iconL?: IconDefinition;
  transparent?: boolean,
  realValue?: string,
  onOpen: (openStyle: { top: number, left: number }) => void,
}> = ({
        realValue,
        disabled,
        withSearch, q, setQ,
        realPlaceholder,
        transparent, changed,
        iconL,
        onOpen,
        error,
        label
      }) => {

  const ref = useRef<HTMLDivElement>(null);
  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (realValue && e.target.value.startsWith(realValue) && e.target.value.length === realValue.length + 1) {
      setQ(e.target.value.slice(realValue.length));
    } else {
      setQ(e.target.value);
    }
  }, [realValue, setQ]);
  const onOpenCont = useCallback(() => {
    if (!disabled && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      onOpen({
        top: rect.bottom + window.scrollY,
        left: rect.left
      });
    }
  }, [onOpen, disabled]);
  const realerValue = useMemo(() => {
    if (withSearch) {
      if (q === "") {
        return realValue;
      }
      return q;
    }
    return realValue;
  }, [realValue, withSearch, q]);
  const selectStyle = useMemo(() => {
    return getClassName(!!realerValue, !!iconL, transparent, disabled);
  }, [transparent, realerValue, disabled, iconL]);

  return (
    <div ref={ref}
         className={"relative flex flex-col items-start justify-stretch w-full"}>
      {!!label && (
        <button type={"button"} onClick={onOpenCont}
                className={`m-0 p-0 outline-0 focus:outline-0 font-semibold text-sm mb-1 relative ${error ? "!text-red" : ""}`}
        >
          {label}
          {changed && (
            <span
              className="absolute top-[-.25rem] right-[-.75rem] text-orange font-bold text-lg">*</span>)}
        </button>
      )}
      {withSearch ? (
        <input className={selectStyle} disabled={disabled} value={realerValue ?? ""}
               placeholder={realPlaceholder}
               onFocus={onOpenCont}
               onChange={onChangeSearch} />
      ) : (
        <button type={"button"} onClick={onOpenCont}
                className={selectStyle}>
          {realerValue ?? realPlaceholder}
        </button>
      )}
      <FontAwesomeIcon
        onClick={onOpenCont}
        icon={faChevronDown}
        className={`w-4 h-4 right-3 absolute cursor-pointer ${
          transparent ? "text-primary bottom-1.5" : "bottom-3.5"
        } ${disabled ? "opacity-60" : ""}`}
      />
      {!!iconL && (
        <FontAwesomeIcon
          onClick={onOpenCont}
          icon={iconL}
          className={`w-4 h-4 left-3 absolute cursor-pointer ${
            transparent ? "text-primary bottom-1.5" : "bottom-3.5"
          } ${disabled ? "opacity-60" : ""}`}
        />
      )}
    </div>
  );
};
const modalRoot = document.getElementById("root") as HTMLElement;

const ClavaSelect: React.FC<{
  className?: string;
  error?: boolean;
  changed?: boolean;
  value?: string;
  label?: string;
  placeholder?: string;
  uniqueId: string;
  disabled?: boolean;
  withNone?: boolean;
  iconL?: IconDefinition;
  items: ClavaSelectItems[],
  transparent?: boolean,
  withSearch?: boolean,
  searchClb?: (val: string) => void,
  onChange: (key: string | undefined) => void
}> = ({
        items, iconL,
        disabled,
        transparent,
        withNone,
        uniqueId,
        placeholder, withSearch,
        onChange, changed, searchClb,
        label,
        error,
        value,
        className
      }) => {
  const { l } = useContext(ClavaContext);
  const [q, setQ] = useState("");
  const [openStyle, setOpenStyle] = useState<{ top: number, left: number }>();
  const realValue = useMemo(
    () => {
      const item = items.find((it) => it.key === value);
      return item?.label ?? item?.key;
    },
    [items, value]
  );
  const setQCont = useCallback((val: string) => {
    if (searchClb) {
      searchClb(val);
    }
    setQ(val);
  }, [searchClb]);
  const realPlaceholder = useMemo(() => {
    if (!placeholder) return translate("select", l);
    return placeholder;
  }, [l, placeholder]);
  const filteredItems = useMemo(() => {
    if (!withSearch || q === "") {
      return items;
    }
    return items.filter((it) => it.label.toLowerCase().includes(q.toLowerCase()));
  }, [items, q, withSearch]);
  const closeDropdown = useCallback(() => {
    setOpenStyle(undefined);
  }, []);
  return (<div className={"relative " + className}>
      <ClavaSelectWrapper q={q} setQ={setQCont} withSearch={withSearch} changed={changed}
                          onOpen={setOpenStyle}
                          label={label}
                          realValue={realValue}
                          iconL={iconL} disabled={disabled} transparent={transparent}
                          realPlaceholder={realPlaceholder} error={error} />
      {!!openStyle && ReactDOM.createPortal(<React.Fragment>
          <Overlay close={closeDropdown} />
          <div
            className="absolute max-h-64 flex flex-col items-stretch justify-start bg-bg-secondary dark:bg-bg-secondary-dark rounded-xl shadow-shadow dark:shadow-shadow-dark drop-shadow-lg overflow-scroll"
            style={openStyle}
          >
            {withNone && (
              <button
                className={`text-left min-w-fit py-1 pl-2 pr-10 border-none hover:bg-primary-20 text-sm text-font dark:text-font-dark ${
                  !value ? "!bg-primary !text-white" : ""
                } rounded-t-xl`}
                type="button"
                onClick={() => {
                  onChange(undefined);
                  setQ("");
                  closeDropdown();
                }}
              >
                {translate("noneSelect", l)}
              </button>
            )}
            {filteredItems.map((item, idx) => (
              <button
                className={`text-left min-w-fit py-1 pl-2 pr-10 border-none hover:bg-primary-20 text-sm text-font dark:text-font-dark ${
                  value === item.key ? "!bg-primary !text-white" : ""
                } ${idx === 0 && !withNone ? "rounded-t-xl" : ""} ${idx === filteredItems.length - 1 ? "rounded-b-xl" : ""}`}
                type="button"
                onClick={() => {
                  onChange(item.key);
                  setQ("");
                  closeDropdown();
                }}
                key={`${uniqueId}-item-${item.key.toString()}`}
              >
                {item.label}
              </button>
            ))}
          </div>
        </React.Fragment>, modalRoot
      )}
    </div>
  );
};

export default ClavaSelect;
