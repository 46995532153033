import React, { useContext, useEffect, useMemo } from "react";
import { SquadImport_Output } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import { useDispatch, useSelector } from "react-redux";
import { AllActions } from "../../redux/actions/types";
import { Dispatch } from "redux";
import { RootState } from "../../redux";
import { fetchPlayerPositions } from "../../redux/actions/generalActions";
import { showTranslated } from "../../config/translator";
import ClavaImage from "../Atoms/ClavaImage";

const PlayerSuccess: React.FC<{ success: SquadImport_Output }> = ({ success }) => {
  const { l } = useContext(ClavaContext);
  const dispatch = useDispatch<Dispatch<AllActions>>();
  const {
    positions,
    statusPositions
  } = useSelector<RootState, RootState["general"]>(state => state.general);
  useEffect(() => {
    setTimeout(() => {
      if (positions.length === 0 && statusPositions === "idle") {
        fetchPlayerPositions(dispatch);
      }
    }, Math.floor(Math.random() * 1000));
  }, [positions, statusPositions, dispatch]);
  const pos = useMemo(() => {
    return positions.find(v => v.key === success.position);
  }, [positions, success.position]);
  return (
    <>
      <div className="flex-1 flex flex-col items-center justify-center">
        <span>{success.familyName}</span>
        <span>{success.givenName}</span>
      </div>
      <div className="flex-1 flex items-start justify-between">
        <span>{pos ? showTranslated(pos.translation, l) : success.position}</span>
      </div>
      <div className="flex-1 flex flex-row items-center justify-start">
        <ClavaImage width={32} imageUrl={success.team.emblemUrl} />
        <span>{showTranslated(success.team.name, l)}</span>
      </div>
    </>
  );
};

export default PlayerSuccess;
