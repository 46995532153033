import React, { useContext } from "react";
import { LeagueImport_Output } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import { showTranslated, translate } from "../../config/translator";
import Pill from "../Atoms/Pill";

const LeagueSuccess: React.FC<{ success: LeagueImport_Output }> = ({ success }) => {
  const { l } = useContext(ClavaContext);

  return (
    <>
      <div className="flex flex-row items-center justify-start">
        <span>{showTranslated(success.name, l)}</span>
      </div>
      <div className="flex-1 flex flex-row items-start justify-between">
        <div className="flex-1 flex flex-col items-center justify-start">
          <span className="font-bold">{translate("mainLeague", l)}</span>
          <Pill status={success.main ? "success" : "warning"} text={success.main ? "yes" : "no"} />
        </div>
        <div className="flex-1 flex flex-col items-start justify-start">
          <span className="font-bold">{translate("province", l)}</span>
          <span>{success.areasOfInterest.map(aoi => showTranslated(aoi.name, l)).join(", ")}</span>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start">
          <span className="font-bold">{translate("category", l)}</span>
          <span>{showTranslated(success.category.translation, l)}</span>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start">
          <span className="font-bold">{translate("sports", l)}</span>
          <span>{success.sports}</span>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start">
          <span className="font-bold">{translate("rankingCalculation", l)}</span>
          <span>{success.tableRating}</span>
        </div>
      </div>
    </>
  );
};

export default LeagueSuccess;
