import React, { KeyboardEventHandler, useCallback, useMemo } from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export declare type ClavaTextInputProps = {
  onChange: (text: string) => void;
  value?: string;
  placeholder?: string;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  disabled?: boolean;
  error?: boolean;
  changed?: boolean;
  step?: number;
  type?: "text" | "password" | "email" | "number";
  title?: string;
  label?: string;
  name?: string;
  className?: string;
  autoCorrect?: "off";
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  autoComplete?: HTMLInputElement["autocomplete"];
  iconL?: FontAwesomeIconProps["icon"];
  innerRef?: React.RefObject<HTMLInputElement>;
};

const ClavaTextInput: React.FC<ClavaTextInputProps> = ({
                                                         onChange,
                                                         value, className,
                                                         placeholder,
                                                         disabled,
                                                         onKeyDown,
                                                         label, step,
                                                         onKeyUp,
                                                         autoCorrect,
                                                         autoComplete, changed,
                                                         name,
                                                         iconL,
                                                         error,
                                                         innerRef,
                                                         title,
                                                         type
                                                       }) => {
  const realPlaceholder = useMemo(() => {
    return placeholder;
  }, [placeholder]);
  const onChangeCont = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );
  const inputClass = useMemo(() => {
    let style =
      "w-full py-2 outline-0 rounded-xl active:bg-input-bg-active active:dark:bg-input-bg-active-dark focus:bg-input-bg-active focus:dark:bg-input-bg-active-dark border-[0.5px] active:dark:border-input-border-active-dark focus:dark:border-input-border-active-dark text-font dark:text-font-dark";

    if (disabled) {
      style += " border-input-border";
    } else {
      style +=
        " bg-input-bg dark:bg-input-bg-dark border-input-border dark:border-input-border-dark active:border-input-border-active focus:border-input-border-active placeholder-placeholder dark:placeholder-placeholder-dark";
    }
    if (error) {
      style += " !border-red";
    }
    if (iconL) {
      style += " pl-8 pr-2";
    } else {
      style += " px-2";
    }
    return style;
  }, [disabled, error, iconL]);
  return (
    <div className={"relative flex flex-col items-start justify-stretch w-full " + className}>
      {!!label && (
        <span
          className={`font-semibold text-sm mb-1 relative ${error ? "!text-red" : ""}`}
        >
          {label}
          {changed && (
            <span
              className="absolute top-[-.25rem] right-[-.75rem] text-orange font-bold text-lg">*</span>)}
        </span>
      )}
      <input
        type={type ?? "text"}
        placeholder={realPlaceholder}
        value={value}
        disabled={disabled}
        ref={innerRef}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        onChange={onChangeCont}
        name={name}
        step={step}
        onKeyUp={onKeyUp}
        title={title}
        tabIndex={0}
        onKeyDown={onKeyDown}
        className={inputClass}
      />
      {!!iconL && (
        <FontAwesomeIcon
          icon={iconL}
          className="w-4 h-4 bottom-3.5 left-3 absolute"
        />
      )}
    </div>
  );
};

export default ClavaTextInput;
