import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import TableHeader from "../../components/Table/TableHeader";
import { ClavaContext } from "../../config/contexts";
import { EventType, EventTypeEnum, MatchSmall, type Translation } from "../../client/api";
import { useNavigate } from "react-router";
import useServer from "../../hooks/useServer";
import { TableRowItem, useList } from "../../hooks/useList";
import { showTranslated, translate, TranslatorKeys } from "../../config/translator";
import {
  actualMatchMinute,
  DAY_IN_MS,
  dayToNumber,
  emptyEmblem,
  extractAoiIdsFromFilterAoi,
  formatDate,
  fullMatchLengthMinutesRaw,
  getClosestDate,
  matchLeagueToMatchSmallElement,
  matchStatusDate,
  numberToDay
} from "../../config/utils";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import MatchIcon from "../../components/Icons/MatchIcon";
import Table from "../../components/Table/Table";
import TableRow from "../../components/Table/TableRow";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";
import { faChevronLeft, faMinus, faPencil, faTrashClock } from "@fortawesome/pro-regular-svg-icons";
import ClavaSelect from "../../components/Atoms/ClavaSelect";
import { ClavaSelectItems, IDType } from "../../config/types";
import CreateEventModal from "./CreateEventModal";

const headers: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "time"
  },
  {
    label: "team1"
  },
  {
    label: "team2"
  },
  {
    label: "score"
  },
  {
    label: "goal1"
  },
  {
    label: "goal2"
  },
  {
    label: "createEvent"
  },
  {
    label: "cancelMatch"
  }
];
type MatchSection = {
  name: Translation;
  id: number;
  matchIds: IDType[];
};
type MatchSmallExt = MatchSmall & {
  eventsTeam1: EventType[]
  eventsTeam2: EventType[]
};
const MatchList = () => {
  const { l, filterAoi, sports, aois } = useContext(ClavaContext);
  const { call, loading, status, StatusComponent } = useServer(true);
  const navigator = useNavigate();
  const [matchdays, setMatchdays] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const sections = useRef<MatchSection[]>([]);
  const leagueItems = useRef<ClavaSelectItems[]>([]);
  const [createEvent, setCreateEvent] = useState<{
    teamId: IDType,
    matchId: IDType,
    otherTeamId: IDType
  }>();
  const closeCreate = useCallback(() => {
    setCreateEvent(undefined);
  }, []);
  const [selectedLeague, setSelectedLeague] = useState<IDType>(-1);
  const sthChangesFunc = useRef<(val: MatchSmallExt) => void>();
  const mapper = useCallback((item: MatchSmallExt): TableRowItem => {
    const matchLength = fullMatchLengthMinutesRaw(item.sectionAmount, item.sectionDuration, item.pauseDuration);
    const ms = matchStatusDate(new Date(item.startTime).getTime(), matchLength, new Date(item.endTime).getTime());
    return {
      search: `${item.team1 ? showTranslated(item.team1.name, l) : ""} ${item.team2 ? showTranslated(item.team2.name, l) : ""}`.toLowerCase(),
      id: item.id,
      items: [
        {
          label: item.id,
          key: "id"
        }, {
          label: typeof ms === "number" ? undefined : formatDate(item.startTime, l, false, true),
          pill: typeof ms === "number" ? {
            text: `${actualMatchMinute(ms, item.sectionAmount, item.sectionDuration, item.pauseDuration)}'` as TranslatorKeys,
            type: "danger"
          } : undefined
        }, {
          icon: item.team1 ? {
            ...emptyEmblem,
            url: item.team1.emblemUrl
          } : undefined,
          label: item.team1 ? `${showTranslated(item.team1.name, l)} {Id:team${item.team1.id}}` : translate("none", l)
        }, {

          icon: item.team2 ? {
            ...emptyEmblem,
            url: item.team2.emblemUrl
          } : undefined,
          label: item.team2 ? `${showTranslated(item.team2.name, l)} {Id:team${item.team2.id}}` : translate("none", l)
        }, {
          label: `${item.goal1} - ${item.goal2}`
        }, {
          buttons: [{
            icon: faMinus,
            label: "1 " + translate("team1Short", l),
            disabled: !item.team1 || item.eventsTeam1.length === 0,
            onClick: () => {
              if (item.team1 && item.eventsTeam1.length !== 0) {
                const eId = item.eventsTeam1[item.eventsTeam1.length - 1].id;
                call("deleteEvent", [eId]).then(() => {
                  if (sthChangesFunc.current)
                    sthChangesFunc.current({
                      ...item,
                      goal1: item.goal1 - 1,
                      goal2: item.goal2,
                      eventsTeam1: item.eventsTeam1.filter(e => e.id !== eId)
                    });
                });
              }
            },
            type: "danger"
          }, {
            icon: faPlus,
            label: "1 " + translate("team1Short", l),
            disabled: !item.team1,
            onClick: () => {
              if (item.team1)
                call("postGoalEvent", [item.id, item.team1.id, item.goal1, item.goal2, undefined, undefined, undefined, undefined, true]).then((res) => {
                  if (sthChangesFunc.current)
                    sthChangesFunc.current({
                      ...item,
                      goal1: item.goal1 + 1,
                      goal2: item.goal2,
                      eventsTeam1: item.eventsTeam1.concat(res)
                    });
                });
            },
            type: "success"
          }]
        }, {
          buttons: [
            {
              icon: faMinus,
              label: "1 " + translate("team2Short", l),
              disabled: !item.team2 || item.eventsTeam2.length === 0,
              onClick: () => {
                if (item.team2 && item.eventsTeam2.length !== 0) {
                  const eId = item.eventsTeam2[item.eventsTeam2.length - 1].id;
                  call("deleteEvent", [eId]).then(() => {
                    if (sthChangesFunc.current)
                      sthChangesFunc.current({
                        ...item,
                        goal1: item.goal1,
                        goal2: item.goal2 - 1,
                        eventsTeam2: item.eventsTeam2.filter(e => e.id !== eId)
                      });
                  });
                }
              },
              type: "danger"
            },
            {
              icon: faPlus,
              label: "1 " + translate("team2Short", l),
              disabled: !item.team2,
              onClick: () => {
                if (item.team2)
                  call("postGoalEvent", [item.id, item.team2.id, item.goal1, item.goal2, undefined, undefined, undefined, undefined, true]).then((res) => {
                    if (sthChangesFunc.current)
                      sthChangesFunc.current({
                        ...item,
                        goal1: item.goal1,
                        goal2: item.goal2 + 1,
                        eventsTeam2: item.eventsTeam2.concat(res)
                      });
                  });
              },
              type: "success"
            }]
        }, {
          buttons: [
            {
              icon: faPencil,
              label: translate("team1Short", l),
              disabled: !item.team1 || !item.team2,
              onClick: () => {
                if (item.team1 && item.team2)
                  setCreateEvent({
                    teamId: item.team1.id,
                    otherTeamId: item.team2.id,
                    matchId: item.id
                  });
              },
              type: "success"
            },
            {
              icon: faPencil,
              label: translate("team2Short", l),
              disabled: !item.team1 || !item.team2,
              onClick: () => {
                if (item.team2 && item.team1)
                  setCreateEvent({
                    teamId: item.team2.id,
                    otherTeamId: item.team1.id,
                    matchId: item.id
                  });
              },
              type: "success"
            }
          ]
        },
        {
          buttons: [
            {
              icon: item.cancelled ? faPencil : faTrashClock,
              type: item.cancelled ? "secondary" : "warning",
              onClick: () => {
                if (item.cancelled) {
                  navigator("/match/" + item.id);
                } else {
                  call("patchMatch", [item.id, { cancelled: true }]).then(m => {
                    if (sthChangesFunc.current) {
                      sthChangesFunc.current({
                        ...matchLeagueToMatchSmallElement(m),
                        eventsTeam1: item.eventsTeam1,
                        eventsTeam2: item.eventsTeam2
                      });
                    }
                  });
                }
              }
            }
          ]
        }
      ]
    };
  }, [l, call, navigator]);
  const {
    q,
    setQ,
    setItems,
    filtered,
    selected,
    allSelected, items,
    onCheck,
    sthChanges,
    onCheckAll,
    sorted, onSort
  } = useList(mapper, 30);
  useEffect(() => {
    sthChangesFunc.current = sthChanges;
  }, [sthChanges]);
  const aoiIds = useMemo(() => {
    return extractAoiIdsFromFilterAoi(filterAoi, aois);
  }, [filterAoi, aois]);
  useEffect(() => {
    call("getMatchDays", [aoiIds, sports]).then((days) => {
      setMatchdays(days);
      setSelectedDate(sel => sel && days.findIndex(d => dayToNumber(d) === dayToNumber(sel)) !== -1 ? sel : getClosestDate(days));
    });
  }, [call, aoiIds, sports]);
  useEffect(() => {
    if (selectedDate) {
      call("getLeagueMatchesOfDay", [selectedDate, aoiIds, sports]).then((homeMatches) => {
        sections.current = [];
        leagueItems.current = [{
          label: translate("chooseLeague", l),
          key: "-1"
        }];
        setItems(homeMatches.reduce<MatchSmallExt[]>((prev, curr) => {
          sections.current.push({
            matchIds: curr.matches.map(m => m.id),
            name: curr.leagueTournamentName,
            id: curr.leagueTournamentId
          });
          leagueItems.current.push({
            label: showTranslated(curr.leagueTournamentName, l),
            key: curr.leagueTournamentId.toString(10)
          });
          return prev.concat(curr.matches.map(m => ({ ...m, eventsTeam1: [], eventsTeam2: [] })));
        }, []));
      });
    }
  }, [l, setItems, selectedDate, call, aoiIds, sports]);
  const onChangeLeague = useCallback((val: string | undefined) => {
    if (val) {
      setSelectedLeague(parseInt(val, 10));
    } else {
      setSelectedLeague(-1);
    }
  }, []);
  const onBack = useCallback(() => {
    navigator("/match");
  }, [navigator]);
  const onChangeDate = useCallback((val: string | undefined) => {
    if (val)
      setSelectedDate(numberToDay(parseInt(val, 10)));
  }, []);
  const dateItems = useMemo<ClavaSelectItems[]>(() => {
    const now = new Date();
    const nowAsNum = dayToNumber(now);
    const yesterday = new Date(now.getTime() - DAY_IN_MS);
    const yesterdayAsNum = dayToNumber(yesterday);
    const tomorrow = new Date(now.getTime() + DAY_IN_MS);
    const tomorrowAsNum = dayToNumber(tomorrow);
    return matchdays.map((day): ClavaSelectItems => {
      const asNum = dayToNumber(day);
      return {
        label: asNum === nowAsNum ? translate("today", l) : asNum === yesterdayAsNum ? translate("yesterday", l) : asNum === tomorrowAsNum ? translate("tomorrow", l) : formatDate(day, l, false, false, true),
        key: asNum.toString(10)
      };
    });
  }, [matchdays, l]);
  const sthChangesCreate = useCallback((evt: EventType) => {
    if (createEvent && items && evt.type === EventTypeEnum.GOAL) {
      const m = items.find(match => match.id === createEvent.matchId);
      if (m && m.team1 && m.team2) {
        sthChanges({
          ...m,
          goal1: m.goal1 + (evt.teamId === m.team1.id ? 1 : 0),
          goal2: m.goal2 + (evt.teamId === m.team2.id ? 1 : 0),
          eventsTeam1: evt.teamId === m.team1.id ? m.eventsTeam1.concat(evt) : m.eventsTeam1,
          eventsTeam2: evt.teamId === m.team2.id ? m.eventsTeam2.concat(evt) : m.eventsTeam2
        });
      }
      setCreateEvent(undefined);
    }
  }, [items, createEvent, sthChanges]);
  let currentSection: MatchSection | undefined = undefined;
  return (
    <React.Fragment>
      <Breadcrumps>
        <ClavaButton onClick={onBack} light>
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          {translate("back", l)}
        </ClavaButton>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"matchLive"} onSearch={setQ} q={q}
                         icon={<MatchIcon />}>
          <ClavaSelect uniqueId={"live-league"} items={leagueItems.current} withSearch
                       value={selectedLeague?.toString(10)}
                       onChange={onChangeLeague} className="mr-2" />
          <ClavaSelect uniqueId={"live-time"} items={dateItems}
                       value={selectedDate ? dayToNumber(selectedDate).toString(10) : undefined}
                       onChange={onChangeDate} className="mr-2" />
        </ListBlockHeader>
        {!!status && !!status.key && StatusComponent}
        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>

          <Table>
            <TableHeader sorted={sorted} onSort={onSort} items={headers} onCheckAll={onCheckAll}
                         allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => {
              let secComp: React.JSX.Element | undefined = undefined;
              if (!currentSection) {
                currentSection = sections.current.find(sec => sec.matchIds.includes(fil.id));
                if (currentSection) {
                  secComp = (<tr className="even:bg-input-bg dark:even:bg-input-bg-dark">
                    <td className="p-2"
                        colSpan={fil.items.length + 1}><span
                      className="font-bold">{showTranslated(currentSection.name, l)}</span></td>
                  </tr>);
                }
              } else {
                const thisSec = sections.current.find(sec => sec.matchIds.includes(fil.id));
                if (thisSec && thisSec.id !== currentSection.id) {
                  currentSection = thisSec;
                  secComp = (<tr className="even:bg-input-bg dark:even:bg-input-bg-dark">
                    <td className="p-2"
                        colSpan={fil.items.length + 1}><span
                      className="font-bold">{showTranslated(thisSec.name, l)}</span></td>
                  </tr>);
                }
              }
              if (selectedLeague !== -1 && selectedLeague !== currentSection?.id)
                return null;
              return (
                <React.Fragment key={"matchLive-" + fil.id}>
                  {secComp}
                  <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                            checked={selected.includes(fil.id)}
                  />
                </React.Fragment>
              );
            })}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>
      {!!createEvent && (
        <CreateEventModal curMinute={90} close={closeCreate} finish={sthChangesCreate}
                          teamId={createEvent.teamId} otherTeamId={createEvent.otherTeamId}
                          matchId={createEvent.matchId} />
      )}
    </React.Fragment>
  );
};

export default MatchList;
