import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const ProvinceIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  const strokeColor = useMemo(() => {
    return primary ? "white" : (theme === "light" ? "white" : "black");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 20 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.01675 7.90833C4.65842 0.691669 15.3501 0.700002 16.9834 7.91667C17.9417 12.15 15.3084 15.7333 13.0001 17.95C11.3251 19.5667 8.67508 19.5667 6.99175 17.95C4.69175 15.7333 2.05842 12.1417 3.01675 7.90833Z"
        fill={finalColor} />
      <path
        d="M10.0004 12.025C11.4363 12.025 12.6004 10.8609 12.6004 9.42495C12.6004 7.98901 11.4363 6.82495 10.0004 6.82495C8.56445 6.82495 7.40039 7.98901 7.40039 9.42495C7.40039 10.8609 8.56445 12.025 10.0004 12.025Z"
        stroke={strokeColor} strokeWidth="1.5" />
    </svg>
  );
};

export default ProvinceIcon;