import React, { useContext } from "react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { translate, TranslatorKeys } from "../../config/translator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClavaContext } from "../../config/contexts";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

const ClickableIcon: React.FC<{
  icon: IconDefinition,
  text?: TranslatorKeys,
  size?: SizeProp,
  onClick: () => void,
  className?: string
}> = ({ icon, className, text, size, onClick }) => {
  const { l } = useContext(ClavaContext);
  return (<button role="button" onClick={onClick}
                  className={"flex flex-row items-center justify-center outline-none border-none bg-none " + className}>
      <FontAwesomeIcon icon={icon} size={size ?? "1x"} />
      {!!text && <span className="ml-1">{translate(text, l)}</span>}
    </button>

  );
};
 
export default ClickableIcon;