import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { translate, TranslatorKeys } from "./config/translator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette } from "@fortawesome/pro-light-svg-icons";
import EventsSocket from "./client/Websockets/events";
import {
  AS_ENDPOINT,
  AS_FILTER_AOI,
  AS_LANG,
  AS_SPORTS_TYPE,
  PROD_ENDPOINT
} from "./config/constants";
import client from "./client";
import {
  browserLang,
  getDeviceInfo,
  isContentManager,
  isModerator,
  isRegistered
} from "./config/utils";
import { ClavaContext, ClavaRootContext } from "./config/contexts";
import { AreaOfInterest, LanguageLocaleEnum, Region, SportEnum, User } from "./client/api";
import { ClavaContextType } from "./config/types";
import Login from "./screens/Login/Login";
import Loading from "./components/Atoms/Loading";
import Main from "./routes/Main";
import { fb } from "./config/firebase";
import moment from "moment";

const browLang = browserLang();
const Wrapper: React.FC<{
  setTheme: (t: "dark" | "light") => void,
}> = ({ setTheme }) => {
  const { theme, fbToken } = useContext(ClavaRootContext);
  const toggleTheme = useCallback(() => {
    setTheme(theme === "dark" ? "light" : "dark");
  }, [setTheme, theme]);
  const [loading, setLoading] = useState(true);
  const [l, setL] = useState(localStorage.getItem(AS_LANG) as LanguageLocaleEnum ?? LanguageLocaleEnum.IT);
  const [user, setUser] = useState<User>();
  const [aois, setAois] = useState<AreaOfInterest[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [error, setError] = useState<TranslatorKeys>();
  const [sports, setSports] = useState<SportEnum>(SportEnum.SOCCER);
  const setUserCont = useCallback((u: User | undefined) => {
    if (!u) {
      setUser(undefined);
      setLoading(false);
      return;
    }
    if (!isRegistered(u)) {
      setUser(undefined);
      setLoading(false);
      return;
    }
    if (isContentManager(u) || isModerator(u)) {
      EventsSocket.setUser(u.id, u.areaOfInterest.id);
      setUser(u);
    } else {
      setError("noRights");
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    moment.locale(browLang);
    const endpoint = window.localStorage.getItem(AS_ENDPOINT);
    const errorFunc = () => {
      setError("serverGone");
      setLoading(false);
    };
    EventsSocket.init(endpoint ?? PROD_ENDPOINT);

    client(endpoint ?? PROD_ENDPOINT).setLang(browLang);
    client().getAois().then((as) => {
      setAois(as);
      client().getRegions().then((reg) => {
        setRegions(reg);
        client().refreshToken().then(setUserCont, () => {
          client().getLanguages().then((lgs) => {
            const language = lgs.find(lg => lg.locale === browLang);
            if (language) {
              client().register({
                languageId: language.id,
                deviceId: fb().getDeviceId(),
                areaOfInterestId: 1,
                deviceInfo: JSON.stringify(getDeviceInfo()),
                firebaseToken: fbToken
              }).then(() => {
                setLoading(false);
              }, errorFunc);
            }
          }, errorFunc);
        });
      }, errorFunc);
    }, errorFunc);

    const asSport = window.localStorage.getItem(AS_SPORTS_TYPE);
    if (asSport) {
      setSports(asSport === SportEnum.HOCKEY ? SportEnum.HOCKEY : asSport === SportEnum.VOLLEYBALL ? SportEnum.VOLLEYBALL : SportEnum.SOCCER);
    }
    return () => {
      EventsSocket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setSportsCont = useCallback((sp: SportEnum) => {
    window.localStorage.setItem(AS_SPORTS_TYPE, sp);
    setSports(sp);
  }, []);
  const setLang = useCallback((lang: LanguageLocaleEnum) => {
    setL(lang);
    localStorage.setItem(AS_LANG, lang);
  }, []);
  const [filterAoi, setFilterAoi] = useState(localStorage.getItem(AS_FILTER_AOI) ?? "all");
  const contextVal = useMemo<ClavaContextType | undefined>(() => {
    if (!user) {
      return undefined;
    }
    if (isContentManager(user) || isModerator(user)) {
      return {
        user,
        aoi: user.areaOfInterest.id,
        filterAoi,
        setFilterAoi,
        aois,
        l,
        setLang,
        setUser: setUserCont,
        sports,
        regions,
        setSports: setSportsCont
      };
    }
    return undefined;
  }, [user, l, setLang, aois, sports, setSportsCont, filterAoi, regions, setUserCont]);
  if (loading) {
    return (
      <Loading fullscreen />
    );
  }
  return (
    <>
      <div className="max-w-[1920px] mx-auto pt-2">
        {contextVal ? (
          <ClavaContext.Provider value={contextVal}>
            <Main />
          </ClavaContext.Provider>
        ) : (
          <Login setUser={setUserCont} l={browLang} error={error} />
        )}
      </div>
      <button
        type="button"
        className="theme-switcher"
        onClick={toggleTheme}
      >
        <FontAwesomeIcon icon={faPalette} />
        <span>{translate(`theme${theme}` as TranslatorKeys, user?.language.locale ?? browLang)}</span>
        <div className="spacer" />
      </button>
    </>
  );
};

export default Wrapper;
