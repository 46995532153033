import React from "react";
import Loading from "./Loading";

const LoadingBlock: React.FC<React.PropsWithChildren<{
  isLoading: boolean,
  smallLoading?: boolean,
  className?: string
}>> = ({
         isLoading,
         children,
         className, smallLoading
       }) => (
  <div className={"relative " + className}>
    {isLoading && smallLoading && (
      <div className="absolute top-2 right-2">
        <Loading />
      </div>
    )}
    {isLoading && !smallLoading ? (
      <Loading />
    ) : children}
  </div>
);

export default LoadingBlock;