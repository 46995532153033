import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IDType } from "../../config/types";
import { ClavaContext } from "../../config/contexts";
import { AreaOfInterest, League } from "../../client/api";
import useServer from "../../hooks/useServer";
import AsideModal from "../Layout/AsideModal";
import { showTranslated, translate } from "../../config/translator";
import ClavaTextInput from "../Atoms/ClavaTextInput";
import LoadingBlock from "../Atoms/LoadingBlock";
import AoiSelect from "../Atoms/Common/AoiSelect";
import LeagueLineItem from "../LineItems/LeagueLineItem";

const AddLeagueModal: React.FC<{
  onAdd: (ids: IDType[]) => void,
  close: () => void,
  alreadyInIds?: IDType[],
}> = ({ close, alreadyInIds, onAdd }) => {
  const { l, sports, aoi } = useContext(ClavaContext);
  const [selectedLeagues, setSelectedLeagues] = useState<IDType[]>([]);
  const [selectedAoi, setSelectedAoi] = useState<AreaOfInterest>();
  const { call, loading } = useServer(false);
  const [q, setQ] = useState("");
  const [leagues, setLeagues] = useState<League[]>([]);
  useEffect(() => {
    call("getLeaguesOfAoi", [selectedAoi?.id ?? aoi, sports]).then(setLeagues);
  }, [selectedAoi, aoi, sports, call]);
  const filtered = useMemo(() => {
    return leagues.filter(le => showTranslated(le.name, l).toLowerCase().indexOf(q.toLowerCase()) !== -1);
  }, [leagues, q, l]);
  const onSave = useCallback(() => {
    onAdd(selectedLeagues);
  }, [selectedLeagues, onAdd]);
  const onSelect = useCallback((id: IDType) => {
    if (selectedLeagues.includes(id)) {
      setSelectedLeagues(selectedLeagues.filter(i => i !== id));
    } else {
      setSelectedLeagues(selectedLeagues.concat([id]));
    }
  }, [selectedLeagues]);

  const allSelected = useMemo(() => {
    if (!alreadyInIds)
      return selectedLeagues;
    return alreadyInIds.concat(selectedLeagues);
  }, [alreadyInIds, selectedLeagues]);
  return (
    <AsideModal title={translate("addLeague", l)} close={close} saveClb={onSave}
                saveDisabled={selectedLeagues.length === 0}>
      <AoiSelect selected={selectedAoi} setSelected={setSelectedAoi} className="mb-2" />

      <ClavaTextInput onChange={setQ} value={q} placeholder={translate("search", l)}
                      label={translate("chooseTeams", l)} className="mb-2" />
      <LoadingBlock isLoading={loading} className="flex flex-col items-stretch justify-start">
        {filtered.map((t) => (
          <LeagueLineItem league={t} onSelect={onSelect} disabled={alreadyInIds?.includes(t.id)}
                          selected={allSelected.includes(t.id)}
                          selectBox
                          key={"add-league-modal-" + t.id} />
        ))}
      </LoadingBlock>
    </AsideModal>
  );
};

export default AddLeagueModal;