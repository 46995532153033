import React, { useCallback, useContext, useMemo, useState } from "react";
import { LanguageLocaleEnum, Location, MatchLeague } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import { AdditionalCheck, useFormData } from "../../hooks/useFormData";
import { actualMatchMinute, calcMinute, formatDate, getMatchDate } from "../../config/utils";
import useServer from "../../hooks/useServer";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { showTranslated, translate } from "../../config/translator";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import { faTrophy } from "@fortawesome/pro-solid-svg-icons";
import useBoolState from "../../hooks/useBoolState";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import ClavaDateInput from "../../components/Atoms/ClavaDateInput";
import ClavaImage from "../../components/Atoms/ClavaImage";
import { Link } from "react-router-dom";
import MatchEvents from "./MatchEvents";
import LocationSelect from "../../components/Atoms/Common/LocationSelect";
import MessageBox from "../../components/Atoms/MessageBox";
import { useNavigate } from "react-router";

const locationCheck: AdditionalCheck<MatchLeague, "location"> = (a, b) => a?.id === b?.id;

const MatchEditForm: React.FC<{
  match: MatchLeague,
  sthChanged: () => void;
}> = ({ match, sthChanged }) => {
  const { l } = useContext(ClavaContext);
  const form = useFormData(
    match,
    ["matchDay", "cancelled"],
    { location: locationCheck },
    [],
    ["cancelled"],
    ["matchDay"],
    [],
    []
  );
  const [del, toggleDelete] = useBoolState(false);
  const [startTime, setStartTime] = useState(getMatchDate(match));
  const { call, loading, status, StatusComponent } = useServer(false);
  const onSave = useCallback(() => {
    if (!form.nothingChanged || startTime.getTime() !== getMatchDate(match).getTime()) {
      call("patchMatch", [match.id, {
        matchDay: form.matchDay,
        cancelled: form.cancelled,
        locationId: form.location?.id,
        startTime: formatDate(startTime, LanguageLocaleEnum.DE, true)
      }]).then((res) => {
        sthChanged();
        form.setInitial(res);
      });
    }
  }, [form, call, sthChanged, match, startTime]);
  const onChangeLocation = useCallback((loc: Location | undefined) => {
    form.setFormData(fd => ({
      ...fd,
      location: loc
    }));
  }, [form]);
  const navigator = useNavigate();
  const onDelete = useCallback(() => {
    call("deleteMatch", [match.id], "deleteFailedBcsSquadMatch").then(() => {
      navigator("/match");
    });
  }, [match.id, navigator, call]);
  const curMinute = useMemo(() => {
    return actualMatchMinute(calcMinute(match), match.league.matchSectionAmount, match.league.matchSectionDurationMinutes, match.league.matchSectionPauseDurationMinutes);
  }, [match]);
  return (
    <RoundedBlock className={"mb-2"}>
      <ItemBlockHeader image={faTrophy}
                       imageRounded
                       name={`${showTranslated(match.team1.name, l)} - ${showTranslated(match.team2.name, l)}`}
                       onDelete={toggleDelete}
                       id={match.id} other={showTranslated(match.league.name, l)}>
        <ClavaButton onClick={onSave}
                     disabled={form.nothingChanged && startTime.getTime() === getMatchDate(match).getTime()}>{translate("save", l)}</ClavaButton>
      </ItemBlockHeader>
      <LoadingBlock isLoading={loading}>
        {!!status && !!status.key && StatusComponent}
        <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeMatchDay}
                          changed={form.matchDayChanged}
                          value={form.matchDayVal}
                          label={translate("matchDay", l)} />
          <ClavaDateInput value={startTime} onChange={setStartTime} className="flex-1 mx-2"
                          name={"matchStartTime"}
                          label={translate("startTime", l)} type={"datetime"}
                          changed={startTime.getTime() !== getMatchDate(match).getTime()} />
          <LocationSelect selected={form.location ?? undefined} setSelected={onChangeLocation}
                          className="flex-1 mx-2" />
        </div>
        <div className="flex flex-row items-center justify-between mx-[-8px] mt-2 my-4">
          <div className="flex-1 mx-2 flex flex-row items-center justify-start">
            <div className="mr-2 w-24 aspect-square">
              <ClavaImage width={"100%"} image={match.team1.emblem} />
            </div>
            <div className="flex-1 flex flex-col items-start justify-evenly">
              <span className="font-semibold">{showTranslated(match.team1.name, l)}</span>
              <Link to={`/team/${match.team1.id}`}
                    className="text-primary">ID: {match.team1.id}</Link>
            </div>
          </div>
          <div className="flex-1 flex flex-row items-center justify-center">
            <span className="font-bold text-center">{match.goal1} - {match.goal2}</span>
          </div>
          <div className="flex-1 mx-2 flex flex-row items-center justify-end">
            <div className="flex-1 flex flex-col items-end justify-evenly">
              <span className="font-semibold">{showTranslated(match.team2.name, l)}</span>
              <Link to={`/team/${match.team2.id}`}
                    className="text-primary">ID: {match.team2.id}</Link>
            </div>
            <div className="ml-2 w-24 aspect-square">
              <ClavaImage width={"100%"} image={match.team2.emblem} />
            </div>
          </div>
        </div>
        <h2 className="font-bold mt-4">{translate("events", l)}</h2>
        <MatchEvents events={match.events} sthChanged={sthChanged} curMinute={curMinute}
                     team1Id={match.team1.id} team2Id={match.team2.id}
                     matchId={match.id} />
      </LoadingBlock>
      <MessageBox type={"danger"} open={del} toggle={toggleDelete}
                  msg={translate("sureDelete", l, { "[name]": `${showTranslated(match.team1.name, l)} - ${showTranslated(match.team2.name, l)}` })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDelete}
                  btn2Text={translate("no", l)} />
    </RoundedBlock>
  );
};

export default MatchEditForm;
