import React, { useContext } from "react";
import { League, LeagueListElement } from "../../client/api";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { showTranslated } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDown } from "@fortawesome/pro-regular-svg-icons";

const DraggableLeagueItem: React.FC<{
  league: League | LeagueListElement,
  idx: number,
  changed: boolean
}> = ({
        league,
        idx,
        changed
      }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: league.id
  });
  const { setNodeRef: setNodeRefDroppable } = useDroppable({
    id: league.id
  });
  const { l } = useContext(ClavaContext);

  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: transform ? 2 : 0,
    opacity: transform ? 0.7 : 1
  };
  return (
    <li ref={setNodeRef} style={style} {...attributes} {...listeners}
        className="relative even:bg-input-bg dark:even:bg-input-bg-dark odd:bg-white dark:odd:bg-bg-dark p-2 flex flex-row items-center justify-start">
      <div className="absolute inset-0" ref={setNodeRefDroppable}></div>
      <span
        className="text-lg flex-1">[{idx + 1}] {showTranslated(league.name, l)} {changed ? "*" : ""}</span>
      <FontAwesomeIcon icon={faArrowsUpDown} size="lg" />
    </li>
  );
};

export default DraggableLeagueItem;