import React, { useCallback, useContext, useMemo } from "react";
import { Team, TeamListElement, TeamListMinimum, TournamentTeam } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import { IDType } from "../../config/types";
import ClavaImage from "../Atoms/ClavaImage";
import { showTranslated } from "../../config/translator";
import { getMainLeague } from "../../config/utils";

const TeamLineItem: React.FC<{
  selectBox?: boolean,
  icon?: boolean,
  disabled?: boolean,
  team: Team | TeamListElement | TeamListMinimum | TournamentTeam,
  onSelect: (id: IDType) => void,
  selected: boolean
}> = ({ team, disabled, icon, selectBox, selected, onSelect }) => {
  const { l } = useContext(ClavaContext);
  const additional = useMemo(() => {
    if ("leagues" in team) {
      return getMainLeague(team.leagues);
    }
    if ("groups" in team && team.groups && team.groups.length > 0) {
      return team.groups[0];
    }
    return undefined;
  }, [team]);
  const onCheckCont = useCallback(() => {
    if (!disabled) {
      onSelect(team.id);
    }
  }, [disabled, onSelect, team.id]);
  return (
    <button onClick={onCheckCont}
            className={`flex self-stretch flex-row items-center border-none border-b border-b-light-gray dark:border-b-light-gray-dark p-1 ${selected ? "even:bg-primary-30 odd:bg-primary-40" : "even:bg-white even:dark:bg-bg-dark odd:bg-bg dark:odd:bg-black"}`}>
      {icon && "emblem" in team && (
        <div className="w-8 aspect-square mr-2">
          <ClavaImage width={"100%"} image={team.emblem} />
        </div>
      )}
      <div className="flex-1 flex flex-col items-start justify-evenly">
        <span className="font-semibold">[{team.id}] {showTranslated(team.name, l)}</span>
        {!!additional && (
          <span
            className="font-light text-sm">[{additional.id}] {showTranslated(additional.name, l)} {"year" in additional ? additional.year : ""}</span>)}
      </div>
      {selectBox && (
        <input type="checkbox" disabled={disabled} className="mx-2" onChange={onCheckCont}
               checked={selected} />)}

    </button>
  );
};

export default TeamLineItem;
