/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PlayerPositionEnum {
    KEEPER = 'keeper',
    DEFENDER = 'defender',
    MIDFIELD = 'midfield',
    OFFENSE = 'offense',
}
