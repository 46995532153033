import React, { useCallback, useContext, useRef } from "react";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ActiveClientsMap from "./ActiveClientsMap";


const Dashboard: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const { call, loading, StatusComponent } = useServer(false);
  const task = useRef<"cache">();
  const onDeleteCache = useCallback(() => {
    task.current = "cache";
    call("clearCache", []).then();
  }, [call]);
  return (<>
      <Breadcrumps />
      <RoundedBlock>
        <LoadingBlock isLoading={loading}>
          <div className="rounded-lg border-red border p-4">
            <h3 className="font-bold text-lg">Danger Zone</h3>
            <p>{translate("resetCacheDesc", l)}</p>
            <ClavaButton onClick={onDeleteCache} className={"my-2"}>
              {translate("resetCache", l)}
            </ClavaButton>
            {task.current === "cache" && StatusComponent}
          </div>
        </LoadingBlock>
      </RoundedBlock>
      <ActiveClientsMap />
    </>
  );
};

export default Dashboard;
