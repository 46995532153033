/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Region } from '../models/Region';
import type { RegionCreate } from '../models/RegionCreate';
import type { RegionList } from '../models/RegionList';
import type { RegionPatch } from '../models/RegionPatch';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RegionService {
    /**
     * Get Regions
     * @returns RegionList Successful Response
     * @throws ApiError
     */
    public static getRegionsRegionGet(): CancelablePromise<RegionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/region/',
        });
    }
    /**
     * Create Region
     * @param requestBody
     * @returns Region Successful Response
     * @throws ApiError
     */
    public static createRegionRegionPost(
        requestBody: RegionCreate,
    ): CancelablePromise<Region> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/region/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Region
     * @param regionId
     * @returns Region Successful Response
     * @throws ApiError
     */
    public static getRegionRegionRegionIdGet(
        regionId: number,
    ): CancelablePromise<Region> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/region/{region_id}',
            path: {
                'region_id': regionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Region
     * @param regionId
     * @param requestBody
     * @returns Region Successful Response
     * @throws ApiError
     */
    public static patchRegionRegionRegionIdPatch(
        regionId: number,
        requestBody: RegionPatch,
    ): CancelablePromise<Region> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/region/{region_id}',
            path: {
                'region_id': regionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Region
     * @param regionId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteRegionRegionRegionIdDelete(
        regionId: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/region/{region_id}',
            path: {
                'region_id': regionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
