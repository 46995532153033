import React, { useContext, useMemo } from "react";
import { FileType, ImagePickerReturnFile } from "../../config/types";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import ClavaImage from "../Atoms/ClavaImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { isPhotoType, isVideoType } from "../../config/mediaUtils";
import { faImage, faVideo } from "@fortawesome/pro-regular-svg-icons";

function FileUploaderMediathek<T extends FileType>({ as, type, callback, mediathek }: {
  as: number,
  type: T,
  mediathek: ImagePickerReturnFile<T>[]
  callback: (file: ImagePickerReturnFile<T>) => void,
}) {
  const { l } = useContext(ClavaContext);
  const filteredMediathek = useMemo(() => {
    return mediathek.filter((m) => {
      if ("name" in m) {
        return type === "pdf";
      }

      if (m.cropAspectRatio !== as)
        return false;
      if (isVideoType(m)) {
        return type === "video" || type === "media";
      }
      return type === "image" || type === "media";
    });
  }, [type, mediathek, as]);
  if (filteredMediathek.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center">
        <span className="font-semibold">{translate("noFilesInMediathek", l)}</span>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-3 grid-rows-3">
      {filteredMediathek.map((file) => (
        <button key={"mediathek-" + ("name" in file ? file.name : file.id)}
                className="m-1 relative border border-light-gray overflow-hidden dark:border-light-gray-dark hover:border-primary hover:dark:border-primary rounded-xl"
                onClick={() => {
                  callback(file);
                }}>
          {"name" in file ? (
            <FontAwesomeIcon icon={faFilePdf} size="2x" />
          ) : isPhotoType(file) ? (
            <>
              <ClavaImage width={"100%"} image={file} />
              <FontAwesomeIcon icon={faImage} size="sm" className="absolute top-1 right-1" />
            </>
          ) : (
            <>
              <ClavaImage width={"100%"}
                          imageUrl={file.dash.replace("manifest/video.mpd", "thumbnails/thumbnail.jpg")} />
              <FontAwesomeIcon icon={faVideo} size="sm" className="absolute top-1 right-1" />
            </>
          )}
          <span
            className="relative my-1 font-bold text-sm text-primary">{"name" in file ? file.name : file.id}</span>
        </button>
      ))}
    </div>
  );
}

export default FileUploaderMediathek;