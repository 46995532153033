import { Reducer } from "redux";
import { GeneralState } from "../constants";
import { AllActions, GeneralActionTypes } from "../actions/types";

const initialState: GeneralState = {
  positions: [],
  tasks: [],
  tasksResponse: [],
  errorPositions: null,
  errorTasks: null,
  statusPositions: "idle",
  statusTasks: "idle"
};

const reducer: Reducer<GeneralState, AllActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GeneralActionTypes.FETCH_PLAYER_POSITIONS: {
      return {
        ...state,
        errorPositions: null, statusPositions: "loading"
      };
    }
    case GeneralActionTypes.FETCH_PLAYER_POSITIONS_SUCCESS: {
      return {
        ...state,
        errorPositions: null,
        statusPositions: "idle",
        positions: action.payload
      };
    }
    case GeneralActionTypes.FETCH_PLAYER_POSITIONS_ERROR: {
      return { ...state, statusPositions: "failed", errorPositions: action.payload };
    }
    case GeneralActionTypes.FETCH_TASK: {
      return {
        ...state,
        errorTasks: null, statusTasks: "loading"
      };
    }
    case GeneralActionTypes.FETCH_TASK_SUCCESS: {
      return {
        ...state,
        errorTasks: null,
        statusTasks: "idle",
        tasksResponse: state.tasksResponse.filter(t => t.id !== action.payload.id).concat([action.payload])
      };
    }
    case GeneralActionTypes.FETCH_TASK_ERROR: {
      return { ...state, statusTasks: "failed", errorTasks: action.payload };
    }
    case GeneralActionTypes.ADD_TASK:
      return {
        ...state,
        tasks: state.tasks.filter(t => t.id !== action.payload.id).concat([action.payload])
      };
    case GeneralActionTypes.REMOVE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter(t => t.id !== action.payload)
      };
    case GeneralActionTypes.LOAD_TASKS:
      return {
        ...state,
        tasks: action.payload
      };
    default: {
      return state;
    }
  }
};

export { reducer as generalReducer };
