import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import useEditParams from "../../hooks/useEditParams";
import { MatchLeague } from "../../client/api";
import useServer from "../../hooks/useServer";
import { showTranslated } from "../../config/translator";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import MatchEditForm from "./MatchEditForm";

const MatchEdit = () => {
  const { l } = useContext(ClavaContext);
  const { id, navNotFound } = useEditParams("matchId", "/match");
  const [match, setMatch] = useState<MatchLeague>();
  const { loading, call, status, StatusComponent } = useServer(true);
  const getMatch = useCallback(() => {
    if (id) {
      call("getMatch", [id], "notFound").then(setMatch, navNotFound);
    } else {
      navNotFound();
    }
  }, [id, navNotFound, call]);
  useEffect(() => {
    getMatch();
  }, [getMatch]);
  const breadcrump = useMemo(() => {
    if (!match) {
      return [];
    }
    return [
      `${showTranslated(match.team1.name, l)} ${showTranslated(match.team2.name, l)}`
    ];
  }, [match, l]);

  return (
    <LoadingBlock isLoading={loading}>
      {!!status && !!status.key && StatusComponent}
      <Breadcrumps additional={breadcrump}></Breadcrumps>
      {!!match &&
        <MatchEditForm match={match} sthChanged={getMatch} />}
    </LoadingBlock>
  );
};

export default MatchEdit;