import React, { useContext } from "react";
import { TeamImport_Output } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import { showTranslated } from "../../config/translator";

const TeamSuccess: React.FC<{ success: TeamImport_Output }> = ({ success }) => {
  const { l } = useContext(ClavaContext);

  return (
    <>

      <div className="flex-1 flex flex-row items-center justify-start">
        <img className="w-8 h-8" src={success.logoUrl} alt={success.name.textDE} />
        <span>{showTranslated(success.name, l)}</span>
      </div>
      <div className="flex-1 flex items-start justify-between">
        <span>{showTranslated(success.league.name, l)}</span>
      </div>
      <div className="flex-1">
      </div>
    </>
  );
};

export default TeamSuccess;
