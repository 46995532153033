import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  League,
  LeagueCategory,
  LeagueCategoryEnum,
  LeagueCreate,
  LeagueRatingEnum,
  SportEnum
} from "../../client/api";
import AsideModal from "../../components/Layout/AsideModal";
import { showTranslated, translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import { AdditionalCheck, useFormData } from "../../hooks/useFormData";
import { sameIdArray } from "../../config/utils";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import ClavaSelect, {
  mapTranslatableToItem,
  mapTranslationToItem
} from "../../components/Atoms/ClavaSelect";
import { ClavaSelectItems } from "../../config/types";
import { SPORTS, tableRatingItems } from "../../config/constants";
import ClavaSelectMultiple from "../../components/Atoms/ClavaSelectMultiple";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ClavaSwitch from "../../components/Atoms/ClavaSwitch";

const emptyLeague: LeagueCreate = {
  sports: SportEnum.SOCCER,
  name: {
    textEN: "",
    textIT: "",
    textDE: ""
  },
  areaOfInterestIds: [1],
  category: LeagueCategoryEnum.LEAGUE,
  main: true,
  internalName: "",
  year: (new Date()).getFullYear(),
  officialName: "",
  tableRating: LeagueRatingEnum.TRENTINO
};

const aoiCheck: AdditionalCheck<LeagueCreate, "areaOfInterestIds"> = sameIdArray;
const LeagueCreateModal: React.FC<{
  onAdd: (league: League) => void,
  close: () => void
}> = ({ onAdd, close }) => {
  const { l, aois, sports } = useContext(ClavaContext);
  const [categories, setCategories] = useState<LeagueCategory[]>([]);
  const [copyName, setCopyName] = useState(!(localStorage.getItem("copyLeagueName") === "false"));
  const toggleCopy = useCallback((val: boolean) => {
    setCopyName(val);
    localStorage.setItem("copyLeagueName", val ? "true" : "false");
  }, []);
  const { call, loading, status, StatusComponent } = useServer(true);
  useEffect(() => {
    call("getLeagueCategories", []).then(setCategories);
  }, [call]);
  const form = useFormData(emptyLeague,
    ["name", "sports", "category", "tableRating", "main", "officialName", "internalName", "year"],
    { areaOfInterestIds: aoiCheck },
    ["category", "internalName", "sports", "officialName", "tableRating"],
    ["main"],
    ["year"],
    [],
    ["name"]
  );
  const aoiItems = useMemo(() => {
    return aois.map(mapTranslatableToItem(l));
  }, [l, aois]);
  const onChangeAois = useCallback((val: string[]) => {
    const idArr = val.map(v => parseInt(v, 10));
    form.onChange("areaOfInterestIds", idArr);
  }, [form]);
  const selectedAois = useMemo(() => {
    return form.areaOfInterestIds.map(aoi => aoi.toString(10));
  }, [form.areaOfInterestIds]);
  const catItems = useMemo<ClavaSelectItems[]>(() => {
    return categories.map((cat) => ({
      key: cat.category,
      label: showTranslated(cat.translation, l)
    }));
  }, [l, categories]);

  const sportItems = useMemo(() => {
    return SPORTS.map(mapTranslationToItem(l));
  }, [l]);
  const onSave = useCallback(() => {
    call("createLeague", [{
      sports,
      areaOfInterestIds: form.areaOfInterestIds,
      name: form.name,
      tableRating: form.tableRating,
      category: form.category,
      year: form.year,
      officialName: form.officialName,
      main: form.main,
      internalName: form.internalName
    }], "saveFailed", "saveSuccessCanCreateNew").then((le) => {
      onAdd(le);
    });
  }, [call, onAdd, sports, form]);

  const onChangeName = useCallback((val: string) => {
    form.onChangeNameDe(val);
    form.onChangeNameIt(val);
    form.onChangeNameEn(val);
  }, [form]);
  return (
    <AsideModal close={close} title={translate("createLeague", l)} saveClb={onSave}
                saveDisabled={!form.allChanged}>
      <LoadingBlock isLoading={loading}
                    className="flex-1 flex flex-col items-stretch justify-start">
        {!!status && !!status.key && StatusComponent}
        <ClavaTextInput onChange={copyName ? onChangeName : form.onChangeNameDe}
                        changed={form.nameChangedDe}
                        value={form.nameDe} label={translate("nameDe", l)} className="my-2" />
        <ClavaSwitch onChange={toggleCopy} value={copyName} label={translate("copyName", l)}
                     horizontal />
        <ClavaTextInput onChange={form.onChangeNameIt} changed={form.nameChangedIt}
                        value={form.nameIt} label={translate("nameIt", l)} className="my-2" />
        <ClavaTextInput onChange={form.onChangeNameEn} changed={form.nameChangedEn}
                        value={form.nameEn} label={translate("nameEn", l)} className="my-2" />
        <ClavaSelectMultiple className="my-2" uniqueId="league-aois" items={aoiItems}
                             value={selectedAois}
                             onChange={onChangeAois}
                             label={translate("province", l)} />
        <ClavaSelect className="my-2" value={form.category}
                     changed={form.categoryChanged}
                     items={catItems}
                     onChange={form.onChangeCategory}
                     label={translate("category", l)} uniqueId={"category"} />
        <ClavaSelect className="my-2" value={form.tableRating}
                     changed={form.tableRatingChanged}
                     items={tableRatingItems}
                     onChange={form.onChangeTableRating}
                     label={translate("rankingCalculation", l)} uniqueId={"tableRating"} />
        <ClavaSelect className="my-2" value={form.sports}
                     changed={form.sportsChanged}
                     items={sportItems}
                     onChange={form.onChangeSports}
                     label={translate("sports", l)} uniqueId={"league-sports"} />
        <div className="flex flex-row items-center justify-evenly my-2">
          <ClavaSwitch onChange={form.onChangeMain} value={form.main}
                       label={translate("mainLeague", l)} className="flex-1 mr-2"
                       changed={form.mainChanged} />
          <ClavaTextInput onChange={form.onChangeYear} value={form.yearVal} type="number"
                          changed={form.yearChanged} label={translate("year", l)}
                          className="flex-1 ml-2" />
        </div>
        <ClavaTextInput onChange={form.onChangeOfficialName} changed={form.officialNameChanged}
                        value={form.officialName} label={translate("officialName", l)}
                        className="my-2" />
        <ClavaTextInput onChange={form.onChangeInternalName} changed={form.internalNameChanged}
                        value={form.internalName} label={translate("internalName", l)}
                        className="my-2" />
      </LoadingBlock>
    </AsideModal>
  );
};

export default LeagueCreateModal;