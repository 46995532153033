import React, { KeyboardEventHandler, useCallback, useMemo } from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { faBold } from "@fortawesome/pro-light-svg-icons";
import { faBold as faBoldSolid } from "@fortawesome/pro-solid-svg-icons";
import useBoolState from "../../hooks/useBoolState";

const FORMATTER_CLASSES = "select-none rounded-xl bg-transparent hover:bg-primary-30 flex flex-col items-center justify-center w-8 h-8";

export declare type ClavaTextAreaProps = {
  onChange: (text: string) => void;
  value?: string;
  placeholder?: string;
  onKeyUp?: KeyboardEventHandler<HTMLTextAreaElement>;
  disabled?: boolean;
  error?: boolean;
  changed?: boolean;
  title?: string;
  label?: string;
  name?: string;
  className?: string;
  formattingEnabled?: boolean;
  cols?: number;
  rows?: number;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  iconL?: FontAwesomeIconProps["icon"];
  innerRef?: React.RefObject<HTMLTextAreaElement>;
};

const ClavaTextArea: React.FC<ClavaTextAreaProps> = ({
                                                       onChange,
                                                       value, className,
                                                       placeholder,
                                                       disabled,
                                                       onKeyDown, formattingEnabled, rows, cols,
                                                       label,
                                                       onKeyUp, changed,
                                                       name,
                                                       iconL,
                                                       error,
                                                       innerRef,
                                                       title
                                                     }) => {
  const [bold, toggleBold] = useBoolState(false);
  const [cursive, toggleCursive] = useBoolState(false);
  const [underline, toggleUnderline] = useBoolState(false);
  const realPlaceholder = useMemo(() => {
    return placeholder;
  }, [placeholder]);
  const onChangeCont = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );
  const inputClass = useMemo(() => {
    let style =
      "w-full py-2 outline-0 rounded-xl active:bg-input-bg-active active:dark:bg-input-bg-active-dark focus:bg-input-bg-active focus:dark:bg-input-bg-active-dark border-[0.5px] active:dark:border-input-border-active-dark focus:dark:border-input-border-active-dark text-font dark:text-font-dark";

    if (disabled) {
      style += " border-input-border";
    } else {
      style +=
        " bg-input-bg dark:bg-input-bg-dark border-input-border dark:border-input-border-dark active:border-input-border-active focus:border-input-border-active placeholder-placeholder dark:placeholder-placeholder-dark";
    }
    if (error) {
      style += " !border-red";
    }
    if (iconL) {
      style += " pl-8 pr-2";
    } else {
      style += " px-2";
    }
    return style;
  }, [disabled, error, iconL]);

  return (
    <div className={"relative flex flex-col items-start justify-stretch w-full " + className}>
      {!!label && (
        <span
          className={`font-semibold text-sm mb-1 relative ${error ? "!text-red" : ""}`}
        >
          {label}
          {changed && (
            <span
              className="absolute top-[-.25rem] right-[-.75rem] text-orange font-bold text-lg">*</span>)}
        </span>
      )}
      {formattingEnabled && (
        <div
          className="flex flex-row items-start justify-stretch w-full rounded-xl bg-light-gray dark:bg-light-gray-dark p-1">
          <button className={FORMATTER_CLASSES} onClick={toggleBold}>
            <FontAwesomeIcon icon={bold ? faBoldSolid : faBold} />
          </button>
          <button className={FORMATTER_CLASSES} onClick={toggleCursive}>
            <FontAwesomeIcon icon={cursive ? faBoldSolid : faBold} />
          </button>
          <button className={FORMATTER_CLASSES} onClick={toggleUnderline}>
            <FontAwesomeIcon icon={underline ? faBoldSolid : faBold} />
          </button>
        </div>
      )}
      <textarea
        placeholder={realPlaceholder}
        disabled={disabled}
        ref={innerRef}
        onChange={onChangeCont}
        name={name}
        onKeyUp={onKeyUp}
        title={title}
        tabIndex={0}
        onKeyDown={onKeyDown}
        cols={cols}
        rows={rows}
        className={inputClass}
      >
        {value}
      </textarea>
      {!!iconL && (
        <FontAwesomeIcon
          icon={iconL}
          className="w-4 h-4 bottom-3.5 left-3 absolute"
        />
      )}
    </div>
  );
};

export default ClavaTextArea;
