import React from "react";
 
const RoundedBlock: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
                                                                                   className,
                                                                                   children
                                                                                 }) => (
  <div
    className={"bg-white dark:bg-bg-dark rounded-[15px] drop-shadow-md shadow-shadow dark:shadow-shadow-dark p-4 " + className}>{children}</div>
);

export default RoundedBlock;