import React, { useCallback, useContext, useEffect, useState } from "react";
import { Image, Team, TeamCreate, TeamListElement } from "../../client/api";
import AsideModal from "../Layout/AsideModal";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import { useFormData } from "../../hooks/useFormData";
import useBoolState from "../../hooks/useBoolState";
import useServer from "../../hooks/useServer";
import ClavaTextInput from "../Atoms/ClavaTextInput";
import { IDType } from "../../config/types";
import MessageBox from "../Atoms/MessageBox";
import FileUploader from "../FileUploader/FileUploader";
import ClavaImage from "../Atoms/ClavaImage";
import AddTeamModal from "./AddTeamModal";

const emptyTeam: TeamCreate = {
  name: {
    textEN: "",
    textIT: "",
    textDE: ""
  },
  emblemId: "",
  leader: null,
  officialName: ""
};

const CreateTeamModal: React.FC<{
  onAdd: (team: Team) => void,
  close: () => void,
  leagueId?: IDType,
}> = ({ onAdd, close }) => {
  const { l } = useContext(ClavaContext);
  const form = useFormData(
    emptyTeam,
    ["name", "officialName"],
    {},
    ["officialName"],
    [],
    [],
    [],
    ["name"]
  );
  const [wantPatchImage, toggleWantPatchImage, setWantPatchImage] = useBoolState(false);
  const [img, setImg] = useState<Image>();
  const [patchImage, togglePatchImage, setPatchImage] = useBoolState(false);
  const [adopt, toggleAdopt, setAdopt] = useBoolState(false);
  const { call, StatusComponent } = useServer(true);
  useEffect(() => {
    if (patchImage || adopt) {
      setWantPatchImage(false);
    }
  }, [adopt, patchImage, setWantPatchImage]);
  const onChangeImage = useCallback((file: Image) => {
    setImg(file);
    setPatchImage(false);
    setAdopt(false);
    form.setFormData(fd => ({
      ...fd,
      emblemId: file.id
    }));
  }, [setAdopt, setPatchImage, form]);
  const onAdopt = useCallback((team: TeamListElement[]) => {
    onChangeImage(team[0].emblem);
  }, [onChangeImage]);
  const onSave = useCallback(() => {
    if (!form.nothingChanged) {
      call("createTeam", [{
        name: form.name,
        officialName: form.officialName,
        emblemId: img?.id,
        leader: form.leader
      }], "saveFailed", "saveSuccess").then((res) => {
        onAdd(res);
        form.setInitial(res);
      });
    }
  }, [form, call, img, onAdd]);
  const onChangeName = useCallback((val: string) => {
    form.onChangeNameDe(val);
    form.onChangeNameIt(val);
    form.onChangeNameEn(val);
  }, [form]);
  return (<React.Fragment>
      <AsideModal close={close} title={translate("createTeam", l)} saveClb={onSave}>
        {StatusComponent}
        <ClavaTextInput className="my-2" onChange={onChangeName}
                        changed={form.nameChangedDe}
                        value={form.nameDe}
                        label={translate("name", l)} />
        <ClavaTextInput className="my-2" onChange={form.onChangeOfficialName}
                        changed={form.officialNameChanged}
                        value={form.officialName}
                        label={translate("officialName", l)} />
        <button onClick={toggleWantPatchImage}
                className={`aspect-square max-w-32 mt-4 flex items-center justify-center ${!!img ? "min-w-32 w-32 overflow-hidden" : "p-4 border border-black dark:border-white"}`}>
          {!!img ? (<ClavaImage image={img} width={"100%"} />) : (
            <span className="text-primary">{translate("addEmblem", l)}</span>)}
        </button>

      </AsideModal>
      {patchImage && (
        <FileUploader as={1} type={"image"} callback={onChangeImage} close={togglePatchImage} />
      )}
      <MessageBox type={"info"} open={wantPatchImage} toggle={toggleWantPatchImage}
                  msg={translate("howCreateTeamEmblem", l)}
                  btn1Clb={togglePatchImage} btn1Text={translate("upload", l)}
                  btn2Clb={toggleAdopt}
                  btn2Text={translate("adoptFormOtherTeam", l)} btn2Primary />
      {adopt && (
        <AddTeamModal onAdd={onAdopt} close={toggleAdopt} single title={"adoptFormOtherTeam"} />)}
    </React.Fragment>
  );
};

export default CreateTeamModal;