import React, { useCallback, useContext, useMemo, useState } from "react";
import { translate, TranslatorKeys } from "../config/translator";
import { CancelablePromise } from "../client/api";
import client, { StaticClient } from "../client";
import { ClavaContext } from "../config/contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStop } from "@fortawesome/pro-solid-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

export type ClientFunc = keyof StaticClient;
export type ClientParams<T extends ClientFunc> = Parameters<StaticClient[T]>;
export type ClientRetval<T extends ClientFunc> = ReturnType<StaticClient[T]>;

function useServer(initialLoading = false) {
  const { l } = useContext(ClavaContext);
  const [loading, setLoading] = useState(initialLoading);
  const [status, setStatus] = useState<{ success: boolean; key?: TranslatorKeys }>();
  const closeStatus = useCallback(() => {
    setStatus(undefined);
  }, []);

  const call = useCallback(<T extends ClientFunc>(func: T, params: ClientParams<T>, onFail?: TranslatorKeys, onSuccess?: TranslatorKeys): ClientRetval<T> => {
    // @ts-ignore
    return new CancelablePromise((resolve, reject) => {
      setLoading(true);
      setStatus(undefined);
      // @ts-ignore
      const res = client()[func](...params);
      if (res instanceof CancelablePromise) {
        res.then((result) => {
          setLoading(false);
          setStatus({ success: true, key: onSuccess });
          resolve(result);
        }, (error) => {
          setLoading(false);
          setStatus({ success: false, key: onFail });
          reject(error);
        });
      } else {
        reject(new Error(`Failed to retrieve ${func}: ${params}`));
      }
    });
  }, []);
  const StatusComponent = useMemo(() => {
    if (!status)
      return null;
    if (!status.key) {
      return (<div className={`${status.success ? "text-green" : "text-red"} self-center`}>
        <FontAwesomeIcon icon={status.success ? faCheck : faStop} size={"lg"} />
      </div>);
    }
    return (
      <span
        className={`${status.success ? "text-green" : "text-red"} font-semibold text-center self-center`}>{translate(status.key, l)}</span>);
  }, [l, status]);
  return {
    loading,
    status,
    setLoading,
    setStatus,
    call,
    closeStatus,
    StatusComponent
  };
}

export default useServer;