import React, { useCallback } from "react";
import TableCellEditable, { TableCellEditableItem } from "./TableCellEditable";
import {
  LeagueImport_Output,
  LeagueMatchImport_Output,
  SquadImport_Output,
  TeamImport_Output
} from "../../client/api";
import useBoolState from "../../hooks/useBoolState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronSquareDown,
  faChevronSquareUp,
  faEyeSlash
} from "@fortawesome/pro-regular-svg-icons";
import MatchSuccess from "../Import/MatchSuccess";
import PlayerSuccess from "../Import/PlayerSuccess";
import TeamSuccess from "../Import/TeamSuccess";
import LeagueSuccess from "../Import/LeagueSuccess";


const TableRowEditable: React.FC<{
  row: number,
  error?: string;
  success?: LeagueMatchImport_Output | TeamImport_Output | LeagueImport_Output | SquadImport_Output;
  items: TableCellEditableItem[],
  onCheck: (row: number) => void,
  autocomplete?: (val: string, row: number) => Promise<string[]>,
  onChange: (row: number, col: number, newVal: string) => void,
  checked: boolean
}> = ({ items, autocomplete, success, error, row, onCheck, onChange, checked }) => {
  const [successOpen, toggleSuccessOpen] = useBoolState(false);
  const [resultHidden, toggleResultHidden] = useBoolState(false);
  const onCheckCont = useCallback(() => {
    onCheck(row);
  }, [row, onCheck]);
  const onChangeCont = useCallback((col: number, val: string) => {
    onChange(row, col, val);
  }, [onChange, row]);
  return (
    <React.Fragment>
      <tr
        className={"even:bg-input-bg dark:even:bg-input-bg-dark " + (!!error ? "text-red" : !!success ? "text-green" : "")}>
        <td className="p-2 text-center">
          <input type="checkbox" onChange={onCheckCont} checked={checked} />
        </td>
        {items.map((it, i) => (
          <TableCellEditable row={row} autocomplete={autocomplete ?? it.autocomplete}
                             value={it.value} col={it.col}
                             onChange={onChangeCont}
                             readonly={it.readonly}
                             key={"tr-" + row + "-" + it.col + "-" + i} />
        ))}
      </tr>
      {!!error && !resultHidden && (
        <tr className="even:bg-input-bg dark:even:bg-input-bg-dark">
          <td colSpan={items.length} className="p-2 text-red font-bold text-center">
            {error}
          </td>
          <td>
            <div className="flex flex-row items-center justify-center">
              <button onClick={toggleResultHidden}
                      className="mx-1 w-10 h-10 flex flex-row items-center justify-center rounded-lg bg-transparent border border-light-gray dark:border-light-gray-dark hover:bg-primary hover:text-white">
                <FontAwesomeIcon icon={faEyeSlash} />
              </button>
            </div>
          </td>
        </tr>
      )}
      {!!success && !resultHidden && (
        <tr className="even:bg-input-bg dark:even:bg-input-bg-dark">
          <td className="p-2 ">
            <button onClick={toggleSuccessOpen}
                    className="bg-green-10 flex flex-row items-center justify-center p-2 mr-4 rounded-full outline-0">
              <FontAwesomeIcon icon={!successOpen ? faChevronSquareDown : faChevronSquareUp} />
            </button>
          </td>
          <td className="p-2 text-green">
            Success
          </td>
          <td colSpan={items.length - 2} className="p-2 text-center">
            {successOpen && (
              <div className="flex flex-row items-center justify-start p-2">
                {
                  "startTime" in success ?
                    <MatchSuccess success={success} /> : "familyName" in success ?
                      <PlayerSuccess success={success} /> : "internalName" in success ?
                        <LeagueSuccess success={success} /> : <TeamSuccess success={success} />
                }
              </div>
            )}
          </td>
          <td>
            <div className="flex flex-row items-center justify-center">
              <button onClick={toggleResultHidden}
                      className="mx-1 w-10 h-10 flex flex-row items-center justify-center rounded-lg bg-transparent border border-light-gray dark:border-light-gray-dark hover:bg-primary hover:text-white">
                <FontAwesomeIcon icon={faEyeSlash} />
              </button>
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default TableRowEditable;
