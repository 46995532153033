import React, { useContext } from "react";
import { translate, TranslatorKeys } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";

const Pill: React.FC<{
  status: "success" | "warning" | "danger" | "info",
  text: TranslatorKeys
}> = ({ status, text }) => {
  const { l } = useContext(ClavaContext);
  return (
    <div
      className={"relative max-w-fit rounded-xl " + (status === "success" ? "bg-green" : status === "warning" ? "bg-orange" : status === "info" ? "bg-primary" : "bg-red")}>
      <div
        className="mx-[7px] my-[3px] text-white font-medium">{translate(text, l)}
      </div>
    </div>
  );
};

export default Pill;