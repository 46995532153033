import React from "react";
import RoundedBlock from "../Atoms/RoundedBlock";

const pkg = require("../../../package.json");

const Footer = () => (
  <RoundedBlock className="mt-2">
    <div className="flex flex-row items-center justify-start">
    <span
      className="font-light">All Rights Reserved © 2024</span>
      <div className="flex-1" />
      <span className="font-bold">Clava</span>
      <span className="font-light text-sm ml-2">v{pkg.version}</span>
    </div>
  </RoundedBlock>
);

export default Footer;