import React, { useCallback, useContext, useMemo, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import { Blog, Image, SportEnum } from "../../client/api";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { showTranslated, translate } from "../../config/translator";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import FileUploader from "../../components/FileUploader/FileUploader";
import ClavaButton from "../../components/Atoms/ClavaButton";
import MessageBox from "../../components/Atoms/MessageBox";
import { useNavigate } from "react-router";
import { useFormData } from "../../hooks/useFormData";
import useBoolState from "../../hooks/useBoolState";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import ClavaTextArea from "../../components/Atoms/ClavaTextArea";
import ClavaSelect, { mapTranslationToItem } from "../../components/Atoms/ClavaSelect";
import { SPORTS } from "../../config/constants";


const as = 16 / 9;

const BlogEditForm: React.FC<{ blog: Blog, changes: (blog: Blog) => void }> = ({
                                                                                 blog,
                                                                                 changes
                                                                               }) => {
  const { l } = useContext(ClavaContext);
  const form = useFormData(blog,
    ["title", "summary", "body", "link", "sports", "image"],
    {},
    ["link", "sports"],
    [],
    [],
    [],
    ["title", "summary", "body"]
  );
  const [del, toggleDelete] = useBoolState(false);
  const [patchImage, togglePatchImage] = useBoolState(false);
  const { loading, call } = useServer(false);
  const [img, setImg] = useState<Image>(blog.image);

  const sportItems = useMemo(() => {
    return [{ key: "all", label: translate("all", l) }].concat(SPORTS.map(mapTranslationToItem(l)));
  }, [l]);
  const onChangeImage = useCallback((file: Image) => {
    togglePatchImage();
    setImg(file);
  }, [togglePatchImage]);
  const onSave = useCallback(() => {
    if (!form.nothingChanged) {
      call("patchNews", [blog.id, {
        title: {
          textDE: form.titleDe,
          textIT: form.titleIt,
          textEN: form.titleEn
        },
        summary: {
          textDE: form.summaryDe,
          textIT: form.summaryIt,
          textEN: form.summaryEn
        },
        body: {
          textDE: form.bodyDe,
          textIT: form.bodyIt,
          textEN: form.bodyEn
        },
        imageId: img.id,
        link: form.link === blog.link ? null : form.link,
        sports: form.sports === ("all" as SportEnum) ? null : form.sports
      }]).then(b => {
        changes(b);
        form.setInitial(b);
      });
    }
  }, [blog.id, blog.link, call, form, img.id, changes]);
  const navigator = useNavigate();
  const onDelete = useCallback(() => {
    call("deleteNews", [blog.id], "deleteFailed").then(() => {
      navigator("/news");
    });
  }, [blog.id, call, navigator]);
  return (
    <RoundedBlock>
      <ItemBlockHeader image={blog.image}

                       name={showTranslated(blog.title, l)}
                       onDelete={toggleDelete}
                       id={blog.id}
                       onPatchImage={togglePatchImage}>
        <ClavaButton onClick={onSave}
                     disabled={form.nothingChanged}>{translate("save", l)}</ClavaButton>
      </ItemBlockHeader>
      <LoadingBlock isLoading={loading}>
        <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeTitleDe}
                          changed={form.titleChangedDe}
                          value={form.titleDe}
                          label={translate("title", l) + " DE"} />
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeTitleIt}
                          changed={form.titleChangedIt}
                          value={form.titleIt}
                          label={translate("title", l) + " IT"} />
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeTitleEn}
                          changed={form.titleChangedEn}
                          value={form.titleEn}
                          label={translate("title", l) + " EN"} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeSummaryDe}
                         changed={form.summaryChangedDe}
                         value={form.summaryDe}
                         rows={5}
                         label={translate("summary", l) + " DE"} />
          <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeSummaryIt}
                         changed={form.summaryChangedIt}
                         value={form.summaryIt}
                         rows={5}
                         label={translate("summary", l) + " IT"} />
          <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeSummaryEn}
                         changed={form.summaryChangedEn}
                         rows={5}
                         value={form.summaryEn}
                         label={translate("summary", l) + " EN"} />
        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeBodyDe}
                         changed={form.bodyChangedDe}
                         value={form.bodyDe}
                         rows={20}
                         label={translate("body", l) + " DE"} />
          <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeBodyIt}
                         changed={form.bodyChangedIt}
                         value={form.bodyIt}
                         rows={20}
                         label={translate("body", l) + " IT"} />
          <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeBodyEn}
                         changed={form.bodyChangedEn}
                         value={form.bodyEn}
                         rows={20}
                         label={translate("body", l) + " EN"} />

        </div>
        <div className="flex flex-row items-center mx-[-8px] my-4">
          <ClavaSelect className="flex-1 mx-2" value={form.sports ?? undefined}
                       changed={form.sportsChanged}
                       items={sportItems}
                       onChange={form.onChangeSports}
                       label={translate("sports", l)} uniqueId={"league-sports"} />

          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeLink}
                          changed={form.linkChanged}
                          value={form.link ?? ""}
                          label={translate("url", l)} />
          <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeLink}
                          changed={form.linkChanged}
                          value={form.link ?? ""}
                          label={translate("url", l)} />
        </div>
      </LoadingBlock>
      {patchImage && (
        <FileUploader as={as} type={"image"} callback={onChangeImage} close={togglePatchImage} />
      )}

      <MessageBox type={"danger"} open={del} toggle={toggleDelete}
                  msg={translate("sureDelete", l, { "[name]": showTranslated(blog.title, l) })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDelete}
                  btn2Text={translate("no", l)} />
    </RoundedBlock>
  );
};

export default BlogEditForm;
