import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AreaOfInterest, League, LeagueListElement } from "../../client/api";
import { ClavaContext, SiteBlockerContext } from "../../config/contexts";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { translate } from "../../config/translator";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { useNavigate } from "react-router";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LeagueIcon from "../../components/Icons/LeagueIcon";
import { DndContext, DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import DraggableLeagueItem from "./DraggableLeagueItem";
import { faChevronLeft, faSave } from "@fortawesome/pro-regular-svg-icons";
import MessageBox from "../../components/Atoms/MessageBox";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import useServer from "../../hooks/useServer";
import AoiSelect from "../../components/Atoms/Common/AoiSelect";

const LeagueReorder = () => {
  const { l, aoi, sports } = useContext(ClavaContext);
  const { block } = useContext(SiteBlockerContext);
  const [leagues, setLeagues] = useState<League[] | LeagueListElement[]>([]);
  const [changed, setChanged] = useState<UniqueIdentifier[]>([]);
  const { status, loading, closeStatus, call } = useServer(true);
  const navigator = useNavigate();
  const [selectedAoi, setSelectedAoi] = useState<AreaOfInterest>();

  useEffect(() => {
    call("getLeaguesOfAoi", [selectedAoi?.id ?? aoi, sports]).then(setLeagues);
  }, [aoi, selectedAoi, sports, call]);
  const breadcrump = useMemo(() => {
    return [
      translate("reorderLeague", l)
    ];
  }, [l]);
  const navBack = useCallback(() => {
    navigator("/league");
  }, [navigator]);
  const handleDragEnd = useCallback((e: DragEndEvent) => {
    if (e.over) {
      const overId = e.over.id;
      const id = e.active.id;
      const leaIdx = leagues.findIndex(le => le.id === id);
      const overIdx = leagues.findIndex(le => le.id === overId);
      if (leaIdx !== -1 && overIdx !== -1) {
        setChanged(ch => ch.concat([id]));
        if (leaIdx > overIdx) {
          setLeagues(leagues.slice(0, overIdx).concat([leagues[leaIdx]]).concat(leagues.slice(overIdx, leaIdx)).concat(leagues.slice(leaIdx + 1)));
        } else {
          setLeagues(leagues.slice(0, leaIdx).concat(leagues.slice(leaIdx + 1, overIdx + 1)).concat([leagues[leaIdx]]).concat(leagues.slice(overIdx + 1)));
        }
      }
    }
  }, [leagues]);
  const onSave = useCallback(() => {
    if (selectedAoi) {
      call("reorderLeagues", [selectedAoi.id, leagues.map(le => le.id)], "saveFailed", "saveSuccess").then((res) => {
        setLeagues(res);
        setChanged([]);
      });
      setChanged([]);
    }
  }, [selectedAoi, leagues, call]);
  const shouldBlock = useMemo(() => {
    return changed.length !== 0;
  }, [changed.length]);
  useEffect(() => {
    block(shouldBlock);
  }, [block, shouldBlock]);
  return (
    <>
      <Breadcrumps additional={breadcrump}>
        <ClavaButton onClick={navBack} lightSecondary className="mr-2">
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          {translate("leagueManagement", l)}
        </ClavaButton>
        <AoiSelect selected={selectedAoi} setSelected={setSelectedAoi} autoSelect
                   className="mr-2 mt-[-8px]" />
        <ClavaButton onClick={onSave} light>
          <FontAwesomeIcon icon={faSave} className="mr-2" />
          {translate("save", l)}
        </ClavaButton>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"reorderLeague"} icon={<LeagueIcon />} />
        <LoadingBlock isLoading={loading}>
          <DndContext onDragEnd={handleDragEnd}>
            <ul>
              {leagues.map((league, idx) => (
                <DraggableLeagueItem league={league} key={"drag-league-" + league.id} idx={idx}
                                     changed={changed.includes(league.id)} />
              ))}
            </ul>
          </DndContext>
        </LoadingBlock>
      </RoundedBlock>
      <MessageBox type={status?.success ? "success" : "danger"} open={!!status && !!status.key}
                  toggle={closeStatus} msg={status && status.key ? translate(status.key, l) : ""}
                  btn1Clb={closeStatus} btn1Text={translate("close", l)} />
    </>
  );
};

export default LeagueReorder;
