/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ScopeEnum {
    REGISTERED = 'REGISTERED',
    MANAGER = 'MANAGER',
    ADMIN = 'ADMIN',
    BLOGGER = 'BLOGGER',
    MODERATOR = 'MODERATOR',
}
