/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { io, Socket } from "socket.io-client";
import { DisconnectDescription } from "socket.io-client/build/esm/socket";
import {
  BETA_ENDPOINT,
  BETA_SOCKET_ENDPOINT,
  DEV_ENDPOINT,
  DEV_SOCKET_ENDPOINT,
  PROD_SOCKET_ENDPOINT,
  STAG_ENDPOINT,
  STAG_SOCKET_ENDPOINT
} from "../../config/constants";
import { StaticClient } from "../index";
import { IDType } from "../../config/types";
import { addLog } from "../../config/logger";
import { Task } from "../api";

type LoginViaSmsMessage = {
  auth_token: string;
  refresh_token: string;
  type: "login";
};
type TaskMessage = {
  type: "task_update",
  task_id: string,
  progress: number,
  total: number,
  finished: boolean,
};

type ActiveUserMessage = { type: "users_per_aoi", values: Record<string, number> };
type SocketMessage =
  | TaskMessage
  | ActiveUserMessage
  | LoginViaSmsMessage
  | { type: "ping" };


class EventsSocket {
  private static websocket: Socket | undefined;

  private static userID: IDType = -1;

  private static aoiID: IDType = -1;

  private static endpoint = `wss://${PROD_SOCKET_ENDPOINT}/`;
  private static loginWithSmsHandler: (() => void) | undefined;
  private static taskHandlers: Record<string, (() => void)> = {};
  private static activeUserHandlers: ((val: Record<string, number>) => void) | undefined = undefined;

  static init(endpoint: string | undefined | null) {
    if (!endpoint) endpoint = PROD_SOCKET_ENDPOINT;
    if (endpoint === BETA_ENDPOINT) endpoint = BETA_SOCKET_ENDPOINT;
    else if (endpoint === DEV_ENDPOINT) endpoint = DEV_SOCKET_ENDPOINT;
    else if (endpoint === STAG_ENDPOINT) endpoint = STAG_SOCKET_ENDPOINT;
    else endpoint = PROD_SOCKET_ENDPOINT;
    EventsSocket.endpoint = `wss://${endpoint}/`;
    EventsSocket.open();
  }

  public static setTaskHandler(task: Task, handler: (() => void)) {
    const sock = EventsSocket.websocket;
    if (sock) {
      sock.emit("room", task.room);
    }
    EventsSocket.taskHandlers[task.id] = handler;
  }

  public static removeTaskHandler(id: string) {
    delete EventsSocket.taskHandlers[id];
  }

  public static setActiveUserHandler(handler: ((values: Record<string, number>) => void)) {
    const sock = EventsSocket.websocket;
    if (sock) {
      sock.emit("room", "users_per_aoi");
    }
    EventsSocket.activeUserHandlers = handler;
  }

  public static removeActiveUserHandler(id: string) {
    delete EventsSocket.taskHandlers[id];
  }

  public static setLoginWithSmsHandler(handler: (() => void) | undefined) {
    EventsSocket.loginWithSmsHandler = handler;
  }

  public static isOpen(websocket: Socket | undefined): websocket is Socket {
    return !!websocket && websocket.active;
  }

  static setUser(id?: IDType, aoi?: IDType) {
    if (id && aoi) {
      EventsSocket.userID = id;
      EventsSocket.aoiID = aoi;
      const sock = EventsSocket.websocket;
      if (sock) {
        sock.emit("user_id", id);
        addLog("socket", { User: id });
      }
      setTimeout(() => {
        if (sock) {
          sock.emit("room", `aoi_${aoi}`);
          addLog("socket", { Room: `aoi_${aoi}` });
        }
      }, 1000);
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        console.log(`Identify as ${id} in room aoi_${aoi}`);
      else
        Sentry.addBreadcrumb({
          type: "info",
          category: "socket",
          data: { user_id: id, room: `aoi_${aoi}` }
        });
    } else if (EventsSocket.userID && EventsSocket.aoiID) {
      const sock = EventsSocket.websocket;
      if (sock) {
        sock.emit("user_id", EventsSocket.userID);
        addLog("socket", { User: id });
      }
      setTimeout(() => {
        if (sock) {
          sock.emit("room", `aoi_${EventsSocket.aoiID}`);
          addLog("socket", { Room: `aoi_${aoi}` });
        }
      }, 1000);
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        console.log(
          `Identify as ${EventsSocket.userID} in room aoi_${EventsSocket.aoiID}`
        );
      else
        Sentry.addBreadcrumb({
          type: "info",
          category: "socket",
          data: {
            user_id: EventsSocket.userID,
            room: `aoi_${EventsSocket.aoiID}`
          }
        });
    }
  }

  static open() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("\x1b[35m%s%O\x1b[0m", `Open WS to ${EventsSocket.endpoint}`);
    addLog("socket", { Open: EventsSocket.endpoint });
    EventsSocket.close();
    EventsSocket.websocket = io(EventsSocket.endpoint);
    EventsSocket.websocket.on("message", EventsSocket.onMessage);
    EventsSocket.websocket.on("connect", EventsSocket.onOpen);
    EventsSocket.websocket.on("connect_error", EventsSocket.onError);
    EventsSocket.websocket.on("disconnect", EventsSocket.onClose);
  }

  static close() {
    if (EventsSocket.isOpen(EventsSocket.websocket))
      EventsSocket.websocket.close();
  }

  static setEndpoint(endpoint: string) {
    const newEndpoint =
      endpoint === DEV_ENDPOINT
        ? DEV_SOCKET_ENDPOINT
        : endpoint === BETA_ENDPOINT
          ? BETA_SOCKET_ENDPOINT
          : endpoint === STAG_ENDPOINT
            ? STAG_SOCKET_ENDPOINT
            : PROD_SOCKET_ENDPOINT;
    if (`wss://${newEndpoint}/` !== EventsSocket.endpoint) {
      EventsSocket.endpoint = `wss://${newEndpoint}/`;
      EventsSocket.close();
      EventsSocket.open();
    }
  }


  private static handleLoginMessage(
    // eslint-disable-next-line camelcase
    access_token: string,
    // eslint-disable-next-line camelcase
    refresh_token: string
  ) {
    StaticClient.setToken({
      // eslint-disable-next-line camelcase
      refresh_token,
      // eslint-disable-next-line camelcase
      access_token
    });
    setTimeout(() => {
      if (EventsSocket.loginWithSmsHandler) {
        EventsSocket.loginWithSmsHandler();
      }
    }, 100);
  }


  private static handleTaskMessage(
    id: string
  ) {
    if (id in EventsSocket.taskHandlers) {
      EventsSocket.taskHandlers[id]();
    }
  }

  /**
   *
   * @private
   * @param reason
   * @param description
   */
  private static onClose(
    reason: Socket.DisconnectReason,
    description?: DisconnectDescription
  ) {
    console.log("\x1b[35m%s%O\x1b[0m", "WS closed, reason: ", reason);
    addLog("socket", { Closed: { reason, description } });
  }

  private static onMessage(payload: string) {
    /*  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        console.log("\x1b[35m%s%O\x1b[0m", `WS Message: `, payload);

     */
    addLog("socket", { Message: payload });
    try {
      const message: SocketMessage = JSON.parse(payload as string);
      if (message.type === "login") {
        EventsSocket.handleLoginMessage(
          message.auth_token,
          message.refresh_token
        );
      } else if (message.type === "task_update") {
        EventsSocket.handleTaskMessage(
          message.task_id
        );
      } else if (message.type === "users_per_aoi") {
        if (EventsSocket.activeUserHandlers) {
          EventsSocket.activeUserHandlers(message.values);
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  private static onOpen() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("\x1b[35m%s%O\x1b[0m", `WS open to ${EventsSocket.endpoint}`);
    addLog("socket", { Connected: EventsSocket.endpoint });
    setTimeout(EventsSocket.setUser, 1000);
  }

  private static onError(error: Error) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("\x1b[35m%s%O\x1b[0m", "WS Error:", error);
    addLog("socket", { Error: error });
  }
}

export default EventsSocket;
