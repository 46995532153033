import React, { useContext, useEffect, useMemo, useState } from "react";
import { League } from "../../client/api";
import { showTranslated } from "../../config/translator";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import LeagueEditForm from "./LeagueEditForm";
import LeagueEditTeams from "./LeagueEditTeams";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import useEditParams from "../../hooks/useEditParams";

const LeagueEdit: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const { id, navNotFound } = useEditParams("leagueId", "/league");
  const [league, setLeague] = useState<League>();
  const { loading, call, status, StatusComponent } = useServer(true);
  useEffect(() => {
    if (id) {
      call("getLeague", [id], "notFound").then(setLeague, navNotFound);
    } else {
      navNotFound();
    }
  }, [call, navNotFound, id]);
  const breadcrump = useMemo(() => {
    if (!league) {
      return [];
    }
    return [
      showTranslated(league.name, l)
    ];
  }, [league, l]);

  return (
    <LoadingBlock isLoading={loading}>
      {!!status && !!status.key && StatusComponent}
      <Breadcrumps additional={breadcrump}></Breadcrumps>
      {!!league &&
        <LeagueEditForm league={league} />}
      {!!league &&
        <LeagueEditTeams league={league} changes={setLeague} />}

    </LoadingBlock>
  );
};
export default LeagueEdit;