import * as Sentry from "@sentry/react";
import {
  Analytics,
  getAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
  setUserProperties
} from "firebase/analytics";
import { getMessaging, getToken, MessagePayload, Messaging, onMessage } from "@firebase/messaging";
import { initializeApp } from "firebase/app";
import { Notification } from "./types";
import { SportEnum, User } from "../client/api";
import { addLog } from "./logger";
import { registration } from "../registerServiceWorker";
import { errorLogging, generatePW, isInsider, isLocalDev } from "./utils";
import { getGoogleAnalyticsClientId } from "@firebase/analytics";

export type AD_SHOWN_EVENT = "ad_view";
export type AD_CLICK_EVENT = "ad_clicked";
export type COMMUNICATO_OPENED = "communicato_opened";
export type COMMUNICATO_LINK_CLICKED = "communicato_link_clicked";
export type MATCH_FAVORIZED = "match_favorite";
export type COMPLETE_SETUP = "complete_setup";
export type MOTM_VOTE = "motm_vote";
export type MATCH_BET = "match_bet";
export type SHARE_SCREEN_ANY = "share_screen_any";
export type SHARE_ANY = "share_any";
export type FEED_VIDEO_STARTED = "feed_video_started";
export type FEED_VIDEO_ENDED = "feed_video_ended";
export type FEED_VIDEO_FULLSCREEN = "feed_video_fullscreen";
export type IMAGE_FULLSCREEN = "image_fullscreen";
export type REGISTER = "register_success";
export type VERIFY = "verify_success";
export type SKIP_VERIFY = "skip_verify";
export type START_FEED_POST = "start_feed_post";
export type IMAGE_SERVER = "image_served";
export type RATE_VIEWED = "rate_viewed";
export type REGION_MISSING = "region_missing";
export declare type ClavaFbEvent =
  | AD_SHOWN_EVENT
  | MATCH_FAVORIZED
  | REGION_MISSING
  | AD_CLICK_EVENT
  | MOTM_VOTE
  | RATE_VIEWED
  | START_FEED_POST
  | REGISTER
  | VERIFY
  | SKIP_VERIFY
  | FEED_VIDEO_STARTED
  | FEED_VIDEO_FULLSCREEN
  | FEED_VIDEO_ENDED
  | SHARE_ANY
  | SHARE_SCREEN_ANY
  | IMAGE_SERVER
  | IMAGE_FULLSCREEN
  | MATCH_BET
  | COMPLETE_SETUP
  | COMMUNICATO_LINK_CLICKED
  | COMMUNICATO_OPENED;
export declare type NotificationSettings =
  | { all: true }
  | {
  match: boolean;
  team: boolean;
  league: boolean;
  insider: boolean;
  teamInsider: boolean;
};

const firebaseConfig = {
  apiKey: "AIzaSyBmNEipJ6XHDuZpxhXYfzXCRSj5NuMbTWo",
  authDomain: "clava-c843b.firebaseapp.com",
  projectId: "clava-c843b",
  storageBucket: "clava-c843b.appspot.com",
  messagingSenderId: "1074581731462",
  appId: "1:1074581731462:web:27433c5022f09ac999f907",
  measurementId: "G-PVW7T1VXED"
};

class FirebaseFactory {
  private static SETTINGS_ASYNC_STORAGE = "NOTIFICATIONS_FIREBASE";

  private static instance: FirebaseFactory | null = null;

  private subscribedTopics: string[];

  private messaging: Messaging;

  private messagingToken: string | null = null;

  private initialized = false;

  private userAlreadyTagged = -1;

  private analytics: Analytics;
  private deviceId: string | null = null;

  private notificationHandler: null | ((notification: Notification) => void) =
    null;

  private constructor() {
    this.subscribedTopics = [];
    try {
      if (isLocalDev()) {
        this.analytics = {} as Analytics;
        this.messaging = {} as Messaging;
        return;
      }

      const app = initializeApp(firebaseConfig);
      this.analytics = getAnalytics(app);
      this.messaging = getMessaging(app);
      this.initialized = true;
    } catch (e) {
      errorLogging("Failed to initialize Firebase");
      this.analytics = {} as Analytics;
      this.messaging = {} as Messaging;
      this.initialized = false;
    }
  }

  public static async initialize() {
    if (FirebaseFactory.instance === null) {
      FirebaseFactory.instance = new FirebaseFactory();
      if (isLocalDev()) return;
      try {
        setAnalyticsCollectionEnabled(
          FirebaseFactory.instance.analytics,
          true
        );
        const reg = registration();
        if (reg) {
          FirebaseFactory.instance.messagingToken = await getToken(
            FirebaseFactory.instance.messaging,
            {
              vapidKey:
                "BJkgC9jde0Xcy7XUhMnwRpJMptozZqzpWgRoxehz-mvr5YlvHajETJHFQfOQU1rUW8R_udFg2DD_BIZ58gvP7uA",
              serviceWorkerRegistration: reg
            }
          );
        }
        FirebaseFactory.instance.deviceId = await getGoogleAnalyticsClientId(FirebaseFactory.instance.analytics);
        onMessage(
          FirebaseFactory.instance.messaging,
          FirebaseFactory.onMessage
        );
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }

  /**
   * @throws Error if not initialized
   */
  public static getInstance(): FirebaseFactory {
    if (FirebaseFactory.instance === null) {
      if (isLocalDev()) FirebaseFactory.instance = new FirebaseFactory();
      else throw new Error("Firebase not initialized");
    }
    return FirebaseFactory.instance;
  }

  private static async onMessage(message: MessagePayload) {
    if (isLocalDev()) return;
    if (
      FirebaseFactory.instance &&
      message.notification &&
      message.notification.body &&
      message.notification.title &&
      FirebaseFactory.instance.notificationHandler
    ) {
      FirebaseFactory.instance.notificationHandler({
        text: message.notification.body,
        title: message.notification.title,
        data: message.data
      });
    }
  }

  private static checkSetting(
    settings: NotificationSettings,
    topic: string
  ): boolean {
    if ("all" in settings) return settings.all;
    if (topic.startsWith("match")) return settings.match;
    if (topic.startsWith("team")) return settings.team;
    if (topic.startsWith("league")) return settings.league;
    if (topic.startsWith("insider")) return settings.insider;
    if (topic.startsWith("team_insider")) return settings.teamInsider;
    return false;
  }

  private static initialSettings(): NotificationSettings {
    return {
      all: true
    };
  }

  getDeviceId(): string {
    return this.deviceId ?? generatePW(12);
  }

  getFirebaseToken(): string | null {
    if (isLocalDev()) return "dummy_fb_token";
    return this.messagingToken;
  }

  subscribe(topic: string, settings?: NotificationSettings) {
    if (isLocalDev() || !this.initialized) return;
    if (settings) {
      if (
        this.subscribedTopics.indexOf(topic) === -1 &&
        FirebaseFactory.checkSetting(settings, topic)
      ) {
        //   this.messaging.subscribeToTopic(topic).then(() => {
        this.subscribedTopics.push(topic);
        addLog("firebase", { Subscribe: topic });
        //   });
      }
    } else {
      this.getNotificationSettings().then((s) => {
        if (
          this.subscribedTopics.indexOf(topic) === -1 &&
          FirebaseFactory.checkSetting(s, topic)
        ) {
          //  this.messaging.subscribeToTopic(topic).then(() => {
          this.subscribedTopics.push(topic);
          addLog("firebase", { Subscribe: topic });
          //   });
        }
      });
    }
  }

  public getNotificationSettings(): Promise<NotificationSettings> {
    return new Promise<NotificationSettings>((resolve) => {
      const result = window.localStorage.getItem(
        FirebaseFactory.SETTINGS_ASYNC_STORAGE
      );

      if (result)
        try {
          resolve(JSON.parse(result));
        } catch (e) {
          Sentry.captureException(e);
          resolve(FirebaseFactory.initialSettings());
        }
      else resolve(FirebaseFactory.initialSettings());
    });
  }

  public logEvent(eventName: ClavaFbEvent, params: Record<string, string | number | object>) {
    if (isLocalDev() || !this.initialized) return;
    logEvent(this.analytics, eventName, params);
  }

  tagUser(user: User, sports: SportEnum) {
    if (isLocalDev() || !this.initialized) return;
    if (this.userAlreadyTagged !== user.id) {
      this.userAlreadyTagged = user.id;
      setUserProperties(this.analytics, {
        web: "true",
        amountFavoriteTeams: user.favoriteTeams.length.toString(10),
        amountFavoriteLeagues: user.favoriteLeagues.length.toString(10),
        amountFavoriteMatches: user.favoriteMatches.length.toString(10),
        hasProfilePic: user.photo ? "true" : "false",
        emailSet: user.tel ? "true" : "false",
        telSet: user.emailConfirmed ? "true" : "false",
        emailConfirmed: user.emailConfirmed ? "true" : "false",
        telConfirmed: user.emailConfirmed ? "true" : "false",
        areaOfInterest: user.areaOfInterest.name.textEN,
        language: user.language.locale,
        anonymous: user.anonymous ? "true" : "false",
        insider: isInsider(user) ? "true" : "false",
        player: user.playerId ? "true" : "false",
        sports
      });
    }
  }
}

export const initFb = FirebaseFactory.initialize;
export const fb = FirebaseFactory.getInstance;
