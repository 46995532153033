import React, { useContext } from "react";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import { Image } from "../../client/api";
import { IDType } from "../../config/types";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import ClavaImage from "../Atoms/ClavaImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClavaButton from "../Atoms/ClavaButton";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";

const ItemBlockHeader: React.FC<React.PropsWithChildren<{
  image: Image | IconDefinition
  name: string
  id?: IDType
  other?: string;
  imageHeader?: false | number;
  imageRounded?: boolean;
  onPatchImage?: () => void;
  onDelete?: () => void;
}>> = ({ image, imageHeader, imageRounded, onPatchImage, onDelete, name, id, other, children }) => {
  const { l } = useContext(ClavaContext);

  return (
    <div
      className={"flex flex-row items-center justify-between relative border-b border-b-primary bg-primary-20 rounded-t-lg p-4 mb-4"}>
      <div
        className={"flex " + (imageHeader && imageHeader > 5 ? "flex-col items-start justify-between" : "flex-row items-center justify-start")}>
        <div
          className={"flex items-center justify-center mr-4 " + (imageHeader ? imageHeader > 5 ? `w-96 aspect-[${Math.round(imageHeader * 10) / 10}]` : `h-20 aspect-[${Math.round(imageHeader * 10) / 10}]` : `w-24 h-24 ${imageRounded ? "rounded-full overflow-hidden bg-white dark:bg-bg-dark" : ""}`)}>
          {typeof image === "object" && "url" in image ? (
            <ClavaImage width={"100%"} image={image} />
          ) : (
            <FontAwesomeIcon icon={image} size="3x" />
          )}
        </div>
        <div
          className={"flex flex-col items-start justify-evenly relative"}>
          <span className="font-semibold text-lg">{name}</span>
          {!!id && (
            <div>
              <span>{translate("id", l)}: </span>
              <span className="font-semibold">{id}</span>
              {!!other && (
                <span> | {other}</span>
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center justify-evenly mb-2 relative">
          {!!onPatchImage ? (
            <ClavaButton onClick={onPatchImage} className="mx-2" secondary>
              <FontAwesomeIcon icon={faPencil} className="mr-2"></FontAwesomeIcon>
              {translate("changeImage", l)}
            </ClavaButton>
          ) : (
            <div className="flex" />
          )}
          {!!onDelete && (
            <ClavaButton onClick={onDelete} className="mx-2" danger>
              <FontAwesomeIcon icon={faTrash} className="mr-2"></FontAwesomeIcon>
              {translate("delete", l)}
            </ClavaButton>
          )}
        </div>
        <div className="p-2">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ItemBlockHeader;