import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { ImportCell } from "../../config/import";

export declare type TableCellEditableItem = ImportCell & {
  autocomplete?: (val: string, row: number) => Promise<string[]>;
  readonly?: boolean;
};

const TableCellEditable: React.FC<TableCellEditableItem & {
  onChange: (col: number, val: string) => void;
  row: number;
}> = ({
        value,
        onChange, col, row, readonly, autocomplete
      }) => {
  const initial = useRef(value);
  const [autocompleteValues, setAutocompleteValues] = useState<string[]>([]);
  const [valueTemp, setValueTemp] = useState(value);
  const timeo = useRef<NodeJS.Timeout>();
  const onChangeCont = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValueTemp(e.target.value);
    if (!!autocomplete && e.target.value !== initial.current && e.target.value !== "") {
      clearTimeout(timeo.current);
      timeo.current = setTimeout(() => {
        autocomplete(e.target.value, row).then(setAutocompleteValues);
      }, 300);
    }
  }, [row, autocomplete]);
  const changed = useMemo(() => {
    return value !== initial.current;
  }, [value]);
  const onSubmit = useCallback(() => {
    onChange(col, valueTemp);
  }, [valueTemp, col, onChange]);
  return (
    <td
      className={"p-0 relative"}>
      <input type="text" onChange={onChangeCont} alt={value} value={valueTemp} onBlur={onSubmit}
             readOnly={readonly} disabled={readonly}
             className={`text-center bg-transparent outline-0 ${readonly ? "" : "hover:bg-primary-20 active:bg-primary-40"} ${changed ? "bg-primary-10" : ""}`} />
      {!!autocomplete && autocompleteValues.length !== 0 && (
        <div
          className="absolute max-h-64 flex flex-col items-stretch justify-start bg-bg-secondary dark:bg-bg-secondary-dark rounded-xl shadow-shadow dark:shadow-shadow-dark drop-shadow-lg overflow-scroll left-0 right-0 z-50">
          {autocompleteValues.map((it, index) => (
            <button key={it} onClick={() => {
              setValueTemp(it);
              onChange(col, it);
              setAutocompleteValues([]);
            }}
                    className={"text-left min-w-fit py-1 pl-2 pr-10 border-none hover:bg-primary-20 text-sm text-font dark:text-font-dark" + (index === 0 ? "rounded-t-xl" : index === autocompleteValues.length - 1 ? "rounded-b-xl" : "")}>
              {it}
            </button>
          ))}
        </div>
      )}
    </td>
  );
};

export default TableCellEditable;