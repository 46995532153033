import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { showTranslated, translate } from "../../config/translator";
import LiveTablesIcon from "../../components/Icons/LiveTablesIcon";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import Filter, { FilterProp } from "../../components/Views/Filter";
import { IDType } from "../../config/types";
import useServer from "../../hooks/useServer";
import { ClavaContext } from "../../config/contexts";
import { League, MatchLocationEnum, Punishment, SportEnum, StandingList } from "../../client/api";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import SingleTable from "./SingleTable";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import PunishmentCreateEditModal from "./PunishmentCreateEditModal";

const Tables = () => {
  const { l, filterAoi, sports } = useContext(ClavaContext);
  const [leagueId, setLeagueId] = useState<IDType>();
  const [aoiId, setAoiId] = useState<string | undefined>(filterAoi);
  const [punishment, setPunishment] = useState<{ teamId: IDType, leagueId: IDType }>();
  const [selectedSport, setSelectedSport] = useState<SportEnum | undefined>(sports);
  const { call, loading, status, StatusComponent } = useServer(true);
  const [type, setType] = useState<MatchLocationEnum>(MatchLocationEnum.ALL);
  const [leagues, setLeagues] = useState<League[]>([]);
  const [standings, setStandings] = useState<Record<string, StandingList>>();
  const [punishments, setPunishments] = useState<Punishment[]>([]);
  const togglePunishment = useCallback(() => {
    setPunishment(undefined);
  }, []);
  const onAddPunishment = useCallback((teamId: IDType, lId: IDType) => {
    setPunishment({ teamId, leagueId: lId });
  }, []);
  useEffect(() => {
    if (aoiId) {
      call("getLeagues", [aoiId]).then((res) => {
        setLeagues(res);
        if (res.length)
          setLeagueId(res[0].id);
      });
    }
  }, [call, aoiId]);
  const onRefresh = useCallback(() => {
    if (leagueId) {
      call("getStandings", [leagueId]).then(setStandings);
      call("getPunishments", [leagueId]).then(setPunishments);
    }
  }, [leagueId, call]);
  useEffect(() => {
    onRefresh();
  }, [onRefresh]);
  const [q, setQ] = useState("");
  const filters = useMemo<FilterProp<"areaOfInterestFilter" | "sportsFilter">[]>(() => {
    return [
      {
        type: "areaOfInterestFilter",
        val: aoiId,
        setVal: setAoiId
      },
      {
        type: "sportsFilter",
        val: selectedSport,
        setVal: setSelectedSport
      }
    ];
  }, [selectedSport, aoiId]);
  const onSetHome = useCallback(() => {
    setType(MatchLocationEnum.HOME);
  }, []);
  const onSetAll = useCallback(() => {
    setType(MatchLocationEnum.ALL);
  }, []);
  const onSetAway = useCallback(() => {
    setType(MatchLocationEnum.AWAY);
  }, []);
  const filteredLeagues = useMemo(() => {
    return leagues.filter(lea => lea.sports === selectedSport).filter(lea => q.length === 0 || showTranslated(lea.name, l).toLowerCase().indexOf(q.toLowerCase()) !== -1);
  }, [l, q, leagues, selectedSport]);
  return (
    <React.Fragment>
      <Breadcrumps>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"liveTables"}
                         icon={<LiveTablesIcon />}>

          <Filter filter={filters} uniqueId={"tables-filters"}
                  className="mr-2" />
        </ListBlockHeader>
      </RoundedBlock>
      {!!status && !!status.key && StatusComponent}
      <div className="flex flex-row items-start justify-start">
        <RoundedBlock className="mr-2 mt-2 w-[30%]">
          <div className="flex flex-col items-stretch justify-start">
            <ClavaTextInput onChange={setQ} value={q} label={translate("search", l)}
                            placeholder={"..."} iconL={faSearch} />
            {filteredLeagues.map((lea) => (
              <ClavaButton size="full" tempDisableTimeout={1000} className="my-1" onClick={() => {
                setLeagueId(lea.id);
              }} secondary={leagueId !== lea.id} key={lea.id + "league"}>
                {showTranslated(lea.name, l)}
              </ClavaButton>
            ))}
          </div>
        </RoundedBlock>
        <RoundedBlock className="ml-2 mt-2 w-[70%]">
          <LoadingBlock isLoading={loading}>

            <div className="flex flex-row items-center justify-evenly my-2">
              <ClavaButton onClick={onSetHome} tempDisableTimeout={100}
                           secondary={type !== MatchLocationEnum.HOME}>
                {translate("homeTable", l)}
              </ClavaButton>
              <ClavaButton onClick={onSetAll} tempDisableTimeout={100}
                           secondary={type !== MatchLocationEnum.ALL}>
                {translate("allTable", l)}
              </ClavaButton>
              <ClavaButton onClick={onSetAway} tempDisableTimeout={100}
                           secondary={type !== MatchLocationEnum.AWAY}>
                {translate("awayTable", l)}
              </ClavaButton>
            </div>
            {!!standings && !!leagueId ? (
              <SingleTable punishments={punishments} standing={standings[type]}
                           onAddPunishment={onAddPunishment} />
            ) : (
              <span>{translate("chooseLeague", l)}</span>
            )}
          </LoadingBlock>
        </RoundedBlock>
      </div>
      {!!punishment && (
        <PunishmentCreateEditModal teamId={punishment.teamId} onRefresh={onRefresh}
                                   leagueId={punishment.leagueId} close={togglePunishment} />
      )}
    </React.Fragment>
  );
};

export default Tables;
