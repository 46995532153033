import React, { useCallback, useContext } from "react";
import { translate, TranslatorKeys } from "../../config/translator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { ClavaContext } from "../../config/contexts";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";

export declare type TableHeaderItem = {
  label: TranslatorKeys;
  key?: string;
  sortable?: boolean;
}

const TableHeaderItemView: React.FC<{
  item: TableHeaderItem, first?: boolean, sorted?: string; onSort?: (key: string) => void,
  moveable?: boolean; idx: number,
  onMoveLeft?: (idx: number) => void;
  onMoveRight?: (idx: number) => void;
}> = ({ item, first, sorted, onSort, moveable, onMoveLeft, onMoveRight, idx }) => {
  const { l } = useContext(ClavaContext);
  const onSortPress = useCallback(() => {
    if (item.sortable && item.key && onSort) {
      onSort(item.key);
    }
  }, [item.key, item.sortable, onSort]);
  const onMoveLeftClick = useCallback(() => {
    if (onMoveLeft)
      onMoveLeft(idx);
  }, [onMoveLeft, idx]);
  const onMoveRightClick = useCallback(() => {
    if (onMoveRight)
      onMoveRight(idx);
  }, [onMoveRight, idx]);
  return (
    <th onClick={onSortPress}
        className={`p-2 bg-input-bg relative dark:bg-input-bg-dark${first ? " rounded-r-xl" : ""}${item.sortable ? " cursor-pointer pr-6" : ""}`}>
      {item.sortable && (
        <div
          className={`absolute top-[-8px] right-2 ${!!sorted && item.key && sorted.indexOf(item.key) !== -1 && sorted.indexOf("desc") !== -1 ? "" : "opacity-50"}`}>
          <FontAwesomeIcon icon={faCaretUp} className="w-3 h-3" />
        </div>
      )}
      {moveable ? (
        <div className="flex flex-row items-center justify-between">
          <button onClick={onMoveLeftClick}
                  className={"mx-1 w-10 h-10 flex flex-row items-center justify-center rounded-lg bg-transparent border border-light-gray dark:border-light-gray-dark hover:bg-primary hover:text-white"}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <span>{translate(item.label, l)}</span>
          <button onClick={onMoveRightClick}
                  className={"mx-1 w-10 h-10 flex flex-row items-center justify-center rounded-lg bg-transparent border border-light-gray dark:border-light-gray-dark hover:bg-primary hover:text-white"}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      ) : (<span>{translate(item.label, l)}</span>)}
      {item.sortable && (
        <div
          className={`absolute bottom-0 right-2 ${!!sorted && item.key && sorted.indexOf(item.key) !== -1 && sorted.indexOf("asc") !== -1 ? "" : "opacity-50"}`}>
          <FontAwesomeIcon icon={faCaretDown} className="w-3 h-3" />
        </div>
      )}
    </th>
  );
};

export default TableHeaderItemView;
