/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AreaOfInterest } from '../models/AreaOfInterest';
import type { AreaOfInterestCreate } from '../models/AreaOfInterestCreate';
import type { AreaOfInterestList } from '../models/AreaOfInterestList';
import type { AreaOfInterestPatch } from '../models/AreaOfInterestPatch';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AreaOfInterestService {
    /**
     * Get Area Of Interests
     * @returns AreaOfInterestList Successful Response
     * @throws ApiError
     */
    public static getAreaOfInterestsAreaOfInterestGet(): CancelablePromise<AreaOfInterestList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/area_of_interest/',
        });
    }
    /**
     * Create Area Of Interest
     * @param requestBody
     * @returns AreaOfInterest Successful Response
     * @throws ApiError
     */
    public static createAreaOfInterestAreaOfInterestPost(
        requestBody: AreaOfInterestCreate,
    ): CancelablePromise<AreaOfInterest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/area_of_interest/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Area Of Interest
     * @param areaOfInterestId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteAreaOfInterestAreaOfInterestAreaOfInterestIdDelete(
        areaOfInterestId: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/area_of_interest/{area_of_interest_id}',
            path: {
                'area_of_interest_id': areaOfInterestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Area Of Interest
     * @param areaOfInterestId
     * @param requestBody
     * @returns AreaOfInterest Successful Response
     * @throws ApiError
     */
    public static patchAreaOfInterestAreaOfInterestAreaOfInterestIdPatch(
        areaOfInterestId: number,
        requestBody: AreaOfInterestPatch,
    ): CancelablePromise<AreaOfInterest> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/area_of_interest/{area_of_interest_id}',
            path: {
                'area_of_interest_id': areaOfInterestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
