import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const HockeyIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 16 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.22251 10.6324C5.60761 10.2857 5.0132 9.91795 4.43279 9.53092C3.21548 8.71932 2.05316 7.84862 1.11481 6.74429C0.666378 6.21652 0.294436 5.64794 0.104965 4.98834C-0.153494 4.08527 0.0694709 3.29057 0.728368 2.60142C1.41026 1.88834 2.28013 1.43892 3.20998 1.08191C4.78473 0.477671 6.43048 0.171799 8.12221 0.0517021C8.56314 0.0202705 9.00308 0.00478925 9.44401 -0.000371164C9.75796 -0.00412419 9.89594 0.0821955 9.89844 0.276884C9.90044 0.463597 9.77495 0.547102 9.475 0.574781C8.2252 0.689717 7.02488 0.975885 5.90156 1.51304C5.44713 1.73024 5.0257 1.99436 4.68825 2.35981C4.07235 3.02598 4.06285 3.78221 4.66675 4.45729C5.17467 5.02587 5.84357 5.38898 6.53496 5.71971C6.74743 5.82151 6.96489 5.91346 7.19386 6.0162C7.06238 6.12035 6.9474 6.2062 6.83841 6.29862C6.42048 6.65234 6.20951 7.083 6.21801 7.6225C6.23351 8.6194 6.22251 9.61677 6.22251 10.6324Z"
        fill="none"
      />
      <path
        d="M12.7488 13.4855C12.7543 13.6511 12.6553 13.7375 12.4769 13.76C12.0794 13.8102 11.68 13.8139 11.0811 13.8182C9.88727 13.806 8.53048 13.6263 7.25968 13.0511C6.80025 12.8433 6.38082 12.5792 6.00987 12.2484C5.8689 12.1227 5.8214 11.982 5.95988 11.8403C6.09986 11.6972 6.25234 11.7329 6.39481 11.8577C7.26818 12.6228 8.34751 12.955 9.49233 13.1417C10.4157 13.2918 11.345 13.3288 12.2789 13.2439C12.3369 13.2388 12.3954 13.236 12.4539 13.236C12.6308 13.2364 12.7433 13.3162 12.7488 13.4855Z"
        fill="none"
      />
      <path
        d="M9.03239 5.85237C9.38333 5.75432 9.74278 5.69193 10.1037 5.64361C10.4917 5.592 10.8831 5.58309 11.2355 5.59153C12.4074 5.58497 13.5122 5.72148 14.5555 6.16152C14.952 6.329 15.3214 6.53917 15.5989 6.86475C15.9338 7.25835 15.9353 7.6435 15.5909 8.03147C15.1584 8.51796 14.568 8.77832 13.9431 8.94815C12.0469 9.46278 10.1562 9.45059 8.28951 8.81116C7.93056 8.68825 7.59761 8.50951 7.30266 8.27589C6.62377 7.73733 6.62527 7.14435 7.30966 6.6086C7.81308 6.21454 8.41799 6.0236 9.03239 5.85237Z"
        fill="none"
      />
      <path
        d="M15.8483 8.75956C15.8518 9.2362 15.8363 9.7133 15.8548 10.1895C15.8703 10.5915 15.6983 10.8978 15.3889 11.1526C14.896 11.5584 14.3071 11.785 13.6842 11.9407C12.2179 12.3076 10.7421 12.301 9.26085 12.0205C9.11387 11.9928 9.03139 11.9426 9.04589 11.7789C9.06388 11.5771 9.05038 11.3731 9.04938 11.1699C9.04888 10.9973 8.9434 10.9021 8.77293 10.9011C8.59995 10.8997 8.49797 11.0015 8.49447 11.1699C8.49097 11.3707 8.49347 11.572 8.49347 11.8056C8.3155 11.7338 8.16652 11.6714 8.01505 11.6147C7.91106 11.5753 7.92806 11.4936 7.92756 11.4176C7.92656 11.0972 7.93006 10.7768 7.92606 10.4564C7.92306 10.219 7.81108 10.0811 7.63111 10.1008C7.41464 10.1243 7.35165 10.2641 7.35615 10.4522C7.36215 10.6999 7.35765 10.9485 7.35765 11.2309C7.0202 11.0109 6.81923 10.736 6.80374 10.3903C6.77774 9.79915 6.79674 9.20617 6.79674 8.58411C8.13703 9.57491 9.69028 9.82308 11.3065 9.82589C12.9278 9.82824 14.485 9.58335 15.8188 8.60053C15.8883 8.6343 15.8478 8.70514 15.8483 8.75956Z"
        fill="none"
      />
      <path
        d="M15.9543 5.35976C15.8573 5.51645 15.7058 5.53333 15.5458 5.44842C14.675 4.98586 13.7416 4.70814 12.7478 4.60493C12.5708 4.58663 12.4718 4.48436 12.4833 4.32204C12.4963 4.14049 12.6273 4.07059 12.8348 4.08185C13.8836 4.18881 14.8879 4.48858 15.8238 4.98774C15.9833 5.07265 16.0533 5.19931 15.9543 5.35976Z"
        fill="none"
      />
      <path fill={finalColor}
            d="M9.89844 0.276884C9.90044 0.463597 9.77495 0.547102 9.475 0.574781C8.2252 0.689717 7.02488 0.975885 5.90156 1.51304C5.44713 1.73024 5.0257 1.99436 4.68825 2.35981C4.07235 3.02598 4.06285 3.78221 4.66675 4.45729C5.17467 5.02587 5.84357 5.38898 6.53496 5.71971C6.74743 5.82151 6.96489 5.91346 7.19386 6.0162C7.06238 6.12035 6.9474 6.2062 6.83841 6.29862C6.42048 6.65234 6.20951 7.083 6.21801 7.6225C6.23351 8.6194 6.22251 9.61677 6.22251 10.6324C5.60761 10.2857 5.0132 9.91795 4.43279 9.53092C3.21548 8.71932 2.05316 7.84862 1.11481 6.74429C0.666378 6.21652 0.294436 5.64794 0.104965 4.98834C-0.153494 4.08527 0.0694709 3.29057 0.728368 2.60142C1.41026 1.88834 2.28013 1.43892 3.20998 1.08191C4.78473 0.477671 6.43048 0.171799 8.12221 0.0517021C8.56314 0.0202705 9.00308 0.00478925 9.44401 -0.000371164C9.75796 -0.00412419 9.89594 0.0821955 9.89844 0.276884Z" />
      <path fill={finalColor}
            d="M15.5909 8.03147C15.1584 8.51796 14.568 8.77832 13.9431 8.94815C12.0469 9.46278 10.1562 9.45059 8.28951 8.81116C7.93056 8.68825 7.59761 8.50951 7.30266 8.27589C6.62377 7.73733 6.62527 7.14435 7.30966 6.6086C7.81308 6.21454 8.41799 6.0236 9.03239 5.85237C9.38333 5.75432 9.74278 5.69193 10.1037 5.64361C10.4917 5.592 10.8831 5.58309 11.2355 5.59153C12.4074 5.58497 13.5122 5.72148 14.5555 6.16152C14.952 6.329 15.3214 6.53917 15.5989 6.86475C15.9338 7.25835 15.9353 7.6435 15.5909 8.03147Z" />
      <path fill={finalColor}
            d="M15.8548 10.1895C15.8703 10.5915 15.6983 10.8978 15.3889 11.1526C14.896 11.5584 14.3071 11.785 13.6842 11.9407C12.2179 12.3076 10.7421 12.301 9.26085 12.0205C9.11387 11.9928 9.03139 11.9426 9.04589 11.7789C9.06388 11.5771 9.05038 11.3731 9.04938 11.1699C9.04888 10.9973 8.9434 10.9021 8.77293 10.9011C8.59995 10.8997 8.49797 11.0015 8.49447 11.1699C8.49097 11.3707 8.49347 11.572 8.49347 11.8056C8.3155 11.7338 8.16652 11.6714 8.01505 11.6147C7.91106 11.5753 7.92806 11.4936 7.92756 11.4176C7.92656 11.0972 7.93006 10.7768 7.92606 10.4564C7.92306 10.219 7.81108 10.0811 7.63111 10.1008C7.41464 10.1243 7.35165 10.2641 7.35615 10.4522C7.36215 10.6999 7.35765 10.9485 7.35765 11.2309C7.0202 11.0109 6.81923 10.736 6.80374 10.3903C6.77774 9.79915 6.79674 9.20617 6.79674 8.58411C8.13703 9.57491 9.69028 9.82308 11.3065 9.82589C12.9278 9.82824 14.485 9.58335 15.8188 8.60053C15.8883 8.6343 15.8478 8.70514 15.8483 8.75956C15.8518 9.2362 15.8363 9.7133 15.8548 10.1895Z" />
      <path fill={finalColor}
            d="M12.7488 13.4855C12.7543 13.6511 12.6553 13.7375 12.4769 13.76C12.0794 13.8102 11.68 13.8139 11.0811 13.8182C9.88727 13.806 8.53048 13.6263 7.25968 13.0511C6.80025 12.8433 6.38082 12.5792 6.00987 12.2484C5.8689 12.1227 5.8214 11.982 5.95988 11.8403C6.09986 11.6972 6.25234 11.7329 6.39481 11.8577C7.26818 12.6228 8.34751 12.955 9.49233 13.1417C10.4157 13.2918 11.345 13.3288 12.2789 13.2439C12.3369 13.2388 12.3954 13.236 12.4539 13.236C12.6308 13.2364 12.7433 13.3162 12.7488 13.4855Z" />
      <path fill={finalColor}
            d="M15.9543 5.35976C15.8573 5.51645 15.7058 5.53333 15.5458 5.44842C14.675 4.98586 13.7416 4.70814 12.7478 4.60493C12.5708 4.58663 12.4718 4.48436 12.4833 4.32204C12.4963 4.14049 12.6273 4.07059 12.8348 4.08185C13.8836 4.18881 14.8879 4.48858 15.8238 4.98774C15.9833 5.07265 16.0533 5.19931 15.9543 5.35976Z" />

    </svg>
  );
};

export default HockeyIcon;