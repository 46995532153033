/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LeagueRatingEnum {
    SOUTHTYROL = 'southtyrol',
    TRENTINO = 'trentino',
    HOCKEY = 'hockey',
    HOCKEY_RITTEN = 'hockey_ritten',
}
