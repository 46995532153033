import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const TeamIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0001 7.16C17.9401 7.15 17.8701 7.15 17.8101 7.16C16.4301 7.11 15.3301 5.98 15.3301 4.58C15.3301 3.15 16.4801 2 17.9101 2C19.3401 2 20.4901 3.16 20.4901 4.58C20.4801 5.98 19.3801 7.11 18.0001 7.16Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round" fill={primary ? finalColor : "none"} />
      <path
        d="M16.9704 14.4399C18.3404 14.6699 19.8504 14.4299 20.9104 13.7199C22.3204 12.7799 22.3204 11.2399 20.9104 10.2999C19.8404 9.58992 18.3104 9.34991 16.9404 9.58991"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round" fill={primary ? finalColor : "none"} />
      <path
        d="M5.97047 7.16C6.03047 7.15 6.10047 7.15 6.16047 7.16C7.54047 7.11 8.64047 5.98 8.64047 4.58C8.64047 3.15 7.49047 2 6.06047 2C4.63047 2 3.48047 3.16 3.48047 4.58C3.49047 5.98 4.59047 7.11 5.97047 7.16Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round" fill={primary ? finalColor : "none"} />
      <path
        d="M7.00043 14.4399C5.63043 14.6699 4.12043 14.4299 3.06043 13.7199C1.65043 12.7799 1.65043 11.2399 3.06043 10.2999C4.13043 9.58992 5.66043 9.34991 7.03043 9.58991"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round" fill={primary ? finalColor : "none"} />
      <path
        d="M12.0001 14.63C11.9401 14.62 11.8701 14.62 11.8101 14.63C10.4301 14.58 9.33008 13.45 9.33008 12.05C9.33008 10.62 10.4801 9.46997 11.9101 9.46997C13.3401 9.46997 14.4901 10.63 14.4901 12.05C14.4801 13.45 13.3801 14.59 12.0001 14.63Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round" fill={primary ? finalColor : "none"} />
      <path
        d="M9.08973 17.7799C7.67973 18.7199 7.67973 20.2599 9.08973 21.1999C10.6897 22.2699 13.3097 22.2699 14.9097 21.1999C16.3197 20.2599 16.3197 18.7199 14.9097 17.7799C13.3197 16.7199 10.6897 16.7199 9.08973 17.7799Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round" fill={primary ? finalColor : "none"} />
    </svg>
  );
};

export default TeamIcon;