import React from "react";
import { ClavaContextType, ClavaRootContextType, SiteBlockerContextType } from "./types";
import { LanguageLocaleEnum, SportEnum, User } from "../client/api";

export const ClavaRootContext = React.createContext<ClavaRootContextType>({
  theme: "light",
  fbToken: "",
  initialized: false
});
export const ClavaContext = React.createContext<ClavaContextType>({
  l: LanguageLocaleEnum.DE,
  aoi: -1,
  aois: [],
  regions: [],
  filterAoi: "all",
  setFilterAoi: () => {
    // nothing
  },
  setLang: () => {
    // nothing
  },
  user: {} as User,
  setUser: () => undefined,
  sports: SportEnum.SOCCER,
  setSports: () => {
    // nothing
  }
});

export const SiteBlockerContext = React.createContext<SiteBlockerContextType>({
  block: () => {
    //
  }
});
