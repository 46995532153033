import React, { useContext, useEffect, useMemo, useState } from "react";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { Circle, CircleProps, GoogleMap, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import { AreaOfInterest, LanguageLocaleEnum, UsersPerAreaOfInterest } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import EventsSocket from "../../client/Websockets/events";
import useBoolState from "../../hooks/useBoolState";
import { showTranslated, translate } from "../../config/translator";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import UserIcon from "../../components/Icons/UserIcon";
import client from "../../client";
import { AgCharts } from "ag-charts-react";
import { intlLang } from "../../config/utils";
import { AgChartOptions } from "ag-charts-community";


// Coordinates for Northern Italian provinces
const provinceCoordinates = {
  BZ: { lat: 46.498295, lng: 11.354758 },  // Bolzano
  TN: { lat: 46.074779, lng: 11.121749 },  // Trento
  BL: { lat: 46.138437, lng: 12.217269 },  // Belluno
  PD: { lat: 45.406434, lng: 11.876761 },  // Padova
  RO: { lat: 45.070339, lng: 11.791656 },  // Rovigo
  TV: { lat: 45.666915, lng: 12.245548 },  // Treviso
  VE: { lat: 45.434904, lng: 12.338452 },  // Venezia
  VR: { lat: 45.438384, lng: 10.991622 },  // Verona
  VI: { lat: 45.545479, lng: 11.535421 },  // Vicenza
  GO: { lat: 45.940715, lng: 13.622285 },  // Gorizia
  PN: { lat: 45.956967, lng: 12.66165 },   // Pordenone
  TS: { lat: 45.649526, lng: 13.776818 },  // Trieste
  UD: { lat: 46.063839, lng: 13.235834 }  // Udine
};

type PROVINCES = keyof typeof provinceCoordinates;
const provinces: PROVINCES[] = Object.keys(provinceCoordinates) as PROVINCES[];

const sum = provinces.reduce((prev, cur) => {
  const curLat = provinceCoordinates[cur].lat;
  const curLng = provinceCoordinates[cur].lng;
  return {
    lat: prev.lat + curLat,
    lng: prev.lng + curLng
  };
}, { lat: 0, lng: 0 });
const center = {
  lat: sum.lat / provinces.length,
  lng: sum.lng / provinces.length
};

const mapContainerStyle = {
  width: "36rem",
  height: "36rem"
};

const ActiveClientsCircle: React.FC<{
  province: AreaOfInterest,
  users: number,
  stats?: UsersPerAreaOfInterest
}> = ({
        province,
        users,
        stats
      }) => {
  const { l } = useContext(ClavaContext);
  const [visible, toggleVisible] = useBoolState(false);
  const coordinates = useMemo(() => {
    return provinceCoordinates[province.key.toUpperCase() as keyof typeof provinceCoordinates];
  }, [province.key]);
  const options = useMemo<CircleProps["options"]>(() => {

    const color = users > 1000 ? "#5AA0E1" : // Red for high activity
      users > 500 ? "#00ff00" : // Red for high activity
        users > 100 ? "#bfff00" : // Red for high activity
          users > 10 ? "#ffc400" : // Orange for medium activity
            users > 5 ? "#ff8800" : // Orange for medium activity
              "#FF0000"; // Green for low activity
    return {
      strokeColor: color,
      fillColor: color,
      strokeWeight: 0.5,
      fillOpacity: 0.2
    };
  }, [users]);
  if (coordinates) {
    return (
      <React.Fragment>
        <Circle
          center={coordinates}
          radius={10000 + (Math.sqrt(users) * 1000)}
          options={options}
          onClick={toggleVisible}
        />
        {visible && (
          <InfoWindow position={coordinates} onCloseClick={toggleVisible}>
            <div>
              <span className="font-bold">{showTranslated(province.name, l)}</span><br />
              <span>{translate("online", l)}: {users}{stats ? `/${stats.userCount}` : ""}</span>
            </div>
          </InfoWindow>
        )}
      </React.Fragment>
    );
  }
  return null;
};
const options: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  second: "2-digit",
  minute: "2-digit"
};
const Graph: React.FC<{ users: Record<string, number> }> = ({ users }) => {
  const [data, setData] = useState<AgChartOptions["data"]>([]);
  useEffect(() => {
    const time = new Date().toLocaleTimeString(intlLang(LanguageLocaleEnum.DE), options);
    setData(oldD => oldD ? oldD.concat([{
      ...users,
      time
    }]) : [{
      ...users,
      time
    }]);
  }, [users]);

  const chartOptions = useMemo<AgChartOptions | undefined>(() => {
    if (!data || data.length === 0) {
      return undefined;
    }
    return {
      data: data,
      series: Object.keys(data[0]).filter(k => k !== "time").map((key) => ({
        type: "line",
        xKey: "time",
        yKey: key
      }))
    };
  }, [data]);
  if (!chartOptions)
    return null;
  return (
    <div className="flex-1 max-w-xl">
      <AgCharts options={chartOptions} />
    </div>
  );
};

const ActiveClientsMap: React.FC = () => {
  const { aois, l } = useContext(ClavaContext);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-scrip",
    googleMapsApiKey: "AIzaSyDRNtR5RRj_qN8m95QFkru7ltK9ARPzj5I" // Replace with your Google Maps API key
  });
  const [userStats, setUserStats] = useState<UsersPerAreaOfInterest[]>([]);
  const [activeUsers, setActiveUsers] = useState<Record<string, number>>();
  useEffect(() => {
    EventsSocket.setActiveUserHandler(setActiveUsers);
  }, []);
  useEffect(() => {
    client().getUserStats().then(setUserStats);
  }, []);

  if (!isLoaded || !activeUsers) return null;
  return (
    <RoundedBlock className="mt-2">
      <ListBlockHeader title={"realtimeOverview"} icon={<UserIcon />} />
      <div className="flex flex-row items-stretch justify-evenly mb-4">
        <div
          className={"py-1 px-2 rounded-lg bg-[#5AA0E177]"}>
          <span className={"font-semibold"}>{"> 1000"}</span>
        </div>
        <div
          className={"py-1 px-2 rounded-lg bg-[#00ff0077]"}>
          <span className={"font-semibold"}>{"> 500"}</span>
        </div>
        <div
          className={"py-1 px-2 rounded-lg bg-[#bfff0077]"}>
          <span className={"font-semibold"}>{"> 100"}</span>
        </div>
        <div
          className={"py-1 px-2 rounded-lg bg-[#ffc40077]"}>
          <span className={"font-semibold"}>{"> 10"}</span>
        </div>
        <div
          className={"py-1 px-2 rounded-lg bg-[#ff880077]"}>
          <span className={"font-semibold"}>{"> 5"}</span>
        </div>
        <div
          className={"py-1 px-2 rounded-lg bg-[#ff000077]"}>
          <span className={"font-semibold"}>{"0 - 5"}</span>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start">
        <GoogleMap
          mapTypeId={"roadmap"}
          mapContainerStyle={mapContainerStyle}
          zoom={7}
          center={center}
        >
          {aois.map((aoi) => {
            const users = activeUsers[aoi.key];
            const stats = userStats.find(stat => stat.areaOfInterestId === aoi.id);
            // noinspection SuspiciousTypeOfGuard
            if (typeof users === "number") {
              return (
                <ActiveClientsCircle province={aoi} users={users} stats={stats}
                                     key={aoi.id + "-map-circle"} />
              );
            }
            return null;
          })}
        </GoogleMap>
        <div className="flex flex-col items-stretch justify-start px-4 w-80">
          {aois.map((aoi) => {
            const users = activeUsers[aoi.key];
            const stats = userStats.find(stat => stat.areaOfInterestId === aoi.id);

            return (
              <div
                className={"flex flex-row items-center justify-between p-1 rounded-lg odd:bg-light-gray dark:odd:bg-light-gray-dark"}
                key={"aoi-stat-" + aoi.id}>
                <span className={"font-semibold"}>{showTranslated(aoi.name, l)}</span>
                <span>{users ?? " - "} / {stats?.userCount ?? "-"}</span>
              </div>
            );
          })}
        </div>
        <Graph users={activeUsers} />
      </div>
    </RoundedBlock>
  );
};

export default ActiveClientsMap;
