import React, { useCallback, useContext, useEffect, useState } from "react";
import { Tournament, TournamentStage } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { showTranslated, translate } from "../../config/translator";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LeagueIcon from "../../components/Icons/LeagueIcon";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import MessageBox from "../../components/Atoms/MessageBox";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Table from "../../components/Table/Table";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { TableRowItem, useList } from "../../hooks/useList";
import { useNavigate } from "react-router";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const headerItems: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  },
  {
    label: "isLooserBracket"
  },
  {
    label: "sectionAmount"
  },
  {
    label: "sectionDuration"
  },
  {
    label: "sectionPause"
  },
  {
    label: "matches"
  },
  {
    label: "action"
  }
];
const TournamentEditStages: React.FC<{
  tournament: Tournament,
  changes: (tournament: TournamentStage[]) => void,
  stages: TournamentStage[],
}> = ({ tournament, changes, stages }) => {
  const { l } = useContext(ClavaContext);
  const { call, loading } = useServer(false);
  const navigator = useNavigate();
  const [del, setDel] = useState<TournamentStage>();
  const mapper = useCallback((stage: TournamentStage): TableRowItem => {
    return {
      id: stage.id,
      search: showTranslated(stage.name, l).toLowerCase(),
      items: [
        { label: stage.id },
        {
          label: showTranslated(stage.name, l)
        },
        {
          pill: {
            type: stage.isLosersBracket ? "warning" : "success",
            text: stage.isLosersBracket ? "yes" : "no"
          }
        },
        {
          label: stage.matchSectionAmount.toString(10)
        },
        {
          label: stage.matchSectionDurationMinutes.toString(10)
        },
        {
          label: stage.matchSectionPauseDurationMinutes.toString(10)
        },
        {
          label: stage.matches.length.toString(10)
        },
        {
          centered: true,
          buttons: [
            {
              icon: faPencil,
              onClick: () => {
                // TODO
              },
              type: "primary"
            },
            {
              icon: faTrash,
              disabled: stage.matches.length !== 0,
              onClick: () => {
                setDel(stage);
              },
              type: "danger"
            }
          ]
        }
      ]
    };
  }, [l]);
  const {
    selected,
    onCheck,
    onCheckAll,
    allSelected,
    q,
    setQ,
    setItems,
    filtered
  } = useList(mapper);
  useEffect(() => {
    setItems(stages);
  }, [setItems, stages]);
  const [add, setAdd] = useState(false);
  const toggleAdd = useCallback(() => {
    setAdd(a => !a);
  }, []);
  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);
  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("deleteCupStage", [id, tournament.id], "deleteFailed").then((res) => {
        changes(res);
        setDel(undefined);
      });
    }
  }, [changes, del, call, tournament.id]);
  return (
    <RoundedBlock className="mb-2">
      <ListBlockHeader title={"stages"} onSearch={setQ} q={q} icon={<LeagueIcon />}>
        <ClavaButton onClick={toggleAdd} lightSecondary className="mr-2">
          {translate("addMatch", l)}
        </ClavaButton>
      </ListBlockHeader>
      <LoadingBlock isLoading={loading}>
        <Table>
          <TableHeader items={headerItems} onCheckAll={onCheckAll} allChecked={allSelected} />
          <tbody>
          {filtered.map(fil => (
            <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                      checked={selected.includes(fil.id)}
                      key={"tournament-stages-" + fil.id} />
          ))}
          </tbody>
        </Table>
      </LoadingBlock>
      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? showTranslated(del.name, l) : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
    </RoundedBlock>
  );
};
TournamentEditStages.displayName = "TournamentEditStages";
export default TournamentEditStages;
