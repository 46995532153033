import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const VolleyIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 16 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00906276 7.48648C0.0910576 6.15691 0.456535 4.90912 1.16699 3.78426C2.46141 1.73421 4.2923 0.463342 6.69265 0.0529309C6.79114 0.0358723 6.88863 0.0147998 6.98713 0.00326018C7.05162 -0.0042657 7.11762 0.00225673 7.24611 0.00225673C5.64471 0.963561 4.46178 2.22891 3.67133 3.85149C2.97938 5.27287 2.6704 6.77504 2.78589 8.35798C2.79539 8.49043 2.78789 8.55967 2.6234 8.5371C1.73546 8.41618 0.897007 8.14224 0.0960573 7.74236C-0.0334346 7.67764 0.0035631 7.5798 0.00906276 7.48648Z"
        fill={"none"}
      />
      <path
        d="M9.32952 0.109894C9.40802 0.0848078 9.48801 0.0757767 9.57451 0.0938388C10.1505 0.212246 10.7084 0.384337 11.2619 0.636705C11.0009 0.855457 10.7449 1.05314 10.5074 1.27088C8.83605 2.80767 7.85161 4.69967 7.59463 6.96596C7.58313 7.0653 7.54213 7.16765 7.55613 7.26248C7.60413 7.59111 7.44364 7.73761 7.15816 7.86505C6.14372 8.31811 5.08928 8.57449 3.98135 8.62466C3.79087 8.63319 3.72037 8.58001 3.70787 8.38735C3.46589 4.63194 5.76224 1.24831 9.32952 0.109894Z"
        fill={"none"}
      />
      <path
        d="M0.657071 10.786C0.487581 10.639 0.372088 10.4725 0.316592 10.2537C0.195599 9.77808 0.0796067 9.30194 0.0371094 8.76309C0.693068 9.04405 1.32253 9.27083 1.98499 9.39827C3.00092 9.59344 4.01936 9.60347 5.0403 9.465C6.02024 9.33204 6.94668 9.02348 7.82562 8.57394C7.94362 8.51373 8.02061 8.52978 8.1186 8.59451C9.15054 9.27585 9.98599 10.1443 10.6284 11.204C10.7124 11.3425 10.6489 11.3831 10.5525 11.4473C9.39802 12.22 8.1391 12.6906 6.74919 12.807C4.43034 13.0012 2.40946 12.3083 0.657071 10.786Z"
        fill={"none"}
      />
      <path
        d="M11.7516 14.8757C11.7591 14.9936 11.7456 15.0729 11.6221 15.1351C8.15985 16.8911 3.80662 15.9378 1.46376 12.6004C1.45926 12.5939 1.45776 12.5853 1.44727 12.5587C4.74706 14.3128 7.95536 14.2034 11.1267 12.2211C11.2512 12.4669 11.3341 12.7394 11.4176 13.0113C11.6051 13.6194 11.7131 14.241 11.7516 14.8757Z"
        fill={"none"}
      />
      <path
        d="M14.1437 9.6713C14.3842 10.7891 14.3557 11.91 14.1077 13.0263C14.0892 13.1086 14.0757 13.1914 14.0187 13.2556C13.6207 13.7057 13.1873 14.1161 12.6693 14.4828C12.3988 12.1699 11.4499 10.2408 9.76447 8.68541C9.415 8.3623 9.04052 8.07029 8.63854 7.81592C8.51905 7.74066 8.47255 7.6639 8.48155 7.51639C8.55405 6.2992 8.88153 5.15778 9.45599 4.08359C9.53799 3.93106 9.60348 3.90899 9.75747 3.98726C12.1163 5.18688 13.5847 7.07838 14.1437 9.6713Z"
        fill={"none"}
      />
      <path
        d="M15.3796 11.0725C15.3496 11.1458 15.3141 11.2165 15.2526 11.351C15.2316 10.5236 15.1591 9.77357 14.9626 9.03904C14.2612 6.41352 12.7083 4.47285 10.3064 3.22104C10.1244 3.12622 10.1009 3.08006 10.2344 2.9165C10.7559 2.2793 11.3549 1.73192 12.0293 1.26281C12.1628 1.16999 12.2593 1.15745 12.4013 1.25629C14.1657 2.47749 15.3306 4.1096 15.8016 6.22085C16.1721 7.88257 16.0201 9.49912 15.3796 11.0725Z"
        fill={"none"}
      />
      <path fill={finalColor}
            d="M14.1077 13.0263C14.0892 13.1086 14.0757 13.1914 14.0187 13.2556C13.6207 13.7057 13.1873 14.1161 12.6693 14.4828C12.3988 12.1699 11.4499 10.2408 9.76447 8.68541C9.415 8.3623 9.04052 8.07029 8.63854 7.81592C8.51905 7.74066 8.47255 7.6639 8.48155 7.51639C8.55405 6.2992 8.88153 5.15778 9.45599 4.08359C9.53799 3.93106 9.60348 3.90899 9.75747 3.98726C12.1163 5.18688 13.5847 7.07838 14.1437 9.6713C14.3842 10.7891 14.3557 11.91 14.1077 13.0263Z" />
      <path fill={finalColor}
            d="M11.2619 0.636705C11.0009 0.855457 10.7449 1.05314 10.5074 1.27088C8.83605 2.80767 7.85161 4.69967 7.59463 6.96596C7.58313 7.0653 7.54213 7.16765 7.55613 7.26248C7.60413 7.59111 7.44364 7.73761 7.15816 7.86505C6.14372 8.31811 5.08928 8.57449 3.98135 8.62466C3.79087 8.63319 3.72037 8.58001 3.70787 8.38735C3.46589 4.63194 5.76224 1.24831 9.32952 0.109894C9.40802 0.0848078 9.48801 0.0757767 9.57451 0.0938388C10.1505 0.212246 10.7084 0.384337 11.2619 0.636705Z" />
      <path fill={finalColor}
            d="M10.5525 11.4473C9.39802 12.22 8.1391 12.6906 6.74919 12.807C4.43034 13.0012 2.40946 12.3083 0.657071 10.786C0.487581 10.639 0.372088 10.4725 0.316592 10.2537C0.195599 9.77808 0.0796067 9.30194 0.0371094 8.76309C0.693068 9.04405 1.32253 9.27083 1.98499 9.39827C3.00092 9.59344 4.01936 9.60347 5.0403 9.465C6.02024 9.33204 6.94668 9.02348 7.82562 8.57394C7.94362 8.51373 8.02061 8.52978 8.1186 8.59451C9.15054 9.27585 9.98599 10.1443 10.6284 11.204C10.7124 11.3425 10.6489 11.3831 10.5525 11.4473Z" />
      <path fill={finalColor}
            d="M11.6221 15.1351C8.15985 16.8911 3.80662 15.9378 1.46376 12.6004C1.45926 12.5939 1.45776 12.5853 1.44727 12.5587C4.74706 14.3128 7.95536 14.2034 11.1267 12.2211C11.2512 12.4669 11.3341 12.7394 11.4176 13.0113C11.6051 13.6194 11.7131 14.241 11.7516 14.8757C11.7591 14.9936 11.7456 15.0729 11.6221 15.1351Z" />
      <path fill={finalColor}
            d="M7.24611 0.00225673C5.64471 0.963561 4.46178 2.22891 3.67133 3.85149C2.97938 5.27287 2.6704 6.77504 2.78589 8.35798C2.79539 8.49043 2.78789 8.55967 2.6234 8.5371C1.73546 8.41618 0.897007 8.14224 0.0960573 7.74236C-0.0334346 7.67764 0.0035631 7.5798 0.00906276 7.48648C0.0910576 6.15691 0.456535 4.90912 1.16699 3.78426C2.46141 1.73421 4.2923 0.463342 6.69265 0.0529309C6.79114 0.0358723 6.88863 0.0147998 6.98713 0.00326018C7.05162 -0.0042657 7.11762 0.00225673 7.24611 0.00225673Z" />
      <path fill={finalColor}
            d="M15.3796 11.0725C15.3496 11.1458 15.3141 11.2165 15.2526 11.351C15.2316 10.5236 15.1591 9.77357 14.9626 9.03904C14.2612 6.41352 12.7083 4.47285 10.3064 3.22104C10.1244 3.12622 10.1009 3.08006 10.2344 2.9165C10.7559 2.2793 11.3549 1.73192 12.0293 1.26281C12.1628 1.16999 12.2593 1.15745 12.4013 1.25629C14.1657 2.47749 15.3306 4.1096 15.8016 6.22085C16.1721 7.88257 16.0201 9.49912 15.3796 11.0725Z" />
    </svg>
  );
};

export default VolleyIcon;