import React, { useCallback, useContext, useMemo } from "react";
import { League, LeagueListElement } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import { IDType } from "../../config/types";
import { showTranslated } from "../../config/translator";

const TeamLineItem: React.FC<{
  selectBox?: boolean,
  league: League | LeagueListElement,
  onSelect: (id: IDType) => void,
  selected: boolean
  disabled?: boolean
}> = ({ league, disabled, selectBox, selected, onSelect }) => {
  const { l } = useContext(ClavaContext);
  const additional = useMemo(() => {
    if ("areasOfInterest" in league) {
      return league.areasOfInterest.map(a => showTranslated(a.name, l)).join(", ");
    }
    return undefined;
  }, [league, l]);
  const onCheckCont = useCallback(() => {
    if (!disabled)
      onSelect(league.id);
  }, [onSelect, disabled, league.id]);
  return (
    <button onClick={onCheckCont}
            className={`flex self-stretch flex-row items-center border-none border-b border-b-light-gray dark:border-b-light-gray-dark p-1 ${selected ? "even:bg-primary-30 odd:bg-primary-40" : "even:bg-white even:dark:bg-bg-dark odd:bg-bg dark:odd:bg-black"}`}>
      <div className="flex-1 flex flex-col items-start justify-evenly">
        <span
          className="font-semibold">[{league.id}] {showTranslated(league.name, l)} {league.year}</span>
        {!!additional && (
          <span
            className="font-light text-sm">{additional}</span>
        )}
      </div>
      {selectBox && (
        <input type="checkbox" className="mx-2" disabled={disabled} onChange={onCheckCont}
               checked={selected} />)}

    </button>
  );
};

export default TeamLineItem;