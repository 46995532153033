import React, { useCallback, useContext, useMemo, useState } from "react";
import { ClavaContext } from "../../config/contexts";
import { BlogCreate, Image, SportEnum } from "../../client/api";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import { showTranslated, translate } from "../../config/translator";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import FileUploader from "../../components/FileUploader/FileUploader";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { useNavigate } from "react-router";
import { AdditionalCheck, useFormData } from "../../hooks/useFormData";
import { SPORTS } from "../../config/constants";
import { useAois } from "../../hooks/useAois";
import { formatDate, generatePW, sameIdArray } from "../../config/utils";
import { faPhotoVideo } from "@fortawesome/pro-light-svg-icons";
import ClavaSelectMultiple from "../../components/Atoms/ClavaSelectMultiple";
import ClavaSelect, { mapTranslationToItem } from "../../components/Atoms/ClavaSelect";
import ClavaTextArea from "../../components/Atoms/ClavaTextArea";
import useBoolState from "../../hooks/useBoolState";

const emptyBlog: BlogCreate = {
  title: {
    textIT: "",
    textEN: "",
    textDE: ""
  },
  body: {
    textIT: "",
    textEN: "",
    textDE: ""
  },
  summary: {
    textIT: "",
    textEN: "",
    textDE: ""
  },
  link: "",
  date: "",
  sports: SportEnum.SOCCER,
  deleted: false,
  uid: "",
  areas_of_interest: [1],
  imageId: ""
};
const aoiCheck: AdditionalCheck<BlogCreate, "areas_of_interest"> = sameIdArray;
const as = 16 / 9;
const BlogCreateView: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const form = useFormData(emptyBlog,
    ["uid", "link", "sports", "title", "summary", "body", "deleted", "date"],
    { areas_of_interest: aoiCheck },
    ["uid", "link", "sports"],
    ["deleted"],
    [],
    ["date"],
    ["title", "body", "summary"],
    ["title"],
    false
  );
  const [patchImage, onPatchImage] = useBoolState(false);
  const [img, setImg] = useState<Image>();
  const { loading, call, StatusComponent } = useServer(false);

  const breadcrump = useMemo(() => {
    return [
      translate("createBlog", l)
    ];
  }, [l]);

  const {
    aoiItems,
    selectedAois,
    onChangeAois
  } = useAois(true, form.areas_of_interest ?? [], (val) => {
    form.onChange("areas_of_interest", val);
  });

  const sportItems = useMemo(() => {
    return [{ key: "all", label: translate("all", l) }].concat(SPORTS.map(mapTranslationToItem(l)));
  }, [l]);
  const onChangeImage = useCallback((file: Image) => {
    setImg(file);
    form.setFormData(fd => ({
      ...fd,
      imageId: file.id
    }));
    onPatchImage();
  }, [onPatchImage, form]);
  const navigator = useNavigate();
  const createEnabled = useMemo(() => {
    return !!form.titleDe && form.titleDe !== "" && !!img;
  }, [img, form]);
  const onSave = useCallback(() => {
    if (createEnabled) {
      const cr: BlogCreate = {
        title: form.title,
        summary: form.summary,
        body: form.body,
        imageId: form.imageId,
        areas_of_interest: form.areas_of_interest,
        date: formatDate(new Date(), l, true, false, true),
        deleted: false,
        uid: "backoffice-created-" + generatePW(12),
        link: form.link && form.link === "" ? null : form.link,
        sports: form.sports === ("all" as SportEnum) ? null : form.sports
      };

      call("createNews", [cr], "saveFailed", "saveSuccess").then((res) => {
        form.setInitial(cr);
        setTimeout(() => {
          navigator("/news/" + res.id);
        }, 5000);

      });
    }
  }, [createEnabled, l, form, call, navigator]);
  return (
    <>
      <Breadcrumps additional={breadcrump}></Breadcrumps>
      <RoundedBlock>
        <ItemBlockHeader image={img ?? faPhotoVideo}
                         imageHeader={as}
                         name={showTranslated(form.title, l) ?? translate("newEntry", l)}
                         onPatchImage={onPatchImage}>
          <ClavaButton onClick={onSave}
                       disabled={!createEnabled}>{translate("save", l)}</ClavaButton>
        </ItemBlockHeader>
        <LoadingBlock isLoading={loading}>
          {StatusComponent}
          <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
            <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeTitleDe}
                            changed={form.titleChangedDe}
                            value={form.titleDe}
                            label={translate("title", l) + " DE"} />
            <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeTitleIt}
                            changed={form.titleChangedIt}
                            value={form.titleIt}
                            label={translate("title", l) + " IT"} />
            <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeTitleEn}
                            changed={form.titleChangedEn}
                            value={form.titleEn}
                            label={translate("title", l) + " EN"} />
          </div>
          <div className="flex flex-row items-center mx-[-8px] my-4">
            <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeSummaryDe}
                           changed={form.summaryChangedDe}
                           value={form.summaryDe}
                           rows={5}
                           label={translate("summary", l) + " DE"} />
            <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeSummaryIt}
                           changed={form.summaryChangedIt}
                           value={form.summaryIt}
                           rows={5}
                           label={translate("summary", l) + " IT"} />
            <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeSummaryEn}
                           changed={form.summaryChangedEn}
                           rows={5}
                           value={form.summaryEn}
                           label={translate("summary", l) + " EN"} />
          </div>
          <div className="flex flex-row items-center mx-[-8px] my-4">
            <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeBodyDe}
                           changed={form.bodyChangedDe}
                           value={form.bodyDe}
                           rows={20}
                           label={translate("body", l) + " DE"} />
            <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeBodyIt}
                           changed={form.bodyChangedIt}
                           value={form.bodyIt}
                           rows={20}
                           label={translate("body", l) + " IT"} />
            <ClavaTextArea className="flex-1 mx-2" onChange={form.onChangeBodyEn}
                           changed={form.bodyChangedEn}
                           value={form.bodyEn}
                           rows={20}
                           label={translate("body", l) + " EN"} />

          </div>
          <div className="flex flex-row items-center mx-[-8px] my-4">
            <ClavaSelectMultiple className="flex-1 mx-2" uniqueId="blog-aois" items={aoiItems}
                                 value={selectedAois}
                                 onChange={onChangeAois}
                                 label={translate("province", l)} />
            <ClavaSelect className="flex-1 mx-2" value={form.sports ?? undefined}
                         changed={form.sportsChanged}
                         items={sportItems}
                         onChange={form.onChangeSports}
                         label={translate("sports", l)} uniqueId={"league-sports"} />

            <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeLink}
                            changed={form.linkChanged}
                            value={form.link ?? ""}
                            label={translate("url", l)} />
          </div>
        </LoadingBlock>
      </RoundedBlock>
      {patchImage && (
        <FileUploader as={as} type={"image"} callback={onChangeImage} close={onPatchImage} />
      )}
    </>
  );
};

export default BlogCreateView;
