/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_change_team_icon_admin_change_team_icon__team_id__post } from '../models/Body_change_team_icon_admin_change_team_icon__team_id__post';
import type { MatchSmall } from '../models/MatchSmall';
import type { Team } from '../models/Team';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AdministrationService {
    /**
     * Clear Cache
     * @returns any Successful Response
     * @throws ApiError
     */
    public static clearCacheAdminClearCachePost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/clear_cache',
        });
    }
    /**
     * Set User Password
     * @param userEmailTel
     * @param userPassword
     * @returns User Successful Response
     * @throws ApiError
     */
    public static setUserPasswordAdminSetUserPasswordPost(
        userEmailTel: string,
        userPassword: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/set_user_password',
            query: {
                'user_email_tel': userEmailTel,
                'user_password': userPassword,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Change Team Icon
     * @param teamId
     * @param formData
     * @param fitToEmblem
     * @param removeBackground
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static changeTeamIconAdminChangeTeamIconTeamIdPost(
        teamId: number,
        formData: Body_change_team_icon_admin_change_team_icon__team_id__post,
        fitToEmblem: boolean = false,
        removeBackground: boolean = false,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/change_team_icon/{team_id}',
            path: {
                'team_id': teamId,
            },
            query: {
                'fit_to_emblem': fitToEmblem,
                'remove_background': removeBackground,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search For Match League
     * @param team1Name
     * @param team2Name
     * @param main
     * @returns MatchSmall Successful Response
     * @throws ApiError
     */
    public static searchForMatchLeagueAdminMatchLeagueGet(
        team1Name: string,
        team2Name: string,
        main: boolean,
    ): CancelablePromise<Array<MatchSmall>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/match/league',
            query: {
                'team_1_name': team1Name,
                'team_2_name': team2Name,
                'main': main,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Events From Match
     * @param matchId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteEventsFromMatchAdminDeleteEventsFromMatchPost(
        matchId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/delete_events_from_match',
            query: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Test New Statistics
     * @returns any Successful Response
     * @throws ApiError
     */
    public static testNewStatisticsAdminTestNewStatisticsPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/test_new_statistics',
        });
    }
    /**
     * Get Match
     * @param team1Id
     * @param team2Id
     * @param leagueId
     * @param beforeMatch
     * @param matchDay
     * @param matchDate
     * @param limit
     * @param playedOnly
     * @param activeLeaguesOnly
     * @param notCancelledOnly
     * @param mainOnly
     * @returns MatchSmall Successful Response
     * @throws ApiError
     */
    public static getMatchAdminMatchGet(
        team1Id?: number,
        team2Id?: number,
        leagueId?: number,
        beforeMatch?: number,
        matchDay?: number,
        matchDate?: string,
        limit: number = 100,
        playedOnly: boolean = false,
        activeLeaguesOnly: boolean = false,
        notCancelledOnly: boolean = false,
        mainOnly: boolean = false,
    ): CancelablePromise<Array<MatchSmall>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/match',
            query: {
                'team_1_id': team1Id,
                'team_2_id': team2Id,
                'league_id': leagueId,
                'before_match': beforeMatch,
                'match_day': matchDay,
                'match_date': matchDate,
                'limit': limit,
                'played_only': playedOnly,
                'active_leagues_only': activeLeaguesOnly,
                'not_cancelled_only': notCancelledOnly,
                'main_only': mainOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Test
     * @returns any Successful Response
     * @throws ApiError
     */
    public static testAdminTestGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/test',
        });
    }
    /**
     * Duplicate
     * @returns any Successful Response
     * @throws ApiError
     */
    public static duplicateAdminDuplicateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/duplicate',
        });
    }
    /**
     * Test Notification
     * @returns any Successful Response
     * @throws ApiError
     */
    public static testNotificationAdminTestNotificationGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/test_notification',
        });
    }
    /**
     * Test Stat
     * @returns any Successful Response
     * @throws ApiError
     */
    public static testStatAdminTestStatGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/test_stat',
        });
    }
}
