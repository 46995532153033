/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Post } from '../models/Post';
import type { PostList } from '../models/PostList';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FeedService {
    /**
     * Get User Feed
     * @param offset
     * @param limit
     * @returns Post Successful Response
     * @throws ApiError
     */
    public static getUserFeedFeedUserGet(
        offset: number,
        limit: number,
    ): CancelablePromise<Array<Post>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed/user',
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Match Feed
     * @param matchId
     * @param offset
     * @param limit
     * @returns Post Successful Response
     * @throws ApiError
     */
    public static getMatchFeedFeedMatchMatchIdGet(
        matchId: number,
        offset: number,
        limit: number,
    ): CancelablePromise<Array<Post>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed/match/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Region Feed
     * @param regionId
     * @param offset
     * @param limit
     * @returns Post Successful Response
     * @throws ApiError
     */
    public static getRegionFeedFeedRegionRegionIdGet(
        regionId: number,
        offset: number,
        limit: number,
    ): CancelablePromise<Array<Post>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed/region/{region_id}',
            path: {
                'region_id': regionId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get My Feed
     * @param limit
     * @param offset
     * @returns PostList Successful Response
     * @throws ApiError
     */
    public static getMyFeedFeedMyGet(
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<PostList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed/my',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Of User Feed
     * @param userId
     * @param limit
     * @param offset
     * @returns PostList Successful Response
     * @throws ApiError
     */
    public static getOfUserFeedFeedUserUserIdGet(
        userId: number,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<PostList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed/user/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
