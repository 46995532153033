import React, { useCallback, useContext, useMemo, useState } from "react";
import { translate } from "../../config/translator";
import { ClavaSelectItems } from "../../config/types";
import { ClavaContext } from "../../config/contexts";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { ClavaSelectWrapper } from "./ClavaSelect";
import ReactDOM from "react-dom";
import Overlay from "../Layout/Overlay";

const modalRoot = document.getElementById("root") as HTMLElement;

const ClavaSelectMultiple: React.FC<{
  className?: string;
  error?: boolean;
  value?: string[];
  label?: string;
  changed?: boolean;
  placeholder?: string;
  uniqueId: string;
  disabled?: boolean;
  iconL?: IconDefinition;
  items: ClavaSelectItems[],
  transparent?: boolean,
  withSearch?: boolean,
  onChange: (key: string[]) => void
}> = ({
        items, iconL,
        disabled,
        transparent,
        uniqueId,
        placeholder,
        onChange, withSearch,
        label, changed,
        error,
        value,
        className
      }) => {
  const { l } = useContext(ClavaContext);
  const [openStyle, setOpenStyle] = useState<{ top: number, left: number }>();
  const realPlaceholder = useMemo(() => {
    if (!placeholder) return translate("select", l);
    return placeholder;
  }, [l, placeholder]);

  const [q, setQ] = useState("");
  const realValue = useMemo(
    () => value && value.length !== 0 ? items.filter((it) => value.includes(it.key)).map(v => v.label ?? v.key).join(", ") : undefined,
    [items, value]
  );
  const filteredItems = useMemo(() => {
    if (!withSearch || q === "") {
      return items;
    }
    return items.filter((it) => it.label.toLowerCase().includes(q.toLowerCase()));
  }, [items, q, withSearch]);

  const closeDropdown = useCallback(() => {
    setOpenStyle(undefined);
  }, []);
  return (<div className={"relative " + className}>
      <ClavaSelectWrapper q={q} setQ={setQ} withSearch={withSearch} changed={changed}
                          onOpen={setOpenStyle}
                          label={label}
                          realValue={realValue}
                          iconL={iconL} disabled={disabled} transparent={transparent}
                          realPlaceholder={realPlaceholder} error={error} />
      {!!openStyle && ReactDOM.createPortal(<React.Fragment>
          <Overlay close={closeDropdown} />
          <div
            className="absolute max-h-64 flex flex-col items-stretch justify-start bg-bg-secondary dark:bg-bg-secondary-dark rounded-xl shadow-shadow dark:shadow-shadow-dark drop-shadow-lg overflow-scroll"
            style={openStyle}
          >
            {filteredItems.map((item, idx) => (
              <button
                className={`min-w-fit text-left pl-2 pr-10 py-1 border-none hover:bg-primary-20 text-sm text-font dark:text-font-dark ${
                  value?.includes(item.key) ? "!bg-primary !text-white" : ""
                } ${idx === 0 ? "rounded-t-xl" : ""} ${idx === filteredItems.length - 1 ? "rounded-b-xl" : ""}`}
                type="button"
                onClick={() => {
                  onChange(value ? (value.includes(item.key) ? value.filter(v => v !== item.key) : value.concat(item.key)) : [item.key]);
                  setQ("");
                }}
                key={`${uniqueId}-item-${item.key.toString()}`}
              >
                {item.label}
              </button>
            ))}
          </div>
        </React.Fragment>, modalRoot
      )}
    </div>
  );
};

export default ClavaSelectMultiple;