import React, { useCallback, useContext } from "react";
import { TournamentTeamCreate } from "../../client/api";
import AsideModal from "../Layout/AsideModal";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import { useFormData } from "../../hooks/useFormData";
import ClavaTextInput from "../Atoms/ClavaTextInput";
import ClavaSwitch from "../Atoms/ClavaSwitch";
import { useLSState } from "../../hooks/useLocalStorageState";

const emptyTeam: TournamentTeamCreate = {
  name: {
    textEN: "",
    textIT: "",
    textDE: ""
  }
};

const CreateTournamentTeamModal: React.FC<{
  onAdd: (team: TournamentTeamCreate) => void,
  close: () => void,
}> = ({ onAdd, close }) => {
  const { l } = useContext(ClavaContext);
  const form = useFormData(
    emptyTeam,
    ["name"],
    {},
    [],
    [],
    [],
    [],
    ["name"]
  );
  const [copyName, toggleCopy] = useLSState("copyTournamentTeamName");
  const onSave = useCallback(() => {
    if (!form.nothingChanged) {
      onAdd({
        name: form.name
      });
    }
  }, [form, onAdd]);
  const onChangeName = useCallback((val: string) => {
    form.onChangeNameDe(val);
    form.onChangeNameIt(val);
    form.onChangeNameEn(val);
  }, [form]);
  return (
    <AsideModal close={close} title={translate("createTeam", l)} saveClb={onSave}>
      <ClavaTextInput onChange={copyName ? onChangeName : form.onChangeNameDe}
                      changed={form.nameChangedDe}
                      value={form.nameDe} label={translate("nameDe", l)} className="my-2" />
      <ClavaSwitch onChange={toggleCopy} value={copyName} label={translate("copyName", l)}
                   horizontal />
      <ClavaTextInput onChange={form.onChangeNameIt} changed={form.nameChangedIt}
                      value={form.nameIt} label={translate("nameIt", l)} className="my-2" />
      <ClavaTextInput onChange={form.onChangeNameEn} changed={form.nameChangedEn}
                      value={form.nameEn} label={translate("nameEn", l)} className="my-2" />

    </AsideModal>
  );
};
CreateTournamentTeamModal.displayName = "CreateTournamentTeamModal";
export default CreateTournamentTeamModal;