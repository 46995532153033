import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  TeamListElement,
  TeamSmall,
  Tournament,
  TournamentGroupStanding,
  TournamentStage,
  TournamentTeamCreate
} from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { showTranslated, translate } from "../../config/translator";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LeagueIcon from "../../components/Icons/LeagueIcon";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import MessageBox from "../../components/Atoms/MessageBox";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Table from "../../components/Table/Table";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { TableRowItem, useList } from "../../hooks/useList";
import AddTeamModal from "../../components/Views/AddTeamModal";
import { useNavigate } from "react-router";
import CreateTournamentTeamModal from "../../components/Views/CreateTournamentTeamModal";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const headerItems: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  },
  {
    label: "group"
  },
  {
    label: "position"
  },
  {
    label: "action"
  }
];
const TournamentEditTeams: React.FC<{
  tournament: Tournament,
  changes: (tournament: Tournament) => void,
  groups: TournamentGroupStanding[],
  stages: TournamentStage[]
}> = ({ tournament, changes, groups, stages }) => {
  const { l } = useContext(ClavaContext);
  const { call, loading } = useServer(false);
  const navigator = useNavigate();
  const [del, setDel] = useState<TeamSmall>();
  const mapper = useCallback((team: TeamSmall): TableRowItem => {
    const group = groups.find(g => !!g.standings.find(st => st.team.id === team.id));
    const teamStages = stages.filter(g => g.matches.find(m => m.team1?.id === team.id || m.team2?.id === team.id));
    return {
      id: team.id,
      search: showTranslated(team.name, l).toLowerCase(),
      items: [
        { label: team.id },
        {
          label: showTranslated(team.name, l)
        },
        {
          label: tournament.cup ? (
            teamStages.map(s => showTranslated(s.name, l)).join(", ")
          ) : (group ? showTranslated(group.name, l) : translate("noGroup", l))
        },
        {
          label: tournament.cup ? "-" : (group ? group.standings.find(g => g.team.id === team.id)?.placement : translate("noGroup", l))
        },
        {
          centered: true,
          buttons: [
            {
              icon: faPencil,
              onClick: () => {
                navigator("/team/" + team.id);
              },
              type: "primary"
            },
            {
              icon: faTrash,
              onClick: () => {
                setDel(team);
              },
              type: "danger"
            }
          ]
        }
      ]
    };
  }, [tournament.cup, groups, stages, l, navigator]);
  const {
    selected,
    onCheck,
    items,
    onCheckAll,
    allSelected,
    q,
    setQ,
    setItems,
    filtered
  } = useList(mapper);
  useEffect(() => {
    setItems(tournament.teams);
  }, [setItems, tournament.teams]);
  const [add, setAdd] = useState(false);
  const toggleAdd = useCallback(() => {
    setAdd(a => !a);
  }, []);
  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);
  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("removeCupTeamCup", [id, tournament.id], "deleteFailed").then(() => {
        setItems(as => as ? as.filter(a => a.id !== id) : undefined);
        setDel(undefined);
      });
    }
  }, [del, call, tournament.id, setItems]);
  const onAddTeams = useCallback((ids: TeamListElement[]) => {
    call("addCupTeams", [tournament.id, ids.map(t => t.id)]).then(changes);
  }, [tournament.id, call, changes]);
  const onCreateTeam = useCallback((team: TournamentTeamCreate) => {
    call("createCupTeam", [tournament.id, team]).then(changes);
  }, [tournament.id, call, changes]);
  const teamIds = useMemo(() => {
    if (!items)
      return [];
    return items.map(t => t.id);
  }, [items]);
  return (
    <RoundedBlock>
      <ListBlockHeader title={"teams"} onSearch={setQ} q={q} icon={<LeagueIcon />}>
        <ClavaButton onClick={toggleAdd} lightSecondary className="mr-2">
          {translate("addTeam", l)}
        </ClavaButton>
      </ListBlockHeader>
      <LoadingBlock isLoading={loading}>
        <Table>
          <TableHeader items={headerItems} onCheckAll={onCheckAll} allChecked={allSelected} />
          <tbody>
          {filtered.map(fil => (
            <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                      checked={selected.includes(fil.id)}
                      key={"tournament-teams-" + fil.id} />
          ))}
          </tbody>
        </Table>
      </LoadingBlock>
      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? showTranslated(del.name, l) : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
      {add && tournament.cup && (
        <AddTeamModal onAdd={onAddTeams} close={toggleAdd} alreadyInIds={teamIds} />
      )}
      {add && !tournament.cup && (
        <CreateTournamentTeamModal onAdd={onCreateTeam} close={toggleAdd} />
      )}
    </RoundedBlock>
  );
};
TournamentEditTeams.displayName = "TournamentEditTeams";
export default TournamentEditTeams;
