import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ClavaSelectItems, IDType } from "../../../config/types";
import { showTranslated, translate } from "../../../config/translator";
import { ClavaContext } from "../../../config/contexts";
import { Location } from "../../../client/api";
import ClavaSelect from "../ClavaSelect";
import useServer from "../../../hooks/useServer";


const LeagueSelect: React.FC<{
  selected?: Location | IDType | undefined,
  setSelected?: (val: Location | undefined) => void;
  className?: string
  disabled?: boolean
}> = ({
        selected,
        setSelected,
        disabled,
        className
      }) => {
  const { l } = useContext(ClavaContext);
  const { call } = useServer(false);
  const [q, setQ] = useState("");
  const [locations, setLocations] = useState<Location[]>();
  useEffect(() => {
    call("searchLocations", [q === "" ? " " : q, 0, 20]).then(setLocations);
  }, [q, call]);

  const setSelectedCont = useCallback((val: string | undefined) => {
    if (setSelected) {
      if (!val || !locations) {
        setSelected(undefined);
      } else {
        const id = parseInt(val, 10);
        setSelected(locations.find((loc) => loc.id === id));
      }
    }
  }, [setSelected, locations]);
  const items = useMemo<ClavaSelectItems[]>(() => {
    if (!locations) {
      if (selected && typeof selected === "object") {
        return [{
          key: selected.id.toString(10),
          label: showTranslated(selected.name, l)
        }];
      }
      return [];
    }
    return (selected && typeof selected === "object" ? [{
      key: selected.id.toString(10),
      label: showTranslated(selected.name, l)
    }] : []).concat(locations.filter(le => !selected || typeof selected === "number" || le.id !== selected.id).map(le => ({
      key: le.id.toString(10),
      label: showTranslated(le.name, l)
    })));
  }, [l, locations, selected]);
  const realSelected = useMemo(() => {
    if (!selected)
      return undefined;
    if (typeof selected === "number") {
      return selected.toString(10);
    }
    return selected.id.toString(10);
  }, [selected]);
  return (
    <ClavaSelect uniqueId={"select-league"} className={className}
                 withSearch searchClb={setQ}
                 items={items}
                 disabled={disabled}
                 onChange={setSelectedCont}
                 value={realSelected}
                 label={translate("selectLocation", l)} />
  );
};

export default LeagueSelect;