import React, { useContext, useMemo } from "react";
import { ClavaRootContext } from "../../config/contexts";

const MatchIcon: React.FC<{ size?: number, primary?: boolean }> = ({ size, primary }) => {
  const { theme } = useContext(ClavaRootContext);
  const finalColor = useMemo(() => {
    return primary ? "#5AA0E1" : (theme === "light" ? "black" : "white");
  }, [primary, theme]);
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1504 16.5V18.6" stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
      <path
        d="M7.15039 22H17.1504V21C17.1504 19.9 16.2504 19 15.1504 19H9.15039C8.05039 19 7.15039 19.9 7.15039 21V22V22Z"
        stroke={finalColor} strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M6.15039 22H18.1504" stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
      <path
        d="M12 16C8.13 16 5 12.87 5 9V6C5 3.79 6.79 2 9 2H15C17.21 2 19 3.79 19 6V9C19 12.87 15.87 16 12 16Z"
        stroke={finalColor} strokeWidth="1.5" fill={primary ? finalColor : "none"}
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.47004 11.6501C4.72004 11.4101 4.06004 10.9701 3.54004 10.4501C2.64004 9.4501 2.04004 8.2501 2.04004 6.8501C2.04004 5.4501 3.14004 4.3501 4.54004 4.3501H5.19004C4.99004 4.8101 4.89004 5.3201 4.89004 5.8501V8.8501C4.89004 9.8501 5.10004 10.7901 5.47004 11.6501Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M18.5303 11.6501C19.2803 11.4101 19.9403 10.9701 20.4603 10.4501C21.3603 9.4501 21.9603 8.2501 21.9603 6.8501C21.9603 5.4501 20.8603 4.3501 19.4603 4.3501H18.8103C19.0103 4.8101 19.1103 5.3201 19.1103 5.8501V8.8501C19.1103 9.8501 18.9003 10.7901 18.5303 11.6501Z"
        stroke={finalColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MatchIcon;