import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PlayerPosition, PlayerSquadElement } from "../../client/api";
import { ClavaSelectItems, IDType } from "../../config/types";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { AdditionalCheck, useFormData } from "../../hooks/useFormData";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { showTranslated, translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import ClavaSelect from "../../components/Atoms/ClavaSelect";

const positionCheck: AdditionalCheck<PlayerSquadElement, "playerPosition"> = (a, b) => a.key === b.key;
const userCheck: AdditionalCheck<PlayerSquadElement, "user"> = (a, b) => a?.id === b?.id;
const TeamEditPlayer: React.FC<{ player: PlayerSquadElement, teamId: IDType }> = ({
                                                                                    player
                                                                                  }) => {
  const { l } = useContext(ClavaContext);
  const { loading, call } = useServer(false);
  const [positions, setPositions] = useState<PlayerPosition[]>([]);
  const form = useFormData(player,
    ["givenName", "familyName"],
    {
      playerPosition: positionCheck,
      user: userCheck
    },
    ["givenName", "familyName"],
    [],
    []);
  useEffect(() => {
    call("getPLayerPositions", []).then(setPositions);
  }, [call]);
  const positionItems = useMemo<ClavaSelectItems[]>(
    () => positions.map(p => ({
      key: p.key,
      label: showTranslated(p.translation, l)
    })),
    [l, positions]
  );
  const onChangePos = useCallback((val: string | undefined) => {
    const foundPos = positions.find(p => p.key === val);
    if (foundPos)
      form.setFormData(fd => ({
        ...fd,
        playerPosition: foundPos
      }));
  }, [positions, form]);
  return (
    <LoadingBlock isLoading={loading}>
      <ItemBlockHeader image={player.photo ?? faUserCircle}
                       name={`${player.givenName} ${player.familyName}`} id={player.id}
                       imageRounded />
      <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
        <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeGivenName}
                        changed={form.givenNameChanged}
                        value={form.givenName}
                        label={translate("givenName", l)} />
        <ClavaTextInput className="flex-1 mx-2" onChange={form.onChangeFamilyName}
                        changed={form.familyNameChanged}
                        value={form.familyName}
                        label={translate("familyName", l)} />
      </div>
      <div className="my-4">
        <ClavaSelect uniqueId={"player-pos"} items={positionItems} onChange={onChangePos}
                     changed={form.playerPositionChanged} label={translate("position", l)}
                     value={form.playerPosition.key} />
      </div>
    </LoadingBlock>
  );
};

export default TeamEditPlayer;