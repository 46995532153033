import { configureStore, Tuple } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { AllStates } from "./constants";
import { AllActions } from "./actions/types";
import logger from "./middleware/logger";
import { generalReducer } from "./reducer/generalReducer";

export const store = configureStore<AllStates, AllActions>({
  reducer: {
    general: generalReducer
  },
  // @ts-ignore
  middleware: () => new Tuple(thunk, logger)
});

export type RootState = ReturnType<typeof store.getState>;

export function isLoading(state: RootState): boolean {
  return (
    state.general.statusTasks === "loading" ||
    state.general.statusPositions === "loading"
  );
}
