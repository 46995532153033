import React, { useCallback, useContext, useEffect, useState } from "react";
import { TeamSmall, Tournament, TournamentGroupStanding } from "../../client/api";
import { ClavaContext } from "../../config/contexts";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { showTranslated, translate } from "../../config/translator";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LeagueIcon from "../../components/Icons/LeagueIcon";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import MessageBox from "../../components/Atoms/MessageBox";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Table from "../../components/Table/Table";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { TableRowItem, useList } from "../../hooks/useList";
import { useNavigate } from "react-router";
import useBoolState from "../../hooks/useBoolState";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";

const headerItems: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  },
  {
    label: "teams"
  },
  {
    label: "action"
  }
];
const TournamentEditGroups: React.FC<{
  tournament: Tournament,
  changes: (tournament: TournamentGroupStanding[]) => void,
  groups: TournamentGroupStanding[],
}> = ({ tournament, changes, groups }) => {
  const { l } = useContext(ClavaContext);
  const { call, loading } = useServer(false);
  const navigator = useNavigate();
  const [del, setDel] = useState<TournamentGroupStanding>();
  const mapper = useCallback((group: TournamentGroupStanding): TableRowItem => {
    return {
      id: group.id,
      search: showTranslated(group.name, l).toLowerCase(),
      items: [
        { label: group.id },
        {
          label: showTranslated(group.name, l)
        },
        {
          label: group.standings.length.toString(10)
        },
        {
          centered: true,
          buttons: [
            {
              icon: faPencil,
              onClick: () => {
                // TODO
              },
              type: "primary"
            },
            {
              icon: faTrash,
              disabled: group.standings.length !== 0,
              onClick: () => {
                setDel(group);
              },
              type: "danger"
            }
          ]
        }
      ]
    };
  }, [l]);
  const {
    selected,
    onCheck,
    onCheckAll,
    allSelected,
    q,
    setQ,
    setItems,
    filtered
  } = useList(mapper);
  useEffect(() => {
    setItems(groups);
  }, [setItems, groups]);
  const [add, toggleAdd] = useBoolState(false);
  const [addMatch, toggleAddMatch] = useBoolState(false);

  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);
  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("deleteCupGroup", [id, tournament.id], "deleteFailed").then((res) => {
        changes(res);
        setDel(undefined);
      });
    }
  }, [del, call, tournament.id, changes]);
  const onAddTeam = useCallback((id: TeamSmall) => {
    call("addTeamToCupGroup", [tournament.id, id.id]).then(changes);
  }, [tournament.id, call, changes]);
  const onRemoveTeam = useCallback((id: TeamSmall) => {
    call("removeCupTeamGroup", [tournament.id, id.id]).then(changes);
  }, [tournament.id, call, changes]);
  return (
    <RoundedBlock className="mb-2">
      <ListBlockHeader title={"groups"} onSearch={setQ} q={q} icon={<LeagueIcon />}>
        <ClavaButton onClick={toggleAdd} lightSecondary className="mr-2">
          {translate("addTeam", l)}
        </ClavaButton>
        <ClavaButton onClick={toggleAddMatch} lightSecondary className="mr-2">
          {translate("addMatch", l)}
        </ClavaButton>
      </ListBlockHeader>
      <LoadingBlock isLoading={loading}>
        <Table>
          <TableHeader items={headerItems} onCheckAll={onCheckAll} allChecked={allSelected} />
          <tbody>
          {filtered.map(fil => (
            <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                      checked={selected.includes(fil.id)}
                      key={"tournament-groups-" + fil.id} />
          ))}
          </tbody>
        </Table>
      </LoadingBlock>
      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? showTranslated(del.name, l) : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
    </RoundedBlock>
  );
};
TournamentEditGroups.displayName = "TournamentEditGroups";
export default TournamentEditGroups;
