import React, { useCallback, useMemo, useRef, useState } from "react";
import { Image } from "../../client/api";
import { nearestSize } from "../../config/utils";
import { calcMediaStyle } from "../../config/mediaUtils";
import { fb } from "../../config/firebase";

const ClavaImage: React.FC<{
  image?: Image;
  imageUrl?: string;
  width: number | string;
  className?: string;
  isEditable?: boolean;
}> = ({ image, imageUrl, width, isEditable, className }) => {
  const outerDiv = useRef<HTMLDivElement>(null);
  const [reload, setReload] = useState(128);
  const divWidth = useMemo(() => {
    if (outerDiv.current) return outerDiv.current.clientWidth;
    return reload;
  }, [reload]);

  const calcEffectiveStyle = useCallback(() => {
    setReload(r => r + 1);
  }, []);
  const w = useMemo(() => {
    if (typeof width === "string" && width.indexOf("%") !== -1) {
      return divWidth;
    }
    let wi = parseInt(width.toString(), 10);
    if (Number.isNaN(wi)) {
      wi = 1920;
    }
    return wi;
  }, [divWidth, width]);
  const cloudflareSize = useMemo(() => nearestSize(w * 20), [w]);
  const src = useMemo(() => {
    if (!image) {
      if (imageUrl) {
        fb().logEvent("image_served", { w: cloudflareSize, platform: "backoffice" });
        return `${imageUrl.replace("/public", "")}${cloudflareSize}`;
      }
      return "";
    }
    if (!image.url) {
      return "";
    }
    if (image.url.indexOf("public") === -1) return image.url;
    fb().logEvent("image_served", { w: cloudflareSize });
    return `${image.url.replace("/public", "")}${cloudflareSize}`;
  }, [image, cloudflareSize, imageUrl]);
  const containerStyle = useMemo<React.CSSProperties>(() => {
    if (image && image.cropAspectRatio) {
      return {
        width,
        aspectRatio: image.cropAspectRatio,
        overflow: "hidden",
        position: "relative"
      };
    }
    return {
      width,
      aspectRatio: image?.cropAspectRatio ?? 1,
      position: "relative"
    };
  }, [image, width]);
  const effectiveStyle = useMemo<React.CSSProperties>(() => {
    if (image) {
      return calcMediaStyle(w, image.aspectRatio, image);
    }
    return {};
  }, [w, image]);

  if ((!image || !image.url) && !imageUrl) {
    return null;
  }
  return (
    <div style={containerStyle} className={className} ref={outerDiv}>
      <div style={effectiveStyle}>
        <div className="relative">
          <img
            src={src}
            onLoad={calcEffectiveStyle}
            key={`image-${src}`}
            className={`w-100 h-100 ${isEditable ? "editable" : ""}`}
            alt="Thumb"
          />
        </div>
      </div>
    </div>
  );
};

export default ClavaImage;
