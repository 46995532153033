import { LogMessage } from "./types";

/**
 *
 * Logger für request.tx
 *
 * @param object
 */
export declare type LogType =
  | "all"
  | "appState"
  | "req"
  | "cam"
  | "response"
  | "socket"
  | "firebase"
  | "cache"
  | "redux";

export const LOG_COLORS: {
  [T in LogType]: string;
} = {
  all: "#fff",
  appState: "#45DF31",
  req: "#CD7F32",
  response: "#CD7F32",
  cam: "#00ff00",
  cache: "#45DF31",
  socket: "#5AA0E1",
  firebase: "#FF9C09",
  redux: "#EA4C89"
};
export const LOG_TYPES: LogType[] = [
  "all",
  "appState",
  "req",
  "response",
  "socket",
  "redux",
  "firebase",
  "cache",
  "cam"
];
let logId = 0;
export const loggerSettings = {
  enabled: true
};

export declare type LogElem = {
  type: LogType;
  color: string;
  time: Date;
  msg: LogMessage;
  id: number;
};
const logs: LogElem[] = [];

export function addLog(type: LogType, msg: LogMessage) {
  if (loggerSettings.enabled) {
    logs.push({
      id: logId++,
      time: new Date(),
      msg,
      color: LOG_COLORS[type] ?? "WHITE",
      type
    });
    if (logs.length > 100) logs.shift();
  }
}

export function getLog(): LogElem[] {
  return logs;
}
